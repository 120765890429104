<template>
  <q-dialog
    ref="dialog"
    transition-show="slide-up"
    transition-hide="slide-down"
    no-refocus
    @hide="hide"
  >
    <q-card class="q-dialog-plugin" style="min-width: 800px">
      <q-card-section>
        <q-card-section class="row items-center q-pa-none q-mb-sm">
          <div class="q-pa-none text-grey-10 text-h5">
            {{ $t("saetzeManager.titel") }}
          </div>
          <q-space />
          <q-btn flat round @click="hide">
            <q-icon name="mdi-close" />
          </q-btn>
        </q-card-section>
        <q-separator />
      </q-card-section>
      <q-card-section>
        <div class="row items-center">
          <q-input
            :label="$t('input.suche')"
            class="full-width"
            style="max-width: 300px"
            v-model="search"
            color="primary"
            outlined
            dense
            clearable
          >
            <template #prepend>
              <q-icon name="mdi-magnify" />
            </template>
          </q-input>
          <q-space />
          <q-checkbox
            v-model="filter"
            dense
            color="primary"
            :label="$t('saetzeManager.ausgewaehlt')"
          />
          <q-space />
        </div>
        <div style="height: 300px">
          <div v-if="loading" class="q-mt-xl text-center">
            <q-spinner size="32" />
          </div>
          <q-virtual-scroll
            v-else-if="displaySaetze.length"
            class="q-mt-md"
            style="max-height: 300px"
            :items="displaySaetze"
            separator
          >
            <template v-slot="{ item }">
              <q-item tag="label">
                <q-item-section avatar>
                  <q-checkbox
                    v-model="selected"
                    :val="item.id"
                    dense
                    color="blue"
                  />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ item.code }}</q-item-label>
                  <q-item-label caption>{{
                    $t(`${model.ITEM_KEY}.saetze.${item.code}`)
                  }}</q-item-label>
                </q-item-section>
              </q-item>
            </template>
          </q-virtual-scroll>
          <div v-else class="text-center text-grey-9 text-bold">
            {{ $t("saetzeManager.nichtGefunden") }}
          </div>
        </div>
      </q-card-section>
      <q-card-section
        class="row items-center q-gutter-x-sm justify-center q-mt-md"
      >
        <q-btn outline no-caps color="grey-7" @click="hide">{{
          $t("input.abbrechen")
        }}</q-btn>
        <q-btn color="primary" no-caps @click="selectItemsAndSave"
          >{{ $t("saetzeManager.auswaehlen", selected.length) }}
        </q-btn>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { QDialog } from "quasar";
import { missingWritePermission } from "../../utils";

export default defineComponent({
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    model: {
      type: Object,
      required: true,
    },
    api: {
      type: Object,
      required: true,
    },
    hasWritePermission: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const dialog = ref<QDialog | null>(null);

    const show = () => dialog.value!.show();
    const hide = () => dialog.value!.hide();

    const search = ref<string>("");
    const filter = ref<boolean>(false);
    const result = ref<any[]>([]);
    const loading = ref<boolean>(false);

    const fetchSaetze = async () => {
      try {
        loading.value = true;

        const { result: _result } = await props.api.fetchAll();
        result.value = _result;
      } finally {
        loading.value = false;
      }
    };

    fetchSaetze();

    const selected = ref<number[]>(props.items.map((i: any) => i.id));

    const displaySaetze = computed(() => {
      let list = filter.value
        ? selected.value.map((id) =>
            (result.value || []).find((s: any) => s.id === id)
          )
        : result.value;

      if (search.value) {
        return list.filter(
          (item: any) =>
            `${item.code}${item.satz}`
              .toLowerCase()
              .indexOf(search.value.toLowerCase()) > -1
        );
      }
      return list;
    });

    const selectItemsAndSave = async () => {
      if (props.hasWritePermission) {
        emit(
          "ok",
          selected.value.map((id) =>
            (result.value || []).find((s: any) => s.id === id)
          )
        );
        hide();
      } else {
        missingWritePermission();
      }
    };

    return {
      dialog,
      show,
      hide,
      search,
      result,
      loading,
      selected,
      displaySaetze,
      selectItemsAndSave,
      filter,
    };
  },
});
</script>

<style scoped>
.ams-data-table--kategorien {
  max-height: 400px;
}

.ams-data-table--kategorien .q-table__top,
.ams-data-table--kategorien .q-table__bottom,
.ams-data-table--kategorien thead tr:first-child th {
  background-color: white;
}

.ams-data-table--kategorien thead tr th {
  position: sticky;
  z-index: 1;
}

.ams-data-table--kategorien thead tr:first-child th {
  top: 0;
}

.ams-data-table--kategorien.q-table--loading thead tr:last-child th {
  top: 48px;
}
</style>
