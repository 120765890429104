import GefaehrdungsbeurteilungTabWrapper from "../../components/Gefaehrdungsbeurteilungen/tabs/TabWrapper.vue";
import { IColumnDefinition, IStatusDefinition } from "./types.data";
import GefaehrdungsbeurteilungCellStatus from "../../components/Gefaehrdungsbeurteilungen/cells/gefaehrdungsbeurteilung-cell-status.vue";
import GefaehrdungsbeurteilungCellName from "../../components/Gefaehrdungsbeurteilungen/cells/gefaehrdungsbeurteilung-cell-name.vue";
import GefaehrdungsbeurteilungCellKategorien from "../../components/Gefaehrdungsbeurteilungen/cells/gefaehrdungsbeurteilung-cell-kategorien.vue";
import GefaehrdungsbeurteilungCellArt from "../../components/Gefaehrdungsbeurteilungen/cells/gefaehrdungsbeurteilung-cell-art.vue";
import GefaehrdungsbeurteilungCellLetztesReview from "../../components/Gefaehrdungsbeurteilungen/cells/gefaehrdungsbeurteilung-cell-letztesReview.vue";
import GefaehrdungsbeurteilungCellReviewIntervall from "../../components/Gefaehrdungsbeurteilungen/cells/gefaehrdungsbeurteilung-cell-reviewIntervall.vue";
import GefaehrdungsbeurteilungCellNaechstesReview from "../../components/Gefaehrdungsbeurteilungen/cells/gefaehrdungsbeurteilung-cell-naechstesReview.vue";
import GefaehrdungsbeurteilungCellAnmerkungen from "../../components/Gefaehrdungsbeurteilungen/cells/gefaehrdungsbeurteilung-cell-anmerkungen.vue";
import GefaehrdungsbeurteilungCellDokumente from "../../components/Gefaehrdungsbeurteilungen/cells/gefaehrdungsbeurteilung-cell-dokumente.vue";
import GefaehrdungsbeurteilungCellStandort from "../../components/Gefaehrdungsbeurteilungen/cells/gefaehrdungsbeurteilung-cell-standort.vue";
import { useGefaehrdungsbeurteilung } from "../../services/gefaehrdungsbeurteilung.service";

export enum gefaehrdungsbeurteilungenModel {
  SINGULAR = "Gefährdungsbeurteilung",
  PLURAL = "Gefährdungsbeurteilungen",
  ROUTE = "/gefaehrdungsbeurteilungen",
  ICON = "mdi-folder-alert-outline",
  GESETZ = "https://www.gesetze-im-internet.de/arbschg/__5.html",
  ITEM_KEY = "gefaehrdungsbeurteilung",
  ITEM_KEY_UPDATE = "gefaehrdungsbeurteilungen",
  MODULE = "true",
}

export const gefaehrdungsbeurteilungArten: string[] = [
  "Betriebsmittel",
  "Gefahrstoff",
  "Tätigkeit",
  "Ort",
  "Psyche",
  "Sonstige",
];

export const defaultGefaehrdungsbeurteilungenHeader: string[] = [
  "status",
  "bezeichnung",
  // "kategorien",
  "art",
  "naechstesReview",
  "anmerkungen",
  "dokumente",
];

export const gefaehrdungsbeurteilungHeader: IColumnDefinition<any>[] = [
  {
    name: "status",
    label: "Status",
    field: "status",
    required: false,
    align: "left",
    sortable: true,
    cell: GefaehrdungsbeurteilungCellStatus,
  },
  {
    name: "bezeichnung",
    label: "Bezeichnung",
    field: "name",
    required: false,
    align: "left",
    sortable: true,
    cell: GefaehrdungsbeurteilungCellName,
  },
  {
    name: "kategorien",
    label: "Kategorien",
    field: "kategorien",
    required: false,
    align: "left",
    sortable: false,
    cell: GefaehrdungsbeurteilungCellKategorien,
  },
  {
    name: "art",
    label: "Art",
    field: "art",
    required: false,
    align: "left",
    sortable: false,
    cell: GefaehrdungsbeurteilungCellArt,
  },
  {
    name: "letztesReview",
    label: "Letztes Review",
    field: "letztesReview",
    required: false,
    align: "left",
    sortable: true,
    cell: GefaehrdungsbeurteilungCellLetztesReview,
  },
  {
    name: "reviewIntervall",
    label: "Überprüfen in",
    field: "reviewIntervall",
    required: false,
    align: "left",
    sortable: true,
    cell: GefaehrdungsbeurteilungCellReviewIntervall,
  },
  {
    name: "naechstesReview",
    label: "Nächstes Review",
    field: "naechstesReview",
    required: false,
    align: "left",
    sortable: true,
    cell: GefaehrdungsbeurteilungCellNaechstesReview,
  },
  {
    name: "anmerkungen",
    label: "Anmerkungen",
    field: "anmerkungen",
    required: false,
    align: "left",
    sortable: true,
    cell: GefaehrdungsbeurteilungCellAnmerkungen,
  },
  {
    name: "dokumente",
    label: "Dokumente",
    field: "dokumente",
    required: false,
    align: "left",
    sortable: false,
    cell: GefaehrdungsbeurteilungCellDokumente,
  },
  {
    name: "standort",
    label: "Standort",
    required: false,
    align: "left",
    sortable: false,
    cell: GefaehrdungsbeurteilungCellStandort,
  },
];

export enum GefaehrdungsbeurteilungStatus {
  FREIGEGEBEN = "Freigegeben",
  IN_ARBEIT = "In Arbeit",
  OFFEN = "Offen",
  PRUEFUNG_ERFORDERLICH = "Prüfung erforderlich",
  KEIN_ERGEBNIS = "Kein Ergebnis",
}

export const gefaehrdungsbeurteilungStatus: {
  [key: string]: IStatusDefinition<GefaehrdungsbeurteilungStatus>;
} = {
  [GefaehrdungsbeurteilungStatus.FREIGEGEBEN]: {
    label: GefaehrdungsbeurteilungStatus.FREIGEGEBEN,
    icon: "mdi-check-outline",
    color: "green",
    val: GefaehrdungsbeurteilungStatus.FREIGEGEBEN,
  },
  [GefaehrdungsbeurteilungStatus.IN_ARBEIT]: {
    label: GefaehrdungsbeurteilungStatus.IN_ARBEIT,
    icon: "mdi-alert-outline",
    color: "orange",
    val: GefaehrdungsbeurteilungStatus.IN_ARBEIT,
  },
  [GefaehrdungsbeurteilungStatus.OFFEN]: {
    label: GefaehrdungsbeurteilungStatus.OFFEN,
    icon: "mdi-alert-circle-outline",
    color: "red",
    val: GefaehrdungsbeurteilungStatus.OFFEN,
  },
  [GefaehrdungsbeurteilungStatus.PRUEFUNG_ERFORDERLICH]: {
    label: GefaehrdungsbeurteilungStatus.PRUEFUNG_ERFORDERLICH,
    icon: "mdi-clock-outline",
    color: "primary",
    val: GefaehrdungsbeurteilungStatus.PRUEFUNG_ERFORDERLICH,
  },
  [GefaehrdungsbeurteilungStatus.KEIN_ERGEBNIS]: {
    label: GefaehrdungsbeurteilungStatus.KEIN_ERGEBNIS,
    icon: "mdi-close-outline",
    color: "grey",
    val: GefaehrdungsbeurteilungStatus.KEIN_ERGEBNIS,
  },
};

export const gefaehrdungsbeurteilungData = {
  model: gefaehrdungsbeurteilungenModel,
  api: useGefaehrdungsbeurteilung(),
  header: gefaehrdungsbeurteilungHeader,
  defaultHeader: defaultGefaehrdungsbeurteilungenHeader,
  tabWrapper: GefaehrdungsbeurteilungTabWrapper,
};
