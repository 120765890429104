<template>
  <q-dialog
    ref="dialog"
    transition-show="slide-up"
    transition-hide="slide-down"
    @hide="hide"
  >
    <q-card class="q-dialog-plugin" style="min-width: 500px">
      <q-card-section>
        <q-card-section class="row items-center q-pa-none q-mb-sm">
          <div class="q-pa-none text-grey-10 text-h5">
            {{ $t(`${model.ITEM_KEY}.exportieren`) }}
          </div>
          <q-space />
          <q-btn flat round @click="hide">
            <q-icon name="mdi-close" />
          </q-btn>
        </q-card-section>
        <q-separator />
      </q-card-section>
      <q-card-section class="q-pa-md">
        <div>
          <div class="text-bold text-grey-9">
            {{
              $t("exportManager.exportText", $t(`${model.ITEM_KEY}.name`, 2))
            }}
          </div>
          <q-input
            style="max-width: 400px"
            outlined
            dense
            label="Suche"
            class="q-mt-md"
            clearable
            v-model="unitSearch"
          >
            <template #prepend>
              <q-icon name="mdi-magnify" />
            </template>
          </q-input>
          <div v-if="unitLoading" class="text-center">
            <q-spinner class="q-mt-xl q-mb-lg" size="32px" />
          </div>
          <q-virtual-scroll
            class="q-mt-md"
            style="max-height: 300px"
            :items="units"
            separator
          >
            <template v-slot="{ item }">
              <q-item :key="item.id" tag="label">
                <q-item-section avatar>
                  <q-radio v-model="unitId" :val="item.id" color="blue" dense />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ item.name || "-" }}</q-item-label>
                  <q-item-label caption>{{ item.ort || "-" }}</q-item-label>
                </q-item-section>
              </q-item>
            </template>
          </q-virtual-scroll>
          <div class="q-mt-md text-grey-9 text-bold">
            {{ $t("standortManager.aktiveStandorte") }}
          </div>
        </div>
      </q-card-section>
      <q-card-section class="row justify-end">
        <q-btn
          no-caps
          color="primary"
          :disable="!unitId"
          @click="exportItems"
          >{{ $t("exportManager.exportieren") }}</q-btn
        >
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { QDialog } from "quasar";
import { useUnits } from "../hooks";

defineProps<{ model: { ITEM_KEY: string } }>();
const emit = defineEmits(["ok"]);

const dialog = ref<QDialog | null>(null);

const hide = () => dialog.value!.hide();

const unitId = ref("");

const { getUnits, unitSearch, unitLoading, units } = useUnits(true);

getUnits();

const exportItems = () => {
  hide();
  emit("ok", unitId.value);
};
</script>
