<template>
  <q-btn flat :color="unitsSelected ? 'grey-7' : 'red'" no-wrap no-caps>
    <q-icon :name="unitsSelected ? 'mdi-apps' : 'mdi-alert'" left />
    {{ appSwitcherLabel }}
    <q-menu v-model="unitMenu" persistent @show="fetchCompaniesAndUnits">
      <q-card style="width: 500px" class="q-pa-md rounded-corners">
        <div class="row items-center">
          <div class="text-h6">{{ $t("standort.appswitcher.header") }}</div>
          <q-space />
          <q-btn size="12px" flat round @click="unitMenu = false">
            <q-icon name="mdi-close" />
          </q-btn>
        </div>
        <div class="text-grey-7">
          {{ $t("standort.appswitcher.description") }}
        </div>
        <div class="text-grey-7">
          {{ $t("standort.appswitcher.companyDescription") }}
        </div>
        <q-checkbox
          class="q-mt-md"
          dense
          color="grey-7"
          :label="$t('standort.appswitcher.auswaehlen')"
          :model-value="checkbox"
          @update:model-value="updateSelectedUnits"
        />
        <div class="row items-center no-wrap q-mt-md">
          <q-input
            class="full-width"
            outlined
            dense
            :label="$t('standort.appswitcher.search')"
            clearable
            autocomplete="off"
            debounce="400"
            v-model="search"
            @clear="search = ''"
          >
            <template #prepend>
              <q-icon name="mdi-magnify" />
            </template>
          </q-input>
          <q-btn
            icon="mdi-plus-circle-outline"
            :label="$t('standort.appswitcher.createCompany')"
            outline
            color="primary"
            no-caps
            no-wrap
            class="q-ml-sm"
            @click.stop
          >
            <q-menu ref="firmenMenu" separate-close-popup :offset="[0, 10]">
              <div class="row items-center no-wrap q-pa-md">
                <q-input
                  v-close-popup
                  :placeholder="$t('standort.appswitcher.companyName')"
                  autofocus
                  style="width: 300px"
                  v-model="firma"
                  dense
                  outlined
                  @keydown.enter="createCompany"
                >
                  <template #prepend>
                    <q-icon name="mdi-bank-outline" />
                  </template>
                </q-input>
                <q-btn
                  v-close-popup
                  outline
                  round
                  color="primary"
                  :disable="!firma"
                  dense
                  size="14px"
                  class="q-ml-md"
                  @click.stop="createCompany"
                >
                  <q-icon name="mdi-check" size="22px" />
                </q-btn>
                <q-btn
                  v-close-popup
                  outline
                  round
                  color="grey-7"
                  dense
                  size="14px"
                  class="q-ml-xs"
                  @click.stop
                >
                  <q-icon name="mdi-close" size="22px" />
                </q-btn>
              </div>
            </q-menu>
          </q-btn>
        </div>
        <div v-if="loading" class="text-center">
          <q-spinner class="q-mt-xl q-mb-lg" size="32px" />
        </div>
        <div v-else-if="companiesOrUnits.length">
          <q-list class="q-mt-md">
            <q-expansion-item
              ref="list"
              v-for="(company, index) in companyList"
              :key="company.id"
              class="q-my-xs"
              @dragover="openExpansionItem(index)"
            >
              <template #header>
                <q-item-section avatar>
                  <q-checkbox
                    v-model="selectedCompanyIds"
                    :val="company.id"
                    color="blue"
                    dense
                    @update:model-value="updateCompanySelection(company)"
                  />
                </q-item-section>
                <q-item-section avatar>
                  <q-icon name="mdi-bank-outline" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ company.name }}</q-item-label>
                  <q-item-label caption
                    >{{ $t("standort.name", 2) }}:
                    {{ company.units?.length ?? 0 }}</q-item-label
                  >
                </q-item-section>
                <q-item-section side>
                  <div class="row items-center">
                    <q-btn
                      size="12px"
                      flat
                      round
                      @click.stop="firma = company.name"
                    >
                      <q-icon name="mdi-pencil" />
                      <q-tooltip>{{
                        $t("standort.appswitcher.editCompany")
                      }}</q-tooltip>
                      <q-menu separate-close-popup :offset="[0, 10]">
                        <div class="row items-center no-wrap q-pa-md">
                          <q-input
                            :placeholder="
                              $t('standort.appswitcher.companyName')
                            "
                            autofocus
                            style="width: 300px"
                            v-model="firma"
                            dense
                            outlined
                            v-close-popup
                            @keydown.enter="updateCompanyName(company.id)"
                          >
                            <template #prepend>
                              <q-icon name="mdi-bank-outline" />
                            </template>
                          </q-input>
                          <q-btn
                            v-close-popup
                            outline
                            round
                            color="primary"
                            :disable="!firma"
                            dense
                            size="14px"
                            class="q-ml-md"
                            @click.stop="updateCompanyName(company.id)"
                          >
                            <q-icon name="mdi-check" size="22px" />
                          </q-btn>
                          <q-btn
                            v-close-popup
                            outline
                            round
                            color="grey-7"
                            dense
                            size="14px"
                            class="q-ml-xs"
                          >
                            <q-icon name="mdi-close" size="22px" />
                          </q-btn>
                        </div>
                      </q-menu>
                    </q-btn>
                    <q-btn
                      size="12px"
                      flat
                      round
                      @click.stop="deleteCompany(company.id)"
                    >
                      <q-icon name="mdi-delete-outline" />
                      <q-tooltip>{{
                        $t("standort.appswitcher.editCompany")
                      }}</q-tooltip>
                    </q-btn>
                  </div>
                </q-item-section>
              </template>
              <div
                v-if="!company.units?.length"
                class="row items-center text-grey-7 text-bold justify-center q-mt-md"
              >
                <q-icon name="mdi-inbox-arrow-down" size="20px" left />
                {{ $t("standort.appswitcher.dragAndDrop") }}
              </div>
              <draggable
                class="q-ml-lg list-group"
                :style="`min-height: 40px; border: 2px dashed ${
                  dragging ? '#027be3' : 'white'
                }; border-radius: 5px`"
                group="units"
                :list="company.units ?? []"
                @start="dragging = true"
                @end="dragging = false"
                @add="updateCompany(company)"
                @remove="updateCompany(company)"
              >
                <q-item
                  v-for="unit in company.units"
                  :key="unit.id"
                  class="q-my-sm"
                  style="
                    border-radius: 10px;
                    border: 1px solid lightgrey;
                    cursor: all-scroll;
                  "
                  dense
                >
                  <q-item-section avatar>
                    <q-checkbox
                      v-model="selectedUnits"
                      :val="unit.id"
                      color="blue"
                      dense
                      @update:model-value="updateSelection"
                    />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>{{ unit.name || "" }}</q-item-label>
                    <q-item-label caption>{{ unit.ort || "" }}</q-item-label>
                  </q-item-section>
                </q-item>
              </draggable>
            </q-expansion-item>
          </q-list>
          <draggable
            class="list-group"
            group="units"
            :list="unitList"
            :style="`min-height: 40px; border: 2px dashed ${
              dragging ? '#027be3' : 'white'
            }; border-radius: 5px`"
            @start="dragging = true"
            @end="dragging = false"
          >
            <q-item
              v-for="unit in unitList"
              :key="unit.id"
              dense
              class="q-my-sm"
              style="
                border-radius: 10px;
                border: 1px solid lightgrey;
                cursor: all-scroll;
              "
            >
              <q-item-section avatar>
                <q-checkbox
                  v-model="selectedUnits"
                  :val="unit.id"
                  color="blue"
                  dense
                  @update:model-value="updateSelection"
                />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ unit.name || "" }}</q-item-label>
                <q-item-label caption>{{ unit.ort || "" }}</q-item-label>
              </q-item-section>
            </q-item>
          </draggable>
        </div>
        <div
          v-else
          class="text-center text-subtitle2 text-grey-7 text-bold q-my-lg"
        >
          {{ $t("standort.appswitcher.noUnits") }}
          <div class="q-mt-sm">
            <q-btn
              outline
              color="primary"
              no-caps
              @click="$router.push('/verwaltung/standorterstellen')"
            >
              <q-icon name="mdi-plus-box" left />{{
                $t("standort.appswitcher.createUnit")
              }}
            </q-btn>
          </div>
        </div>
      </q-card>
    </q-menu>
  </q-btn>
</template>

<script lang="ts" setup>
import { useUnits } from "../../hooks";
import { computed, ref, watch } from "vue";
import { useStore } from "../../store";
import { Notify, QExpansionItem } from "quasar";
import { VueDraggableNext as Draggable } from "vue-draggable-next";
import { confirmDelete, searchKey } from "../../utils";
import client from "../../services";
import { GetAsTreeQuery } from "@/types/graphql.types";
import { useI18n } from "vue-i18n";

type CompanyOrUnit = GetAsTreeQuery["getAsTree"][number];
type Company = CompanyOrUnit & {
  __typename: "Company";
};
type Unit = CompanyOrUnit & {
  __typename: "Unit";
};

const isCompany = (companyOrUnit: CompanyOrUnit): companyOrUnit is Company =>
  companyOrUnit.__typename === "Company";
const isUnit = (companyOrUnit: CompanyOrUnit): companyOrUnit is Unit =>
  companyOrUnit.__typename === "Unit";

const store = useStore();
const { t } = useI18n();

const unitMenu = ref(false);
const companiesOrUnits = ref<CompanyOrUnit[]>([]);
const selectedCompanyIds = ref<string[]>([]);
const dragging = ref(false);
const loading = ref(false);
const search = ref("");

const list = ref<QExpansionItem[]>([]);
const unitList = ref<Unit[]>([]);
const companyList = ref<Company[]>([]);
const firma = ref("");

const updateCompanySelection = (company: Company) => {
  if (selectedCompanyIds.value.includes(company.id)) {
    company.units?.forEach((unit) => {
      if (!selectedUnits.value.includes(unit.id)) {
        selectedUnits.value = [...selectedUnits.value, unit.id];
      }
    });
  } else {
    selectedUnits.value = selectedUnits.value.filter(
      (unitId) => !company.units?.some((unit) => unit.id === unitId)
    );
  }
  updateSelection();
};

const unitsSelected = computed<boolean>(() => !!store.currentUnitIds.length);

const checkbox = computed<boolean | null>(() => {
  // TODO: warum null?
  if (!selectedUnits.value.length) return false;

  const unitsLength = companiesOrUnits.value.reduce(
    (a, b) => a + (isCompany(b) ? b.units?.length ?? 0 : 1),
    0
  );
  return selectedUnits.value.length === unitsLength ? true : null;
});

const updateSelectedUnits = () => {
  if (checkbox.value === null || checkbox.value) {
    selectedCompanyIds.value = [];
    selectedUnits.value = [];
  } else {
    selectedCompanyIds.value = companiesOrUnits.value
      .filter(isCompany)
      .map((company) => company.id);
    companiesOrUnits.value.forEach((companyOrUnit) => {
      if (isCompany(companyOrUnit)) {
        const units = companyOrUnit.units || [];
        selectedUnits.value = [
          ...selectedUnits.value,
          ...units.map((unit) => unit.id),
        ];
      } else {
        selectedUnits.value = [...selectedUnits.value, companyOrUnit.id];
      }
    });
  }

  updateSelection();
};

const fetchCompaniesAndUnits = async () => {
  try {
    loading.value = true;

    const { getAsTree } = await client.GetAsTree();
    companiesOrUnits.value = getAsTree;

    companyList.value = companiesOrUnits.value
      .filter(isCompany)
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    unitList.value = companiesOrUnits.value
      .filter(isUnit)
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    companiesOrUnits.value.forEach((companyOrUnit) => {
      if (!isCompany(companyOrUnit)) return;

      const id = companyOrUnit.id;
      const units = companyOrUnit.units || [];
      if (
        store.currentUnitIds.filter((unitId: string) =>
          units.some((unit) => unit.id === unitId)
        ).length
      ) {
        selectedCompanyIds.value = [...selectedCompanyIds.value, id];
      }
    });
  } catch {
    Notify.create({
      type: "ams-error",
      message: t("standort.notifications.errorLoadingCompanies"),
    });
  } finally {
    loading.value = false;
  }
};
// TODO: Somewhere in here is a bug so that the drop area does not close
// TODO: Search show also company where the unit is in?
// TODO: Refactor this
watch(
  () => search.value,
  () => {
    if (search.value) {
      // TODO: das ist etwas dirty
      companyList.value = companiesOrUnits.value
        .filter(isCompany) //TODO: Das ist neu, warum wird companiesOrUnits nicht typesafe verwendet
        .map((company): Company => {
          if (company.units) {
            return {
              ...company,
              units: searchKey(search.value, "name", company.units),
            };
          }
          return company;
        })
        .filter((company, index) => {
          if (company.units?.length) {
            list.value[index]?.show();
            return true;
          }
          return false;
        });
    } else {
      companyList.value = companiesOrUnits.value.filter(isCompany);
    }

    unitList.value = companiesOrUnits.value.filter(
      (companyOrUnit): companyOrUnit is Unit => {
        if (!isUnit(companyOrUnit)) return false;

        const unit = companyOrUnit;
        return unit.name.toLowerCase().indexOf(search.value.toLowerCase()) > -1;
      }
    );

    if (!search.value) {
      companyList.value.map((_, index: number) => list.value[index]?.hide());
    }
  }
);

const createCompany = async () => {
  try {
    await client.CreateCompany({
      name: firma.value,
      units: [],
    });

    await fetchCompaniesAndUnits();

    Notify.create({
      type: "ams-success",
      message: t("standort.notifications.successfulCreateCompany"),
    });
  } catch {
    Notify.create({
      type: "ams-error",
      message: t("standort.notifications.errorCreation"),
    });
  } finally {
    firma.value = "";
  }
};

const { units, getUnits, selectedUnits, changeCurrentUnit } = useUnits();
const updateSelection = async () => {
  await changeCurrentUnit();
  await client.UpdateMe({
    updateUser: {
      defaultUnits: selectedUnits.value,
    },
  });
  store.changeUnit();
};

getUnits();

const appSwitcherLabel = computed(() => {
  if (!store.currentUnitIds.length) {
    return t("standort.select", 2);
  }

  if (store.currentUnitIds.length === 1) {
    return (
      units.value.find((u: any) => u.id === store.currentUnitIds[0])?.name ??
      t("standort.name", 2)
    );
  }

  return t("standort.name", 2);
});

const openExpansionItem = (index: number) => {
  list.value[index].show();
};

const updateCompany = async (company: Company) => {
  try {
    await client.UpdateCompany({
      id: company.id,
      units: (company.units ?? []).map((unit) => unit.id),
    });

    Notify.create({
      type: "ams-success",
      message: t("standort.notifications.successfulUpdateCompany"),
    });
  } catch {
    Notify.create({
      type: "ams-error",
      message: t("standort.notifications.errorUpdate"),
    });
  }
};

const updateCompanyName = async (companyId: string) => {
  try {
    await client.UpdateCompany({
      id: companyId,
      name: firma.value,
    });

    await fetchCompaniesAndUnits();

    Notify.create({
      type: "ams-success",
      message: t("standort.notifications.successfulUpdateCompany"),
    });
  } catch {
    Notify.create({
      type: "ams-error",
      message: t("standort.notifications.errorUpdate"),
    });
  } finally {
    firma.value = "";
  }
};

const deleteCompany = (id: string) => {
  confirmDelete(
    t("standort.notifications.deleteCompany"),
    t("standort.notifications.deleteCompanyConfirmation"),
    async () => {
      try {
        await client.DeleteCompany({
          id,
        });
        await fetchCompaniesAndUnits();

        Notify.create({
          type: "ams-success",
          message: t("standort.notifications.successfulDeleteCompany"),
        });
      } catch {
        Notify.create({
          type: "ams-error",
          message: t("standort.notifications.errorDelete"),
        });
      }
    }
  );
};
</script>
