import { defineStore } from "pinia";

export const useBrandingStore = defineStore("branding", {
  state: () => ({
    company: "AMS-Pro",
    logo: new URL("../assets/logo.svg", import.meta.url).href,
    favicon: "favicon.ico",
  }),
  getters: {
    isLogoAnimated: (state) => state.company === "AMS-Pro",
    showSocials: (state) => state.company === "AMS-Pro",
  },
  actions: {
    setupBranding() {
      if (window.location.origin.includes("company-protect") || import.meta.env.VITE_COMPANY === "company-protect") {
        this.company = "Company Protect";
        this.logo = new URL("../assets/company-protect/logo.png", import.meta.url).href;
        this.favicon = "cp-favicon.ico";
      }
      console.log("Branding setup", this.company);
    },
  },
});
