<template>
  <div>
    <div class="text-h6 text-bold text-grey-9">
      {{ $t("gefahrstoff.informationen.allgemein") }}
    </div>
    <div class="row q-gutter-x-md q-mt-md">
      <div class="col q-gutter-y-md">
        <ams-input
          :label="$t('gefahrstoff.bezeichnung')"
          :value="data.name"
          @input="updateInput('name', $event)"
          icon="mdi-wrench-outline"
        />
        <q-select
          id="test"
          :label="$t('gefahrstoff.kategorien')"
          color="primary"
          outlined
          dense
          :model-value="kategorieValue"
          @focus="openKategorieManager"
        >
          <template #before>
            <q-icon name="mdi-bookmark" />
          </template>
        </q-select>
        <q-select
          :label="$t('gefahrstoff.signalwort')"
          color="primary"
          outlined
          dense
          :model-value="data.signalwort ? $t(`status.${data.signalwort}`) : ''"
          :options="gefahrstoffSignalwoerter"
          @update:model-value="updateInput('signalwort', $event)"
        >
          <template #before>
            <q-icon name="mdi-alert" />
          </template>
          <template #option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section>
                <q-item-label>{{ $t(`status.${scope.opt}`) }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>
      <div class="col q-gutter-y-md">
        <q-select
          :label="$t('gefahrstoff.raum')"
          color="primary"
          outlined
          dense
          :model-value="raumValue"
          @focus="openRaumManager"
        >
          <template #before>
            <q-icon name="mdi-garage" />
          </template>
        </q-select>
        <q-select
          :label="$t('gefahrstoff.lagerklasse')"
          color="primary"
          outlined
          dense
          :model-value="data.lagerklasse"
          :options="gefahrstoffLagerklassen"
          @update:model-value="updateInput('lagerklasse', $event.label)"
        >
          <template #before>
            <q-icon name="mdi-barrel" />
          </template>
          <template #option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section avatar>
                <q-icon name="mdi-barrel" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ scope.opt.label }}</q-item-label>
                <q-item-label caption>{{
                  $t(`gefahrstoff.lagerklassen["${scope.opt.label}"]`)
                }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-select>
        <q-input
          :label="`${$t('gefahrstoff.zusammenlagerung')} (${$t(
            'input.automatischGeneriert'
          )})`"
          outlined
          color="primary"
          :model-value="
            zusammenlagerungValue
              ? $t(`status.${zusammenlagerungValue.val}`)
              : ''
          "
          dense
          readonly
        >
          <template #before>
            <q-btn
              round
              outline
              :color="zusammenlagerungValue.color"
              dense
              size="12px"
            >
              <q-icon :name="zusammenlagerungValue.icon" size="18px" />
              <q-menu
                v-if="
                  currentZusammenlagerung
                    ? $t(`status.${currentZusammenlagerung.val}`)
                    : $t(`status.${'Raum oder Lagerklasse unbekannt'}`)
                "
              >
                <div class="q-pa-md">
                  <div class="row items-center">
                    <div class="text-subtitle1 text-bold">
                      {{ separatlagerungPhrase }}
                    </div>
                    <q-space />
                    <q-btn
                      class="q-ml-lg"
                      round
                      flat
                      dense
                      size="12px"
                      @click.stop="
                        openURL(
                          'https://ams-public-assets.s3.eu-central-1.amazonaws.com/TRGS510.png'
                        )
                      "
                    >
                      <q-icon name="mdi-information-outline" size="24px" />
                      <q-tooltip>
                        {{ $t("gefahrstoff.zusammenlagerungTabelle") }}
                      </q-tooltip>
                    </q-btn>
                  </div>
                  <q-separator class="q-my-sm" />
                  <q-list separator dense style="min-width: 400px">
                    <q-item
                      v-for="item in separatlagerungen"
                      :key="item.id"
                      dense
                    >
                      <q-item-section avatar>
                        <q-icon name="mdi-flask" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>{{ item.name }}</q-item-label>
                        <q-item-label caption
                          >{{ $t("gefahrstoff.lagerklasse") }}:
                          {{ item.lagerklasse }}</q-item-label
                        >
                      </q-item-section>
                      <q-item-section side>
                        <q-btn
                          flat
                          round
                          size="12px"
                          @click="openNewTab(item.id)"
                        >
                          <q-icon name="mdi-open-in-new" size="24px" />
                          <q-tooltip>{{
                            $t(`input.imTabOeffnen`, [$t(`gefahrstoff.name`)])
                          }}</q-tooltip>
                        </q-btn>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </div>
              </q-menu>
            </q-btn>
          </template>
          <template #append>
            <q-btn
              round
              flat
              dense
              size="12px"
              @click.stop="
                openURL(
                  'https://ams-public-assets.s3.eu-central-1.amazonaws.com/TRGS510.png'
                )
              "
            >
              <q-icon name="mdi-information-outline" size="24px" />
              <q-tooltip>{{
                $t("gefahrstoff.zusammenlagerungTabelle")
              }}</q-tooltip>
            </q-btn>
          </template>
        </q-input>
      </div>
    </div>
    <div class="text-h6 text-bold text-grey-9 q-mt-lg">
      {{ $t("gefahrstoff.informationen.verbrauchsmengen") }}
    </div>
    <div class="row q-gutter-x-md q-mt-md">
      <div class="col q-gutter-y-md">
        <ams-input
          :label="$t('gefahrstoff.menge')"
          :value="data.menge!"
          type="number"
          @input="updateInput('menge', parseFloat($event))"
          icon="mdi-database"
        />
        <ams-input
          :label="$t('gefahrstoff.verwendungszweck')"
          :value="data.zweck!"
          type="textarea"
          icon="mdi-briefcase-edit"
          input-style="min-height: 80px"
          @input="updateInput('zweck', $event)"
        />
      </div>
      <div class="col q-gutter-y-md">
        <ams-select
          :label="$t('gefahrstoff.mengeneinheit')"
          :model-value="data.mengeneinheit"
          icon="mdi-ruler"
          :options="gefahrstoffeMengeneinheiten"
          @update:model-value="updateInput('mengeneinheit', $event)"
        />
        <ams-input
          :label="$t('gefahrstoff.anmerkungen')"
          :value="data.anmerkungen!"
          type="textarea"
          icon="mdi-square-edit-outline"
          input-style="min-height: 80px"
          @input="updateInput('anmerkungen', $event)"
        />
      </div>
    </div>
    <div class="text-h6 text-bold text-grey-9 q-mt-lg">
      {{ $t("gefahrstoff.informationen.freigabe") }}
    </div>
    <div class="row q-gutter-x-md q-mt-md">
      <div class="col q-gutter-y-md">
        <ams-status-input
          :label="$t('gefahrstoff.status')"
          :value="statusValue"
          :statusOptions="gefahrstoffeStatus"
          @input="updateInput('status', $event)"
        />
      </div>
      <div class="col q-gutter-y-md">
        <AmsFreigebenderInput :freigebender="data.freigebender!" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { Dialog } from "quasar";
import AmsStatusInput from "../../../components/forms/AmsStatusInput.vue";
import AmsInput from "../../../components/forms/AmsInput.vue";
import {
  gefahrstoffeModel as model,
  gefahrstoffeStatus,
  gefahrstoffeZusammenlagerung,
  gefahrstoffSignalwoerter,
  gefahrstoffLagerklassen,
  gefahrstoffeMengeneinheiten,
  GefahrstoffStatus,
  kategorienModel,
  raumModel,
  GefahrstoffZusammenlagerung,
} from "../../../data";
import { trim } from "../../../utils";
import AmsSelect from "../../../components/forms/AmsSelect.vue";
import RelationManagerDialog from "../../../components/RelationManager/RelationManagerDialog.vue";
import { useKategorie } from "../../../services/kategorie.service";
import { useRaum } from "../../../services/raum.service";
import { openURL } from "quasar";
import AmsFreigebenderInput from "../../../components/forms/AmsFreigebender.vue";
import { hashids } from "../../../services/hashids.service";
import type { Gefahrstoff, Raum } from "src/types/graphql.types";
import i18n from "src/i18n";

const props = defineProps<{
  data: Gefahrstoff;
  hasWritePermission?: boolean;
}>();
const emit = defineEmits([
  "updateInput",
  "update:data",
  "updateVariables",
  "save",
]);

const updateInput = (origin: string, val: any) => {
  emit("updateInput", { origin, val });
  emit("update:data", { ...props.data, [origin]: val });
};

const updateKategorien = (origin: string, val: any[]) => {
  emit("updateVariables", { origin, val: val.map((v) => v.id) });
  emit("update:data", { ...props.data, [origin]: val });
};

const updateRaum = (items: Raum[]) => {
  const val = items.length ? items[0] : { id: 0 };
  emit("updateInput", { origin: "raumId", val: val.id });
  emit("update:data", { ...props.data, raum: val });
};

const kategorieValue = computed(() =>
  props.data.kategorien && props.data.kategorien.length
    ? trim(props.data.kategorien.map((k: any) => k.name).join(", "), 40)
    : ""
);

const raumValue = computed(() => props.data.raum?.name ?? "");

const statusValue = computed(
  () =>
    gefahrstoffeStatus[
      (props.data.status as GefahrstoffStatus) ||
        GefahrstoffStatus.KEIN_ERGEBNIS
    ]
);

const zusammenlagerungValue = computed(
  () =>
    gefahrstoffeZusammenlagerung[
      (props.data.zusammenlagerung as GefahrstoffZusammenlagerung) ||
        GefahrstoffZusammenlagerung.UNBEKANNT
    ]
);

const openKategorieManager = () => {
  Dialog.create({
    component: RelationManagerDialog,
    componentProps: {
      api: useKategorie(),
      model: kategorienModel,
      preSelected: props.data.kategorien,
      unitId: props.data.unit.id,
      hasWritePermission: props.hasWritePermission,
    },
  }).onOk((items: any[]) => {
    updateKategorien("kategorien", items);
  });
};

const openRaumManager = () => {
  Dialog.create({
    component: RelationManagerDialog,
    componentProps: {
      api: useRaum(),
      model: raumModel,
      preSelected: props.data.raum ? [props.data.raum] : [],
      unitId: props.data.unit.id,
      selection: "single",
      hasWritePermission: props.hasWritePermission,
    },
  }).onOk((items: Raum[]) => {
    updateRaum(items);
  });
};

const currentZusammenlagerung = computed(
  () =>
    gefahrstoffeZusammenlagerung[
      props.data.zusammenlagerung as GefahrstoffZusammenlagerung
    ]
);

const separatlagerungen = computed(() => {
  if (props.data.separatlagerungErforderlich?.length) {
    return props.data.separatlagerungErforderlich;
  } else if (props.data.zusammenlagerungEingeschraenkt?.length) {
    return props.data.zusammenlagerungEingeschraenkt;
  } else {
    return [];
  }
});

const separatlagerungPhrase = computed(() => {
  if (props.data.separatlagerungErforderlich?.length) {
    return i18n.global.t(`gefahrstoff.separatlagerungErforderlich`, [
      props.data.raum?.name,
    ]);
  } else if (props.data.zusammenlagerungEingeschraenkt?.length) {
    return i18n.global.t(`gefahrstoff.zusammenlagerungEingeschraenkt`, [
      props.data.raum?.name,
    ]);
  } else {
    return i18n.global.t(`gefahrstoff.zusammenlagerungErlaubt`, [
      props.data.raum?.name,
    ]);
  }
});

const openNewTab = (id: string) => {
  window.open(
    `${window.location.protocol}//${window.location.host}${
      model.ROUTE
    }/${hashids.encode(id)}`,
    "_blank"
  );
};
</script>
