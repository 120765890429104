<template>
  <q-td :props="props" :key="colKey" class="text-grey-8">
    <ams-base-cell-kategorien
      :props="props"
      :api="api"
      :hasWritePermission="hasWritePermission"
      @updateData="$emit('updateData', $event)"
    />
  </q-td>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useBetriebsanweisung } from "../../../services/betriebsanweisung.service";
import { betriebsanweisungenModel as model } from "../../../data";
import BaseCellKategorien from "../../../components/BaseCells/BaseCellKategorien.vue";

export default defineComponent({
  components: {
    "ams-base-cell-kategorien": BaseCellKategorien,
  },
  props: {
    props: {
      type: Object,
      required: true,
    },
    colKey: {
      type: String,
      required: true,
    },
    hasWritePermission: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const api = useBetriebsanweisung();

    return {
      api,
      model,
    };
  },
});
</script>
