<template>
  <q-list>
    <q-item v-for="n in 5" :key="n">
      <q-item-section avatar>
        <q-skeleton type="rect" height="20px" width="20px" />
      </q-item-section>
      <q-item-section>
        <q-skeleton type="text" />
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({});
</script>
