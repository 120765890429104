<template>
  <div>
    <div class="row">
      <div class="col-8">
        <div class="row items-center text-bold">
          <q-icon
            name="mdi-information-outline"
            color="grey-8"
            size="20px"
            left
          />{{ $t('verwaltung.passwortaendern.requirements') }}
        </div>
        <ul class="q-mt-sm q-mx-md">
          <li>{{ $t('verwaltung.passwortaendern.atLeast8Chars') }}</li>
          <li>{{ $t('verwaltung.passwortaendern.atLeast1Letter') }}</li>
          <li>{{ $t('verwaltung.passwortaendern.atLeast1Number') }}</li>
        </ul>
        <div class="q-mt-lg" style="max-width: 500px">
          <q-input
            outlined
            dense
            :label="$t('verwaltung.passwortaendern.password')"
            type="password"
            v-model="password_1"
          >
            <template #before>
              <q-icon name="mdi-lock" />
            </template>
          </q-input>
          <q-input
            class="q-my-md"
            outlined
            dense
            :label="$t('verwaltung.passwortaendern.repeat')"
            type="password"
            v-model="password_2"
            @keydown.enter="changePassword"
          >
            <template #before>
              <q-icon name="mdi-lock" />
            </template>
          </q-input>
        </div>
      </div>
      <div class="col row items-start justify-end">
        <q-btn
          color="primary"
          outline
          no-caps
          :loading="loading"
          @click="changePassword"
        >
          <q-icon name="mdi-content-save" left />{{ $t('input.speichern') }}
        </q-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "../../store";
import { ref } from "vue";
import { Notify } from "quasar";

const store = useStore();

const password_1 = ref("");
const password_2 = ref("");

const loading = ref(false);

const changePassword = async () => {
  if (!password_1.value || !password_2.value) {
    Notify.create({
      type: "ams-warning",
      message: "Passwort wird benötigt!",
    });
    return;
  }

  if (password_1.value !== password_2.value) {
    Notify.create({
      type: "ams-warning",
      message: "Passwörter müssen identisch sein!",
    });
    return;
  }

  if (
    !RegExp("(?=.{8,})(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])").test(password_1.value)
  ) {
    Notify.create({
      type: "ams-warning",
      message: "Passwort zu schwach!",
    });
    return;
  }

  try {
    loading.value = true;

    const res = await fetch(`/auth/password/change`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${store.token}`,
      },
      body: JSON.stringify({
        password: password_1.value,
      }),
    });

    if (!res.ok) {
      throw new Error("unsuccessful");
    }

    password_1.value = "";
    password_2.value = "";

    Notify.create({
      type: "ams-success",
      message: "Passwort erfolgreich aktualisiert!",
    });
  } catch {
    Notify.create({
      type: "ams-error",
      message: "Passwort aktualisieren fehlgeschlagen!",
    });
  } finally {
    loading.value = false;
  }
};
</script>
