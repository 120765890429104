import {
  KategorieOverviewQueryVariables,
  CreateKategorieMutationVariables,
  DeleteKategorieMutationVariables,
  UpdateKategorieMutationVariables,
} from "../types/graphql.types";
import client from "./index";
import { hashids } from "./hashids.service";

export const useKategorie = () => {
  return {
    fetchAll: async (variables: KategorieOverviewQueryVariables) => {
      const { findKategorien } = await client.KategorieOverview(variables);

      return {
        result: findKategorien,
      };
    },
    fetchById: async (id: string | number) => {
      if (typeof id === "string") {
        const [parsed] = hashids.decode(id) as number[];
        id = parsed;
      }

      const { findKategorieById } = await client.KategorieById({
        id,
      });

      return {
        result: findKategorieById,
      };
    },
    create: async (variables: CreateKategorieMutationVariables) => {
      const { createKategorie } = await client.CreateKategorie(variables);

      return { result: createKategorie };
    },
    update: async (variables: UpdateKategorieMutationVariables) => {
      const { updateKategorie } = await client.UpdateKategorie(variables);

      return { result: updateKategorie };
    },
    delete: async (variables: DeleteKategorieMutationVariables) => {
      const { deleteKategorie } = await client.DeleteKategorie(variables);

      return { result: deleteKategorie };
    },
    queryKey: "findKategorie",
    mutateKey: "updateKategorie",
    createKey: "createKategorie",
    exportKey: "exportKategorie",
  };
};
