<template>
  <q-layout view="hHh LpR fFf" style="background-color: #fafafa">
    <ams-header @toggle="toggleDrawer" />
    <ams-drawer :drawer="drawer" />
    <q-page-container>
      <router-view />
    </q-page-container>
    <ams-footer />
  </q-layout>
</template>

<script lang="ts">
import Header from "./layouts/app/Header.vue";
import Drawer from "./layouts/app/Drawer.vue";
import Footer from "./layouts/app/Footer.vue";
import { ref, defineComponent } from "vue";
import { LocalStorage, useMeta } from "quasar";
import { useStore } from "./store";
import { useBrandingStore } from "./store/branding";
import client from "./services";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    "ams-header": Header,
    "ams-drawer": Drawer,
    "ams-footer": Footer,
  },
  setup() {
    const drawer = ref(true);
    const branding = useBrandingStore();
    useMeta({
      title: branding.company,
      link: {
        favicon: {
          rel: "icon",
          type: "image/ico",
          href: branding.favicon,
        },
      },
    });

    if (LocalStorage.getItem("ams-menu") !== null) {
      drawer.value = !!LocalStorage.getItem("ams-menu");
    }

    const toggleDrawer = () => {
      drawer.value = !drawer.value;
      LocalStorage.set("ams-menu", drawer.value);
    };

    const store = useStore();
    const { locale } = useI18n();

    if (store.authenticated) {
      client.Me().then(({ me }) => {
        if (me.preferredLanguage) {
          locale.value = me.preferredLanguage;
        }
      });
    }

    return {
      drawer,
      toggleDrawer,
    };
  },
});
</script>
