import { IColumnDefinition } from "./types.data";
import DokumentCellName from "../../components/Dokumente/cells/dokument-cell-name.vue";
import DokumentCellTags from "../../components/Dokumente/cells/dokument-cell-tags.vue";
import DokumentCellUpdated from "../../components/Dokumente/cells/dokument-cell-updated.vue";
import DokumentCellAnmerkungen from "../../components/Dokumente/cells/dokument-cell-anmerkungen.vue";
import DokumentCellDownload from "../../components/Dokumente/cells/dokument-cell-download.vue";
import DokumentCellStandort from "../../components/Dokumente/cells/dokument-cell-standort.vue";
import { useProtokoll } from "../../services/protokoll.service";

export enum protokolleModel {
  SINGULAR = "Protokoll",
  PLURAL = "Protokolle",
  ROUTE = "/protokolle",
  ICON = "mdi-file-document-edit-outline",
  ITEM_KEY = "protokoll",
  ITEM_KEY_UPDATE = "dokumente",
}

export const defaultProtokolleHeader: string[] = [
  "status",
  "dateiname",
  "tags",
  "updatedAt",
  "anmerkungen",
  "herunterladen",
];

export const protokolleHeader: IColumnDefinition<any>[] = [
  {
    name: "dateiname",
    label: "Dateiname",
    field: "name",
    required: false,
    align: "left",
    sortable: true,
    cell: DokumentCellName,
  },
  {
    name: "tags",
    label: "Tags",
    field: "tags",
    required: false,
    align: "left",
    sortable: false,
    cell: DokumentCellTags,
  },
  {
    name: "updatedAt",
    label: "Zuletzt geändert",
    field: "updatedAt",
    required: false,
    align: "left",
    sortable: false,
    cell: DokumentCellUpdated,
  },
  {
    name: "anmerkungen",
    label: "Anmerkungen",
    field: "notes",
    required: false,
    align: "left",
    sortable: true,
    cell: DokumentCellAnmerkungen,
  },
  {
    name: "herunterladen",
    label: "Herunterladen",
    field: "objectKey",
    required: false,
    align: "left",
    sortable: false,
    cell: DokumentCellDownload,
  },
  {
    name: "standort",
    label: "Standort",
    required: false,
    align: "left",
    sortable: false,
    cell: DokumentCellStandort,
  },
];

export const protokolleData = {
  model: protokolleModel,
  api: useProtokoll(),
  header: protokolleHeader,
  defaultHeader: defaultProtokolleHeader,
};
