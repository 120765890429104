<template>
  <div>
    <q-chip
      v-if="props.row.art"
      outline
      color="grey-7"
      icon="mdi-cards-outline"
      dense
      clickable
      @click.stop="openArtenManager"
      >{{ props.row.art.name }}</q-chip
    >
    <q-chip
      v-else
      outline
      color="grey-7"
      icon="mdi-plus"
      dense
      clickable
      @click.stop="openArtenManager"
    >
      {{ $t("input.hinzufuegen") }}
    </q-chip>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import RelationManagerDialog from "../../components/RelationManager/RelationManagerDialog.vue";
import { Dialog, Notify } from "quasar";

export default defineComponent({
  props: {
    props: {
      type: Object,
      required: true,
    },
    parentApi: {
      type: Object,
      required: true,
    },
    api: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    hasWritePermission: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const openArtenManager = () => {
      Dialog.create({
        component: RelationManagerDialog,
        componentProps: {
          api: props.api,
          model: props.model,
          preSelected: props.props.row.art ? [props.props.row.art] : [],
          unitId: props.props.row.unit?.id,
          selection: "single",
          hasWritePermission: props.hasWritePermission,
          save: true,
        },
      }).onOk(async (arten: any[]) => {
        const art = arten.length ? arten[0] : { id: 0 };

        const { result: item } = await props.parentApi.update({
          id: props.props.row.id,
          object: {
            object: {
              artId: art.id,
            },
          },
        });

        emit("updateData", item);

        Notify.create({
          type: "ams-success",
          message: "Arten aktualisiert!",
        });
      });
    };

    return {
      openArtenManager,
    };
  },
});
</script>
