import { ref, computed, watch } from "vue";
import { searchKey } from "../utils";
import { IUnit } from "../data/views/types.data";
import { Notify } from "quasar";
import { useStore } from "../store";
import client from "../services";

export function useUnits(onlyShowActiveUnits: boolean = false) {
  const store = useStore();

  const result = ref<any>([]);
  const unitSearch = ref<string>("");
  const selectedUnits = ref<string[]>([...store.currentUnitIds]);
  const unitLoading = ref<boolean>(true);
  const unitFilter = ref<boolean>(false);

  const refetch = async () => {
    try {
      unitLoading.value = true;
      const { units } = await client.GetUnits();
      result.value = units;
    } catch {
      Notify.create({
        type: "ams-error",
        message: "Laden der Standorte fehlgeschlagen",
      });
    } finally {
      unitLoading.value = false;
    }
  };

  watch(
    () => result.value,
    () => {
      const unitMap = result.value.reduce((map: any, obj: any) => {
        map[obj.id] = obj;
        return map;
      }, {});
      store.setAuth({ ...store.auth, unitMap });
    }
  );

  const units = computed(() => {
    const list = searchKey(
      unitSearch.value,
      "name",
      result.value as IUnit[]
    ).filter(
      (unit) => store.currentUnitIds.includes(unit.id) || !onlyShowActiveUnits
    );

    if (unitFilter.value) {
      return list.filter((l) => selectedUnits.value.includes(l.id));
    }
    return list;
  });

  const getUnits = async () => {
    await refetch();
  };

  const changeCurrentUnit = async () => {
    const res = await fetch(`/auth/change-unit`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${store.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        unitIds: selectedUnits.value,
      }),
    });

    if (!res.ok) {
      Notify.create({
        type: "ams-error",
        message: "Wechseln des Standorts nicht möglich.",
      });
      return;
    }

    const body = await res.json();

    store.setAuth(body);
  };

  return {
    unitSearch,
    selectedUnits,
    unitFilter,
    getUnits,
    unitLoading,
    units,
    changeCurrentUnit,
  };
}
