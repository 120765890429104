<template>
  <div>
    <div class="text-h6 text-bold text-grey-9">
      {{ $t("pruefbericht.informationen.allgemein") }}
    </div>
    <div class="row q-gutter-x-md q-mt-md">
      <div class="col q-gutter-y-md">
        <ams-input
          :label="$t('pruefbericht.bezeichnung')"
          :value="data.name"
          @input="updateInput('name', $event)"
          icon="mdi-note-text"
        />
      </div>
      <div class="col q-gutter-y-md" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AmsInput from "../../../components/forms/AmsInput.vue";

export default defineComponent({
  components: {
    "ams-input": AmsInput,
  },
  props: {
    data: {
      type: Object as () => any,
      required: true,
    },
  },
  setup(props, { emit }) {
    const updateInput = (origin: string, val: any) => {
      emit("updateInput", { origin, val });
      emit("update:data", { ...props.data, [origin]: val });
    };

    const updateVariables = (origin: string, val: any) => {
      emit("updateVariables", { origin, val });
      emit("update:data", { ...props.data, [origin]: val });
    };

    return {
      updateInput,
      updateVariables,
    };
  },
});
</script>
