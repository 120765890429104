<template>
  <div>
    <q-splitter v-model="splitter" :limits="[20, 80]">
      <template #before>
        <div>
          <q-input
            dense
            outlined
            clearable
            v-model="search"
            :placeholder="$t('input.suche')"
            style="min-width: 250px; max-width: 400px"
            class="q-mr-md"
          >
            <template #prepend>
              <q-icon name="mdi-magnify" />
            </template>
          </q-input>
          <q-spinner
            v-if="loading"
            size="24px"
            class="q-mt-xl block q-mx-auto"
          />
          <div v-else>
            <div class="text-grey-9 text-bold q-my-sm">
              {{ $t("verwaltung.standort.treffer", displayUnits.length) }}
            </div>
            <q-list separator>
              <q-item
                clickable
                v-for="unit in displayUnits"
                :key="unit.id"
                tag="label"
              >
                <q-item-section avatar>
                  <q-radio
                    v-model="unitId"
                    :val="unit.id"
                    color="blue"
                    dense
                    @update:model-value="selected = unit"
                  />
                </q-item-section>
                <q-item-section>
                  <q-item-label>
                    {{ unit.name || "-" }}
                  </q-item-label>
                  <q-item-label caption>{{ unit.ort || "-" }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
        </div>
      </template>
      <template #after>
        <div v-if="unitId" class="q-pa-md">
          <div class="text-right q-mt-md">
            <q-btn
              :outline="!changed"
              color="primary"
              no-caps
              :loading="updating"
              @click="updateStandort"
            >
              <q-icon name="mdi-content-save" left />{{ $t("input.speichern") }}
            </q-btn>
          </div>
          <ams-input
            class="q-mt-md"
            :label="$t('unit.name')"
            v-model="selected.name"
            icon="mdi-factory"
            @input="updateInput('name', $event)"
          />
          <ams-input
            class="q-mt-md"
            :label="$t('unit.berufsgenossenschaft')"
            v-model="selected.berufsgenossenschaft"
            icon="mdi-account-group"
            @input="updateInput('berufsgenossenschaft', $event)"
          />
          <ams-input
            class="q-mt-md"
            :label="$t('unit.ort')"
            v-model="selected.ort"
            icon="mdi-map-marker"
            @input="updateInput('ort', $event)"
          />
          <ams-input
            class="q-mt-md"
            :label="$t('unit.plz')"
            v-model="selected.plz"
            icon="mdi-mailbox-open-outline"
            @input="updateInput('plz', $event)"
          />
          <ams-input
            class="q-mt-md"
            :label="$t('unit.strasse')"
            v-model="selected.strasse"
            icon="mdi-truck-outline"
            @input="updateInput('strasse', $event)"
          />
          <ams-input
            class="q-mt-md"
            :label="$t('unit.hausnummer')"
            v-model="selected.hausnummer"
            icon="mdi-counter"
            @input="updateInput('hausnummer', $event)"
          />
          <ams-input
            class="q-mt-md"
            :label="$t('unit.anmerkungen')"
            v-model="selected.anmerkungen"
            icon="mdi-square-edit-outline"
            type="textarea"
            @input="updateInput('anmerkungen', $event)"
          />
        </div>
        <div v-else class="text-grey-9 text-bold q-pa-md">
          {{ $t("verwaltung.standort.choose") }}
        </div>
      </template>
    </q-splitter>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import AmsInput from "../forms/AmsInput.vue";
import { Notify } from "quasar";
import client from "../../services";
import { searchKey } from "src/utils";
import { GetUnitsQuery } from "src/types/graphql.types";

const search = ref("");
const unitId = ref("");
const selected = ref({} as any);
const updateItem = ref({});
const units = ref<GetUnitsQuery["units"]>([]);
const loading = ref(false);
const updating = ref(false);
const splitter = ref(40);

const getUnits = async () => {
  try {
    loading.value = true;

    const { units: _units } = await client.GetUnits();
    units.value = _units;
  } finally {
    loading.value = false;
  }
};

const displayUnits = computed(() =>
  searchKey(search.value, "name", units.value)
);

getUnits();

const updateInput = (origin: string, val: any) => {
  updateItem.value = { ...updateItem.value, [origin]: val };
};

const updateStandort = async () => {
  try {
    updating.value = true;

    const { updateUnit } = await client.UpdateUnitById({
      unitId: unitId.value,
      updateUnit: updateItem.value,
    });

    selected.value = updateUnit;
    updateItem.value = {};

    Notify.create({
      type: "ams-success",
      message: "Standort aktualisiert!",
    });
  } finally {
    updating.value = false;
  }
};

const changed = computed<boolean>(() => {
  return !!Object.keys(updateItem.value).length;
});
</script>
