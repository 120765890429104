<template>
  <q-td :props="props" :key="colKey">{{ datetimeValue }}</q-td>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { date } from "quasar";

export default defineComponent({
  props: {
    props: {
      type: Object,
      required: true,
    },
    colKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const datetimeValue = computed(() =>
      date.formatDate(
        props.props.row.unfallzeitpunkt.substring(
          0,
          props.props.row.unfallzeitpunkt.length - 1
        ),
        "DD.MM.YYYY HH:mm"
      )
    );

    return {
      datetimeValue,
    };
  },
});
</script>
