import { createI18n } from "vue-i18n";
import de from "./de";
import en from "./en";

const supportedLanguages = ["de", "en"];
let preferredLocale = navigator.languages[0];

if (!supportedLanguages.includes(preferredLocale)) {
  preferredLocale = supportedLanguages[0];
}

export default createI18n({
  legacy: false,
  locale: preferredLocale,
  fallbackLocale: "de",
  globalInjection: true,
  warnHtmlMessage: false,
  messages: {
    de,
    en,
  },
});
