<template>
  <q-td :props="props" :key="colKey">
    <ams-base-cell-anmerkungen
      :props="props"
      :model="model"
      :api="api"
      :hasWritePermission="hasWritePermission"
      @updateData="$emit('updateData', $event)"
    />
  </q-td>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseCellAnmerkungen from "../../../components/BaseCells/BaseCellAnmerkungen.vue";
import { useBetriebsanweisung } from "../../../services/betriebsanweisung.service";
import { betriebsanweisungenModel } from "../../../data";

export default defineComponent({
  components: {
    "ams-base-cell-anmerkungen": BaseCellAnmerkungen,
  },
  props: {
    props: {
      type: Object,
      required: true,
    },
    colKey: {
      type: String,
      required: true,
    },
    hasWritePermission: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      model: betriebsanweisungenModel,
      api: useBetriebsanweisung(),
    };
  },
});
</script>
