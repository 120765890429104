<template>
  <q-drawer
    :model-value="drawer && authenticated"
    :breakpoint="500"
    :width="270"
    bordered
    content-class="bg-grey-2"
  >
    <q-scroll-area class="fit">
      <q-item
        class="q-mt-lg q-mx-sm q-pa-xs q-px-none"
        style="border-radius: 10px"
        clickable
        dense
        to="/verwaltung/profildaten"
      >
        <q-item-section avatar>
          <q-icon color="primary" name="mdi-account-circle" size="32px" />
        </q-item-section>
        <q-item-section>
          <q-item-label>{{ name }}</q-item-label>
          <q-item-label caption>{{ email }}</q-item-label>
        </q-item-section>
      </q-item>
      <q-separator class="q-mt-md" />
      <q-list padding>
        <div v-for="(routes, index) in routeSection" :key="`routes-${index}`">
          <div
            v-for="(route, subindex) in routes"
            :key="`sub-routes-${subindex}`"
          >
            <q-item
              v-if="route.model"
              :to="route.model.ROUTE"
              v-ripple
              :class="isActive(route.model.ROUTE)"
            >
              <q-item-section avatar>
                <q-icon color="grey-7" :name="route.model.ICON" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{
                  $t(`${route.model.ITEM_KEY}.name`, 2)
                }}</q-item-label>
                <q-badge v-if="route.model.COMING_SOON" floating
                  >Demnächst</q-badge
                >
              </q-item-section>
            </q-item>
            <q-expansion-item
              v-else
              v-model="weitereAnlagen"
              class="ams-list-icon"
            >
              <template #header>
                <q-item-section avatar>
                  <q-icon
                    color="grey-7"
                    :name="
                      weitereAnlagen
                        ? 'mdi-folder-open-outline'
                        : 'mdi-folder-outline'
                    "
                  />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ $t("drawer.weitereAnlagen") }}</q-item-label>
                </q-item-section>
              </template>
              <q-list padding>
                <q-item
                  v-for="subroute in route.submodels"
                  :key="subroute.ROUTE"
                  :to="subroute.ROUTE"
                  v-ripple
                  :class="isActive(subroute.ROUTE)"
                >
                  <q-item-section class="q-pl-md" avatar>
                    <q-icon color="grey-7" :name="subroute.ICON" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>{{
                      $t(`${subroute.ITEM_KEY}.name`, 2)
                    }}</q-item-label>
                    <q-badge v-if="subroute.COMING_SOON" floating>
                      Demnächst
                    </q-badge>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-expansion-item>
          </div>
          <q-separator class="q-my-sm" />
        </div>
        <div class="q-mt-md" v-if="branding.showSocials" />
        <div class="row items-center q-px-md" v-if="branding.showSocials">
          Social Media:
          <q-btn
            color="grey-8"
            class="q-ml-md q-mr-sm"
            outline
            size="8px"
            @click="openURL('https://www.xing.com/companies/amsproug')"
          >
            <q-icon name="mdi-xing" />
          </q-btn>
          <q-btn
            color="grey-8"
            outline
            size="8px"
            @click="
              openURL(
                'https://www.linkedin.com/company/ams-pro-ug-haftungsbeschr%C3%A4nkt/about/'
              )
            "
          >
            <q-icon name="mdi-linkedin" />
          </q-btn>
        </div>
        <q-separator class="q-my-md" v-if="branding.showSocials" />
        <div
          v-for="(links, index) in linkSection"
          :key="`links-${index}`"
          class="q-px-md text-grey-9"
        >
          <div class="row items-center q-gutter-x-sm q-gutter-y-xs">
            <a
              v-for="link in links"
              :key="link.label"
              class="ams-drawer-footer-link"
              :href="`https://ams-pro.de${link.path}`"
              target="_blank"
              >{{ link.label }}</a
            >
          </div>
        </div>
      </q-list>
    </q-scroll-area>
  </q-drawer>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from "vue";
import { useIcons } from "../../hooks";
import {
  betriebsmittelModel,
  gefahrstoffeModel,
  gefaehrdungsbeurteilungenModel,
  betriebsanweisungenModel,
  verbandbuchModel,
  dokumenteModel,
  verwaltungModel,
  dashboardModel,
  bedienungsanleitungenModel,
  sicherheitsdatenblaetterModel,
  pruefberichteModel,
  protokolleModel,
} from "../../data";
import { openURL } from "quasar";
import { useStore } from "../../store";
import { useBrandingStore } from "../../store/branding";
import { useRouter } from "vue-router";

export default defineComponent({
  props: {
    drawer: {
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const icons = useIcons();
    const router = useRouter();

    const weitereAnlagen = ref(false);

    const authenticated = computed<boolean>(() => store.authenticated);

    const routeSection = [
      [{ model: dashboardModel }],
      [
        { model: betriebsmittelModel },
        { model: gefahrstoffeModel },
        { model: gefaehrdungsbeurteilungenModel },
        { model: betriebsanweisungenModel },
      ],
      [{ model: verbandbuchModel }],
      [
        {
          submodels: [
            bedienungsanleitungenModel,
            pruefberichteModel,
            sicherheitsdatenblaetterModel,
            protokolleModel,
            dokumenteModel,
          ],
        },
      ],
      [{ model: verwaltungModel }],
    ];

    const branding = useBrandingStore();

    const linkSection = [
      [
        { label: `${branding.company} Webseite`, path: "/" },
        { label: "Über uns", path: "/unternehmen" },
        { label: "Kontakt", path: "/kontakt" },
      ],
      [
        { label: "Impressum", path: "/impressum" },
        { label: "Datenschutzerklärung", path: "/datenschutz" },
      ],
    ];

    const isActive = (route: string): string => {
      if (route === "/" && route === router.currentRoute.value.path) {
        return "ams-list-item ams-list-item--active";
      }
      if (route !== "/" && router.currentRoute.value.path.startsWith(route)) {
        return "ams-list-item ams-list-item--active";
      }
      return "ams-list-item";
    };

    const name = computed(() => store.name);
    const email = computed(() => store.email);

    return {
      branding,
      routeSection,
      linkSection,
      icons,
      isActive,
      authenticated,
      openURL,
      name,
      email,
      weitereAnlagen,
    };
  },
});
</script>

<style scoped>
.ams-drawer-footer-link {
  color: inherit;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.75rem;
}

.ams-drawer-footer-link:hover {
  color: #000;
}

.ams-list-item {
  line-height: 24px;
  border-radius: 0 24px 24px 0;
  margin-right: 12px;
}

.ams-list-item .q-item__label:not(.q-item__label--caption) {
  color: #3c4043;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
}

.ams-list-item--active {
  background-color: rgba(2, 122, 227, 0.125);
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  margin-right: 12px;
}
</style>
