<template>
  <q-td :props="props" :key="colKey" class="text-right">
    <q-btn
      outline
      :icon="props.expand ? 'mdi-chevron-up' : 'mdi-chevron-down'"
      color="primary"
      :label="$t('verbandbuch.aenderungshistorie')"
      dense
      no-caps
      @click.stop="toggleExpand"
    />
    <q-btn
      class="q-ml-sm"
      outline
      icon="mdi-open-in-new"
      color="black"
      :label="$t('input.bearbeiten')"
      dense
      no-caps
      @click.stop="updateEintrag"
    />
  </q-td>
</template>

<script lang="ts">
import { verbandbuchModel } from "../../../data";
import { useScopes } from "../../../hooks/useScopes";
import { missingWritePermission } from "../../../utils";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    props: {
      type: Object,
      required: true,
    },
    colKey: {
      type: String,
      required: true,
    },
    verbandbuecher: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { hasPermission } = useScopes(verbandbuchModel);

    const toggleExpand = () => {
      props.props.expand = !props.props.expand;

      if (props.props.expand) {
        emit("fetchRevisions");
      }
    };

    const updateEintrag = () => {
      if (props.verbandbuecher.length) {
        if (
          hasPermission(
            (
              props.verbandbuecher.find(
                (v: any) => v.id === props.props.row.verbandbuch.id
              ) as any
            ).unit?.id,
            "write"
          )
        ) {
          emit("updateEintrag");
        } else {
          missingWritePermission();
        }
      } else {
        emit("updateEintrag");
      }
    };

    return {
      toggleExpand,
      updateEintrag,
    };
  },
});
</script>
