<template>
  <q-td :props="props" :key="colKey" class="text-grey-8">
    <q-chip
      v-if="tags.length === 0"
      outline
      color="grey-7"
      icon="mdi-plus"
      dense
      clickable
      @click.stop="openTagManager"
    >
      {{ $t("input.hinzufuegen") }}
    </q-chip>
    <q-chip
      v-if="tagsValue"
      outline
      color="grey-7"
      icon="mdi-bookmark"
      dense
      clickable
      @click.stop="openTagManager"
    >
      {{ tagsValue }}
      <q-tooltip>{{ tagsValue }}</q-tooltip>
    </q-chip>
    <q-chip
      v-if="moreValue"
      outline
      color="grey-7"
      icon="mdi-bookmark"
      dense
      clickable
      @click.stop="openTagManager"
    >
      {{ moreValue }}
      <q-tooltip>{{ tooltipValue }}</q-tooltip>
    </q-chip>
  </q-td>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useDokument } from "../../../services/dokument.service";
import RelationManagerDialog from "../../../components/RelationManager/RelationManagerDialog.vue";
import { Dialog, Notify } from "quasar";
import { useTag } from "../../../services/tag.service";
import { tagModel } from "../../../data";

export default defineComponent({
  props: {
    props: {
      type: Object,
      required: true,
    },
    colKey: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const api = useDokument();

    const tags = computed(() => props.props.row.tags);

    const tagsValue = computed(() =>
      tags.value.length ? tags.value[0].name : null
    );

    const moreValue = computed(() => {
      if (tags.value.length === 2) {
        return tags.value[1].name;
      } else if (tags.value.length > 2) {
        return `${tags.value.length - 1} weitere`;
      }
      return null;
    });

    const tooltipValue = computed(() =>
      tags.value.map((k: any) => k.name).join(", ")
    );

    const openTagManager = () => {
      Dialog.create({
        component: RelationManagerDialog,
        componentProps: {
          api: useTag(),
          model: tagModel,
          preSelected: tags.value,
          unitId: props.props.row.unit.id,
          hasWritePermission: true,
          save: true,
        },
      }).onOk(async (tags: any[]) => {
        const { result: item } = await api.update({
          id: props.props.row.id,
          object: {
            relations: {
              tags: tags.map((k) => k.id),
            },
          },
        } as any);

        emit("updateData", item);

        Notify.create({
          type: "ams-success",
          message: "Tags aktualisiert!",
        });
      });
    };

    return {
      tags,
      tagsValue,
      moreValue,
      tooltipValue,
      openTagManager,
    };
  },
});
</script>
