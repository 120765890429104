export enum verwaltungModel {
  SINGULAR = "Verwaltung",
  PLURAL = "Verwaltung",
  ROUTE = "/verwaltung",
  ICON = "mdi-cog-outline",
  ITEM_KEY = "verwaltung",
  ITEM_KEY_UPDATE = "verwaltungen",
}

export const verwaltungItems = [
  {
    label: "Standort & Account",
    icon: "mdi-account-circle",
    info: "Allgemeine Informationen zu Standort & Account",
    route: "account",
  },
  {
    label: "Zugänge",
    icon: "mdi-account-group",
    info: "Rechte und Rollen Ihrer Mitarbeiter",
    route: "zugaenge",
  },
];

export const settings = (route: string) => [
  {
    icon: "mdi-plus-box",
    route: "standorterstellen",
    model: "standorterstellen" === route,
  },
  {
    icon: "mdi-factory",
    route: "standort",
    model: "standort" === route,
  },
  {
    icon: "mdi-account-multiple-plus",
    route: "nutzer",
    model: "nutzer" === route,
  },
  {
    icon: "mdi-bell",
    route: "reports",
    model: "reports" === route,
  },
  {
    icon: "mdi-account-circle",
    route: "profildaten",
    model: "profildaten" === route,
  },
  {
    icon: "mdi-lock",
    route: "passwortaendern",
    model: "passwortaendern" === route,
  },
  {
    icon: "mdi-credit-card-multiple-outline",
    route: "zahlungen",
    model: "zahlungen" === route,
  },
  {
    icon: "mdi-account-multiple-check",
    route: "berechtigungen",
    model: "berechtigungen" === route,
  },
  // {
  //   icon: 'mdi-qrcode',
  //   label: `2FA Einstellungen`,
  //   description:
  //     'Aktivieren oder deaktivieren Sie die Zwei-Faktor-Authentifizierung',
  //   route: '2fa',
  //   model: '2fa' === route
  // }
];

export const sonstigeEinstellungen = (route: string) => [
  {
    icon: "mdi-folder-download",
    label: "Export",
    description: "Exporieren Sie Ihre Daten in ein Excel Dokument",
    route: "export",
    model: "export" === route,
  },
];
