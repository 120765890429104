import {
  BetriebsanweisungArtOverviewQueryVariables,
  CreateBetriebsanweisungArtMutationVariables,
  DeleteBetriebsanweisungArtMutationVariables,
  UpdateBetriebsanweisungArtMutationVariables,
} from "../types/graphql.types";
import client from "./index";
import { hashids } from "./hashids.service";

export const useBetriebsanweisungArt = () => {
  return {
    fetchAll: async (variables: BetriebsanweisungArtOverviewQueryVariables) => {
      const { findBetriebsanweisungArten } =
        await client.BetriebsanweisungArtOverview(variables);

      return {
        result: findBetriebsanweisungArten,
      };
    },
    fetchById: async (id: string | number) => {
      if (typeof id === "string") {
        const [parsed] = hashids.decode(id) as number[];
        id = parsed;
      }

      const { findBetriebsanweisungArtById } =
        await client.BetriebsanweisungArtById({
          id,
        });

      return {
        result: findBetriebsanweisungArtById,
      };
    },
    create: async (variables: CreateBetriebsanweisungArtMutationVariables) => {
      const { createBetriebsanweisungArt } =
        await client.CreateBetriebsanweisungArt(variables);

      return { result: createBetriebsanweisungArt };
    },
    update: async (variables: UpdateBetriebsanweisungArtMutationVariables) => {
      const { updateBetriebsanweisungArt } =
        await client.UpdateBetriebsanweisungArt(variables);

      return { result: updateBetriebsanweisungArt };
    },
    delete: async (variables: DeleteBetriebsanweisungArtMutationVariables) => {
      const { deleteBetriebsanweisungArt } =
        await client.DeleteBetriebsanweisungArt(variables);

      return { result: deleteBetriebsanweisungArt };
    },
  };
};
