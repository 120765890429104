import BetriebsanweisungTabWrapper from "../../components/Betriebsanweisungen/tabs/TabWrapper.vue";
import { IStatusDefinition, IColumnDefinition } from "./types.data";
import BetriebsanweisungCellStatus from "../../components/Betriebsanweisungen/cells/betriebsanweisung-cell-status.vue";
import BetriebsanweisungCellName from "../../components/Betriebsanweisungen/cells/betriebsanweisung-cell-name.vue";
import BetriebsanweisungCellKategorien from "../../components/Betriebsanweisungen/cells/betriebsanweisung-cell-kategorien.vue";
import BetriebsanweisungCellArt from "../../components/Betriebsanweisungen/cells/betriebsanweisung-cell-art.vue";
import BetriebsanweisungCellLetztesReview from "../../components/Betriebsanweisungen/cells/betriebsanweisung-cell-letztesReview.vue";
import BetriebsanweisungCellReviewIntervall from "../../components/Betriebsanweisungen/cells/betriebsanweisung-cell-reviewIntervall.vue";
import BetriebsanweisungCellNaechstesReview from "../../components/Betriebsanweisungen/cells/betriebsanweisung-cell-naechstesReview.vue";
import BetriebsanweisungCellAnmerkungen from "../../components/Betriebsanweisungen/cells/betriebsanweisung-cell-anmerkungen.vue";
import BetriebsanweisungCellDokumente from "../../components/Betriebsanweisungen/cells/betriebsanweisung-cell-dokumente.vue";
import BetriebsanweisungCellStandort from "../../components/Betriebsanweisungen/cells/betriebsanweisung-cell-standort.vue";
import { useBetriebsanweisung } from "../../services/betriebsanweisung.service";

export enum betriebsanweisungenModel {
  SINGULAR = "Betriebsanweisung",
  PLURAL = "Betriebsanweisungen",
  ROUTE = "/betriebsanweisungen",
  ICON = "mdi-folder-cog-outline",
  GESETZ = "https://publikationen.dguv.de/widgets/pdf/download/article/339",
  ITEM_KEY = "betriebsanweisung",
  ITEM_KEY_UPDATE = "betriebsanweisungen",
  MODULE = "true",
  PICTOGRAMS = "https://bgn-branchenwissen.de/organisation-des-arbeitsschutzes/bgn-service-und-dienstleistungsangebote/interaktive-module/symbolbibliothek",
}

export const betriebsanweisungArten: string[] = [
  "Betriebsmittel",
  "Gefahrstoff",
  "Tätigkeit",
  "Ort",
  "Psyche",
  "Sonstige",
];

export const defaultBetriebsanweisungenHeader: string[] = [
  "status",
  "bezeichnung",
  // "kategorien",
  "art",
  "naechstesReview",
  "anmerkungen",
  "dokumente",
];

export const betriebsanweisungHeader: IColumnDefinition<any>[] = [
  {
    name: "status",
    label: "Status",
    field: "status",
    required: false,
    align: "left",
    sortable: true,
    cell: BetriebsanweisungCellStatus,
  },
  {
    name: "bezeichnung",
    label: "Bezeichnung",
    field: "name",
    required: false,
    align: "left",
    sortable: true,
    cell: BetriebsanweisungCellName,
  },
  {
    name: "kategorien",
    label: "Kategorien",
    field: "kategorien",
    required: false,
    align: "left",
    sortable: false,
    cell: BetriebsanweisungCellKategorien,
  },
  {
    name: "art",
    label: "Art",
    field: "art",
    required: false,
    align: "left",
    sortable: false,
    cell: BetriebsanweisungCellArt,
  },
  {
    name: "letztesReview",
    label: "Letztes Review",
    field: "letztesReview",
    required: false,
    align: "left",
    sortable: true,
    cell: BetriebsanweisungCellLetztesReview,
  },
  {
    name: "reviewIntervall",
    label: "Überprüfen in",
    field: "reviewIntervall",
    required: false,
    align: "left",
    sortable: true,
    cell: BetriebsanweisungCellReviewIntervall,
  },
  {
    name: "naechstesReview",
    label: "Nächstes Review",
    field: "naechstesReview",
    required: false,
    align: "left",
    sortable: true,
    cell: BetriebsanweisungCellNaechstesReview,
  },
  {
    name: "anmerkungen",
    label: "Anmerkungen",
    field: "anmerkungen",
    required: false,
    align: "left",
    sortable: true,
    cell: BetriebsanweisungCellAnmerkungen,
  },
  {
    name: "dokumente",
    label: "Dokumente",
    field: "dokumente",
    required: false,
    align: "left",
    sortable: false,
    cell: BetriebsanweisungCellDokumente,
  },
  {
    name: "standort",
    label: "Standort",
    required: false,
    align: "left",
    sortable: false,
    cell: BetriebsanweisungCellStandort,
  },
];

export enum BetriebsanweisungStatus {
  FREIGEGEBEN = "Freigegeben",
  IN_ARBEIT = "In Arbeit",
  OFFEN = "Offen",
  PRUEFUNG_ERFORDERLICH = "Prüfung erforderlich",
  KEIN_ERGEBNIS = "Kein Ergebnis",
}

export const betriebsanweisungStatus: {
  [P in BetriebsanweisungStatus]: IStatusDefinition<BetriebsanweisungStatus>;
} = {
  [BetriebsanweisungStatus.FREIGEGEBEN]: {
    label: BetriebsanweisungStatus.FREIGEGEBEN,
    icon: "mdi-check-outline",
    color: "green",
    val: BetriebsanweisungStatus.FREIGEGEBEN,
  },
  [BetriebsanweisungStatus.IN_ARBEIT]: {
    label: BetriebsanweisungStatus.IN_ARBEIT,
    icon: "mdi-alert-outline",
    color: "orange",
    val: BetriebsanweisungStatus.IN_ARBEIT,
  },
  [BetriebsanweisungStatus.OFFEN]: {
    label: BetriebsanweisungStatus.OFFEN,
    icon: "mdi-alert-circle-outline",
    color: "red",
    val: BetriebsanweisungStatus.OFFEN,
  },
  [BetriebsanweisungStatus.PRUEFUNG_ERFORDERLICH]: {
    label: BetriebsanweisungStatus.PRUEFUNG_ERFORDERLICH,
    icon: "mdi-clock-outline",
    color: "primary",
    val: BetriebsanweisungStatus.PRUEFUNG_ERFORDERLICH,
  },
  [BetriebsanweisungStatus.KEIN_ERGEBNIS]: {
    label: BetriebsanweisungStatus.KEIN_ERGEBNIS,
    icon: "mdi-close-outline",
    color: "grey",
    val: BetriebsanweisungStatus.KEIN_ERGEBNIS,
  },
};

export const betriebsanweisungData = {
  model: betriebsanweisungenModel,
  api: useBetriebsanweisung(),
  header: betriebsanweisungHeader,
  defaultHeader: defaultBetriebsanweisungenHeader,
  tabWrapper: BetriebsanweisungTabWrapper,
};
