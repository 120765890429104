import { LocalStorage, Notify } from "quasar";
import { IAuth } from "src/data/views/types.data";
import { refreshAuthIfNeeded } from "src/services";
import { NavigationGuard, useRoute } from "vue-router";
import { decodeJWT, useStore } from "../store";

export const isTokenExpired = (token: string) => {
  if (!token?.length) {
    return true;
  }

  const decodedToken = decodeJWT<{ exp: number }>(token);

  if (!decodedToken.exp) {
    return true;
  }

  return Date.now() / 1000 >= decodedToken.exp;
};

export const AuthGuard: NavigationGuard = async (to, from, next) => {
  const store = useStore();
  const auth = LocalStorage.getItem("ams-auth") as IAuth;

  const idToken = auth?.id_token ?? "";

  // Bei einem authentifizierten Nutzer wird ein redirect auf das Dashboard vorgenommen
  if (to.matched.some((n) => n.name === "Login" || n.name === "Register")) {
    if (auth && idToken?.length && !isTokenExpired(idToken)) {
      if (to.query.redirect) {
        return next({ path: to.query.redirect as string });
      }
      return next("/");
    }
  }

  // Öffentliche Routen sind immer zugänglich
  if (to.matched.some((r) => r.meta.public)) {
    return next();
  }

  // Wenn Nutzer eingeladen wird muss er sich registrieren
  if (to.matched.some((r) => r.meta.invite)) {
    if (idToken?.length && !isTokenExpired(idToken)) {
      return next();
    }
    
    return next({ name: "Register", query: { redirect: to.fullPath } });
  }

  // Gültiger, aktiver Token
  if (auth && idToken?.length && !isTokenExpired(idToken)) {
    // Route zurück, wenn man mit vollständigen account auf welcome page will
    if (
      to.matched.some((r) => r.meta.protected) &&
      auth.accessibleUnits?.length
    ) {
      return next(from.path);
    }

    // Route auf gewünschte seite, da vollständiger account
    if (auth.accessibleUnits?.length) {
      return next();
    } else if (!to.matched.some((r) => r.meta.protected)) {
      if (
        !to.matched.some((n) => n.name === "Login" || n.name === "Register")
      ) {
        Notify.create({
          type: "ams-info",
          message: "Sie besitzen keinen Standort!",
          caption:
            "Nehmen Sie eine Einladung an, oder kontaktieren Sie uns über unsere Webseite",
        });
      }
      return next("/welcome");
    } else {
      return next();
    }
  }

  const newToken = await refreshAuthIfNeeded("");
  if (newToken) {
    return next();
  }

  // Zurück zum Login
  store.clearAuth();
  localStorage.clear();

  return next({ name: "Login", query: { redirect: to.fullPath } });
};
