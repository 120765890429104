<template>
  <q-dialog
    ref="dialog"
    transition-show="slide-up"
    transition-hide="slide-down"
    @hide="hide"
  >
    <q-card class="q-dialog-plugin" style="min-width: 600px">
      <q-card-section>
        <q-card-section class="row items-center q-pa-none q-mb-sm">
          <div class="q-pa-none text-grey-10 text-h5">
            {{ $t(`${model.ITEM_KEY}.name`) }} - {{ $t("dokument.name", 2) }}
          </div>
          <q-space />
          <q-btn flat round @click="hide">
            <q-icon name="mdi-close" />
          </q-btn>
        </q-card-section>
        <q-separator />
      </q-card-section>
      <q-card-section v-if="loading">
        <q-spinner class="q-my-xl block q-mx-auto" size="32px" />
      </q-card-section>
      <q-card-section v-else>
        <div class="text-grey-9 text-bold">
          {{ $t("dokumenteManager.anlageDokumente", [name, dokumente.length]) }}
        </div>
        <q-virtual-scroll
          v-if="dokumente.length"
          class="q-mt-md"
          style="max-height: 300px"
          :items="dokumente"
          separator
        >
          <template v-slot="{ item }">
            <q-item>
              <q-item-section avatar>
                <q-icon name="mdi-paperclip" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ item.name }}</q-item-label>
                <q-item-label caption>
                  {{ formatDate(item.updatedAt) }} -
                  {{ humanFileSize(item.size) }}
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <div class="row items-center q-gutter-x-xs">
                  <q-btn outline round size="sm" @click="downloadFile(item)">
                    <q-icon name="mdi-download" />
                    <q-tooltip>{{ $t("dokument.herunterladen") }}</q-tooltip>
                  </q-btn>
                  <q-btn outline round size="sm">
                    <q-icon
                      name="mdi-open-in-new"
                      @click="openNewTab(item.id)"
                    />
                    <q-tooltip>{{
                      $t("input.imTabOeffnen", [$t(`dokument.name`)])
                    }}</q-tooltip>
                  </q-btn>
                </div>
              </q-item-section>
            </q-item>
          </template>
        </q-virtual-scroll>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { Notify, QDialog } from "quasar";
import { hashids } from "../../services/hashids.service";
import { downloadFile, humanFileSize, formatDate } from "../../utils";

export default defineComponent({
  props: {
    model: {
      type: Object,
      required: true,
    },
    api: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      default: "-",
    },
  },
  setup(props) {
    const dialog = ref<QDialog | null>(null);
    const dokumente = ref<any>([]);
    const loading = ref<boolean>(false);

    const show = () => dialog.value!.show();
    const hide = () => dialog.value!.hide();

    onMounted(async () => {
      try {
        loading.value = true;
        const { result } = await props.api.fetchById(hashids.encode(props.id));
        dokumente.value = result.dokumente;
      } catch {
        Notify.create({
          type: "ams-error",
          message: "Laden von Dokumenten fehlgeschlagen!",
        });
      } finally {
        loading.value = false;
      }
    });

    const openNewTab = (id: string) => {
      window.open(
        `${window.location.protocol}//${
          window.location.host
        }/dokumente/${hashids.encode(id)}`,
        "_blank"
      );
    };

    return {
      dialog,
      show,
      hide,
      dokumente,
      loading,
      downloadFile,
      openNewTab,
      humanFileSize,
      formatDate,
    };
  },
});
</script>
