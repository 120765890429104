<template>
  <q-td :props="props" :key="colKey">
    <ams-format-date
      :date="props.row.letztePruefung"
      :addMonths="props.row.pruefintervall"
    />
  </q-td>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    props: {
      type: Object,
      required: true,
    },
    colKey: {
      type: String,
      required: true,
    },
  },
});
</script>
