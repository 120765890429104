<template>
  <q-td :props="props" :key="colKey">
    <ams-base-cell-status
      :props="props"
      :statusList="gefaehrdungsbeurteilungStatus"
    />
  </q-td>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { gefaehrdungsbeurteilungStatus } from "../../../data";
import BaseCellStatus from "../../../components/BaseCells/BaseCellStatus.vue";

export default defineComponent({
  components: {
    "ams-base-cell-status": BaseCellStatus,
  },
  props: {
    props: {
      type: Object,
      required: true,
    },
    colKey: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      gefaehrdungsbeurteilungStatus,
    };
  },
});
</script>
