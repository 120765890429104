<template>
  <q-td :props="props" :key="colKey">
    <q-btn
      outline
      round
      :color="anlagenAmount ? 'primary' : 'grey-7'"
      dense
      size="12px"
      class="q-mr-xs"
      @click.stop
    >
      <q-icon name="mdi-file-multiple" size="18px" />
      <q-tooltip>{{
        `${$t("betriebsmittel.anlagen")} (${anlagenAmount})`
      }}</q-tooltip>
      <q-menu auto-close>
        <q-list separator style="min-width: 325px">
          <q-item
            v-for="anlage in anlagen"
            :key="anlage.model.ITEM_KEY"
            clickable
            @click="route(anlage)"
          >
            <q-item-section avatar>
              <q-btn
                size="10px"
                round
                outline
                :color="anlage.amount ? 'primary' : 'grey-7'"
              >
                <q-icon size="20px" :name="anlage.model.ICON" />
              </q-btn>
            </q-item-section>
            <q-item-section>{{
              `${$t(`${anlage.model.ITEM_KEY}.name`, 2)} (${anlage.amount})`
            }}</q-item-section>
          </q-item>
        </q-list>
      </q-menu>
    </q-btn>
  </q-td>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRouter } from "vue-router";
import {
  betriebsmittelModel,
  gefaehrdungsbeurteilungenModel,
  betriebsanweisungenModel,
  bedienungsanleitungenModel,
  pruefberichteModel,
} from "../../../data";
import { hashids } from "../../../services/hashids.service";

export default defineComponent({
  props: {
    props: {
      type: Object,
      required: true,
    },
    colKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();

    const anlagen = [
      {
        model: gefaehrdungsbeurteilungenModel,
        amount: props.props.row.gefaehrdungsbeurteilungCount || 0,
      },
      {
        model: betriebsanweisungenModel,
        amount: props.props.row.betriebsanweisungCount || 0,
      },
      {
        model: bedienungsanleitungenModel,
        amount: props.props.row.bedienungsanleitungCount || 0,
      },
      {
        model: pruefberichteModel,
        amount: props.props.row.pruefberichtCount || 0,
      },
    ];

    const anlagenAmount = computed(() =>
      anlagen.reduce((a: any, b: any) => a + (b.amount || 0), 0)
    );

    const route = (anlage: any) =>
      router.push(
        `${betriebsmittelModel.ROUTE}/${hashids.encode(props.props.row.id)}/${
          anlage.model.ITEM_KEY_UPDATE
        }`
      );

    return {
      anlagen,
      anlagenAmount,
      route,
    };
  },
});
</script>
