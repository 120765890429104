<template>
  <div>
    <q-table
      :title="$t('paymentAccount.yourPaymentAccounts', [accounts.length])"
      class="q-mt-md"
      flat
      row-key="id"
      selection="single"
      :columns="columns"
      v-model:selected="selected"
      :rows="accounts"
      :loading="loading"
      no-data-label="Sie besitzen noch keine Rechnungsadressen"
    >
      <template #top-right>
        <div class="row items-center q-gutter-x-sm">
          <q-btn
            outline
            :disable="!selected.length"
            no-caps
            color="primary"
            @click="editAccount"
            >
            {{ $t('paymentAccount.edit') }}
          </q-btn>
        </div>
      </template>
      <template #body="props">
        <q-tr :props="props" @click="props.selected = !props.selected">
          <q-td key="selected">
            <q-checkbox
              class="q-ml-sm"
              color="blue"
              v-model="props.selected"
              dense
            />
          </q-td>
          <q-td :props="props" key="name">
            <q-item-label>{{ props.row.name }}</q-item-label>
            <q-item-label caption>{{ props.row.invoiceEmail }}</q-item-label>
          </q-td>
          <q-td :props="props" key="quantity">{{ props.row.quantity }}</q-td>
          <q-td
            :props="props"
            key="free"
            :class="`text-bold ${
              props.row.quantity - props.row.units.length > 0
                ? 'text-green'
                : 'text-red'
            }`"
            >{{ props.row.quantity - props.row.units.length }}</q-td
          >
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { Dialog, Notify, QTableProps } from "quasar";
import ZahlungenDialog from "./ZahlungenDialog.vue";
import client from "../../services";
import { GetPaymentAccountsQuery } from "src/types/graphql.types";
import { useI18n } from 'vue-i18n';

const { t } = useI18n()

const columns: QTableProps["columns"] = [
  {
    name: "name",
    label: t("unit.bezeichnung"),
    field: "name",
    align: "left",
    sortable: true,
  },
  {
    name: "quantity",
    label: t("unit.numberOfUnits"),
    field: "quantity",
    align: "left",
    sortable: true,
  },
  {
    name: "free",
    label: t("unit.unitsRemaining"),
    field: "quantity",
    align: "left",
    sortable: true,
  },
];

const accounts = ref([] as GetPaymentAccountsQuery["paymentAccounts"]);
const loading = ref(false);

const getAccounts = async () => {
  try {
    loading.value = true;

    const { paymentAccounts } = await client.GetPaymentAccounts();
    accounts.value = paymentAccounts;
  } finally {
    loading.value = false;
  }
};

getAccounts();

const selected = ref([]);

const editAccount = () => {
  Dialog.create({
    component: ZahlungenDialog,
    componentProps: {
      account: selected.value[0],
    },
  }).onOk(async () => await getAccounts());
};
</script>
