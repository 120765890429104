<template>
  <q-td :props="props" :key="colKey">
    <ams-base-cell-arten
      :props="props"
      :parentApi="parentApi"
      :api="api"
      :model="model"
      :unitId="props.row.unit?.id"
      :hasWritePermission="hasWritePermission"
      @updateData="$emit('updateData', $event)"
    />
  </q-td>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useGefaehrdungsbeurteilung } from "../../../services/gefaehrdungsbeurteilung.service";
import { useGefaehrdungsbeurteilungArt } from "../../../services/gefaehrdungsbeurteilungArt.service";
import BaseCellArten from "../../../components/BaseCells/BaseCellArten.vue";
import { gefaehrdungsbeurteilungArtenModel } from "../../../data";

export default defineComponent({
  components: {
    "ams-base-cell-arten": BaseCellArten,
  },
  props: {
    props: {
      type: Object,
      required: true,
    },
    colKey: {
      type: String,
      required: true,
    },
    hasWritePermission: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      parentApi: useGefaehrdungsbeurteilung(),
      api: useGefaehrdungsbeurteilungArt(),
      model: gefaehrdungsbeurteilungArtenModel,
    };
  },
});
</script>
