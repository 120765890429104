import {
  CountBedienungsanleitungenQueryVariables,
  BedienungsanleitungOverviewQueryVariables,
  CreateBedienungsanleitungMutationVariables,
  DeleteBedienungsanleitungMutationVariables,
  UpdateBedienungsanleitungMutationVariables,
} from "../types/graphql.types";
import client from "./index";
import { hashids } from "./hashids.service";

export const useBedienungsanleitung = () => {
  return {
    fetchAll: async (variables: BedienungsanleitungOverviewQueryVariables) => {
      const { findBedienungsanleitungen, countBedienungsanleitungen } =
        await client.BedienungsanleitungOverview(variables);

      return {
        result: findBedienungsanleitungen,
        count: countBedienungsanleitungen,
      };
    },
    count: async (variables: CountBedienungsanleitungenQueryVariables) => {
      const { countBedienungsanleitungen } =
        await client.CountBedienungsanleitungen(variables);

      return {
        result: countBedienungsanleitungen,
      };
    },
    fetchById: async (id: string | number) => {
      if (typeof id === "string") {
        const [parsed] = hashids.decode(id) as number[];
        id = parsed;
      }

      const { findBedienungsanleitungById } =
        await client.BedienungsanleitungById({
          id,
        });

      return {
        result: findBedienungsanleitungById,
      };
    },
    create: async (variables: CreateBedienungsanleitungMutationVariables) => {
      const { createBedienungsanleitung } =
        await client.CreateBedienungsanleitung(variables);

      return { result: createBedienungsanleitung };
    },
    update: async (variables: UpdateBedienungsanleitungMutationVariables) => {
      const { updateBedienungsanleitung } =
        await client.UpdateBedienungsanleitung(variables);

      return { result: updateBedienungsanleitung };
    },
    delete: async (variables: DeleteBedienungsanleitungMutationVariables) => {
      const { deleteBedienungsanleitung } =
        await client.DeleteBedienungsanleitung(variables);

      return { result: deleteBedienungsanleitung };
    },
  };
};
