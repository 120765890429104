import { useStore } from "../store";
import { Ref } from "vue";

export function useLogout(menu: Ref<boolean>, router: any) {
  const logout = () => {
    const store = useStore();
    store.clearAuth();

    menu.value = false;
    localStorage.clear();

    router.replace({
      path: "/login",
      query: { redirect: router.currentRoute.value.fullPath },
    });
  };

  return {
    logout,
  };
}
