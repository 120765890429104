import BedienungsanleitungTabWrapper from "../../components/Bedienungsanleitungen/tabs/TabWrapper.vue";
import { IColumnDefinition } from "./types.data";
import BedienungsanleitungCellName from "../../components/Bedienungsanleitungen/cells/bedienungsanleitung-cell-name.vue";
import BedienungsanleitungCellDokumente from "../../components/Bedienungsanleitungen/cells/bedienungsanleitung-cell-dokumente.vue";
import BedienungsanleitungCellStandort from "../../components/Bedienungsanleitungen/cells/bedienungsanleitung-cell-standort.vue";
import { useBedienungsanleitung } from "../../services/bedienungsanleitung.service";

export enum bedienungsanleitungenModel {
  SINGULAR = "Bedienungsanleitung",
  PLURAL = "Bedienungsanleitungen",
  ROUTE = "/bedienungsanleitungen",
  ICON = "mdi-file-document-outline",
  ITEM_KEY = "bedienungsanleitung",
  ITEM_KEY_UPDATE = "bedienungsanleitungen",
}

export const defaultBedienungsanleitungHeader: string[] = [
  "bezeichnung",
  "dokumente",
];

export const bedienungsanleitungHeader: IColumnDefinition<any>[] = [
  {
    name: "bezeichnung",
    label: "Bezeichnung",
    field: "name",
    required: false,
    align: "left",
    sortable: true,
    cell: BedienungsanleitungCellName,
  },
  {
    name: "dokumente",
    label: "Dokumente",
    field: "dokumente",
    required: false,
    align: "left",
    sortable: false,
    cell: BedienungsanleitungCellDokumente,
  },
  {
    name: "standort",
    label: "Standort",
    required: false,
    align: "left",
    sortable: false,
    cell: BedienungsanleitungCellStandort,
  },
];

export const bedienungsanleitungData = {
  model: bedienungsanleitungenModel,
  api: useBedienungsanleitung(),
  header: bedienungsanleitungHeader,
  defaultHeader: defaultBedienungsanleitungHeader,
  tabWrapper: BedienungsanleitungTabWrapper,
};
