<template>
  <q-dialog
    ref="dialog"
    transition-show="slide-up"
    transition-hide="slide-down"
    @hide="hide"
  >
    <q-card class="q-dialog-plugin" style="min-width: 900px">
      <q-card-section>
        <q-card-section class="row items-center q-pa-none q-mb-sm">
          <div class="q-pa-none text-grey-10 text-h5">
            {{
              $t("erstellenManager.erstellen", [$t(`${model.ITEM_KEY}.name`)])
            }}
          </div>
          <q-space />
          <q-btn flat round @click="hide">
            <q-icon name="mdi-close" />
          </q-btn>
        </q-card-section>
        <q-separator />
      </q-card-section>
      <q-card-section>
        <component
          :is="tabWrapper"
          v-model:data="moduleData"
          :hasWritePermission="hasWritePermission"
          @updateInput="updateInput"
          @updateVariables="updateVariables"
        />
      </q-card-section>
      <q-card-section
        class="row items-center justify-end q-gutter-x-sm q-mt-md"
      >
        <q-btn flat color="grey-7" no-caps @click="hide">{{
          $t("input.abbrechen")
        }}</q-btn>
        <q-btn
          color="primary"
          no-caps
          :loading="creatingModule"
          @click="createAnlage"
          >{{
            $t("erstellenManager.erstellen", [$t(`${model.ITEM_KEY}.name`)])
          }}</q-btn
        >
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { QDialog, Notify } from "quasar";
import { IUpdate } from "../../data/views/types.data";
import i18n from "src/i18n";

export default defineComponent({
  props: {
    model: {
      type: Object,
      required: true,
    },
    parentModel: {
      type: Object,
      required: true,
    },
    api: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
    },
    unitId: {
      type: String,
      required: true,
    },
    tabWrapper: {
      required: true,
    },
    hasWritePermission: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const dialog = ref<QDialog | null>(null);

    const show = () => dialog.value!.show();
    const hide = () => dialog.value!.hide();

    const moduleData = ref<any>({ unitId: props.unitId });
    const moduleInput = ref<any>({});
    const moduleVariables = ref<any>({});
    const creatingModule = ref<boolean>(false);

    const updateInput = ({ origin, val }: IUpdate<any>) => {
      moduleInput.value = { ...moduleInput.value, [origin]: val };
    };

    const updateVariables = ({ origin, val }: IUpdate<any>) => {
      moduleVariables.value = { ...moduleVariables.value, [origin]: val };
    };

    const createAnlage = async () => {
      if (moduleInput.value.name) {
        try {
          creatingModule.value = true;

          // check for sdb module which is many-to-one
          if (
            props.parentModel.ITEM_KEY_UPDATE === "sicherheitsdatenblaetter"
          ) {
            const { result: item } = await props.api.create({
              object: {
                object: {
                  ...moduleInput.value,
                  unitId: props.unitId,
                  sicherheitsdatenblattId: props.id ? props.id : null,
                },
                relations: {
                  ...moduleVariables.value,
                },
              },
            });

            emit("ok", item);
          } else {
            const { result: item } = await props.api.create({
              object: {
                object: { ...moduleInput.value, unitId: props.unitId },
                relations: {
                  [props.parentModel.ITEM_KEY_UPDATE]: props.id
                    ? [props.id]
                    : null,
                  ...moduleVariables.value,
                },
              },
            });

            emit("ok", item);
          }

          hide();
        } catch {
          Notify.create({
            type: "ams-error",
            message: i18n.global.t("erstellenManager.nichtHinzugefuegt", [
              i18n.global.t(`${props.model.ITEM_KEY}.name`),
            ]),
          });
        } finally {
          creatingModule.value = false;
        }
      } else {
        Notify.create({
          type: "ams-warning",
          message: i18n.global.t("erstellenManager.nameBenoetigt"),
        });
      }
    };

    return {
      dialog,
      show,
      hide,
      moduleData,
      updateInput,
      updateVariables,
      creatingModule,
      createAnlage,
    };
  },
});
</script>
