<template>
  <q-input
    dense
    outlined
    color="primary"
    :label-color="labelColor"
    :label="label"
    :model-value="dateValue"
    stack-label
    :clearable="clearable"
    :readonly="readonly"
    @click="!readonly && $refs.datePicker.show()"
    @clear="$emit('update:model-value', '')"
  >
    <template #before>
      <q-icon class="cursor-pointer" name="mdi-calendar">
        <q-popup-proxy
          v-if="!readonly"
          ref="datePicker"
          transition-show="scale"
          transition-hide="scale"
        >
          <q-date
            :model-value="modelValue"
            :mask="mask"
            today-btn
            @update:model-value="
              (date) => {
                $refs.datePicker.hide();
                $emit('update:model-value', transformDate(date));
              }
            "
          />
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { date } from "quasar";
import { transformDate } from "../../utils";

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      default: "YYYY-MM-DD",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    labelColor: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const dateValue = computed(() =>
      date.formatDate(props.modelValue, "DD.MM.YYYY")
    );

    return {
      dateValue,
      transformDate,
    };
  },
});
</script>
