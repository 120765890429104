<template>
  <div>
    <div class="row items-center">
      <div class="row items-center" v-if="loading">
        <q-spinner size="20px" class="q-mx-md" />
      </div>
      <div v-else class="text-grey-9 text-bold">
        {{ $t('verwaltung.profildaten.description') }}
      </div>
      <q-space />
      <q-btn
        outline
        color="primary"
        no-caps
        :loading="updating"
        @click="updateProfil"
      >
        <q-icon name="mdi-content-save" left />
        {{ $t('input.speichern') }}
      </q-btn>
    </div>
    <q-separator class="q-my-md" />
    <div class="row q-mt-lg">
      <q-input
        class="col"
        outlined
        :label="$t('verwaltung.profildaten.vorname')"
        color="primary"
        dense
        v-model="profil.vorname"
      >
        <template #before>
          <q-icon name="mdi-account" />
        </template>
      </q-input>
      <q-input
        class="col q-ml-sm"
        outlined
        :label="$t('verwaltung.profildaten.nachname')"
        color="primary"
        dense
        v-model="profil.nachname"
      />
    </div>
    <q-input
      class="q-mt-md"
      outlined
      :label="$t('verwaltung.profildaten.email')"
      color="primary"
      dense
      readonly
      v-model="profil.email"
    >
      <template #before>
        <q-icon name="mdi-email" />
      </template>
    </q-input>
    <div class="row q-my-md">
      <q-input
        class="col"
        outlined
        :label="$t('verwaltung.profildaten.telefonnummer')"
        color="primary"
        dense
        v-model="profil.telefonnummer"
      >
        <template #before>
          <q-icon name="mdi-phone" />
        </template>
      </q-input>
      <q-input
        class="col q-ml-sm"
        outlined
        :label="$t('verwaltung.profildaten.mobilnummer')"
        color="primary"
        dense
        v-model="profil.mobilnummer"
      />
    </div>
    <div class="row q-my-md">
      <q-select
      :label="$t('verwaltung.profildaten.language')"
        class="col"
        style="width: 50%"
        v-model="profil.preferredLanguage"
        :options="availableLocales"
        outlined
        dense
      >
      <template #before>
        <q-icon name="mdi-earth"/>
      </template>
    </q-select>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch, watchEffect } from "vue";
import { Notify } from "quasar";
import client, { refreshAuthIfNeeded } from "../../services";
import { useI18n } from 'vue-i18n';
import { MeQuery } from '@/types/graphql.types';

const profil = ref({} as MeQuery['me']);
const loading = ref(false);
const updating = ref(false);

const getMe = async () => {
  try {
    loading.value = true;

    const { me } = await client.Me();
    profil.value = me;
  } finally {
    loading.value = false;
  }
};

getMe();

const { locale, availableLocales } = useI18n()

const updateProfil = async () => {
  try {
    updating.value = true;

    await client.UpdateMe({
      updateUser: {
        vorname: profil.value.vorname,
        nachname: profil.value.nachname,
        telefonnummer: profil.value.telefonnummer,
        mobilnummer: profil.value.mobilnummer,
        preferredLanguage: profil.value.preferredLanguage
      },
    });

    if (profil.value.preferredLanguage) {
      locale.value = profil.value.preferredLanguage
    }

    await refreshAuthIfNeeded("");

    Notify.create({
      type: "ams-success",
      message: "Profil aktualisiert!",
    });
  } catch {
    Notify.create({
      type: "ams-error",
      message: "Aktualisieren fehlgeschlagen!",
    });
  } finally {
    updating.value = false;
  }
};
</script>
