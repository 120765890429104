<template>
  <q-td :props="props" :key="colKey">
    {{ verbandbuch }}
  </q-td>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    props: {
      type: Object,
      required: true,
    },
    colKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const verbandbuch = computed(
      () => props.props.row.verbandbuch.name
    );

    return {
      verbandbuch,
    };
  },
});
</script>
