<template>
  <q-splitter v-model="splitter" :limits="[20, 80]">
    <template #before>

      <div class="text-grey-9 text-bold">
        {{ $t('verwaltung.berechtigungen.nutzer') }}
      </div>
      <q-spinner v-if="loading" size="24px" class="q-mt-xl block q-mx-auto" />
      <q-tree v-show="!loading" class="q-mt-sm" ref="tree" :nodes="nodes" node-key="id" selected-color="primary"
        v-model:selected="selected" no-nodes-label="Keine Nutzer gefunden, wählen Sie mindestens einen Standort aus">
      </q-tree>
    </template>
    <template #after>
      <div v-if="selected">
        <div class="row items-center no-wrap">
          <div class="text-grey-9 text-bold">
            {{ $t('verwaltung.berechtigungen.editPermissions') }}
          </div>
          <q-space />
          <q-btn class="q-ml-md" color="primary" outline no-caps no-wrap :loading="updating"
            :disable="!hasPermission(selected.split(' ')[1], 'write')" @click="updateScopes">
            <q-icon :name="hasPermission(selected.split(' ')[1], 'write')
              ? 'mdi-content-save-outline'
              : 'mdi-alert'
              " left />
            {{
              hasPermission(selected.split(" ")[1], "write")
              ? $t('input.speichern')
              : $t('verwaltung.berechtigungen.noWritePermissions')
            }}
          </q-btn>
        </div>
        <q-markup-table class="q-mt-md" separator="cell" flat bordered>
          <thead>
            <tr>
              <th class="text-left">{{ $t('verwaltung.berechtigungen.module') }}</th>
              <th class="text-left">{{ $t('verwaltung.berechtigungen.read') }}</th>
              <th class="text-left">{{ $t('verwaltung.berechtigungen.write') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="scope in scopes" :key="scope.module">
              <td>{{ $t(`${scope.module}.name`) }}</td>
              <td>
                <q-checkbox :disable="!hasPermission(selected.split(' ')[1], 'write')" dense v-model="scope.read"
                  color="blue" />
              </td>
              <td>
                <q-checkbox :disable="!hasPermission(selected.split(' ')[1], 'write')" dense v-model="scope.write"
                  color="blue" />
              </td>
            </tr>
          </tbody>
        </q-markup-table>
      </div>
      <div v-else class="text-center text-grey-9 text-bold q-px-xl">
        {{ $t('verwaltung.berechtigungen.chooseUnit') }}
      </div>
    </template>
  </q-splitter>
</template>

<script lang="ts" setup>
import { ref, computed, watch } from "vue";
import { IUnit } from "../../data/views/types.data";
import { QTree, Notify } from "quasar";
import { parseURN, serializeToUrn } from "@ams-pro/scope-utils";
import {
  roleModel,
  betriebsmittelModel,
  gefahrstoffeModel,
  gefaehrdungsbeurteilungenModel,
  betriebsanweisungenModel,
  verbandbuchModel,
} from "../../data";
import { useScopes } from "../../hooks/useScopes";
import { useStore } from "../../store";
import client from "../../services";
import { useI18n } from 'vue-i18n';

const roleModules = [
  roleModel,
  betriebsmittelModel,
  gefahrstoffeModel,
  gefaehrdungsbeurteilungenModel,
  betriebsanweisungenModel,
  verbandbuchModel,
];


const store = useStore();

const splitter = ref(30);
const selected = ref<any>(null);
const tree = ref<QTree | null>(null);

const scopes = ref<any>([]);

const { hasPermission } = useScopes(roleModel);

watch(
  () => selected.value,
  () => {
    const currentNode = tree.value?.getNodeByKey(selected.value);
    const parsedScopes = currentNode ? parseURN(currentNode.scopes) : null;

    scopes.value = roleModules.map((r) => {
      if (parsedScopes) {
        const scope = parsedScopes.get(r.ITEM_KEY);

        if (scope) {
          return {
            module: r.ITEM_KEY,
            label: r.PLURAL,
            read: scope.read || false,
            write: scope.write || false,
          };
        }
      }
      return {
        module: r.ITEM_KEY,
        label: r.PLURAL,
        read: false,
        write: false,
      };
    });
  }
);

const users = ref<any[]>([]);
const loading = ref<boolean>(false);

const refetch = async () => {
  try {
    loading.value = true;

    const { users: _users } = await client.GetUsersForUnit();
    users.value = _users;
  } finally {
    loading.value = false;
  }
};

refetch();

const units = ref<any[]>([]);

const fetchUnitResult = async () => {
  const { units: _units } = await client.GetUnits();
  units.value = _units;
};

fetchUnitResult();

watch(
  () => store.unitChange,
  async () => {
    selected.value = null;
    tree.value?.collapseAll();
    await refetch();
  }
);

const { t } = useI18n()

const nodes = computed(() =>
  users.value.map((user: any) => ({
    id: user.id,
    label:
      store.userId === user.id
        ? `${user.vorname} ${user.nachname} ${t('verwaltung.berechtigungen.you')}`
        : `${user.vorname} ${user.nachname}`,
    caption: user.email,
    icon: "mdi-account",
    selectable: false,
    handler: (userNode: any) => {
      const isExpanded: boolean = tree.value!.isExpanded(userNode.id);

      if (!isExpanded) {
        selected.value = null;
      }

      tree.value!.setExpanded(userNode.id, isExpanded);
    },
    children: user.accessibleUnits.reduce(
      (filtered: any[], accessibleUnit: any) => {
        const unit: IUnit = units.value.find(
          (u: IUnit) => u.id === accessibleUnit.unit
        );

        if (unit) {
          filtered.push({
            id: `${user.id} ${unit.id}`,
            label: hasPermission(unit.id, "read")
              ? unit.name
              : `${unit.name} (${t('verwaltung.berechtigungen.noReadPermissions')})`,
            icon: "mdi-factory",
            disabled: false,
            scopes: accessibleUnit.scopes,
          });
        }
        return filtered;
      },
      []
    ),
  }))
);

const updating = ref<boolean>(false);

const updateScopes = async () => {
  try {
    updating.value = false;

    const serializedScopes = serializeToUrn(
      scopes.value.map((s: any) => ({
        module: s.module,
        read: s.read,
        write: s.write,
      }))
    )
      .split(" ")
      .map((s) => (s.slice(-1) === ":" ? `${s}null` : s));

    const userId = selected.value.split(" ")[0];
    const unitId = selected.value.split(" ")[1];

    const { updateScopes } = await client.UpdateScopesForUser({
      unit: unitId,
      user: userId,
      updateScopes: {
        scopes: serializedScopes,
      },
    });

    await refetch();

    if (userId === store.userId) {
      store.setAuth({
        ...store.auth,
        accessibleUnits: updateScopes.accessibleUnits as any[],
      });
    }

    Notify.create({
      type: "ams-success",
      message: "Berechtigungen aktualisiert!",
    });
  } catch {
    Notify.create({
      type: "ams-error",
      message: "Aktualisieren fehlgeschlagen!",
    });
  } finally {
    updating.value = false;
  }
};

</script>
