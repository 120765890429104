import {
  CountPruefberichteQueryVariables,
  PruefberichtOverviewQueryVariables,
  CreatePruefberichtMutationVariables,
  DeletePruefberichtMutationVariables,
  UpdatePruefberichtMutationVariables,
} from "../types/graphql.types";
import client from "./index";
import { hashids } from "./hashids.service";

export const usePruefbericht = () => {
  return {
    fetchAll: async (variables: PruefberichtOverviewQueryVariables) => {
      const { findPruefberichte, countPruefberichte } =
        await client.PruefberichtOverview(variables);

      return {
        result: findPruefberichte,
        count: countPruefberichte,
      };
    },
    count: async (variables: CountPruefberichteQueryVariables) => {
      const { countPruefberichte } = await client.CountPruefberichte(variables);

      return {
        result: countPruefberichte,
      };
    },
    fetchById: async (id: string | number) => {
      if (typeof id === "string") {
        const [parsed] = hashids.decode(id) as number[];
        id = parsed;
      }

      const { findPruefberichtById } = await client.PruefberichtDetail({
        id,
      });

      return {
        result: findPruefberichtById,
      };
    },
    create: async (variables: CreatePruefberichtMutationVariables) => {
      const { createPruefbericht } = await client.CreatePruefbericht(variables);

      return { result: createPruefbericht };
    },
    update: async (variables: UpdatePruefberichtMutationVariables) => {
      const { updatePruefbericht } = await client.UpdatePruefbericht(variables);

      return { result: updatePruefbericht };
    },
    delete: async (variables: DeletePruefberichtMutationVariables) => {
      const { deletePruefbericht } = await client.DeletePruefbericht(variables);

      return { result: deletePruefbericht };
    },
  };
};
