<template>
  <q-select
    ref="amsStatusInput"
    :options="options"
    :label="label"
    outlined
    color="primary"
    :model-value="$t(`status.${value.val}`)"
    dense
    :readonly="readonly"
    @update:model-value="$emit('input', $event.val)"
  >
    <template #before>
      <q-btn
        class="rounded-borders"
        outline
        :color="value.color"
        dense
        size="12px"
        @click="$refs.amsStatusInput.showPopup()"
      >
        <q-icon :name="value.icon" size="18px" />
      </q-btn>
    </template>
    <template #option="scope">
      <q-item :class="`text-${scope.opt.color}`" v-bind="scope.itemProps">
        <q-item-section avatar>
          <q-btn
            class="rounded-borders"
            outline
            :color="scope.opt.color"
            dense
            size="12px"
          >
            <q-icon
              :name="scope.opt.icon"
              :color="scope.opt.color"
              size="18px"
            />
          </q-btn>
        </q-item-section>
        <q-item-section class="text-black">
          {{ $t(`status.${scope.opt.val}`) }}
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      default: "Status",
    },
    value: {
      type: Object,
      required: true,
    },
    statusOptions: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const options = computed(() => Object.values(props.statusOptions));

    return {
      options,
    };
  },
});
</script>
