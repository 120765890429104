<template>
  <div>
    <div class="row items-center">
      <div class="text-grey-9 text-bold">
        {{ $t('verwaltung.reports.detailedDescription') }}
      </div>
      <q-space />
      <q-btn outline color="primary" no-caps @click="save" :loading="loading">
        <q-icon name="mdi-content-save" left />
        {{ $t('input.speichern') }}
      </q-btn>
    </div>
    <q-table
      class="q-mt-md"
      flat
      color="primary"
      selection="multiple"
      :loading="!units.units"
      :columns="columns"
      :rows="units.units"
      no-data-label="Sie besitzen keine Standorte"
      v-model:selected="selected"
    >
    </q-table>
  </div>
</template>

<script lang="ts" setup>
import { trim } from "../utils";
import { Notify, QTableProps } from "quasar";
import client from "src/services";
import { ref } from "vue";
import { useI18n } from 'vue-i18n';

const loading = ref(false);

function valueOrDash(val: string): string {
  return val?.length ? val : "-";
}

const { t } = useI18n()

const columns: QTableProps["columns"] = [
  {
    name: "name",
    label: t("unit.bezeichnung"),
    field: "name",
    align: "left",
    format: valueOrDash,
    sortable: true,
  },
  {
    name: "ort",
    label: t("unit.ort"),
    field: "ort",
    align: "left",
    format: valueOrDash,
    sortable: true,
  },
  {
    name: "berufsgenossenschaft",
    label: t("unit.berufsgenossenschaft"),
    field: "berufsgenossenschaft",
    format: valueOrDash,
    align: "left",
    sortable: true,
  },
  {
    name: "anmerkungen",
    label: t("unit.anmerkungen"),
    field: "anmerkungen",
    align: "left",
    format: (val) => trim(val),
    sortable: true,
  },
];

const [units, disabledUnits] = await Promise.all([
  client.GetUnits(),
  client.GetReportingDisabledUnits(),
]);
const selected = ref(
  units.units.filter(
    (u) => !disabledUnits.getReportingDisabledUnits.includes(u.id)
  )
);

async function save() {
  try {
    loading.value = true;

    const updatedDisabledUnits = await client.SetUnitReportingStates({
      states: units.units.map((u) => ({
        unitId: u.id,
        enabled: selected.value.some((s) => s.id === u.id),
      })),
    });

    selected.value = units.units.filter(
      (u) => !updatedDisabledUnits.setUnitReportingStates.includes(u.id)
    );

    Notify.create({
      type: "ams-success",
      message: "Auswahl gespeichert!",
    });
  } catch {
    Notify.create({
      type: "ams-error",
      message: "Speichern fehlgeschlagen!",
    });
  } finally {
    loading.value = false;
  }
}
</script>
