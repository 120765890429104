<template>
  <div>
    <div class="text-h6 text-bold text-grey-9">
      {{ $t("gefaehrdungsbeurteilung.informationen.allgemein") }}
    </div>
    <div class="row q-gutter-x-md q-mt-md">
      <div class="col q-gutter-y-md">
        <ams-input
          :label="$t('gefaehrdungsbeurteilung.bezeichnung')"
          :value="data.name"
          @input="updateInput('name', $event)"
          icon="mdi-wrench-outline"
        />
        <q-select
          :label="$t('gefaehrdungsbeurteilung.art')"
          color="primary"
          outlined
          dense
          :model-value="artValue"
          @focus="openArtenManager"
        >
          <template #before>
            <q-icon name="mdi-cards-outline" />
          </template>
        </q-select>
      </div>
      <div class="col q-gutter-y-md">
        <q-select
          :label="$t('gefaehrdungsbeurteilung.kategorien')"
          color="primary"
          outlined
          dense
          :model-value="kategorieValue"
          @focus="openKategorieManager"
        >
          <template #before>
            <q-icon name="mdi-bookmark" />
          </template>
        </q-select>
        <q-select
          :label="$t('gefaehrdungsbeurteilung.raum')"
          color="primary"
          outlined
          dense
          :model-value="raumValue"
          @focus="openRaumManager"
        >
          <template #before>
            <q-icon name="mdi-garage" />
          </template>
        </q-select>
      </div>
    </div>
    <div class="text-h6 text-bold text-grey-9 q-mt-lg">
      {{ $t("gefaehrdungsbeurteilung.informationen.pruefinformationen") }}
    </div>
    <div class="row q-gutter-x-md q-mt-md">
      <div class="col q-gutter-y-md">
        <ams-date-input
          :label="$t('gefaehrdungsbeurteilung.letztesReview')"
          :model-value="data.letztesReview"
          @update:model-value="updateInput('letztesReview', $event)"
        />
        <ams-input
          :label="`${$t('gefaehrdungsbeurteilung.reviewIntervall')} (${$t(
            'input.monat',
            2
          )})`"
          :value="data.reviewIntervall || 0"
          type="number"
          @input="updateInput('reviewIntervall', parseInt($event, 10))"
          icon="mdi-history"
        />
        <ams-date-input
          readonly
          :label="`${$t('gefaehrdungsbeurteilung.naechstesReview')} (${$t(
            'input.automatischGeneriert'
          )})`"
          :model-value="naechstesReviewValue"
        />
      </div>
      <div class="col q-gutter-y-md">
        <ams-input
          :label="$t('gefaehrdungsbeurteilung.anmerkungen')"
          :value="data.anmerkungen!"
          type="textarea"
          icon="mdi-square-edit-outline"
          input-style="min-height: 80px"
          @input="updateInput('anmerkungen', $event)"
        />
      </div>
    </div>
    <div class="text-h6 text-bold text-grey-9 q-mt-lg">
      {{ $t("gefaehrdungsbeurteilung.informationen.freigabe") }}
    </div>
    <div class="row q-gutter-x-md q-mt-md">
      <div class="col q-gutter-y-md">
        <ams-status-input
          :label="$t('gefaehrdungsbeurteilung.status')"
          :value="statusValue"
          :statusOptions="gefaehrdungsbeurteilungStatus"
          @input="updateInput('status', $event)"
        />
      </div>
      <div class="col q-gutter-y-md">
        <ams-freigebender-input :freigebender="data.freigebender!" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { date, Dialog } from "quasar";
import AmsStatusInput from "../../../components/forms/AmsStatusInput.vue";
import AmsInput from "../../../components/forms/AmsInput.vue";
import {
  gefaehrdungsbeurteilungStatus,
  GefaehrdungsbeurteilungStatus,
  kategorienModel,
  gefaehrdungsbeurteilungArtenModel,
  raumModel,
} from "../../../data";
import AmsDateInput from "../../../components/forms/AmsDateInput.vue";
import { trim } from "../../../utils";
import RelationManagerDialog from "../../../components/RelationManager/RelationManagerDialog.vue";
import { useKategorie } from "../../../services/kategorie.service";
import { useRaum } from "../../../services/raum.service";
import AmsFreigebenderInput from "../../../components/forms/AmsFreigebender.vue";
import { useGefaehrdungsbeurteilungArt } from "../../../services/gefaehrdungsbeurteilungArt.service";
import { Gefaehrdungsbeurteilung } from "src/types/graphql.types";

const { addToDate } = date;

const props = defineProps<{
  data: Gefaehrdungsbeurteilung;
  hasWritePermission?: boolean;
}>();
const emit = defineEmits([
  "updateInput",
  "update:data",
  "updateVariables",
  "save",
]);

const updateInput = (origin: string, val: any) => {
  emit("updateInput", { origin, val });
  emit("update:data", { ...props.data, [origin]: val });
};

const updateVariables = (origin: string, val: any) => {
  emit("updateVariables", { origin, val });
  emit("update:data", { ...props.data, [origin]: val });
};

const updateKategorien = (origin: string, val: any[]) => {
  emit("updateVariables", { origin, val: val.map((v) => v.id) });
  emit("update:data", { ...props.data, [origin]: val });
};

const updateArt = (items: any[]) => {
  const val = items.length ? items[0] : { id: 0 };
  emit("updateInput", { origin: "artId", val: val.id });
  emit("update:data", { ...props.data, art: val });
};

const updateRaum = (items: any[]) => {
  const val = items.length ? items[0] : { id: 0 };
  emit("updateInput", { origin: "raumId", val: val.id });
  emit("update:data", { ...props.data, raum: val });
};

const naechstesReviewValue = computed(() => {
  if (props.data.letztesReview) {
    return addToDate(props.data.letztesReview as string, {
      month: props.data.reviewIntervall || 0,
    }).toString();
  }
  return "";
});

const statusValue = computed(
  () =>
    gefaehrdungsbeurteilungStatus[
      props.data.status || GefaehrdungsbeurteilungStatus.KEIN_ERGEBNIS
    ]
);

const raumValue = computed(() => props.data.raum?.name ?? "");

const kategorieValue = computed(() =>
  props.data.kategorien && props.data.kategorien.length
    ? trim(props.data.kategorien.map((k: any) => k.name).join(", "), 40)
    : ""
);

const artValue = computed(() => props.data.art?.name ?? "");

const openKategorieManager = () => {
  Dialog.create({
    component: RelationManagerDialog,
    componentProps: {
      api: useKategorie(),
      model: kategorienModel,
      preSelected: props.data.kategorien,
      unitId: props.data.unit.id,
      hasWritePermission: props.hasWritePermission,
    },
  }).onOk((items: any[]) => {
    updateKategorien("kategorien", items);
  });
};

const openArtenManager = () => {
  Dialog.create({
    component: RelationManagerDialog,
    componentProps: {
      api: useGefaehrdungsbeurteilungArt(),
      model: gefaehrdungsbeurteilungArtenModel,
      preSelected: props.data.art ? [props.data.art] : [],
      unitId: props.data.unit.id,
      selection: "single",
      hasWritePermission: props.hasWritePermission,
    },
  }).onOk((items: any[]) => {
    updateArt(items);
  });
};

const openRaumManager = () => {
  Dialog.create({
    component: RelationManagerDialog,
    componentProps: {
      api: useRaum(),
      model: raumModel,
      preSelected: props.data.raum ? [props.data.raum] : [],
      unitId: props.data.unit.id,
      selection: "single",
      hasWritePermission: props.hasWritePermission,
    },
  }).onOk((items: any[]) => {
    updateRaum(items);
  });
};
</script>
