<template>
  <ams-tab-informationen
    :data="data"
    :hasWritePermission="hasWritePermission"
    @update:data="$emit('update:data', $event)"
    @updateInput="$emit('updateInput', $event)"
    @updateVariables="$emit('updateVariables', $event)"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TabInformationen from "./TabInformationen.vue";

export default defineComponent({
  components: {
    "ams-tab-informationen": TabInformationen,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    hasWritePermission: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
