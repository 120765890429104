import { date, Dialog, Notify } from "quasar";
import client from "src/services";
import i18n from "src/i18n";

export const searchKey = <T>(search: string, key: keyof T, list: T[]) => {
  if (!search) return list;
  return list.filter(
    (item: T) =>
      (item[key] as unknown as string)
        .toLowerCase()
        .indexOf(search.toLowerCase()) > -1
  );
};

export const transformDate = (date: string) => {
  if (!date) return null;

  const dateString = new Date(date).toISOString();
  return dateString.substring(0, dateString.length - 1);
};

export const downloadFile = async (dokument: any) => {
  if (typeof dokument === "object") {
    window.open(dokument.downloadUrl, "_blank");
  } else if (typeof dokument === "string") {
    const res = await client.DocumentById({ id: parseInt(dokument, 10) });
    window.open(res.findDocumentById.downloadUrl, "_blank");
  } else {
    const res = await client.DocumentById({ id: dokument });
    window.open(res.findDocumentById.downloadUrl, "_blank");
  }
};

export const confirmDelete = (
  title: string,
  message: string,
  cb: () => Promise<void>
) => {
  Dialog.create({
    title,
    persistent: true,
    options: {
      type: "checkbox",
      model: [],
      isValid: (model) => model.includes("check"),
      items: [{ label: message, value: "check", color: "red" }],
    },
    ok: {
      label: i18n.global.t("input.unwiderruflichLoeschen"),
      color: "red",
      noCaps: true,
    },
    cancel: {
      label: i18n.global.t("input.abbrechen"),
      flat: true,
      noCaps: true,
    },
  }).onOk(cb);
};

export const confirmArchive = (
  title: string,
  message: string,
  cb: () => Promise<void>,
  archive: boolean = true
) => {
  Dialog.create({
    title,
    message,
    persistent: true,
    ok: {
      label: archive
        ? i18n.global.t("input.archivieren")
        : i18n.global.t("input.wiederherstellen"),
      color: "primary",
      noCaps: true,
    },
    cancel: {
      label: i18n.global.t("input.abbrechen"),
      flat: true,
      noCaps: true,
    },
  }).onOk(cb);
};

export const confirmRestore = (
  title: string,
  message: string,
  cb: () => Promise<void>
) => {
  Dialog.create({
    title,
    message,
    persistent: true,
    ok: {
      label: i18n.global.t("input.wiederherstellen"),
      color: "primary",
      noCaps: true,
    },
    cancel: {
      label: i18n.global.t("input.abbrechen"),
      flat: true,
      noCaps: true,
    },
  }).onOk(cb);
};

export const updatePropertyDialog = (
  label: string,
  name: string,
  update: (name: string) => Promise<void>
) => {
  Dialog.create({
    title: `${label} bearbeiten`,
    prompt: {
      model: name,
      label,
      outlined: true,
    },
    ok: {
      label: "Aktualisieren",
      color: "primary",
      noCaps: true,
    },
    cancel: {
      label: "Abbrechen",
      flat: true,
      noCaps: true,
    },
  }).onOk(update);
};

export const createAnlageDialog = (
  title: string,
  message: string,
  create: () => Promise<void>,
  link: () => Promise<void>
) => {
  Dialog.create({
    title,
    message,
    persistent: true,
    focus: "none",
    ok: {
      label: "Bestehende hinzufügen",
      color: "primary",
      noCaps: true,
    },
    cancel: {
      label: "Neue erstellen",
      color: "primary",
      noCaps: true,
    },
  })
    .onOk(link)
    .onCancel(create);
};

export const missingWritePermission = () =>
  Notify.create({
    type: "ams-warning",
    message: "Sie besitzen keine Schreibrechte!",
    caption:
      "Wenden Sie sich an den Standortbesitzer, um Berechtigungen zu ändern",
  });

export const isDateValid = (dateString: any) => {
  if (typeof dateString === "object") {
    return false;
  }
  const regEx = /^\d{4}-\d{2}-\d{2}T00:00:00.000$/;
  return dateString.match(regEx) != null;
};

export const encodeFilter = (filterObject: any, op: "and" | "or" = "and") => {
  let filterString = "";

  Object.values(filterObject).forEach((operationObject: any) => {
    const operationKey = Object.keys(operationObject)[0];

    let operation = "";

    if (operationKey.toString() === "$or") {
      operation += encodeFilter(operationObject[operationKey], "or");
    } else if (operationKey.toString() === "$and") {
      operation += encodeFilter(operationObject[operationKey], "and");
    } else {
      operation = `${operationKey.toString()}(`;

      const nestedKey = Object.keys(operationObject[operationKey])[0];

      if (nestedKey === "archived") {
        return;
      }

      if (typeof operationObject[operationKey][nestedKey] === "boolean") {
        operation += `${nestedKey.toString()},${JSON.stringify(
          operationObject[operationKey][nestedKey]
        )}`;
      } else if (isDateValid(operationObject[operationKey][nestedKey])) {
        operation += `${nestedKey.toString()},${JSON.stringify(
          operationObject[operationKey][nestedKey]
        ).replace(/"/g, "")}`;
      } else {
        operation += `${nestedKey.toString()},${JSON.stringify(
          operationObject[operationKey][nestedKey]
        )}`;
      }

      operation += ")";
    }

    filterString = filterString
      ? `${op}(${operation},${filterString})`
      : operation;
  });

  return filterString;
};

export const formatDate = (value: string, formatString?: string): string =>
  date.formatDate(value, formatString || "DD.MM.YYYY");

export const trim = (value: string, length?: number): string => {
  if (value) {
    return value.length < (length || 30)
      ? value
      : `${value.substring(0, length || 30)}…`;
  }
  return "-";
};

const byteValueNumberFormatter = Intl.NumberFormat("en", {
  notation: "compact",
  style: "unit",
  unit: "byte",
  unitDisplay: "narrow",
});

export const humanFileSize = (size?: number): string =>
  size
    ? byteValueNumberFormatter
        .formatToParts(size)
        .map(({ value }) => value)
        .join("")
    : "-";
