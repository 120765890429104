import { PSatzOverviewQueryVariables } from "../types/graphql.types";
import client from "./index";

export const usePSatz = () => {
  return {
    fetchAll: async (variables: PSatzOverviewQueryVariables) => {
      const { findPSaetze } = await client.PSatzOverview(variables);

      return {
        result: findPSaetze,
      };
    },
  };
};
