import {
  GefaehrdungsbeurteilungArtOverviewQueryVariables,
  CreateGefaehrdungsbeurteilungArtMutationVariables,
  DeleteGefaehrdungsbeurteilungArtMutationVariables,
  UpdateGefaehrdungsbeurteilungArtMutationVariables,
} from "../types/graphql.types";
import client from "./index";
import { hashids } from "./hashids.service";

export const useGefaehrdungsbeurteilungArt = () => {
  return {
    fetchAll: async (
      variables: GefaehrdungsbeurteilungArtOverviewQueryVariables
    ) => {
      const { findGefaehrdungsbeurteilungArten } =
        await client.GefaehrdungsbeurteilungArtOverview(variables);

      return {
        result: findGefaehrdungsbeurteilungArten,
      };
    },
    fetchById: async (id: string | number) => {
      if (typeof id === "string") {
        const [parsed] = hashids.decode(id) as number[];
        id = parsed;
      }

      const { findGefaehrdungsbeurteilungArtById } =
        await client.GefaehrdungsbeurteilungArtById({
          id,
        });

      return {
        result: findGefaehrdungsbeurteilungArtById,
      };
    },
    create: async (
      variables: CreateGefaehrdungsbeurteilungArtMutationVariables
    ) => {
      const { createGefaehrdungsbeurteilungArt } =
        await client.CreateGefaehrdungsbeurteilungArt(variables);

      return { result: createGefaehrdungsbeurteilungArt };
    },
    update: async (
      variables: UpdateGefaehrdungsbeurteilungArtMutationVariables
    ) => {
      const { updateGefaehrdungsbeurteilungArt } =
        await client.UpdateGefaehrdungsbeurteilungArt(variables);

      return { result: updateGefaehrdungsbeurteilungArt };
    },
    delete: async (
      variables: DeleteGefaehrdungsbeurteilungArtMutationVariables
    ) => {
      const { deleteGefaehrdungsbeurteilungArt } =
        await client.DeleteGefaehrdungsbeurteilungArt(variables);

      return { result: deleteGefaehrdungsbeurteilungArt };
    },
  };
};
