<template>
  <q-td :props="props" :key="colKey">
    <q-btn
      outline
      round
      :color="vorhanden ? 'primary' : 'grey-7'"
      dense
      size="12px"
      class="q-mr-xs"
      @click.stop="route"
    >
      <q-icon name="mdi-clipboard-text" size="18px" />
      <q-tooltip>{{
        `${$t("sicherheitsdatenblatt.name")} (${
          vorhanden ? $t("input.hinterlegt") : $t("input.nichtHinterlegt")
        })`
      }}</q-tooltip>
    </q-btn>
  </q-td>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRouter } from "vue-router";
import { gefahrstoffeModel } from "../../../data";
import { hashids } from "../../../services/hashids.service";

export default defineComponent({
  props: {
    props: {
      type: Object,
      required: true,
    },
    colKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();

    const vorhanden = computed<boolean>(
      () => !!props.props.row.sicherheitsdatenblatt
    );

    const route = (anlage: any) =>
      router.push(
        `${gefahrstoffeModel.ROUTE}/${hashids.encode(
          props.props.row.id
        )}/sicherheitsdatenblatt`
      );

    return {
      vorhanden,
      route,
    };
  },
});
</script>
