<template>
  <q-td :props="props" :key="colKey">
    <ams-base-cell-standort :props="eintrag" />
  </q-td>
</template>

<script lang="ts">
import BaseCellStandort from "../../../components/BaseCells/BaseCellStandort.vue";
import { defineComponent, computed } from "vue";

export default defineComponent({
  components: {
    "ams-base-cell-standort": BaseCellStandort,
  },
  props: {
    props: {
      type: Object,
      required: true,
    },
    colKey: {
      type: String,
      required: true,
    },
    verbandbuecher: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const eintrag = computed(() => ({
      ...props.props,
      row: {
        ...props.props.row,
        unit: props.props.row.verbandbuch.unit,
      },
    }));

    return {
      eintrag,
    };
  },
});
</script>
