<template>
  <q-splitter
    v-model="splitter"
    :limits="[25, 70]"
    style="height: calc(100vh - 300px)"
  >
    <template #before>
      <div
        :class="`q-pr-md ${dragging ? 'dragging' : 'not-dragging'}`"
        @drop.prevent="addFiles"
        @dragover.prevent="dragging = true"
        @dragleave.self="dragging = false"
      >
        <div v-if="selected.length">
          <div class="text-bold text-grey-9">
            {{ $t("input.verknuepft") }}
            {{ $t(`${model.ITEM_KEY}.name`, 2) }} ({{ selected.length }})
          </div>
          <div class="text-grey-9 q-mt-xs" style="font-size: 12px">
            <q-icon name="mdi-information-outline" />
            {{ $t(`linkTab.dateien`, [$t(`${model.ITEM_KEY}.name`, 2)]) }}
          </div>
          <q-virtual-scroll
            class="q-mt-md"
            :items="selected"
            style="max-height: calc(100vh - 410px)"
            separator
          >
            <template v-slot="{ item }">
              <q-expansion-item
                group="anlagen"
                @show="openDokumenteDownload(item)"
              >
                <template #header>
                  <q-item-section avatar>
                    <q-spinner v-if="loadingDokumente" size="24px" />
                    <q-icon v-else :name="model.ICON" size="24px" />
                  </q-item-section>
                  <q-item-section>{{ item.name || "-" }}</q-item-section>
                  <q-item-section side>
                    <div class="row items-center q-gutter-x-xs">
                      <q-btn
                        outline
                        round
                        size="sm"
                        @click.stop="openNewTab(item.id)"
                      >
                        <q-icon name="mdi-open-in-new" />
                        <q-tooltip>{{
                          $t(`input.imTabOeffnen`, [
                            $t(`${model.ITEM_KEY}.name`),
                          ])
                        }}</q-tooltip>
                      </q-btn>
                      <q-btn
                        outline
                        round
                        size="sm"
                        @click.stop="remove(item.id)"
                      >
                        <q-icon name="mdi-close" />
                        <q-tooltip>
                          {{
                            $t("linkTab.entfernen", [
                              $t(`${model.ITEM_KEY}.name`),
                              $t(`${parentModel.ITEM_KEY}.name`),
                            ])
                          }}
                        </q-tooltip>
                      </q-btn>
                    </div>
                  </q-item-section>
                </template>
                <div v-if="anlagenDetails">
                  <div v-if="anlagenDetails.dokumente?.length">
                    <div class="q-ma-sm">{{ $t("dokument.name", 2) }}:</div>
                    <q-list separator>
                      <q-item
                        v-for="dokument in anlagenDetails.dokumente"
                        :key="dokument.id"
                      >
                        <q-item-section avatar>
                          <q-icon class="q-ml-md" name="mdi-paperclip" />
                        </q-item-section>
                        <q-item-section>
                          <q-item-label>{{ dokument.name }}</q-item-label>
                          <q-item-label caption class="flex">
                            <ams-format-date
                              :date="dokument.updatedAt"
                              dateString="DD.MM.YYYY HH:mm"
                            />, {{ humanFileSize(dokument.size) }}
                          </q-item-label>
                        </q-item-section>
                        <q-item-section side>
                          <q-btn
                            outline
                            round
                            size="sm"
                            @click.stop="downloadFile(dokument)"
                          >
                            <q-icon name="mdi-download" />
                            <q-tooltip>{{
                              $t("dokument.herunterladen")
                            }}</q-tooltip>
                          </q-btn>
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </div>
                  <div v-else class="q-ma-md">
                    {{ $t("linkTab.keineVorhanden", [$t("dokument.name", 2)]) }}
                  </div>
                </div>
              </q-expansion-item>
            </template>
          </q-virtual-scroll>
        </div>
        <div v-else class="text-center text-grey-7 text-bold q-mt-xl">
          {{ $t("linkTab.keineVerknuepft", [$t(`${model.ITEM_KEY}.name`, 2)]) }}
          <div class="text-grey-9 text-center q-mt-xs" style="font-size: 12px">
            <q-icon name="mdi-information-outline" />
            {{ $t("linkTab.dateien", [$t(`${model.ITEM_KEY}.name`, 2)]) }}
          </div>
        </div>
      </div>
    </template>
    <template #after>
      <div class="q-pl-md">
        <div class="text-bold text-grey-9">
          {{ $t(`${model.ITEM_KEY}.name`, 2) }}
        </div>
        <div class="row items-center q-mt-sm">
          <q-input
            :label="$t('input.suche')"
            clearable
            outlined
            color="primary"
            dense
            style="max-width: 300px"
            class="full-width"
            debounce="400"
            v-model="search"
            @update:model-value="updateSearch"
          >
            <template #prepend>
              <q-icon name="mdi-magnify" />
            </template>
          </q-input>
          <div class="q-mx-lg text-subtitle1 text-bold text-grey-9">
            {{ $t("moduleHeader.treffer", rowsNumber || 0) }}
          </div>
          <q-space />
          <q-btn outline no-caps color="primary" @click="createAnlage">
            <q-icon name="mdi-plus" left />{{ $t("input.erstellen") }}
          </q-btn>
        </div>
        <q-table
          virtual-scroll
          flat
          bordered
          class="ams-data-table q-mt-sm"
          style="max-height: calc(100vh - 350px)"
          selection="multiple"
          :columns="header"
          :visible-columns="defaultHeader"
          :rows="result"
          v-model:selected="selected"
          :pagination="pagination"
          :virtual-scroll-sticky-size-start="48"
          :loading="loading"
          :hide-bottom="!!result.length"
          :no-data-label="
            $t(`linkTab.keineVorhanden`, [$t(`${model.ITEM_KEY}.name`, 2)])
          "
          @request="sort($event.pagination)"
          @update:selected="updateAnlagen"
          @virtual-scroll="fetchMore"
        >
          <template #header="props">
            <q-tr :props="props">
              <q-th class="text-left">
                <q-checkbox
                  class="q-ml-sm"
                  color="grey-7"
                  v-model="props.selected"
                  dense
                />
              </q-th>
              <q-th v-for="col in props.cols" :key="col.name" :props="props">{{
                $t(`${model.ITEM_KEY}.${col.name}`)
              }}</q-th>
            </q-tr>
          </template>
          <template #body="props">
            <q-tr :props="props" @click="props.selected = !props.selected">
              <q-td key="selected">
                <q-checkbox
                  class="q-ml-sm"
                  color="blue"
                  v-model="props.selected"
                  dense
                />
              </q-td>
              <component
                v-for="col in props.cols"
                :key="col.name"
                :is="col.cell"
                :props="props"
                :colKey="col.name"
                :hasWritePermission="hasWritePermission"
                @updateData="updateData"
              />
            </q-tr>
          </template>
        </q-table>
      </div>
    </template>
  </q-splitter>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from "vue";
import CheckListLoading from "../../components/loading/CheckListLoading.vue";
import { missingWritePermission } from "../../utils";
import { Notify, Dialog, Loading } from "quasar";
import { hashids } from "../../services/hashids.service";
import { useVirtualScroll } from "../../hooks";
import CreateAnlageDialog from "../../components/AnlagenLinkTab/CreateAnlageDialog.vue";
import { downloadFile, humanFileSize } from "../../utils";
import { useRouter } from "vue-router";
import { useDokument } from "src/services/dokument.service";
import i18n from "src/i18n";

export default defineComponent({
  components: {
    "ams-check-list-loading": CheckListLoading,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    parentModel: {
      type: Object,
      required: true,
    },
    api: {
      type: Object,
      required: true,
    },
    header: {
      type: Array,
      required: true,
    },
    defaultHeader: {
      type: Array,
      required: true,
    },
    anlagen: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      required: true,
    },
    tabWrapper: {
      required: true,
    },
    hasWritePermission: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const api = useDokument();

    const splitter = ref<number>(30);

    const id = router.currentRoute.value.params.id;
    const decodedId = computed(() => hashids.decode(id as string)[0]);

    const selected = ref<any[]>([]);
    const search = ref<string>("");

    const loadingDokumente = ref<boolean>(false);
    const anlagenDetails = ref<any>({});

    const dragging = ref<boolean>(false);

    watch(
      () => props.anlagen,
      () => (selected.value = [...props.anlagen]),
      {
        deep: true,
        immediate: true,
      }
    );

    const {
      data: result,
      loading,
      rowsNumber,
      pagination,
      filter,
      fetchMore,
      updateData,
      refetchData,
      sort,
    } = useVirtualScroll(props.api, 50, props.data.unit?.id);

    const updateSearch = (searchTerm: string | number | null) => {
      if (searchTerm) {
        const formattedSearch = search.value
          .split("")
          .map((s) => `${s}%`)
          .join("");

        filter.value = {
          val: {
            ...filter.value.val,
            search: {
              like: {
                name: `%${formattedSearch}`,
              },
            },
          },
          meta: {
            ...filter.value.meta,
            search: search.value,
          },
        };
      } else {
        const { search: _, ...val } = filter.value.val;
        const { search: __, ...meta } = filter.value.meta;
        filter.value = { val, meta };
      }
    };

    const createAnlage = () => {
      if (props.hasWritePermission) {
        Dialog.create({
          component: CreateAnlageDialog,
          componentProps: {
            model: props.model,
            parentModel: props.parentModel,
            api: props.api,
            id: decodedId.value,
            unitId: props.data.unit?.id,
            tabWrapper: props.tabWrapper,
            hasWritePermission: true,
          },
        }).onOk(async (item: any) => {
          await refetchData();
          updateAnlagen(
            [...selected.value, item].map((s) => ({
              id: s.id,
              name: s.name,
            }))
          );
        });
      } else {
        missingWritePermission();
      }
    };

    const updateAnlagen = (anlagen: readonly any[]) => {
      emit("update:data", {
        ...props.data,
        [props.model.ITEM_KEY_UPDATE]: anlagen,
      });

      emit("updateVariables", {
        origin: props.model.ITEM_KEY_UPDATE,
        val: anlagen.map((a: any) => a.id),
      });
    };

    const openNewTab = (id: string) => {
      window.open(
        `${window.location.protocol}//${window.location.host}${
          props.model.ROUTE
        }/${hashids.encode(id)}`,
        "_blank"
      );
    };

    const openDokumenteDownload = async (item: any) => {
      try {
        loadingDokumente.value = true;
        const { result } = await props.api.fetchById(item.id);
        anlagenDetails.value = result;
      } catch {
        Notify.create({
          type: "ams-error",
          message: i18n.global.t("nachricht.nichtHochgeladen"),
        });
      } finally {
        loadingDokumente.value = false;
      }
    };

    const remove = (id: string) => {
      selected.value = selected.value.filter((s) => s.id !== id);
      updateAnlagen(selected.value);
    };

    const addFiles = async (event: any) => {
      try {
        Loading.show();

        const files = [...event.dataTransfer.files];
        const items = [];

        if (files.length) {
          for (const file of files) {
            const { result: dokument } = await api.create({
              object: {
                object: {
                  unitId: props.data.unit?.id,
                  name: file.name,
                  size: file.size,
                },
              },
            });

            await fetch(dokument.uploadUrl, {
              method: "PUT",
              body: file,
            });

            const { result: item } = await props.api.create({
              object: {
                object: {
                  name: dokument.name,
                  unitId: props.data.unit?.id,
                },
                relations: {
                  dokumente: [dokument.id],
                  [props.parentModel.ITEM_KEY_UPDATE]: [decodedId.value],
                },
              },
            });

            items.push(item);
          }

          await refetchData();
          updateAnlagen(
            [...selected.value, ...items].map((s) => ({
              id: s.id,
              name: s.name,
            }))
          );

          Notify.create({
            type: "ams-success",
            message: i18n.global.t("nachricht.erstellt", [
              i18n.global.t(`${props.model.ITEM_KEY}.name`),
            ]),
          });
        }
      } catch {
        Notify.create({
          type: "ams-error",
          message: i18n.global.t("nachricht.nichtHochgeladen"),
        });
      } finally {
        Loading.hide();
        dragging.value = false;
      }
    };

    return {
      splitter,
      selected,
      result,
      loading,
      rowsNumber,
      pagination,
      fetchMore,
      sort,
      updateData,
      updateAnlagen,
      remove,
      createAnlage,
      openNewTab,
      search,
      updateSearch,
      openDokumenteDownload,
      addFiles,
      dragging,
      anlagenDetails,
      loadingDokumente,
      downloadFile,
      humanFileSize,
    };
  },
});
</script>

<style scoped>
.not-dragging {
  border: 2px dashed transparent;
  border-radius: 5px;
  padding: 5px;
}

.dragging {
  border: 2px dashed #027be3;
  border-radius: 5px;
  padding: 5px;
}
</style>
