<template>
  <q-td :props="props" :key="colKey">{{ menge }}</q-td>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    props: {
      type: Object,
      required: true,
    },
    colKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const menge = computed(() => {
      if (props.props.row.menge && props.props.row.mengeneinheit) {
        return `${props.props.row.menge} ${props.props.row.mengeneinheit}`;
      }
      return "-";
    });

    return {
      menge,
    };
  },
});
</script>
