<template>
  <div class="row items-center align-left" style="flex-wrap: nowrap">
    <q-btn v-if="props.row.archiviert" class="q-mr-xs" flat round size="8px">
      <q-icon name="mdi-archive-outline" color="grey-7" size="16px" />
      <q-tooltip>
        <div class="row items-center">
          <q-icon name="mdi-information-outline" size="16px" class="q-mr-sm" />
          <div>{{ $t("input.zurzeitArchiviert") }}</div>
        </div>
      </q-tooltip>
    </q-btn>
    <ams-trim :text="props.row.name" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    props: {
      type: Object,
      required: true,
    },
  },
});
</script>
