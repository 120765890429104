<template>
  <q-dialog
    ref="dialog"
    transition-show="slide-up"
    transition-hide="slide-down"
    full-width
    full-height
    @hide="hide"
  >
    <q-card class="q-dialog-plugin">
      <q-card-section>
        <q-card-section class="row items-center q-pa-none q-mb-sm">
          <div v-if="data.unitName" class="q-pa-none text-grey-10 text-h5">
            {{
              $t("erstellenManager.erstellenAmStandort", [
                $t(`${model.ITEM_KEY}.name`),
                data.unitName,
              ])
            }}
          </div>
          <div v-else class="q-pa-none text-grey-10 text-h5">
            {{
              $t("erstellenManager.erstellen", [$t(`${model.ITEM_KEY}.name`)])
            }}
          </div>
          <q-space />
          <q-btn flat round @click="hide">
            <q-icon name="mdi-close" />
          </q-btn>
        </q-card-section>
        <q-separator />
      </q-card-section>
      <q-card-section>
        <ams-create-dialog-layout
          :model="model"
          :data="data"
          :anlagenTabs="anlagenTabs"
          :moduleTabs="moduleTabs"
          :tabWrapper="tabWrapper"
          @updateData="updateData"
          @updateInput="updateInput"
          @updateVariables="updateVariables"
          @hide="hide"
          @updateRemote="updateRemote"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { QDialog, Loading, Notify } from "quasar";
import { IUpdate } from "../data/views/types.data";
import CreateDialogLayout from "../layouts/CreateDialogLayout.vue";
import i18n from "src/i18n";

export default defineComponent({
  components: {
    "ams-create-dialog-layout": CreateDialogLayout,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    api: {
      type: Object,
      required: true,
    },
    tabWrapper: {
      required: true,
    },
    anlagenTabs: {
      type: Array,
      default: () => [],
    },
    moduleTabs: {
      type: Array,
      default: () => [],
    },
    defaultData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const dialog = ref<QDialog | null>(null);

    const show = () => dialog.value!.show();
    const hide = () => dialog.value!.hide();

    const data = ref({ ...props.defaultData, unitId: null, unitName: null });
    const input = ref<any>({});
    const variables = ref<any>({});

    const updateData = (d: any) => {
      data.value = { ...d };
    };

    const updateInput = ({ origin, val }: IUpdate<any>) => {
      input.value = { ...input.value, [origin]: val };
    };

    const updateVariables = ({ origin, val }: IUpdate<any>) => {
      variables.value = { ...variables.value, [origin]: val };
    };

    const updateRemote = async () => {
      if (input.value.name) {
        try {
          Loading.show({
            message: i18n.global.t("erstellenManager.wirdErstellt", [
              i18n.global.t(`${props.model.ITEM_KEY}.name`),
            ]),
          });

          const { result: item } = await props.api.create({
            object: {
              object: input.value,
              relations: variables.value,
            },
          });
          emit("ok", item);
          Notify.create({
            type: "ams-success",
            message: i18n.global.t("erstellenManager.wurdeErstellt", [
              i18n.global.t(`${props.model.ITEM_KEY}.name`),
            ]),
          });
        } finally {
          Loading.hide();
          hide();
        }
      } else {
        Notify.create({
          type: "ams-warning",
          message: i18n.global.t("erstellenManager.nameBenoetigt"),
        });
      }
    };

    return {
      dialog,
      show,
      hide,
      data,
      input,
      variables,
      updateData,
      updateInput,
      updateVariables,
      updateRemote,
    };
  },
});
</script>
