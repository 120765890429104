import {
  bedienungsanleitungenModel,
  betriebsanweisungenModel,
  betriebsmittelModel,
  dokumenteModel,
  gefaehrdungsbeurteilungenModel,
  gefahrstoffeModel,
  protokolleModel,
  pruefberichteModel,
  sicherheitsdatenblaetterModel,
  verbandbuchModel,
  verwaltungModel,
} from "../data";
import { RouteRecordRaw } from "vue-router";
import Verwaltung from "../pages/Verwaltung.vue";
import PasswortAendern from "../components/Verwaltung/PasswortAendern.vue";
import Zahlungsdaten from "../components/Verwaltung/Zahlungsdaten.vue";
import StandortBearbeiten from "../components/Verwaltung/StandortBearbeiten.vue";
import StandortErstellen from "../components/Verwaltung/StandortErstellen.vue";
import NutzerHinzufuegen from "../components/Verwaltung/NutzerHinzufuegen.vue";
import Profildaten from "../components/Verwaltung/Profildaten.vue";
import Berechtigungen from "../components/Verwaltung/Berechtigungen.vue";
import Reports from "../pages/Reports.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/login",
    name: "Login",
    component: () => import("pages/Login.vue"),
    meta: {
      public: true,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("pages/Register.vue"),
    meta: {
      public: true,
    },
  },
  {
    path: "/pw/reset/:link",
    name: "PasswordReset",
    component: () => import("pages/PasswordReset.vue"),
    meta: {
      public: true,
    },
  },
  {
    path: "/",
    name: "Dashboard",
    component: () => import("pages/Dashboard.vue"),
  },
  {
    path: betriebsmittelModel.ROUTE,
    name: betriebsmittelModel.ITEM_KEY,
    component: () => import("pages/Betriebsmittel.vue"),
  },
  {
    path: `${betriebsmittelModel.ROUTE}/:id`,
    name: `${betriebsmittelModel.ITEM_KEY}Details`,
    component: () =>
      import("components/Betriebsmittel/BetriebsmittelDetails.vue"),
    props: true,
  },
  {
    path: `${betriebsmittelModel.ROUTE}/:id/:tab`,
    name: `${betriebsmittelModel.ITEM_KEY}DetailsTab`,
    component: () =>
      import("components/Betriebsmittel/BetriebsmittelDetails.vue"),
  },
  {
    path: gefahrstoffeModel.ROUTE,
    name: gefahrstoffeModel.ITEM_KEY,
    component: () => import("pages/Gefahrstoffe.vue"),
  },
  {
    path: `${gefahrstoffeModel.ROUTE}/:id`,
    name: `${gefahrstoffeModel.ITEM_KEY}Details`,
    component: () => import("components/Gefahrstoffe/GefahrstoffeDetails.vue"),
    props: true,
  },
  {
    path: `${gefahrstoffeModel.ROUTE}/:id/:tab`,
    name: `${gefahrstoffeModel.ITEM_KEY}DetailsTab`,
    component: () => import("components/Gefahrstoffe/GefahrstoffeDetails.vue"),
  },
  {
    path: gefaehrdungsbeurteilungenModel.ROUTE,
    name: gefaehrdungsbeurteilungenModel.ITEM_KEY,
    component: () => import("pages/Gefaehrdungsbeurteilungen.vue"),
  },
  {
    path: `${gefaehrdungsbeurteilungenModel.ROUTE}/:id`,
    name: `${gefaehrdungsbeurteilungenModel.ITEM_KEY}Details`,
    component: () =>
      import(
        "components/Gefaehrdungsbeurteilungen/GefaehrdungsbeurteilungenDetails.vue"
      ),
    props: true,
  },
  {
    path: `${gefaehrdungsbeurteilungenModel.ROUTE}/:id/:tab`,
    name: `${gefaehrdungsbeurteilungenModel.ITEM_KEY}DetailsTab`,
    component: () =>
      import(
        "components/Gefaehrdungsbeurteilungen/GefaehrdungsbeurteilungenDetails.vue"
      ),
  },
  {
    path: betriebsanweisungenModel.ROUTE,
    name: betriebsanweisungenModel.ITEM_KEY,
    component: () => import("pages/Betriebsanweisungen.vue"),
  },
  {
    path: `${betriebsanweisungenModel.ROUTE}/:id`,
    name: `${betriebsanweisungenModel.ITEM_KEY}Details`,
    component: () =>
      import("components/Betriebsanweisungen/BetriebsanweisungenDetails.vue"),
    props: true,
  },
  {
    path: `${betriebsanweisungenModel.ROUTE}/:id/:tab`,
    name: `${betriebsanweisungenModel.ITEM_KEY}DetailsTab`,
    component: () =>
      import("components/Betriebsanweisungen/BetriebsanweisungenDetails.vue"),
  },
  {
    path: bedienungsanleitungenModel.ROUTE,
    name: bedienungsanleitungenModel.ITEM_KEY,
    component: () => import("pages/Bedienungsanleitungen.vue"),
  },
  {
    path: `${bedienungsanleitungenModel.ROUTE}/:id`,
    name: `${bedienungsanleitungenModel.ITEM_KEY}Details`,
    component: () =>
      import(
        "components/Bedienungsanleitungen/BedienungsanleitungenDetails.vue"
      ),
    props: true,
  },
  {
    path: `${bedienungsanleitungenModel.ROUTE}/:id/:tab`,
    name: `${bedienungsanleitungenModel.ITEM_KEY}DetailsTab`,
    component: () =>
      import(
        "components/Bedienungsanleitungen/BedienungsanleitungenDetails.vue"
      ),
  },
  {
    path: pruefberichteModel.ROUTE,
    name: pruefberichteModel.ITEM_KEY,
    component: () => import("pages/Pruefberichte.vue"),
  },
  {
    path: `${pruefberichteModel.ROUTE}/:id`,
    name: `${pruefberichteModel.ITEM_KEY}Details`,
    component: () =>
      import("components/Pruefberichte/PruefberichteDetails.vue"),
    props: true,
  },
  {
    path: `${pruefberichteModel.ROUTE}/:id/:tab`,
    name: `${pruefberichteModel.ITEM_KEY}DetailsTab`,
    component: () =>
      import("components/Pruefberichte/PruefberichteDetails.vue"),
  },
  {
    path: sicherheitsdatenblaetterModel.ROUTE,
    name: sicherheitsdatenblaetterModel.ITEM_KEY,
    component: () => import("pages/Sicherheitsdatenblaetter.vue"),
  },
  {
    path: `${sicherheitsdatenblaetterModel.ROUTE}/:id`,
    name: `${sicherheitsdatenblaetterModel.ITEM_KEY}Details`,
    component: () =>
      import(
        "components/Sicherheitsdatenblaetter/SicherheitsdatenblaetterDetails.vue"
      ),
    props: true,
  },
  {
    path: `${sicherheitsdatenblaetterModel.ROUTE}/:id/:tab`,
    name: `${sicherheitsdatenblaetterModel.ITEM_KEY}DetailsTab`,
    component: () =>
      import(
        "components/Sicherheitsdatenblaetter/SicherheitsdatenblaetterDetails.vue"
      ),
  },
  {
    path: dokumenteModel.ROUTE,
    name: dokumenteModel.ITEM_KEY,
    component: () => import("pages/Dokumente.vue"),
  },
  {
    path: `${dokumenteModel.ROUTE}/:id`,
    name: `${dokumenteModel.ITEM_KEY}Details`,
    component: () => import("components/Dokumente/DokumenteDetails.vue"),
  },
  {
    path: `${dokumenteModel.ROUTE}/:id/:tab`,
    name: `${dokumenteModel.ITEM_KEY}DetailsTab`,
    component: () => import("components/Dokumente/DokumenteDetails.vue"),
  },
  {
    path: protokolleModel.ROUTE,
    name: protokolleModel.ITEM_KEY,
    component: () => import("pages/Protokolle.vue"),
  },
  {
    path: `${protokolleModel.ROUTE}/:id`,
    name: `${protokolleModel.ITEM_KEY}Details`,
    component: () => import("components/Dokumente/ProtokolleDetails.vue"),
  },
  {
    path: `${protokolleModel.ROUTE}/:id/:tab`,
    name: `${protokolleModel.ITEM_KEY}DetailsTab`,
    component: () => import("components/Dokumente/ProtokolleDetails.vue"),
  },
  {
    path: verbandbuchModel.ROUTE,
    name: verbandbuchModel.ITEM_KEY,
    component: () => import("pages/Verbandbuch.vue"),
  },
  {
    path: `${verbandbuchModel.ROUTE}/:id`,
    name: `${verbandbuchModel.ITEM_KEY}Details`,
    component: () => import("components/Verbandbuch/VerbandbuchDetails.vue"),
    props: true,
  },
  {
    path: `${verbandbuchModel.ROUTE}/:id/:tab`,
    name: `${verbandbuchModel.ITEM_KEY}DetailsTab`,
    component: () => import("components/Verbandbuch/VerbandbuchDetails.vue"),
  },
  {
    path: verwaltungModel.ROUTE,
    name: verwaltungModel.ITEM_KEY,
    component: Verwaltung,
    children: [
      {
        path: "standort",
        component: StandortBearbeiten,
      },
      {
        path: "nutzer",
        component: NutzerHinzufuegen,
      },
      {
        path: "standorterstellen",
        component: StandortErstellen,
      },
      {
        path: "profildaten",
        component: Profildaten,
      },
      {
        path: "passwortaendern",
        component: PasswortAendern,
      },
      {
        path: "zahlungen",
        component: Zahlungsdaten,
      },
      {
        path: "berechtigungen",
        component: Berechtigungen,
      },
      {
        path: "reports",
        component: Reports,
      },
    ],
  },
  {
    path: "/profilverwalten",
    name: "ProfilVerwalten",
    component: () => import("pages/ProfilVerwaltung.vue"),
    meta: {
      protected: true,
    },
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: () => import("pages/Welcome.vue"),
    meta: {
      protected: true,
    },
  },
  {
    path: "/publiceverbandbuch/:identifier",
    name: "PublicVerbandbuch",
    component: () => import("pages/PublicVerbandbuch.vue"),
    meta: {
      public: true,
    },
  },
  {
    path: "/invite/accept/:token",
    name: "AcceptInvite",
    meta: {
      invite: true,
    },
    component: () => import("pages/AcceptInvite.vue"),
  },
  {
    path: "/:catchAll(.*)*",
    name: "Error",
    component: () => import("pages/NotFound.vue"),
  },
];

export default routes;
