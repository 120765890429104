<template>
  <div>
    <div class="text-h6 text-bold text-grey-9">
      {{ $t("gefahrstoff.informationen.substitutionspruefung") }}
    </div>
    <div class="row q-gutter-x-md q-mt-md">
      <div class="col q-gutter-y-md">
        <ams-date-input
          :label="$t('gefahrstoff.letzteSubstitutionspruefung')"
          :model-value="data.letzteSubstitutionspruefung"
          @update:model-value="
            updateInput('letzteSubstitutionspruefung', $event)
          "
        />
        <ams-input
          :label="`${$t('gefahrstoff.pruefintervall')} (${$t(
            'input.monat',
            2
          )})`"
          :value="data.substitutionspruefungIntervall || 0"
          type="number"
          @input="
            updateInput('substitutionspruefungIntervall', parseInt($event, 10))
          "
          icon="mdi-history"
        />
        <ams-date-input
          readonly
          :label="`${$t('gefahrstoff.naechsteSubstitutionspruefung')} (${$t(
            'input.automatischGeneriert'
          )})`"
          :model-value="naechstePruefungValue"
        />
      </div>
      <div class="col q-gutter-y-md">
        <q-checkbox
          :label="$t('gefahrstoff.substitutionErforderlich')"
          :model-value="data.substitutionMoeglich || false"
          color="blue"
          @update:model-value="updateInput('substitutionMoeglich', $event)"
        />
      </div>
    </div>
    <div class="text-h6 text-bold text-grey-9 q-mt-lg">
      {{ $t("gefahrstoff.informationen.hUndPSaetze") }}
    </div>
    <div class="row q-gutter-x-md q-mt-sm">
      <div class="col q-gutter-y-md">
        <q-select
          :label="$t('gefahrstoff.hSaetze')"
          color="primary"
          outlined
          dense
          :model-value="hSatzValues"
          @focus="openHSaetzeManager"
        >
          <template #before>
            <q-icon name="mdi-alpha-h-box" />
          </template>
        </q-select>
      </div>
      <div class="col q-gutter-y-md">
        <q-select
          :label="$t('gefahrstoff.pSaetze')"
          color="primary"
          outlined
          dense
          :model-value="pSatzValues"
          @focus="openPSaetzeManager"
        >
          <template #before>
            <q-icon name="mdi-alpha-p-box" />
          </template>
        </q-select>
      </div>
    </div>
    <div class="text-h6 text-bold text-grey-9 q-mt-lg">
      {{ $t("gefahrstoff.informationen.gefahren") }}
    </div>
    <div class="row q-gutter-x-md q-mt-sm">
      <div class="col q-gutter-y-md">
        <div>
          <q-checkbox
            :label="$t('gefahrstoff.brandgefahr')"
            :model-value="data.brandgefahr || false"
            color="blue"
            @update:model-value="updateInput('brandgefahr', $event)"
          />
        </div>
        <div>
          <q-checkbox
            :label="$t('gefahrstoff.explosionsgefahr')"
            :model-value="data.explosionsgefahr || false"
            color="blue"
            @update:model-value="updateInput('explosionsgefahr', $event)"
          />
        </div>
        <div>
          <q-checkbox
            :label="$t('gefahrstoff.einatemgefahr')"
            :model-value="data.einatemgefahr || false"
            color="blue"
            @update:model-value="updateInput('einatemgefahr', $event)"
          />
        </div>
      </div>
      <div class="col q-gutter-y-md">
        <div>
          <q-checkbox
            :label="$t('gefahrstoff.hautkontaktgefahr')"
            :model-value="data.hautkontaktgefahr || false"
            color="blue"
            @update:model-value="updateInput('hautkontaktgefahr', $event)"
          />
        </div>
        <div>
          <q-checkbox
            :label="$t('gefahrstoff.cmr')"
            :model-value="data.cmr || false"
            color="blue"
            @update:model-value="updateInput('cmr', $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { date, Dialog } from "quasar";
import AmsInput from "../../../components/forms/AmsInput.vue";
import AmsDateInput from "../../../components/forms/AmsDateInput.vue";
import { trim } from "../../../utils";
import { useHSatz } from "../../../services/hsatz.service";
import { usePSatz } from "../../../services/psatz.service";
import SaetzeManagerDialog from "../../../components/SaetzeManager/SaetzeManagerDialog.vue";
import { Hsatz, type Gefahrstoff, Psatz } from "src/types/graphql.types";
import { hSatzModel } from "src/data/views/hsaetze.data";
import { pSatzModel } from "src/data/views/psaetze.data";

const { addToDate } = date;

const props = defineProps<{
  data: Gefahrstoff;
  hasWritePermission?: boolean;
}>();
const emit = defineEmits();

// export default defineComponent({
//   components: {
//     "ams-input": AmsInput,
//     "ams-date-input": AmsDateInput,
//   },
//   props: {
//     data: {
//       type: Object as unknown as Gefahrstoff,
//       required: true,
//     },
//     hasWritePermission: {
//       type: Boolean,
//       default: false,
//     },
//   },
//   setup(props, { emit }) {
const updateInput = (origin: string, val: any) => {
  emit("updateInput", { origin, val });
  emit("update:data", { ...props.data, [origin]: val });
};

const hSatzValues = computed(() => {
  return props.data.hSaetze && props.data.hSaetze.length
    ? trim(props.data.hSaetze.map((h) => h.code).join(", "), 40)
    : "";
});

const pSatzValues = computed(() =>
  props.data.pSaetze && props.data.pSaetze.length
    ? trim(props.data.pSaetze.map((p: any) => p.code).join(", "), 40)
    : ""
);

const updateSaetze = (origin: string, val: (Hsatz | Psatz)[]) => {
  emit("updateVariables", { origin, val: val.map((v) => v.id) });
  emit("update:data", { ...props.data, [origin]: val });
};

const naechstePruefungValue = computed(() => {
  if (props.data.letzteSubstitutionspruefung) {
    return addToDate(props.data.letzteSubstitutionspruefung as string, {
      month: props.data.substitutionspruefungIntervall || 0,
    }).toString();
  }
  return "";
});

const openHSaetzeManager = () => {
  Dialog.create({
    component: SaetzeManagerDialog,
    componentProps: {
      model: hSatzModel,
      api: useHSatz(),
      items: props.data.hSaetze,
      hasWritePermission: props.hasWritePermission,
    },
  }).onOk((items: any[]) => {
    updateSaetze("hSaetze", items);
  });
};

const openPSaetzeManager = () => {
  Dialog.create({
    component: SaetzeManagerDialog,
    componentProps: {
      model: pSatzModel,
      api: usePSatz(),
      items: props.data.pSaetze,
      hasWritePermission: props.hasWritePermission,
    },
  }).onOk((items: any[]) => {
    updateSaetze("pSaetze", items);
  });
};
</script>
