<template>
  <div>
    <div class="text-h6 text-bold text-grey-9">
      {{ $t("betriebsmittel.informationen.allgemein") }}
    </div>
    <div class="row q-gutter-x-md q-mt-md">
      <div class="col q-gutter-y-md">
        <ams-input
          :label="$t('betriebsmittel.bezeichnung')"
          :model-value="data.name"
          @input="updateInput('name', $event)"
          icon="mdi-wrench-outline"
        />
        <ams-input
          :label="$t('betriebsmittel.seriennummer')"
          :model-value="data.seriennummer"
          @input="updateInput('seriennummer', $event)"
          icon="mdi-barcode"
        />
        <ams-input
          :label="$t('betriebsmittel.inventarnummer')"
          :model-value="data.inventarnummer"
          @input="updateInput('inventarnummer', $event)"
          icon="mdi-minus-box-outline"
        />
      </div>
      <div class="col q-gutter-y-md">
        <q-select
          id="test"
          :label="$t('betriebsmittel.kategorien')"
          color="primary"
          outlined
          dense
          :model-value="kategorieValue"
          @focus="openKategorieManager"
        >
          <template #before>
            <q-icon name="mdi-bookmark" />
          </template>
        </q-select>
        <q-select
          :label="$t('betriebsmittel.raum')"
          color="primary"
          outlined
          dense
          :model-value="raumValue"
          @focus="openRaumManager"
        >
          <template #before>
            <q-icon name="mdi-garage" />
          </template>
        </q-select>
      </div>
    </div>
    <div class="text-h6 text-bold text-grey-9 q-mt-lg">
      {{ $t("betriebsmittel.informationen.pruefung") }}
    </div>
    <div class="row q-gutter-x-md q-mt-md">
      <div class="col q-gutter-y-md">
        <ams-date-input
          :label="$t('betriebsmittel.letztePruefung')"
          :model-value="data.letztePruefung"
          @update:model-value="updateInput('letztePruefung', $event)"
        />
        <ams-input
          :label="`${$t('betriebsmittel.pruefintervall')} (${$t(
            'input.monat',
            2
          )})`"
          :value="data.pruefintervall || 0"
          type="number"
          min="0"
          @input="updateInput('pruefintervall', parseInt($event, 10))"
          icon="mdi-history"
        />
        <ams-date-input
          readonly
          :label="`${$t('betriebsmittel.naechstePruefung')} (${$t(
            'input.automatischGeneriert'
          )})`"
          :model-value="naechstePruefungValue"
        />
      </div>
      <div class="col q-gutter-y-md">
        <ams-status-input
          :label="$t('betriebsmittel.status')"
          :value="statusValue"
          :statusOptions="betriebsmittelStatus"
          @input="updateInput('status', $event)"
        />
        <ams-input
          :label="$t('betriebsmittel.anmerkungen')"
          :model-value="data.anmerkungen"
          type="textarea"
          input-style="min-height: 80px"
          @input="updateInput('anmerkungen', $event)"
          icon="mdi-square-edit-outline"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { date, Dialog } from "quasar";
import AmsStatusInput from "../../../components/forms/AmsStatusInput.vue";
import AmsInput from "../../../components/forms/AmsInput.vue";
import {
  betriebsmittelStatus,
  BetriebsmittelStatus,
  kategorienModel,
  raumModel,
} from "../../../data";
import AmsDateInput from "../../../components/forms/AmsDateInput.vue";
import { trim } from "../../../utils";
import { useKategorie } from "../../../services/kategorie.service";
import RelationManagerDialog from "../../../components/RelationManager/RelationManagerDialog.vue";
import { useRaum } from "../../../services/raum.service";
import { Betriebsmittel } from "@/types/graphql.types";

const { addToDate } = date;

export default defineComponent({
  components: {
    "ams-input": AmsInput,
    "ams-status-input": AmsStatusInput,
    "ams-date-input": AmsDateInput,
  },
  props: {
    data: {
      type: Object as () => Betriebsmittel,
      required: true,
    },
    hasWritePermission: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const updateInput = (origin: string, val: any) => {
      emit("updateInput", { origin, val });
      emit("update:data", { ...props.data, [origin]: val });
    };

    const updateVariables = (origin: string, val: any) => {
      emit("updateVariables", { origin, val });
      emit("update:data", { ...props.data, [origin]: val });
    };

    const updateKategorien = (origin: string, val: any[]) => {
      emit("updateVariables", { origin, val: val.map((v) => v.id) });
      emit("update:data", { ...props.data, [origin]: val });
    };

    const updateRaum = (items: any[]) => {
      const val = items.length ? items[0] : { id: 0 };
      emit("updateInput", { origin: "raumId", val: val.id });
      emit("update:data", { ...props.data, raum: val });
    };

    const kategorieValue = computed(() =>
      props.data.kategorien && props.data.kategorien.length
        ? trim(props.data.kategorien.map((k: any) => k.name).join(", "), 40)
        : ""
    );

    const raumValue = computed(() => props.data.raum?.name ?? "");

    const naechstePruefungValue = computed(() => {
      if (props.data.letztePruefung) {
        return addToDate(props.data.letztePruefung as string, {
          month: props.data.pruefintervall || 0,
        }).toString();
      }
      return "";
    });

    const statusValue = computed(
      () =>
        betriebsmittelStatus[
          (props.data.status as BetriebsmittelStatus) ||
            BetriebsmittelStatus.KEIN_ERGEBNIS
        ]
    );

    const openKategorieManager = () => {
      Dialog.create({
        component: RelationManagerDialog,
        componentProps: {
          api: useKategorie(),
          model: kategorienModel,
          preSelected: props.data.kategorien,
          unitId: props.data.unit?.id,
          hasWritePermission: props.hasWritePermission,
        },
      }).onOk((items: any[]) => {
        updateKategorien("kategorien", items);
      });
    };

    const openRaumManager = () => {
      Dialog.create({
        component: RelationManagerDialog,
        componentProps: {
          api: useRaum(),
          model: raumModel,
          preSelected: props.data.raum ? [props.data.raum] : [],
          unitId: props.data.unit?.id,
          selection: "single",
          hasWritePermission: props.hasWritePermission,
        },
      }).onOk((items: any[]) => {
        updateRaum(items);
      });
    };

    return {
      updateInput,
      updateVariables,
      updateKategorien,
      updateRaum,
      kategorieValue,
      raumValue,
      naechstePruefungValue,
      statusValue,
      betriebsmittelStatus,
      useKategorie,
      kategorienModel,
      openKategorieManager,
      openRaumManager,
    };
  },
});
</script>
