import { GraphQLClient } from 'graphql-request';
import { GraphQLClientRequestHeaders } from 'graphql-request/build/cjs/types';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  NaiveDateTime: { input: any; output: any; }
};

export type AccessibleUnit = {
  default: Scalars['Boolean']['output'];
  scopes?: Maybe<Array<Scalars['String']['output']>>;
  unit: Scalars['String']['output'];
};

export type Bedienungsanleitung = {
  archiviert: Scalars['Boolean']['output'];
  betriebsmittel: Array<Betriebsmittel>;
  createdAt: Scalars['NaiveDateTime']['output'];
  dokumentCount: Scalars['Int']['output'];
  dokumente: Array<Document>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  unit: Unit;
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type BedienungsanleitungInput = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  unitId: Scalars['String']['input'];
};

export type BedienungsanleitungInputMutation = {
  object?: InputMaybe<BedienungsanleitungInput>;
  relations?: InputMaybe<BedienungsanleitungRelations>;
};

export type BedienungsanleitungPatch = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BedienungsanleitungPatchMutation = {
  object?: InputMaybe<BedienungsanleitungPatch>;
  relations?: InputMaybe<BedienungsanleitungRelations>;
};

export type BedienungsanleitungRelations = {
  betriebsmittel?: InputMaybe<Array<Scalars['Int']['input']>>;
  dokumente?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Betriebsanweisung = {
  anmerkungen?: Maybe<Scalars['String']['output']>;
  archiviert: Scalars['Boolean']['output'];
  art?: Maybe<BetriebsanweisungArt>;
  artId?: Maybe<Scalars['Int']['output']>;
  betriebsmittel: Array<Betriebsmittel>;
  betriebsmittelCount: Scalars['Int']['output'];
  createdAt: Scalars['NaiveDateTime']['output'];
  dokumentCount: Scalars['Int']['output'];
  dokumente: Array<Document>;
  freigebender?: Maybe<User>;
  gefahrstoffCount: Scalars['Int']['output'];
  gefahrstoffe: Array<Gefahrstoff>;
  id: Scalars['Int']['output'];
  kategorieCount: Scalars['Int']['output'];
  kategorien: Array<Kategorie>;
  letztesReview?: Maybe<Scalars['NaiveDateTime']['output']>;
  naechstesReview?: Maybe<Scalars['NaiveDateTime']['output']>;
  name: Scalars['String']['output'];
  raum?: Maybe<Raum>;
  raumId?: Maybe<Scalars['Int']['output']>;
  reviewIntervall: Scalars['Int']['output'];
  status: Scalars['String']['output'];
  unit: Unit;
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type BetriebsanweisungArt = {
  betriebsanweisungen: Array<Betriebsanweisung>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  unit: Unit;
};

export type BetriebsanweisungArtInput = {
  name: Scalars['String']['input'];
  unitId: Scalars['String']['input'];
};

export type BetriebsanweisungArtInputMutation = {
  object?: InputMaybe<BetriebsanweisungArtInput>;
};

export type BetriebsanweisungArtPatch = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BetriebsanweisungArtPatchMutation = {
  object?: InputMaybe<BetriebsanweisungArtPatch>;
};

export type BetriebsanweisungInput = {
  anmerkungen?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  artId?: InputMaybe<Scalars['Int']['input']>;
  letztesReview?: InputMaybe<Scalars['NaiveDateTime']['input']>;
  name: Scalars['String']['input'];
  raumId?: InputMaybe<Scalars['Int']['input']>;
  reviewIntervall?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  unitId: Scalars['String']['input'];
};

export type BetriebsanweisungInputMutation = {
  object?: InputMaybe<BetriebsanweisungInput>;
  relations?: InputMaybe<BetriebsanweisungRelations>;
};

export type BetriebsanweisungPatch = {
  anmerkungen?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  artId?: InputMaybe<Scalars['Int']['input']>;
  letztesReview?: InputMaybe<Scalars['NaiveDateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  raumId?: InputMaybe<Scalars['Int']['input']>;
  reviewIntervall?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type BetriebsanweisungPatchMutation = {
  object?: InputMaybe<BetriebsanweisungPatch>;
  relations?: InputMaybe<BetriebsanweisungRelations>;
};

export type BetriebsanweisungRelations = {
  betriebsmittel?: InputMaybe<Array<Scalars['Int']['input']>>;
  dokumente?: InputMaybe<Array<Scalars['Int']['input']>>;
  gefahrstoffe?: InputMaybe<Array<Scalars['Int']['input']>>;
  kategorien?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Betriebsmittel = {
  anmerkungen?: Maybe<Scalars['String']['output']>;
  archiviert: Scalars['Boolean']['output'];
  bedienungsanleitungCount: Scalars['Int']['output'];
  bedienungsanleitungen: Array<Bedienungsanleitung>;
  betriebsanweisungCount: Scalars['Int']['output'];
  betriebsanweisungen: Array<Betriebsanweisung>;
  createdAt: Scalars['NaiveDateTime']['output'];
  gefaehrdungsbeurteilungCount: Scalars['Int']['output'];
  gefaehrdungsbeurteilungen: Array<Gefaehrdungsbeurteilung>;
  id: Scalars['Int']['output'];
  inventarnummer?: Maybe<Scalars['String']['output']>;
  kategorieCount: Scalars['Int']['output'];
  kategorien: Array<Kategorie>;
  letztePruefung?: Maybe<Scalars['NaiveDateTime']['output']>;
  naechstePruefung?: Maybe<Scalars['NaiveDateTime']['output']>;
  name: Scalars['String']['output'];
  pruefberichtCount: Scalars['Int']['output'];
  pruefberichte: Array<Pruefbericht>;
  pruefintervall: Scalars['Int']['output'];
  raum?: Maybe<Raum>;
  raumId?: Maybe<Scalars['Int']['output']>;
  seriennummer?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  unit: Unit;
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type BetriebsmittelInput = {
  anmerkungen?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  inventarnummer?: InputMaybe<Scalars['String']['input']>;
  letztePruefung?: InputMaybe<Scalars['NaiveDateTime']['input']>;
  name: Scalars['String']['input'];
  pruefintervall?: InputMaybe<Scalars['Int']['input']>;
  raumId?: InputMaybe<Scalars['Int']['input']>;
  seriennummer?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  unitId: Scalars['String']['input'];
};

export type BetriebsmittelInputMutation = {
  object?: InputMaybe<BetriebsmittelInput>;
  relations?: InputMaybe<BetriebsmittelRelations>;
};

export type BetriebsmittelPatch = {
  anmerkungen?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  inventarnummer?: InputMaybe<Scalars['String']['input']>;
  letztePruefung?: InputMaybe<Scalars['NaiveDateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pruefintervall?: InputMaybe<Scalars['Int']['input']>;
  raumId?: InputMaybe<Scalars['Int']['input']>;
  seriennummer?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type BetriebsmittelPatchMutation = {
  object?: InputMaybe<BetriebsmittelPatch>;
  relations?: InputMaybe<BetriebsmittelRelations>;
};

export type BetriebsmittelRelations = {
  bedienungsanleitungen?: InputMaybe<Array<Scalars['Int']['input']>>;
  betriebsanweisungen?: InputMaybe<Array<Scalars['Int']['input']>>;
  gefaehrdungsbeurteilungen?: InputMaybe<Array<Scalars['Int']['input']>>;
  kategorien?: InputMaybe<Array<Scalars['Int']['input']>>;
  pruefberichte?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Company = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  units?: Maybe<Array<Unit>>;
};

export type CompanyUnion = Company | Unit;

export type CreateEintragDto = {
  arbeitsbereich: Scalars['String']['input'];
  eintragType: Scalars['String']['input'];
  erstehilfezeitpunkt: Scalars['String']['input'];
  ersthelfer: Scalars['String']['input'];
  hergang: Scalars['String']['input'];
  massnahmen: Scalars['String']['input'];
  umfang: Scalars['String']['input'];
  unfallort: Scalars['String']['input'];
  unfallzeitpunkt: Scalars['String']['input'];
  verbandbuch: Scalars['String']['input'];
  verletzter: Scalars['String']['input'];
  verletzung: Scalars['String']['input'];
  zeugen: Scalars['String']['input'];
};

export type CreatePublicEintragDto = {
  arbeitsbereich: Scalars['String']['input'];
  eintragType: Scalars['String']['input'];
  erstehilfezeitpunkt: Scalars['String']['input'];
  ersthelfer: Scalars['String']['input'];
  hergang: Scalars['String']['input'];
  massnahmen: Scalars['String']['input'];
  umfang: Scalars['String']['input'];
  unfallort: Scalars['String']['input'];
  unfallzeitpunkt: Scalars['String']['input'];
  verbandbuchIdentifier: Scalars['String']['input'];
  verletzter: Scalars['String']['input'];
  verletzung: Scalars['String']['input'];
  zeugen: Scalars['String']['input'];
};

export type CreateUnitInput = {
  anmerkungen?: InputMaybe<Scalars['String']['input']>;
  berufsgenossenschaft?: InputMaybe<Scalars['String']['input']>;
  hausnummer?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  ort?: InputMaybe<Scalars['String']['input']>;
  paymentAccountId: Scalars['String']['input'];
  plz?: InputMaybe<Scalars['String']['input']>;
  strasse?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVerbandbuchDto = {
  identifier?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notificationList?: InputMaybe<Array<Scalars['String']['input']>>;
  unitId: Scalars['String']['input'];
};

export type DashboardChart = {
  labels: Array<Scalars['String']['output']>;
  series: Array<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  view: Scalars['String']['output'];
};

export type DateFilter = {
  /** Datum, bis zu welchen Einträge angezeigt werden. **Muss** ein Iso-String (mit Z!) sein */
  bis?: InputMaybe<Scalars['String']['input']>;
  /** Datum, ab dem Einträge angezeigt werden. **Muss** ein Iso-String (mit Z!) sein */
  von?: InputMaybe<Scalars['String']['input']>;
};

export type DiagramInformation = {
  date: Scalars['String']['output'];
  total: Scalars['Float']['output'];
};

export type Document = {
  archiviert: Scalars['Boolean']['output'];
  bedienungsanleitungen: Array<Bedienungsanleitung>;
  betriebsanweisungen: Array<Betriebsanweisung>;
  createdAt: Scalars['NaiveDateTime']['output'];
  downloadUrl: Scalars['String']['output'];
  gefaehrdungsbeurteilungen: Array<Gefaehrdungsbeurteilung>;
  id: Scalars['Int']['output'];
  isPublic: Scalars['Boolean']['output'];
  mimetype?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  objectKey: Scalars['String']['output'];
  ownerId: Scalars['String']['output'];
  protokoll: Scalars['Boolean']['output'];
  pruefberichte: Array<Pruefbericht>;
  sicherheitsdatenblaetter: Array<Sicherheitsdatenblatt>;
  size: Scalars['Int']['output'];
  tags: Array<Tag>;
  unit: Unit;
  updatedAt: Scalars['NaiveDateTime']['output'];
  uploadUrl: Scalars['String']['output'];
};

export type DocumentInput = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  mimetype?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  protokoll?: InputMaybe<Scalars['Boolean']['input']>;
  size: Scalars['Int']['input'];
  unitId: Scalars['String']['input'];
};

export type DocumentInputMutation = {
  object?: InputMaybe<DocumentInput>;
  relations?: InputMaybe<DocumentRelations>;
};

export type DocumentPatch = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  protokoll?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DocumentPatchMutation = {
  object?: InputMaybe<DocumentPatch>;
  relations?: InputMaybe<DocumentRelations>;
};

export type DocumentRelations = {
  bedienungsanleitungen?: InputMaybe<Array<Scalars['Int']['input']>>;
  betriebsanweisungen?: InputMaybe<Array<Scalars['Int']['input']>>;
  gefaehrdungsbeurteilungen?: InputMaybe<Array<Scalars['Int']['input']>>;
  pruefberichte?: InputMaybe<Array<Scalars['Int']['input']>>;
  sicherheitsdatenblaetter?: InputMaybe<Array<Scalars['Int']['input']>>;
  tags?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Eintrag = {
  arbeitsbereich: Scalars['String']['output'];
  archiviert?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  eintragType: Scalars['String']['output'];
  erstehilfezeitpunkt: Scalars['DateTime']['output'];
  ersthelfer: Scalars['String']['output'];
  hergang: Scalars['String']['output'];
  id: Scalars['String']['output'];
  massnahmen: Scalars['String']['output'];
  revision?: Maybe<Scalars['Int']['output']>;
  umfang: Scalars['String']['output'];
  unfallort: Scalars['String']['output'];
  unfallzeitpunkt: Scalars['DateTime']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  verbandbuch: Verbandbuch;
  verletzter: Scalars['String']['output'];
  verletzung: Scalars['String']['output'];
  zeugen: Scalars['String']['output'];
};

export type EintragFilterArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  eintragType?: InputMaybe<Scalars['String']['input']>;
  /**
   * Filter für den Unfallzeitpunkt. Beide Properties **müssen** ISO-Strings sein.
   * Es ist möglich nur eine der beiden Properites anzugeben.
   */
  unfallzeitpunkt?: InputMaybe<DateFilter>;
  verletzter?: InputMaybe<Scalars['String']['input']>;
};

export type EintragRevision = {
  arbeitsbereich: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  eintragType: Scalars['String']['output'];
  erstehilfezeitpunkt: Scalars['DateTime']['output'];
  ersthelfer: Scalars['String']['output'];
  hergang: Scalars['String']['output'];
  historyId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  massnahmen: Scalars['String']['output'];
  revision?: Maybe<Scalars['Int']['output']>;
  umfang: Scalars['String']['output'];
  unfallort: Scalars['String']['output'];
  unfallzeitpunkt: Scalars['DateTime']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  verbandbuch: Scalars['String']['output'];
  verletzter: Scalars['String']['output'];
  verletzung: Scalars['String']['output'];
  zeugen: Scalars['String']['output'];
};

export type Gefaehrdungsbeurteilung = {
  anmerkungen?: Maybe<Scalars['String']['output']>;
  archiviert: Scalars['Boolean']['output'];
  art?: Maybe<GefaehrdungsbeurteilungArt>;
  artId?: Maybe<Scalars['Int']['output']>;
  betriebsmittel: Array<Betriebsmittel>;
  betriebsmittelCount: Scalars['Int']['output'];
  createdAt: Scalars['NaiveDateTime']['output'];
  dokumentCount: Scalars['Int']['output'];
  dokumente: Array<Document>;
  freigebender?: Maybe<User>;
  gefahrstoffCount: Scalars['Int']['output'];
  gefahrstoffe: Array<Gefahrstoff>;
  id: Scalars['Int']['output'];
  kategorieCount: Scalars['Int']['output'];
  kategorien: Array<Kategorie>;
  letztesReview?: Maybe<Scalars['NaiveDateTime']['output']>;
  naechstesReview?: Maybe<Scalars['NaiveDateTime']['output']>;
  name: Scalars['String']['output'];
  raum?: Maybe<Raum>;
  raumId?: Maybe<Scalars['Int']['output']>;
  reviewIntervall: Scalars['Int']['output'];
  status: Scalars['String']['output'];
  unit: Unit;
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type GefaehrdungsbeurteilungArt = {
  gefaehrdungsbeurteilungen: Array<Gefaehrdungsbeurteilung>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  unit: Unit;
};

export type GefaehrdungsbeurteilungArtInput = {
  name: Scalars['String']['input'];
  unitId: Scalars['String']['input'];
};

export type GefaehrdungsbeurteilungArtInputMutation = {
  object?: InputMaybe<GefaehrdungsbeurteilungArtInput>;
  relations?: InputMaybe<GefaehrdungsbeurteilungArtRelations>;
};

export type GefaehrdungsbeurteilungArtPatch = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GefaehrdungsbeurteilungArtPatchMutation = {
  object?: InputMaybe<GefaehrdungsbeurteilungArtPatch>;
  relations?: InputMaybe<GefaehrdungsbeurteilungArtRelations>;
};

export type GefaehrdungsbeurteilungArtRelations = {
  gefaehrdungsbeurteilungen?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type GefaehrdungsbeurteilungInput = {
  anmerkungen?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  artId?: InputMaybe<Scalars['Int']['input']>;
  letztesReview?: InputMaybe<Scalars['NaiveDateTime']['input']>;
  name: Scalars['String']['input'];
  raumId?: InputMaybe<Scalars['Int']['input']>;
  reviewIntervall?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  unitId: Scalars['String']['input'];
};

export type GefaehrdungsbeurteilungInputMutation = {
  object?: InputMaybe<GefaehrdungsbeurteilungInput>;
  relations?: InputMaybe<GefaehrdungsbeurteilungRelations>;
};

export type GefaehrdungsbeurteilungPatch = {
  anmerkungen?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  artId?: InputMaybe<Scalars['Int']['input']>;
  letztesReview?: InputMaybe<Scalars['NaiveDateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  raumId?: InputMaybe<Scalars['Int']['input']>;
  reviewIntervall?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type GefaehrdungsbeurteilungPatchMutation = {
  object?: InputMaybe<GefaehrdungsbeurteilungPatch>;
  relations?: InputMaybe<GefaehrdungsbeurteilungRelations>;
};

export type GefaehrdungsbeurteilungRelations = {
  betriebsmittel?: InputMaybe<Array<Scalars['Int']['input']>>;
  dokumente?: InputMaybe<Array<Scalars['Int']['input']>>;
  gefahrstoffe?: InputMaybe<Array<Scalars['Int']['input']>>;
  kategorien?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Gefahrstoff = {
  anmerkungen?: Maybe<Scalars['String']['output']>;
  archiviert: Scalars['Boolean']['output'];
  betriebsanweisungCount: Scalars['Int']['output'];
  betriebsanweisungen: Array<Betriebsanweisung>;
  brandgefahr?: Maybe<Scalars['Boolean']['output']>;
  cmr?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['NaiveDateTime']['output'];
  einatemgefahr?: Maybe<Scalars['Boolean']['output']>;
  explosionsgefahr?: Maybe<Scalars['Boolean']['output']>;
  freigebender?: Maybe<User>;
  gefaehrdungsbeurteilungCount: Scalars['Int']['output'];
  gefaehrdungsbeurteilungen: Array<Gefaehrdungsbeurteilung>;
  hautkontaktgefahr?: Maybe<Scalars['Boolean']['output']>;
  hSaetze: Array<Hsatz>;
  hSatzCount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  kategorieCount: Scalars['Int']['output'];
  kategorien: Array<Kategorie>;
  lagerklasse?: Maybe<Scalars['String']['output']>;
  letzteSubstitutionspruefung?: Maybe<Scalars['NaiveDateTime']['output']>;
  menge?: Maybe<Scalars['Float']['output']>;
  mengeneinheit?: Maybe<Scalars['String']['output']>;
  naechsteSubstitutionspruefung?: Maybe<Scalars['NaiveDateTime']['output']>;
  name: Scalars['String']['output'];
  pSaetze: Array<Psatz>;
  pSatzCount: Scalars['Int']['output'];
  raum?: Maybe<Raum>;
  raumId?: Maybe<Scalars['Int']['output']>;
  separatlagerungErforderlich: Array<Gefahrstoff>;
  sicherheitsdatenblatt?: Maybe<Sicherheitsdatenblatt>;
  sicherheitsdatenblattId?: Maybe<Scalars['Int']['output']>;
  signalwort?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  substitutionMoeglich?: Maybe<Scalars['Boolean']['output']>;
  substitutionspruefungIntervall: Scalars['Int']['output'];
  unit: Unit;
  updatedAt: Scalars['NaiveDateTime']['output'];
  zusammenlagerung: Scalars['String']['output'];
  zusammenlagerungEingeschraenkt: Array<Gefahrstoff>;
  zweck?: Maybe<Scalars['String']['output']>;
};

export type GefahrstoffInput = {
  anmerkungen?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  brandgefahr?: InputMaybe<Scalars['Boolean']['input']>;
  cmr?: InputMaybe<Scalars['Boolean']['input']>;
  einatemgefahr?: InputMaybe<Scalars['Boolean']['input']>;
  explosionsgefahr?: InputMaybe<Scalars['Boolean']['input']>;
  hautkontaktgefahr?: InputMaybe<Scalars['Boolean']['input']>;
  lagerklasse?: InputMaybe<Scalars['String']['input']>;
  letzteSubstitutionspruefung?: InputMaybe<Scalars['NaiveDateTime']['input']>;
  menge?: InputMaybe<Scalars['Float']['input']>;
  mengeneinheit?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  raumId?: InputMaybe<Scalars['Int']['input']>;
  sicherheitsdatenblattId?: InputMaybe<Scalars['Int']['input']>;
  signalwort?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  substitutionMoeglich?: InputMaybe<Scalars['Boolean']['input']>;
  substitutionspruefungIntervall?: InputMaybe<Scalars['Int']['input']>;
  unitId: Scalars['String']['input'];
  zusammenlagerung?: InputMaybe<Scalars['String']['input']>;
  zweck?: InputMaybe<Scalars['String']['input']>;
};

export type GefahrstoffInputMutation = {
  object?: InputMaybe<GefahrstoffInput>;
  relations?: InputMaybe<GefahrstoffRelations>;
};

export type GefahrstoffPatch = {
  anmerkungen?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  brandgefahr?: InputMaybe<Scalars['Boolean']['input']>;
  cmr?: InputMaybe<Scalars['Boolean']['input']>;
  einatemgefahr?: InputMaybe<Scalars['Boolean']['input']>;
  explosionsgefahr?: InputMaybe<Scalars['Boolean']['input']>;
  hautkontaktgefahr?: InputMaybe<Scalars['Boolean']['input']>;
  lagerklasse?: InputMaybe<Scalars['String']['input']>;
  letzteSubstitutionspruefung?: InputMaybe<Scalars['NaiveDateTime']['input']>;
  menge?: InputMaybe<Scalars['Float']['input']>;
  mengeneinheit?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  raumId?: InputMaybe<Scalars['Int']['input']>;
  sicherheitsdatenblattId?: InputMaybe<Scalars['Int']['input']>;
  signalwort?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  substitutionMoeglich?: InputMaybe<Scalars['Boolean']['input']>;
  substitutionspruefungIntervall?: InputMaybe<Scalars['Int']['input']>;
  zusammenlagerung?: InputMaybe<Scalars['String']['input']>;
  zweck?: InputMaybe<Scalars['String']['input']>;
};

export type GefahrstoffPatchMutation = {
  object?: InputMaybe<GefahrstoffPatch>;
  relations?: InputMaybe<GefahrstoffRelations>;
};

export type GefahrstoffRelations = {
  betriebsanweisungen?: InputMaybe<Array<Scalars['Int']['input']>>;
  gefaehrdungsbeurteilungen?: InputMaybe<Array<Scalars['Int']['input']>>;
  hSaetze?: InputMaybe<Array<Scalars['Int']['input']>>;
  kategorien?: InputMaybe<Array<Scalars['Int']['input']>>;
  pSaetze?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Hsatz = {
  code: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  satz: Scalars['String']['output'];
};

export type Invite = {
  email: Scalars['String']['output'];
  inviteAccepted?: Maybe<Scalars['String']['output']>;
  inviteId: Scalars['String']['output'];
  inviteSent?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type InviteInput = {
  emails: Array<Scalars['String']['input']>;
  scopes?: InputMaybe<Array<Scalars['String']['input']>>;
  unitIds: Array<Scalars['String']['input']>;
};

export type Kategorie = {
  betriebsanweisungCount: Scalars['Int']['output'];
  betriebsanweisungen: Array<Betriebsanweisung>;
  betriebsmittel: Array<Betriebsmittel>;
  betriebsmittelCount: Scalars['Int']['output'];
  createdAt: Scalars['NaiveDateTime']['output'];
  gefaehrdungsbeurteilungCount: Scalars['Int']['output'];
  gefaehrdungsbeurteilungen: Array<Gefaehrdungsbeurteilung>;
  gefahrstoffCount: Scalars['Int']['output'];
  gefahrstoffe: Array<Gefahrstoff>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  unit: Unit;
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type KategorieInput = {
  name: Scalars['String']['input'];
  unitId: Scalars['String']['input'];
};

export type KategorieInputMutation = {
  object?: InputMaybe<KategorieInput>;
};

export type KategoriePatch = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type KategoriePatchMutation = {
  object?: InputMaybe<KategoriePatch>;
};

export type Mutation = {
  acceptInvite?: Maybe<Scalars['String']['output']>;
  createBedienungsanleitung: Bedienungsanleitung;
  createBetriebsanweisung: Betriebsanweisung;
  createBetriebsanweisungArt: BetriebsanweisungArt;
  createBetriebsmittel: Betriebsmittel;
  createCompany: Company;
  createDocument: Document;
  /**
   * Ein Eintrag wird von einem angemeldeten Nutzer zu einem Verbandbuch hinzugefügt
   *     Der `verbandbuch`-Key (`Verbandbuch.id`) stellt dabei das Verbandbuch dar, zu dem hinzugefügt wird
   */
  createEintrag: Eintrag;
  createGefaehrdungsbeurteilung: Gefaehrdungsbeurteilung;
  createGefaehrdungsbeurteilungArt: GefaehrdungsbeurteilungArt;
  createGefahrstoff: Gefahrstoff;
  createKategorie: Kategorie;
  createPruefbericht: Pruefbericht;
  /**
   * Offene Mutation, welche es Nutzern, die nicht im System angemeldet sind, ermöglicht
   *       einen Eintrag in einem Verbandbuch zu tätigen. Das Verbandbuch, in dem
   *       der Eintrag hinzgefügt wird, wird dabei über den `identifier` bestimmt
   */
  createPublicEintrag: Eintrag;
  createRaum: Raum;
  createSicherheitsdatenblatt: Sicherheitsdatenblatt;
  createTag: Tag;
  createUnit: Unit;
  /**
   * Erstellt ein neues Verbandbuch für einen Nutzer an einer Unit.
   *       Versucht der Nutzer ein Verbandbuch für eine Unit zu erstellen, die *nicht* in seinen
   *       `currentUnitIds` ist, wird ein Fehler geworfen. Der erstellende Nutzer wird *automatisch* zu der `notificationList` hinzugefügt
   */
  createVerbandbuch: Verbandbuch;
  deleteBedienungsanleitung: Scalars['String']['output'];
  deleteBetriebsanweisung: Scalars['String']['output'];
  deleteBetriebsanweisungArt: Scalars['String']['output'];
  deleteBetriebsmittel: Scalars['String']['output'];
  deleteCompany?: Maybe<Scalars['Boolean']['output']>;
  deleteDocument: Scalars['String']['output'];
  deleteGefaehrdungsbeurteilung: Scalars['String']['output'];
  deleteGefaehrdungsbeurteilungArt: Scalars['String']['output'];
  deleteGefahrstoff: Scalars['String']['output'];
  deleteKategorie: Scalars['String']['output'];
  deletePruefbericht: Scalars['String']['output'];
  deleteRaum: Scalars['String']['output'];
  deleteSicherheitsdatenblatt: Scalars['String']['output'];
  deleteTag: Scalars['String']['output'];
  /** Not implemented */
  deleteVerbandbuch: Scalars['String']['output'];
  /** Exports all Betriebsanweisungen matching the filter. A unitId must be provided as a upload target. */
  exportBetriebsanweisungen: Scalars['String']['output'];
  /** Exports all Betriebsmittel matching the filter. A unitId must be provided as a upload target. */
  exportBetriebsmittel: Scalars['String']['output'];
  /** Exports all Gefaehrdungsbeurteilungen matching the filter. A unitId must be provided as a upload target. */
  exportGefaehrdungsbeurteilungen: Scalars['String']['output'];
  /** Exports all Gefahrstoffe matching the filter. A unitId must be provided as a upload target. */
  exportGefahrstoffe: Scalars['String']['output'];
  /** Exports all VerbandbuchEintrag matching the filter. A unitId must be provided as a upload target. */
  exportVerbandbuchEintraege: Scalars['String']['output'];
  invite?: Maybe<Scalars['String']['output']>;
  /** Enables or disables units in a single mutation. If the same unitId is supplied with both disabled and enabled state, enabled takes precedence. Returns the disabled Units after the operation. */
  setUnitReportingStates: Array<Scalars['String']['output']>;
  updateBedienungsanleitung: Bedienungsanleitung;
  updateBetriebsanweisung: Betriebsanweisung;
  updateBetriebsanweisungArt: BetriebsanweisungArt;
  updateBetriebsmittel: Betriebsmittel;
  updateCompany: Company;
  updateDocument: Document;
  /**
   * Aktualisiert einen Eintrag. Hierbei wird eine neue `revision` des
   *       Eintrags erstellt welche mit Verbandbuch verknüpft wird. Der alte Eintrag wird
   *       in einer Revision-Collection gespeichert
   */
  updateEintrag: Eintrag;
  updateGefaehrdungsbeurteilung: Gefaehrdungsbeurteilung;
  updateGefaehrdungsbeurteilungArt: GefaehrdungsbeurteilungArt;
  updateGefahrstoff: Gefahrstoff;
  updateKategorie: Kategorie;
  updateMe: User;
  updatePaymentAccount: PaymentAccount;
  updatePruefbericht: Pruefbericht;
  updateRaum: Raum;
  updateScopes: User;
  updateSicherheitsdatenblatt: Sicherheitsdatenblatt;
  updateTag: Tag;
  updateUnit: Unit;
  /**
   * Aktualisiert die Stammdaten eines Verbandbuchs.**Wichtig**:
   *     Wird der `identifier` eines Verbandbuchs geändert, so sind alle vorherigen QR-Codes ungültig
   */
  updateVerbandbuch: Verbandbuch;
};


export type MutationAcceptInviteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCreateBedienungsanleitungArgs = {
  object: BedienungsanleitungInputMutation;
};


export type MutationCreateBetriebsanweisungArgs = {
  object: BetriebsanweisungInputMutation;
};


export type MutationCreateBetriebsanweisungArtArgs = {
  object: BetriebsanweisungArtInputMutation;
};


export type MutationCreateBetriebsmittelArgs = {
  object: BetriebsmittelInputMutation;
};


export type MutationCreateCompanyArgs = {
  name: Scalars['String']['input'];
  units?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type MutationCreateDocumentArgs = {
  object: DocumentInputMutation;
};


export type MutationCreateEintragArgs = {
  createEintrag: CreateEintragDto;
};


export type MutationCreateGefaehrdungsbeurteilungArgs = {
  object: GefaehrdungsbeurteilungInputMutation;
};


export type MutationCreateGefaehrdungsbeurteilungArtArgs = {
  object: GefaehrdungsbeurteilungArtInputMutation;
};


export type MutationCreateGefahrstoffArgs = {
  object: GefahrstoffInputMutation;
};


export type MutationCreateKategorieArgs = {
  object: KategorieInputMutation;
};


export type MutationCreatePruefberichtArgs = {
  object: PruefberichtInputMutation;
};


export type MutationCreatePublicEintragArgs = {
  createEintrag: CreatePublicEintragDto;
};


export type MutationCreateRaumArgs = {
  object: RaumInputMutation;
};


export type MutationCreateSicherheitsdatenblattArgs = {
  object: SicherheitsdatenblattInputMutation;
};


export type MutationCreateTagArgs = {
  object: TagInputMutation;
};


export type MutationCreateUnitArgs = {
  input: CreateUnitInput;
};


export type MutationCreateVerbandbuchArgs = {
  createVerbandbuch: CreateVerbandbuchDto;
};


export type MutationDeleteBedienungsanleitungArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteBetriebsanweisungArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteBetriebsanweisungArtArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteBetriebsmittelArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDocumentArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteGefaehrdungsbeurteilungArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteGefaehrdungsbeurteilungArtArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteGefahrstoffArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteKategorieArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeletePruefberichtArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteRaumArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteSicherheitsdatenblattArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteTagArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteVerbandbuchArgs = {
  id: Scalars['String']['input'];
};


export type MutationExportBetriebsanweisungenArgs = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['Boolean']['input']>;
  order_criteria?: InputMaybe<Scalars['String']['input']>;
  unitId: Scalars['String']['input'];
};


export type MutationExportBetriebsmittelArgs = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['Boolean']['input']>;
  order_criteria?: InputMaybe<Scalars['String']['input']>;
  unitId: Scalars['String']['input'];
};


export type MutationExportGefaehrdungsbeurteilungenArgs = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['Boolean']['input']>;
  order_criteria?: InputMaybe<Scalars['String']['input']>;
  unitId: Scalars['String']['input'];
};


export type MutationExportGefahrstoffeArgs = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['Boolean']['input']>;
  order_criteria?: InputMaybe<Scalars['String']['input']>;
  unitId: Scalars['String']['input'];
};


export type MutationExportVerbandbuchEintraegeArgs = {
  order?: InputMaybe<Scalars['Boolean']['input']>;
  order_criteria?: InputMaybe<Scalars['String']['input']>;
  unitId: Scalars['String']['input'];
};


export type MutationInviteArgs = {
  input: InviteInput;
};


export type MutationSetUnitReportingStatesArgs = {
  states: Array<ReportingState>;
};


export type MutationUpdateBedienungsanleitungArgs = {
  id: Scalars['Int']['input'];
  object: BedienungsanleitungPatchMutation;
};


export type MutationUpdateBetriebsanweisungArgs = {
  id: Scalars['Int']['input'];
  object: BetriebsanweisungPatchMutation;
};


export type MutationUpdateBetriebsanweisungArtArgs = {
  id: Scalars['Int']['input'];
  object: BetriebsanweisungArtPatchMutation;
};


export type MutationUpdateBetriebsmittelArgs = {
  id: Scalars['Int']['input'];
  object: BetriebsmittelPatchMutation;
};


export type MutationUpdateCompanyArgs = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  units?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type MutationUpdateDocumentArgs = {
  id: Scalars['Int']['input'];
  object: DocumentPatchMutation;
};


export type MutationUpdateEintragArgs = {
  id: Scalars['String']['input'];
  updateEintrag: UpdateEintragDto;
};


export type MutationUpdateGefaehrdungsbeurteilungArgs = {
  id: Scalars['Int']['input'];
  object: GefaehrdungsbeurteilungPatchMutation;
};


export type MutationUpdateGefaehrdungsbeurteilungArtArgs = {
  id: Scalars['Int']['input'];
  object: GefaehrdungsbeurteilungArtPatchMutation;
};


export type MutationUpdateGefahrstoffArgs = {
  id: Scalars['Int']['input'];
  object: GefahrstoffPatchMutation;
};


export type MutationUpdateKategorieArgs = {
  id: Scalars['Int']['input'];
  object: KategoriePatchMutation;
};


export type MutationUpdateMeArgs = {
  input: UpdateUserInput;
};


export type MutationUpdatePaymentAccountArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePaymentAccountInput;
};


export type MutationUpdatePruefberichtArgs = {
  id: Scalars['Int']['input'];
  object: PruefberichtPatchMutation;
};


export type MutationUpdateRaumArgs = {
  id: Scalars['Int']['input'];
  object: RaumPatchMutation;
};


export type MutationUpdateScopesArgs = {
  input: UpdateScopesInput;
  unit: Scalars['ID']['input'];
  user: Scalars['ID']['input'];
};


export type MutationUpdateSicherheitsdatenblattArgs = {
  id: Scalars['Int']['input'];
  object: SicherheitsdatenblattPatchMutation;
};


export type MutationUpdateTagArgs = {
  id: Scalars['Int']['input'];
  object: TagPatchMutation;
};


export type MutationUpdateUnitArgs = {
  id: Scalars['ID']['input'];
  input: UpdateUnitInput;
};


export type MutationUpdateVerbandbuchArgs = {
  id: Scalars['String']['input'];
  updateVerbandbuch?: InputMaybe<UpdateVerbandbuchDto>;
};

export type Order = {
  criteria: Scalars['String']['input'];
  direction: Scalars['Boolean']['input'];
};

export type Pagination = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Order>;
};

export type PaymentAccount = {
  activated: Scalars['Boolean']['output'];
  hausnummer?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoiceEmail: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  ort?: Maybe<Scalars['String']['output']>;
  plz?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  strasse?: Maybe<Scalars['String']['output']>;
  units: Array<Scalars['String']['output']>;
};

export type Pruefbericht = {
  archiviert: Scalars['Boolean']['output'];
  betriebsmittel: Array<Betriebsmittel>;
  createdAt: Scalars['NaiveDateTime']['output'];
  dokumentCount: Scalars['Int']['output'];
  dokumente: Array<Document>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  unit: Unit;
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type PruefberichtInput = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  unitId: Scalars['String']['input'];
};

export type PruefberichtInputMutation = {
  object?: InputMaybe<PruefberichtInput>;
  relations?: InputMaybe<PruefberichtRelations>;
};

export type PruefberichtPatch = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PruefberichtPatchMutation = {
  object?: InputMaybe<PruefberichtPatch>;
  relations?: InputMaybe<PruefberichtRelations>;
};

export type PruefberichtRelations = {
  betriebsmittel?: InputMaybe<Array<Scalars['Int']['input']>>;
  dokumente?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Psatz = {
  code: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  satz: Scalars['String']['output'];
};

export type Query = {
  companies: Array<Company>;
  countBedienungsanleitungen: Scalars['Int']['output'];
  countBetriebsanweisungArten: Scalars['Int']['output'];
  countBetriebsanweisungen: Scalars['Int']['output'];
  countBetriebsmittel: Scalars['Int']['output'];
  countDocuments: Scalars['Int']['output'];
  countEintrag: Scalars['Int']['output'];
  countGefaehrdungsbeurteilungArten: Scalars['Int']['output'];
  countGefaehrdungsbeurteilungen: Scalars['Int']['output'];
  countGefahrstoffe: Scalars['Int']['output'];
  countHSaetze: Scalars['Int']['output'];
  countKategorien: Scalars['Int']['output'];
  countProtocols: Scalars['Int']['output'];
  countPruefberichte: Scalars['Int']['output'];
  countPSaetze: Scalars['Int']['output'];
  countRaeume: Scalars['Int']['output'];
  countSicherheitsdatenblaetter: Scalars['Int']['output'];
  countTags: Scalars['Int']['output'];
  countVerbandbuch: Scalars['Int']['output'];
  /** Fetches and returns the dashboard chart-data */
  dashboard: Array<DashboardChart>;
  findBedienungsanleitungById: Bedienungsanleitung;
  findBedienungsanleitungen: Array<Bedienungsanleitung>;
  findBetriebsanweisungArtById: BetriebsanweisungArt;
  findBetriebsanweisungArten: Array<BetriebsanweisungArt>;
  findBetriebsanweisungById: Betriebsanweisung;
  findBetriebsanweisungen: Array<Betriebsanweisung>;
  findBetriebsmittel: Array<Betriebsmittel>;
  findBetriebsmittelById: Betriebsmittel;
  findDocumentById: Document;
  findDocuments: Array<Document>;
  findGefaehrdungsbeurteilungArtById: GefaehrdungsbeurteilungArt;
  findGefaehrdungsbeurteilungArten: Array<GefaehrdungsbeurteilungArt>;
  findGefaehrdungsbeurteilungById: Gefaehrdungsbeurteilung;
  findGefaehrdungsbeurteilungen: Array<Gefaehrdungsbeurteilung>;
  findGefahrstoffById: Gefahrstoff;
  findGefahrstoffe: Array<Gefahrstoff>;
  findHSaetze: Array<Hsatz>;
  findHSatzById: Hsatz;
  findKategorieById: Kategorie;
  findKategorien: Array<Kategorie>;
  findProtocols: Array<Document>;
  findPruefberichtById: Pruefbericht;
  findPruefberichte: Array<Pruefbericht>;
  findPSaetze: Array<Psatz>;
  findPSatzById: Psatz;
  findRaeume: Array<Raum>;
  findRaumById: Raum;
  findSicherheitsdatenblaetter: Array<Sicherheitsdatenblatt>;
  findSicherheitsdatenblattById: Sicherheitsdatenblatt;
  findTagById: Tag;
  findTags: Array<Tag>;
  /**
   * Gibt alle Einträge zurück, die in allen Verbandbücher aller Units der `currentUnitIds` des Nutzers sind.
   *     Mit dem `unitIds` Argument, kann ein Subset der `currentUnitIds` ausgewählt werden.
   */
  getAllEintraegeForUnits: Array<Eintrag>;
  getAsTree: Array<CompanyUnion>;
  getDiagramInformation: Array<DiagramInformation>;
  /** Gibt Detailinformationen über einen Eintrag in einem Verbandbuch */
  getEintragById: Eintrag;
  /** Gibt alle Einträge für ein Verbandbuch zurück. */
  getEintragForVerbandbuch: Array<Eintrag>;
  /**
   * Gibt die Eintragshistorie für einen speziellen Eintrag zurück.
   *       Das erste Element stellt dabei die aktuelle Version dar
   */
  getEintragRevisions: Array<EintragRevision>;
  /** Returns all unitIds for which reporting is disabled. */
  getReportingDisabledUnits: Array<Scalars['String']['output']>;
  /**
   * Gibt dem Nutzer ein bestimmtes Verbandbuch über dessen `id` zurück,
   *       sofern es über die `currentUnitIds` erreichbar ist
   */
  getVerbandbuchById: Verbandbuch;
  /** Gibt alle Verbandbücher der `currentUnitIds` des Nutzers zurück */
  getVerbandbuecher: Array<Verbandbuch>;
  identifyBedienungsanleitungenByDocumentId: Array<Bedienungsanleitung>;
  identifyBetriebsanweisungenByDocumentId: Array<Betriebsanweisung>;
  identifyDocumentsByDocumentId: Array<Document>;
  identifySicherheitsdatenblaetterByDocumentId: Array<Sicherheitsdatenblatt>;
  identifyTagsByDocumentId: Array<Tag>;
  me: User;
  paymentAccount: PaymentAccount;
  paymentAccounts: Array<PaymentAccount>;
  unit: Unit;
  units: Array<Unit>;
  user: User;
  users: Array<User>;
};


export type QueryCountBedienungsanleitungenArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryCountBetriebsanweisungArtenArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryCountBetriebsanweisungenArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryCountBetriebsmittelArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryCountDocumentsArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryCountGefaehrdungsbeurteilungArtenArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryCountGefaehrdungsbeurteilungenArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryCountGefahrstoffeArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryCountHSaetzeArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryCountKategorienArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryCountProtocolsArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryCountPruefberichteArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryCountPSaetzeArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryCountRaeumeArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryCountSicherheitsdatenblaetterArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryCountTagsArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryFindBedienungsanleitungByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFindBedienungsanleitungenArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryFindBetriebsanweisungArtByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFindBetriebsanweisungArtenArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryFindBetriebsanweisungByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFindBetriebsanweisungenArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryFindBetriebsmittelArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryFindBetriebsmittelByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFindDocumentByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFindDocumentsArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryFindGefaehrdungsbeurteilungArtByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFindGefaehrdungsbeurteilungArtenArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryFindGefaehrdungsbeurteilungByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFindGefaehrdungsbeurteilungenArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryFindGefahrstoffByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFindGefahrstoffeArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryFindHSaetzeArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryFindHSatzByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFindKategorieByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFindKategorienArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryFindProtocolsArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryFindPruefberichtByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFindPruefberichteArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryFindPSaetzeArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryFindPSatzByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFindRaeumeArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryFindRaumByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFindSicherheitsdatenblaetterArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryFindSicherheitsdatenblattByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFindTagByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFindTagsArgs = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryGetAllEintraegeForUnitsArgs = {
  filter?: InputMaybe<EintragFilterArgs>;
  order?: InputMaybe<Scalars['Boolean']['input']>;
  order_criteria?: InputMaybe<Scalars['String']['input']>;
  unitIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryGetEintragByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetEintragForVerbandbuchArgs = {
  filter?: InputMaybe<EintragFilterArgs>;
  order?: InputMaybe<Scalars['Boolean']['input']>;
  order_criteria?: InputMaybe<Scalars['String']['input']>;
  verbandbuch: Scalars['ID']['input'];
};


export type QueryGetEintragRevisionsArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetVerbandbuchByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryIdentifyBedienungsanleitungenByDocumentIdArgs = {
  documentId: Scalars['Int']['input'];
};


export type QueryIdentifyBetriebsanweisungenByDocumentIdArgs = {
  documentId: Scalars['Int']['input'];
};


export type QueryIdentifyDocumentsByDocumentIdArgs = {
  documentId: Scalars['Int']['input'];
};


export type QueryIdentifySicherheitsdatenblaetterByDocumentIdArgs = {
  documentId: Scalars['Int']['input'];
};


export type QueryIdentifyTagsByDocumentIdArgs = {
  documentId: Scalars['Int']['input'];
};


export type QueryPaymentAccountArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUnitArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  unit?: InputMaybe<Scalars['ID']['input']>;
};

export type Raum = {
  betriebsanweisungen: Array<Betriebsanweisung>;
  betriebsmittel: Array<Betriebsmittel>;
  createdAt: Scalars['NaiveDateTime']['output'];
  gefaehrdungsbeurteilungen: Array<Gefaehrdungsbeurteilung>;
  gefahrstoffe: Array<Gefahrstoff>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  unit: Unit;
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type RaumInput = {
  name: Scalars['String']['input'];
  unitId: Scalars['String']['input'];
};

export type RaumInputMutation = {
  object?: InputMaybe<RaumInput>;
};

export type RaumPatch = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type RaumPatchMutation = {
  object?: InputMaybe<RaumPatch>;
};

/** Represents the reporting state to set a unit to. */
export type ReportingState = {
  enabled: Scalars['Boolean']['input'];
  unitId: Scalars['String']['input'];
};

export type Sicherheitsdatenblatt = {
  archiviert: Scalars['Boolean']['output'];
  createdAt: Scalars['NaiveDateTime']['output'];
  dokumentCount: Scalars['Int']['output'];
  dokumente: Array<Document>;
  gefahrstoffe: Array<Gefahrstoff>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  unit: Unit;
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type SicherheitsdatenblattInput = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  unitId: Scalars['String']['input'];
};

export type SicherheitsdatenblattInputMutation = {
  object?: InputMaybe<SicherheitsdatenblattInput>;
  relations?: InputMaybe<SicherheitsdatenblattRelations>;
};

export type SicherheitsdatenblattPatch = {
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SicherheitsdatenblattPatchMutation = {
  object?: InputMaybe<SicherheitsdatenblattPatch>;
  relations?: InputMaybe<SicherheitsdatenblattRelations>;
};

export type SicherheitsdatenblattRelations = {
  dokumente?: InputMaybe<Array<Scalars['Int']['input']>>;
  gefahrstoff?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Tag = {
  createdAt: Scalars['NaiveDateTime']['output'];
  documents: Array<Document>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  unit: Unit;
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type TagInput = {
  name: Scalars['String']['input'];
  unitId: Scalars['String']['input'];
};

export type TagInputMutation = {
  object?: InputMaybe<TagInput>;
  relations?: InputMaybe<TagRelations>;
};

export type TagPatch = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type TagPatchMutation = {
  object?: InputMaybe<TagPatch>;
  relations?: InputMaybe<TagRelations>;
};

export type TagRelations = {
  dokumente?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Unit = {
  anmerkungen?: Maybe<Scalars['String']['output']>;
  berufsgenossenschaft?: Maybe<Scalars['String']['output']>;
  hausnummer?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invites?: Maybe<Array<Invite>>;
  name: Scalars['String']['output'];
  ort?: Maybe<Scalars['String']['output']>;
  plz?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  strasse?: Maybe<Scalars['String']['output']>;
};

export type UpdateEintragDto = {
  arbeitsbereich?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
  eintragType?: InputMaybe<Scalars['String']['input']>;
  erstehilfezeitpunkt?: InputMaybe<Scalars['String']['input']>;
  ersthelfer?: InputMaybe<Scalars['String']['input']>;
  hergang?: InputMaybe<Scalars['String']['input']>;
  massnahmen?: InputMaybe<Scalars['String']['input']>;
  umfang?: InputMaybe<Scalars['String']['input']>;
  unfallort?: InputMaybe<Scalars['String']['input']>;
  unfallzeitpunkt?: InputMaybe<Scalars['String']['input']>;
  verbandbuch?: InputMaybe<Scalars['String']['input']>;
  verletzter?: InputMaybe<Scalars['String']['input']>;
  verletzung?: InputMaybe<Scalars['String']['input']>;
  zeugen?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePaymentAccountInput = {
  hausnummer?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ort?: InputMaybe<Scalars['String']['input']>;
  plz?: InputMaybe<Scalars['String']['input']>;
  strasse?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateScopesInput = {
  scopes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateUnitInput = {
  anmerkungen?: InputMaybe<Scalars['String']['input']>;
  berufsgenossenschaft?: InputMaybe<Scalars['String']['input']>;
  hausnummer?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ort?: InputMaybe<Scalars['String']['input']>;
  plz?: InputMaybe<Scalars['String']['input']>;
  strasse?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  defaultUnits?: InputMaybe<Array<Scalars['String']['input']>>;
  mobilnummer?: InputMaybe<Scalars['String']['input']>;
  nachname?: InputMaybe<Scalars['String']['input']>;
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
  telefonnummer?: InputMaybe<Scalars['String']['input']>;
  vorname?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateVerbandbuchDto = {
  identifier?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notificationList?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type User = {
  accessibleUnits?: Maybe<Array<AccessibleUnit>>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mobilnummer?: Maybe<Scalars['String']['output']>;
  nachname: Scalars['String']['output'];
  preferredLanguage?: Maybe<Scalars['String']['output']>;
  telefonnummer?: Maybe<Scalars['String']['output']>;
  vorname: Scalars['String']['output'];
};

export type Verbandbuch = {
  archiviert?: Maybe<Scalars['Boolean']['output']>;
  eintraege: Array<Eintrag>;
  exports?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  identifier?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /**
   * Die `notificationList` beinhaltet ein Array von E-Mailadressen (diese müssen nicht im System sein), die eine Email erhalten, wenn ein neuer Eintrag in das
   *     Verbandbuch getätigt wird
   */
  notificationList?: Maybe<Array<Scalars['String']['output']>>;
  qrCode?: Maybe<VerbandbuchQrCode>;
  /** Das `settingsDocument` enthält den direkten Download-Link zu der PDF, die den QR-Code und den Aushang beinhaltet */
  settingsDocument?: Maybe<Scalars['String']['output']>;
  unit: Unit;
};

export type VerbandbuchQrCode = {
  forIdentifier: Scalars['String']['output'];
  svg: Scalars['String']['output'];
};

export type GetUserByIdQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type GetUserByIdQuery = { user: { vorname: string, nachname: string } };

export type GetUsersForUnitQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersForUnitQuery = { users: Array<{ id: string, vorname: string, nachname: string, accessibleUnits?: Array<{ scopes?: Array<string> | null, unit: string }> | null }> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { me: { id: string, vorname: string, nachname: string, email: string, telefonnummer?: string | null, mobilnummer?: string | null, preferredLanguage?: string | null } };

export type UpdateMeMutationVariables = Exact<{
  updateUser: UpdateUserInput;
}>;


export type UpdateMeMutation = { updateMe: { id: string, vorname: string, nachname: string, email: string, telefonnummer?: string | null, mobilnummer?: string | null, preferredLanguage?: string | null } };

export type UpdateScopesForUserMutationVariables = Exact<{
  unit: Scalars['ID']['input'];
  user: Scalars['ID']['input'];
  updateScopes: UpdateScopesInput;
}>;


export type UpdateScopesForUserMutation = { updateScopes: { id: string, vorname: string, nachname: string, email: string, telefonnummer?: string | null, mobilnummer?: string | null, accessibleUnits?: Array<{ default: boolean, scopes?: Array<string> | null, unit: string }> | null } };

export type CreateCompanyMutationVariables = Exact<{
  units?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  name: Scalars['String']['input'];
}>;


export type CreateCompanyMutation = { createCompany: { id: string, name: string, units?: Array<{ id: string, name: string }> | null } };

export type DeleteCompanyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteCompanyMutation = { deleteCompany?: boolean | null };

export type GetAsTreeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAsTreeQuery = { getAsTree: Array<{ __typename: 'Company', id: string, name: string, units?: Array<{ __typename: 'Unit', id: string, name: string, ort?: string | null }> | null } | { __typename: 'Unit', id: string, name: string, ort?: string | null }> };

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  units?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateCompanyMutation = { updateCompany: { id: string, name: string, units?: Array<{ id: string, name: string }> | null } };

export type GetPaymentAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPaymentAccountsQuery = { paymentAccounts: Array<{ units: Array<string>, id: string, name?: string | null, invoiceEmail: string, plz?: string | null, strasse?: string | null, ort?: string | null, hausnummer?: string | null, activated: boolean, quantity: number }> };

export type UpdatePaymentAccountMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
  updatePaymentAccountDto: UpdatePaymentAccountInput;
}>;


export type UpdatePaymentAccountMutation = { updatePaymentAccount: { id: string, name?: string | null, invoiceEmail: string, plz?: string | null, strasse?: string | null, ort?: string | null, hausnummer?: string | null, activated: boolean, quantity: number, units: Array<string> } };

export type AcceptInviteMutationVariables = Exact<{
  inviteId: Scalars['ID']['input'];
}>;


export type AcceptInviteMutation = { acceptInvite?: string | null };

export type CreateUnitMutationVariables = Exact<{
  createUnit: CreateUnitInput;
}>;


export type CreateUnitMutation = { createUnit: { id: string, status?: string | null, name: string, berufsgenossenschaft?: string | null, ort?: string | null, plz?: string | null, hausnummer?: string | null, strasse?: string | null, anmerkungen?: string | null } };

export type GetUnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnitsQuery = { units: Array<{ id: string, status?: string | null, name: string, berufsgenossenschaft?: string | null, ort?: string | null, plz?: string | null, hausnummer?: string | null, strasse?: string | null, anmerkungen?: string | null, invites?: Array<{ status: string, inviteSent?: string | null, inviteAccepted?: string | null, email: string, inviteId: string }> | null }> };

export type InviteUserForUnitMutationVariables = Exact<{
  inviteObject: InviteInput;
}>;


export type InviteUserForUnitMutation = { invite?: string | null };

export type UpdateUnitByIdMutationVariables = Exact<{
  updateUnit: UpdateUnitInput;
  unitId: Scalars['ID']['input'];
}>;


export type UpdateUnitByIdMutation = { updateUnit: { id: string, status?: string | null, name: string, berufsgenossenschaft?: string | null, ort?: string | null, plz?: string | null, hausnummer?: string | null, strasse?: string | null, anmerkungen?: string | null, invites?: Array<{ status: string, inviteSent?: string | null, inviteAccepted?: string | null, email: string, inviteId: string }> | null } };

export type CountBedienungsanleitungenQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CountBedienungsanleitungenQuery = { countBedienungsanleitungen: number };

export type CreateBedienungsanleitungMutationVariables = Exact<{
  object: BedienungsanleitungInputMutation;
}>;


export type CreateBedienungsanleitungMutation = { createBedienungsanleitung: { id: number, createdAt: any, updatedAt: any, name: string, unit: { id: string }, betriebsmittel: Array<{ id: number, name: string }>, dokumente: Array<{ id: number, name: string, size: number }> } };

export type DeleteBedienungsanleitungMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteBedienungsanleitungMutation = { deleteBedienungsanleitung: string };

export type BedienungsanleitungByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type BedienungsanleitungByIdQuery = { findBedienungsanleitungById: { id: number, createdAt: any, updatedAt: any, name: string, unit: { id: string }, betriebsmittel: Array<{ id: number, name: string }>, dokumente: Array<{ id: number, name: string, size: number, updatedAt: any, downloadUrl: string, uploadUrl: string }> } };

export type BedienungsanleitungOverviewQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type BedienungsanleitungOverviewQuery = { countBedienungsanleitungen: number, findBedienungsanleitungen: Array<{ id: number, archiviert: boolean, createdAt: any, updatedAt: any, name: string, unit: { id: string, name: string }, betriebsmittel: Array<{ id: number, name: string }>, dokumente: Array<{ id: number, name: string, size: number }> }> };

export type UpdateBedienungsanleitungMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  object: BedienungsanleitungPatchMutation;
}>;


export type UpdateBedienungsanleitungMutation = { updateBedienungsanleitung: { id: number, createdAt: any, updatedAt: any, name: string, unit: { id: string }, betriebsmittel: Array<{ id: number, name: string }>, dokumente: Array<{ id: number, name: string, size: number, updatedAt: any, downloadUrl: string, uploadUrl: string }> } };

export type CountBetriebsanweisungenQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CountBetriebsanweisungenQuery = { countBetriebsanweisungen: number };

export type CreateBetriebsanweisungMutationVariables = Exact<{
  object: BetriebsanweisungInputMutation;
}>;


export type CreateBetriebsanweisungMutation = { createBetriebsanweisung: { id: number, createdAt: any, updatedAt: any, name: string, anmerkungen?: string | null, archiviert: boolean, letztesReview?: any | null, reviewIntervall: number, naechstesReview?: any | null, status: string, artId?: number | null, raumId?: number | null, unit: { id: string }, art?: { id: number, name: string } | null, raum?: { id: number, name: string } | null, kategorien: Array<{ id: number, name: string }>, gefahrstoffe: Array<{ id: number, name: string }>, betriebsmittel: Array<{ id: number, name: string }>, dokumente: Array<{ id: number, name: string, size: number }> } };

export type DeleteBetriebsanweisungMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteBetriebsanweisungMutation = { deleteBetriebsanweisung: string };

export type BetriebsanweisungByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type BetriebsanweisungByIdQuery = { findBetriebsanweisungById: { id: number, createdAt: any, updatedAt: any, name: string, anmerkungen?: string | null, archiviert: boolean, letztesReview?: any | null, reviewIntervall: number, naechstesReview?: any | null, status: string, artId?: number | null, raumId?: number | null, unit: { id: string }, art?: { id: number, name: string } | null, raum?: { id: number, name: string } | null, freigebender?: { vorname: string, nachname: string } | null, kategorien: Array<{ id: number, name: string }>, gefahrstoffe: Array<{ id: number, name: string }>, betriebsmittel: Array<{ id: number, name: string }>, dokumente: Array<{ id: number, name: string, size: number, updatedAt: any, downloadUrl: string, uploadUrl: string }> } };

export type ExportBetriebsanweisungenMutationVariables = Exact<{
  unitId: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
}>;


export type ExportBetriebsanweisungenMutation = { exportBetriebsanweisungen: string };

export type BetriebsanweisungOverviewQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type BetriebsanweisungOverviewQuery = { countBetriebsanweisungen: number, findBetriebsanweisungen: Array<{ id: number, name: string, anmerkungen?: string | null, archiviert: boolean, letztesReview?: any | null, reviewIntervall: number, naechstesReview?: any | null, status: string, artId?: number | null, unit: { id: string, name: string }, art?: { id: number, name: string } | null, dokumente: Array<{ id: number, name: string, size: number }> }> };

export type UpdateBetriebsanweisungMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  object: BetriebsanweisungPatchMutation;
}>;


export type UpdateBetriebsanweisungMutation = { updateBetriebsanweisung: { id: number, createdAt: any, updatedAt: any, name: string, anmerkungen?: string | null, archiviert: boolean, letztesReview?: any | null, reviewIntervall: number, naechstesReview?: any | null, status: string, artId?: number | null, raumId?: number | null, unit: { id: string }, art?: { id: number, name: string } | null, raum?: { id: number, name: string } | null, kategorien: Array<{ id: number, name: string }>, gefahrstoffe: Array<{ id: number, name: string }>, betriebsmittel: Array<{ id: number, name: string }>, dokumente: Array<{ id: number, name: string, size: number, updatedAt: any, downloadUrl: string, uploadUrl: string }> } };

export type CreateBetriebsanweisungArtMutationVariables = Exact<{
  object: BetriebsanweisungArtInputMutation;
}>;


export type CreateBetriebsanweisungArtMutation = { createBetriebsanweisungArt: { id: number, name: string, unit: { id: string } } };

export type DeleteBetriebsanweisungArtMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteBetriebsanweisungArtMutation = { deleteBetriebsanweisungArt: string };

export type BetriebsanweisungArtByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type BetriebsanweisungArtByIdQuery = { findBetriebsanweisungArtById: { id: number, name: string, unit: { id: string } } };

export type BetriebsanweisungArtOverviewQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type BetriebsanweisungArtOverviewQuery = { findBetriebsanweisungArten: Array<{ id: number, name: string, unit: { id: string } }> };

export type UpdateBetriebsanweisungArtMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  object: BetriebsanweisungArtPatchMutation;
}>;


export type UpdateBetriebsanweisungArtMutation = { updateBetriebsanweisungArt: { id: number, name: string, unit: { id: string } } };

export type CountBetriebsmittelQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CountBetriebsmittelQuery = { countBetriebsmittel: number };

export type CreateBetriebsmittelMutationVariables = Exact<{
  object: BetriebsmittelInputMutation;
}>;


export type CreateBetriebsmittelMutation = { createBetriebsmittel: { id: number, createdAt: any, updatedAt: any, name: string, inventarnummer?: string | null, seriennummer?: string | null, anmerkungen?: string | null, archiviert: boolean, letztePruefung?: any | null, pruefintervall: number, naechstePruefung?: any | null, raumId?: number | null, status: string, raum?: { id: number, name: string } | null, unit: { id: string }, kategorien: Array<{ id: number, name: string }>, pruefberichte: Array<{ id: number, name: string }>, betriebsanweisungen: Array<{ id: number, name: string }>, bedienungsanleitungen: Array<{ id: number, name: string }>, gefaehrdungsbeurteilungen: Array<{ id: number, name: string }> } };

export type DeleteBetriebsmittelMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteBetriebsmittelMutation = { deleteBetriebsmittel: string };

export type BetriebsmittelByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type BetriebsmittelByIdQuery = { findBetriebsmittelById: { id: number, createdAt: any, updatedAt: any, name: string, inventarnummer?: string | null, seriennummer?: string | null, anmerkungen?: string | null, archiviert: boolean, letztePruefung?: any | null, pruefintervall: number, naechstePruefung?: any | null, raumId?: number | null, status: string, raum?: { id: number, name: string } | null, unit: { id: string }, kategorien: Array<{ id: number, name: string }>, pruefberichte: Array<{ id: number, name: string }>, betriebsanweisungen: Array<{ id: number, name: string }>, bedienungsanleitungen: Array<{ id: number, name: string }>, gefaehrdungsbeurteilungen: Array<{ id: number, name: string }> } };

export type ExportBetriebsmittelMutationVariables = Exact<{
  unitId: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
}>;


export type ExportBetriebsmittelMutation = { exportBetriebsmittel: string };

export type BetriebsmittelOverviewQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type BetriebsmittelOverviewQuery = { countBetriebsmittel: number, findBetriebsmittel: Array<{ id: number, name: string, anmerkungen?: string | null, archiviert: boolean, letztePruefung?: any | null, pruefintervall: number, naechstePruefung?: any | null, status: string, gefaehrdungsbeurteilungCount: number, betriebsanweisungCount: number, bedienungsanleitungCount: number, pruefberichtCount: number, unit: { id: string, name: string } }> };

export type UpdateBetriebsmittelMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  object: BetriebsmittelPatchMutation;
}>;


export type UpdateBetriebsmittelMutation = { updateBetriebsmittel: { id: number, createdAt: any, updatedAt: any, name: string, inventarnummer?: string | null, seriennummer?: string | null, anmerkungen?: string | null, archiviert: boolean, letztePruefung?: any | null, pruefintervall: number, naechstePruefung?: any | null, raumId?: number | null, status: string, raum?: { id: number, name: string } | null, unit: { id: string }, kategorien: Array<{ id: number, name: string }>, pruefberichte: Array<{ id: number, name: string }>, betriebsanweisungen: Array<{ id: number, name: string }>, bedienungsanleitungen: Array<{ id: number, name: string }>, gefaehrdungsbeurteilungen: Array<{ id: number, name: string }> } };

export type ChartQueryVariables = Exact<{ [key: string]: never; }>;


export type ChartQuery = { dashboard: Array<{ title: string, view: string, labels: Array<string>, series: Array<number> }> };

export type CountDocumentsQueryVariables = Exact<{
  filter?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CountDocumentsQuery = { countDocuments: number };

export type CreateDocumentMutationVariables = Exact<{
  object: DocumentInputMutation;
}>;


export type CreateDocumentMutation = { createDocument: { id: number, archiviert: boolean, downloadUrl: string, uploadUrl: string, isPublic: boolean, createdAt: any, updatedAt: any, name: string, mimetype?: string | null, size: number, notes?: string | null, objectKey: string, unit: { id: string }, tags: Array<{ id: number, name: string }>, gefaehrdungsbeurteilungen: Array<{ id: number, name: string }>, betriebsanweisungen: Array<{ id: number, name: string }>, bedienungsanleitungen: Array<{ id: number, name: string }>, pruefberichte: Array<{ id: number, name: string }>, sicherheitsdatenblaetter: Array<{ id: number, name: string }> } };

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteDocumentMutation = { deleteDocument: string };

export type DocumentByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DocumentByIdQuery = { findDocumentById: { id: number, archiviert: boolean, downloadUrl: string, uploadUrl: string, createdAt: any, updatedAt: any, name: string, mimetype?: string | null, size: number, notes?: string | null, objectKey: string, isPublic: boolean, unit: { id: string }, tags: Array<{ id: number, name: string }>, gefaehrdungsbeurteilungen: Array<{ id: number, name: string }>, betriebsanweisungen: Array<{ id: number, name: string }>, bedienungsanleitungen: Array<{ id: number, name: string }>, pruefberichte: Array<{ id: number, name: string }>, sicherheitsdatenblaetter: Array<{ id: number, name: string }> } };

export type DocumentOverviewQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type DocumentOverviewQuery = { countDocuments: number, findDocuments: Array<{ id: number, archiviert: boolean, downloadUrl: string, uploadUrl: string, name: string, size: number, notes?: string | null, updatedAt: any, unit: { id: string }, tags: Array<{ id: number, name: string }>, gefaehrdungsbeurteilungen: Array<{ id: number }>, betriebsanweisungen: Array<{ id: number }>, bedienungsanleitungen: Array<{ id: number }>, pruefberichte: Array<{ id: number }>, sicherheitsdatenblaetter: Array<{ id: number }> }> };

export type UpdateDocumentMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  object: DocumentPatchMutation;
}>;


export type UpdateDocumentMutation = { updateDocument: { id: number, archiviert: boolean, downloadUrl: string, uploadUrl: string, createdAt: any, updatedAt: any, name: string, mimetype?: string | null, size: number, notes?: string | null, objectKey: string, isPublic: boolean, unit: { id: string }, tags: Array<{ id: number, name: string }>, gefaehrdungsbeurteilungen: Array<{ id: number, name: string }>, betriebsanweisungen: Array<{ id: number, name: string }>, bedienungsanleitungen: Array<{ id: number, name: string }>, pruefberichte: Array<{ id: number, name: string }>, sicherheitsdatenblaetter: Array<{ id: number, name: string }> } };

export type CountGefaehrdungsbeurteilungenQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CountGefaehrdungsbeurteilungenQuery = { countGefaehrdungsbeurteilungen: number };

export type CreateGefaehrdungsbeurteilungMutationVariables = Exact<{
  object: GefaehrdungsbeurteilungInputMutation;
}>;


export type CreateGefaehrdungsbeurteilungMutation = { createGefaehrdungsbeurteilung: { id: number, createdAt: any, updatedAt: any, name: string, anmerkungen?: string | null, archiviert: boolean, letztesReview?: any | null, reviewIntervall: number, naechstesReview?: any | null, status: string, artId?: number | null, raumId?: number | null, unit: { id: string }, art?: { id: number, name: string } | null, raum?: { id: number, name: string } | null, kategorien: Array<{ id: number, name: string }>, gefahrstoffe: Array<{ id: number, name: string }>, betriebsmittel: Array<{ id: number, name: string }>, dokumente: Array<{ id: number, name: string, size: number }> } };

export type DeleteGefaehrdungsbeurteilungMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteGefaehrdungsbeurteilungMutation = { deleteGefaehrdungsbeurteilung: string };

export type GefaehrdungsbeurteilungByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GefaehrdungsbeurteilungByIdQuery = { findGefaehrdungsbeurteilungById: { id: number, createdAt: any, updatedAt: any, name: string, anmerkungen?: string | null, archiviert: boolean, letztesReview?: any | null, reviewIntervall: number, naechstesReview?: any | null, status: string, artId?: number | null, raumId?: number | null, unit: { id: string }, art?: { id: number, name: string } | null, raum?: { id: number, name: string } | null, freigebender?: { vorname: string, nachname: string } | null, kategorien: Array<{ id: number, name: string }>, gefahrstoffe: Array<{ id: number, name: string }>, betriebsmittel: Array<{ id: number, name: string }>, dokumente: Array<{ id: number, name: string, size: number, updatedAt: any, downloadUrl: string, uploadUrl: string }> } };

export type ExportGefaehrdungsbeurteilungenMutationVariables = Exact<{
  unitId: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
}>;


export type ExportGefaehrdungsbeurteilungenMutation = { exportGefaehrdungsbeurteilungen: string };

export type GefaehrdungsbeurteilungenOverviewQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GefaehrdungsbeurteilungenOverviewQuery = { countGefaehrdungsbeurteilungen: number, findGefaehrdungsbeurteilungen: Array<{ id: number, name: string, anmerkungen?: string | null, archiviert: boolean, letztesReview?: any | null, reviewIntervall: number, naechstesReview?: any | null, status: string, artId?: number | null, unit: { id: string, name: string }, art?: { id: number, name: string } | null, dokumente: Array<{ id: number, name: string, size: number }> }> };

export type UpdateGefaehrdungsbeurteilungMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  object: GefaehrdungsbeurteilungPatchMutation;
}>;


export type UpdateGefaehrdungsbeurteilungMutation = { updateGefaehrdungsbeurteilung: { id: number, createdAt: any, updatedAt: any, name: string, anmerkungen?: string | null, archiviert: boolean, letztesReview?: any | null, reviewIntervall: number, naechstesReview?: any | null, status: string, artId?: number | null, raumId?: number | null, unit: { id: string }, art?: { id: number, name: string } | null, raum?: { id: number, name: string } | null, kategorien: Array<{ id: number, name: string }>, gefahrstoffe: Array<{ id: number, name: string }>, betriebsmittel: Array<{ id: number, name: string }>, dokumente: Array<{ id: number, name: string, size: number, updatedAt: any, downloadUrl: string, uploadUrl: string }> } };

export type CreateGefaehrdungsbeurteilungArtMutationVariables = Exact<{
  object: GefaehrdungsbeurteilungArtInputMutation;
}>;


export type CreateGefaehrdungsbeurteilungArtMutation = { createGefaehrdungsbeurteilungArt: { id: number, name: string, unit: { id: string } } };

export type DeleteGefaehrdungsbeurteilungArtMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteGefaehrdungsbeurteilungArtMutation = { deleteGefaehrdungsbeurteilungArt: string };

export type GefaehrdungsbeurteilungArtByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GefaehrdungsbeurteilungArtByIdQuery = { findGefaehrdungsbeurteilungArtById: { id: number, name: string, unit: { id: string } } };

export type GefaehrdungsbeurteilungArtOverviewQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GefaehrdungsbeurteilungArtOverviewQuery = { findGefaehrdungsbeurteilungArten: Array<{ id: number, name: string, unit: { id: string } }> };

export type UpdateGefaehrdungsbeurteilungArtMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  object: GefaehrdungsbeurteilungArtPatchMutation;
}>;


export type UpdateGefaehrdungsbeurteilungArtMutation = { updateGefaehrdungsbeurteilungArt: { id: number, name: string, unit: { id: string } } };

export type CountGefahrstoffeQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CountGefahrstoffeQuery = { countGefahrstoffe: number };

export type CreateGefahrstoffMutationVariables = Exact<{
  object: GefahrstoffInputMutation;
}>;


export type CreateGefahrstoffMutation = { createGefahrstoff: { id: number, createdAt: any, updatedAt: any, name: string, zweck?: string | null, menge?: number | null, mengeneinheit?: string | null, brandgefahr?: boolean | null, einatemgefahr?: boolean | null, hautkontaktgefahr?: boolean | null, explosionsgefahr?: boolean | null, cmr?: boolean | null, substitutionMoeglich?: boolean | null, anmerkungen?: string | null, archiviert: boolean, letzteSubstitutionspruefung?: any | null, substitutionspruefungIntervall: number, naechsteSubstitutionspruefung?: any | null, raumId?: number | null, zusammenlagerung: string, lagerklasse?: string | null, status: string, signalwort?: string | null, sicherheitsdatenblattId?: number | null, raum?: { id: number, name: string } | null, unit: { id: string }, freigebender?: { vorname: string, nachname: string } | null, kategorien: Array<{ id: number, name: string }>, sicherheitsdatenblatt?: { id: number, name: string } | null, betriebsanweisungen: Array<{ id: number, name: string }>, gefaehrdungsbeurteilungen: Array<{ id: number, name: string }>, hSaetze: Array<{ id: number, satz: string, code: string }>, pSaetze: Array<{ id: number, satz: string, code: string }>, separatlagerungErforderlich: Array<{ id: number, name: string, lagerklasse?: string | null }>, zusammenlagerungEingeschraenkt: Array<{ id: number, name: string, lagerklasse?: string | null }> } };

export type DeleteGefahrstoffMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteGefahrstoffMutation = { deleteGefahrstoff: string };

export type GefahrstoffByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GefahrstoffByIdQuery = { findGefahrstoffById: { id: number, createdAt: any, updatedAt: any, name: string, zweck?: string | null, menge?: number | null, mengeneinheit?: string | null, brandgefahr?: boolean | null, einatemgefahr?: boolean | null, hautkontaktgefahr?: boolean | null, explosionsgefahr?: boolean | null, cmr?: boolean | null, substitutionMoeglich?: boolean | null, anmerkungen?: string | null, archiviert: boolean, letzteSubstitutionspruefung?: any | null, substitutionspruefungIntervall: number, naechsteSubstitutionspruefung?: any | null, raumId?: number | null, zusammenlagerung: string, lagerklasse?: string | null, status: string, signalwort?: string | null, sicherheitsdatenblattId?: number | null, raum?: { id: number, name: string } | null, unit: { id: string }, freigebender?: { vorname: string, nachname: string } | null, kategorien: Array<{ id: number, name: string }>, sicherheitsdatenblatt?: { id: number, name: string } | null, betriebsanweisungen: Array<{ id: number, name: string }>, gefaehrdungsbeurteilungen: Array<{ id: number, name: string }>, hSaetze: Array<{ id: number, satz: string, code: string }>, pSaetze: Array<{ id: number, satz: string, code: string }>, separatlagerungErforderlich: Array<{ id: number, name: string, lagerklasse?: string | null }>, zusammenlagerungEingeschraenkt: Array<{ id: number, name: string, lagerklasse?: string | null }> } };

export type ExportGefahrstoffeMutationVariables = Exact<{
  unitId: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
}>;


export type ExportGefahrstoffeMutation = { exportGefahrstoffe: string };

export type GefahrstoffOverviewQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GefahrstoffOverviewQuery = { countGefahrstoffe: number, findGefahrstoffe: Array<{ id: number, name: string, anmerkungen?: string | null, archiviert: boolean, zusammenlagerung: string, lagerklasse?: string | null, status: string, signalwort?: string | null, raumId?: number | null, sicherheitsdatenblattId?: number | null, unit: { id: string, name: string }, raum?: { id: number, name: string } | null, sicherheitsdatenblatt?: { id: number, name: string } | null, separatlagerungErforderlich: Array<{ id: number, name: string, lagerklasse?: string | null }>, zusammenlagerungEingeschraenkt: Array<{ id: number, name: string, lagerklasse?: string | null }> }> };

export type UpdateGefahrstoffMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  object: GefahrstoffPatchMutation;
}>;


export type UpdateGefahrstoffMutation = { updateGefahrstoff: { id: number, createdAt: any, updatedAt: any, name: string, zweck?: string | null, menge?: number | null, mengeneinheit?: string | null, brandgefahr?: boolean | null, einatemgefahr?: boolean | null, hautkontaktgefahr?: boolean | null, explosionsgefahr?: boolean | null, cmr?: boolean | null, substitutionMoeglich?: boolean | null, anmerkungen?: string | null, archiviert: boolean, letzteSubstitutionspruefung?: any | null, substitutionspruefungIntervall: number, naechsteSubstitutionspruefung?: any | null, raumId?: number | null, zusammenlagerung: string, lagerklasse?: string | null, status: string, signalwort?: string | null, sicherheitsdatenblattId?: number | null, raum?: { id: number, name: string } | null, unit: { id: string }, freigebender?: { vorname: string, nachname: string } | null, kategorien: Array<{ id: number, name: string }>, sicherheitsdatenblatt?: { id: number, name: string } | null, betriebsanweisungen: Array<{ id: number, name: string }>, gefaehrdungsbeurteilungen: Array<{ id: number, name: string }>, hSaetze: Array<{ id: number, satz: string, code: string }>, pSaetze: Array<{ id: number, satz: string, code: string }>, separatlagerungErforderlich: Array<{ id: number, name: string, lagerklasse?: string | null }>, zusammenlagerungEingeschraenkt: Array<{ id: number, name: string, lagerklasse?: string | null }> } };

export type HSatzOverviewQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<Scalars['String']['input']>;
}>;


export type HSatzOverviewQuery = { findHSaetze: Array<{ id: number, satz: string, code: string }> };

export type CreateKategorieMutationVariables = Exact<{
  object: KategorieInputMutation;
}>;


export type CreateKategorieMutation = { createKategorie: { id: number, createdAt: any, updatedAt: any, name: string, unit: { id: string }, gefahrstoffe: Array<{ id: number }>, betriebsmittel: Array<{ id: number }>, gefaehrdungsbeurteilungen: Array<{ id: number }>, betriebsanweisungen: Array<{ id: number }> } };

export type DeleteKategorieMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteKategorieMutation = { deleteKategorie: string };

export type KategorieByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type KategorieByIdQuery = { findKategorieById: { id: number, createdAt: any, updatedAt: any, name: string, unit: { id: string }, gefahrstoffe: Array<{ id: number }>, betriebsmittel: Array<{ id: number }>, gefaehrdungsbeurteilungen: Array<{ id: number }>, betriebsanweisungen: Array<{ id: number }> } };

export type KategorieOverviewQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<Scalars['String']['input']>;
}>;


export type KategorieOverviewQuery = { findKategorien: Array<{ id: number, createdAt: any, updatedAt: any, name: string, unit: { id: string }, gefahrstoffe: Array<{ id: number }>, betriebsmittel: Array<{ id: number }>, gefaehrdungsbeurteilungen: Array<{ id: number }>, betriebsanweisungen: Array<{ id: number }> }> };

export type UpdateKategorieMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  object: KategoriePatchMutation;
}>;


export type UpdateKategorieMutation = { updateKategorie: { id: number, createdAt: any, updatedAt: any, name: string, unit: { id: string }, gefahrstoffe: Array<{ id: number }>, betriebsmittel: Array<{ id: number }>, gefaehrdungsbeurteilungen: Array<{ id: number }>, betriebsanweisungen: Array<{ id: number }> } };

export type CountProtocolsQueryVariables = Exact<{
  filter?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CountProtocolsQuery = { countProtocols: number };

export type ProtocolOverviewQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ProtocolOverviewQuery = { countProtocols: number, findProtocols: Array<{ id: number, archiviert: boolean, downloadUrl: string, uploadUrl: string, name: string, size: number, notes?: string | null, updatedAt: any, unit: { id: string, name: string }, tags: Array<{ id: number, name: string }>, gefaehrdungsbeurteilungen: Array<{ id: number }>, betriebsanweisungen: Array<{ id: number }>, bedienungsanleitungen: Array<{ id: number }>, pruefberichte: Array<{ id: number }>, sicherheitsdatenblaetter: Array<{ id: number }> }> };

export type CountPruefberichteQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CountPruefberichteQuery = { countPruefberichte: number };

export type CreatePruefberichtMutationVariables = Exact<{
  object: PruefberichtInputMutation;
}>;


export type CreatePruefberichtMutation = { createPruefbericht: { id: number, createdAt: any, updatedAt: any, name: string, unit: { id: string }, betriebsmittel: Array<{ id: number, name: string }>, dokumente: Array<{ id: number, name: string, size: number }> } };

export type DeletePruefberichtMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeletePruefberichtMutation = { deletePruefbericht: string };

export type PruefberichtDetailQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type PruefberichtDetailQuery = { findPruefberichtById: { id: number, createdAt: any, updatedAt: any, name: string, unit: { id: string }, betriebsmittel: Array<{ id: number, name: string }>, dokumente: Array<{ id: number, name: string, size: number, updatedAt: any, downloadUrl: string, uploadUrl: string }> } };

export type PruefberichtOverviewQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type PruefberichtOverviewQuery = { countPruefberichte: number, findPruefberichte: Array<{ id: number, archiviert: boolean, createdAt: any, updatedAt: any, name: string, unit: { id: string, name: string }, betriebsmittel: Array<{ id: number, name: string }>, dokumente: Array<{ id: number, name: string, size: number }> }> };

export type UpdatePruefberichtMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  object: PruefberichtPatchMutation;
}>;


export type UpdatePruefberichtMutation = { updatePruefbericht: { id: number, createdAt: any, updatedAt: any, name: string, unit: { id: string }, betriebsmittel: Array<{ id: number, name: string }>, dokumente: Array<{ id: number, name: string, size: number, updatedAt: any, downloadUrl: string, uploadUrl: string }> } };

export type PSatzOverviewQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<Scalars['String']['input']>;
}>;


export type PSatzOverviewQuery = { findPSaetze: Array<{ id: number, satz: string, code: string }> };

export type CreateRaumMutationVariables = Exact<{
  object: RaumInputMutation;
}>;


export type CreateRaumMutation = { createRaum: { id: number, createdAt: any, updatedAt: any, name: string, unit: { id: string } } };

export type DeleteRaumMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteRaumMutation = { deleteRaum: string };

export type RaumByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type RaumByIdQuery = { findRaumById: { id: number, createdAt: any, updatedAt: any, name: string, unit: { id: string } } };

export type RaumOverviewQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<Scalars['String']['input']>;
}>;


export type RaumOverviewQuery = { findRaeume: Array<{ id: number, createdAt: any, updatedAt: any, name: string, unit: { id: string } }> };

export type UpdateRaumMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  object: RaumPatchMutation;
}>;


export type UpdateRaumMutation = { updateRaum: { id: number, createdAt: any, updatedAt: any, name: string, unit: { id: string } } };

export type GetReportingDisabledUnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReportingDisabledUnitsQuery = { getReportingDisabledUnits: Array<string> };

export type SetUnitReportingStatesMutationVariables = Exact<{
  states: Array<ReportingState> | ReportingState;
}>;


export type SetUnitReportingStatesMutation = { setUnitReportingStates: Array<string> };

export type CountSicherheitsdatenblaetterQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CountSicherheitsdatenblaetterQuery = { countSicherheitsdatenblaetter: number };

export type CreateSicherheitsdatenblattMutationVariables = Exact<{
  object: SicherheitsdatenblattInputMutation;
}>;


export type CreateSicherheitsdatenblattMutation = { createSicherheitsdatenblatt: { id: number, createdAt: any, updatedAt: any, name: string, unit: { id: string }, gefahrstoffe: Array<{ id: number, name: string }>, dokumente: Array<{ id: number, name: string, size: number }> } };

export type DeleteSicherheitsdatenblattMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteSicherheitsdatenblattMutation = { deleteSicherheitsdatenblatt: string };

export type SicherheitsdatenblattByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type SicherheitsdatenblattByIdQuery = { findSicherheitsdatenblattById: { id: number, createdAt: any, updatedAt: any, name: string, unit: { id: string }, gefahrstoffe: Array<{ id: number, name: string }>, dokumente: Array<{ id: number, name: string, size: number, updatedAt: any, downloadUrl: string, uploadUrl: string }> } };

export type SicherheitsdatenblattOverviewQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<Scalars['String']['input']>;
  archiviert?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type SicherheitsdatenblattOverviewQuery = { countSicherheitsdatenblaetter: number, findSicherheitsdatenblaetter: Array<{ id: number, archiviert: boolean, createdAt: any, updatedAt: any, name: string, unit: { id: string, name: string }, gefahrstoffe: Array<{ id: number, name: string }>, dokumente: Array<{ id: number, name: string, size: number }> }> };

export type UpdateSicherheitsdatenblattMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  object: SicherheitsdatenblattPatchMutation;
}>;


export type UpdateSicherheitsdatenblattMutation = { updateSicherheitsdatenblatt: { id: number, createdAt: any, updatedAt: any, name: string, unit: { id: string }, gefahrstoffe: Array<{ id: number, name: string }>, dokumente: Array<{ id: number, name: string, size: number, updatedAt: any, downloadUrl: string, uploadUrl: string }> } };

export type CreateTagMutationVariables = Exact<{
  object: TagInputMutation;
}>;


export type CreateTagMutation = { createTag: { id: number, createdAt: any, updatedAt: any, name: string, unit: { id: string } } };

export type DeleteTagMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteTagMutation = { deleteTag: string };

export type TagByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type TagByIdQuery = { findTagById: { id: number, createdAt: any, updatedAt: any, name: string, unit: { id: string } } };

export type TagOverviewQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<Scalars['String']['input']>;
}>;


export type TagOverviewQuery = { findTags: Array<{ id: number, createdAt: any, updatedAt: any, name: string, unit: { id: string } }> };

export type UpdateTagMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  object: TagPatchMutation;
}>;


export type UpdateTagMutation = { updateTag: { id: number, createdAt: any, updatedAt: any, name: string, unit: { id: string } } };

export type CreateEintragMutationVariables = Exact<{
  body: CreateEintragDto;
}>;


export type CreateEintragMutation = { createEintrag: { unfallzeitpunkt: any, erstehilfezeitpunkt: any, revision?: number | null, id: string, verletzter: string, arbeitsbereich: string, unfallort: string, hergang: string, verletzung: string, zeugen: string, umfang: string, massnahmen: string, ersthelfer: string, createdAt?: any | null, updatedAt?: any | null, eintragType: string, verbandbuch: { id: string, name: string } } };

export type CreatePublicEintragMutationVariables = Exact<{
  body: CreatePublicEintragDto;
}>;


export type CreatePublicEintragMutation = { createPublicEintrag: { unfallzeitpunkt: any, erstehilfezeitpunkt: any, revision?: number | null, id: string, verletzter: string, arbeitsbereich: string, unfallort: string, hergang: string, verletzung: string, zeugen: string, umfang: string, massnahmen: string, ersthelfer: string, createdAt?: any | null, updatedAt?: any | null, eintragType: string } };

export type EintragDetailQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type EintragDetailQuery = { getEintragById: { unfallzeitpunkt: any, erstehilfezeitpunkt: any, revision?: number | null, id: string, verletzter: string, arbeitsbereich: string, unfallort: string, hergang: string, verletzung: string, zeugen: string, umfang: string, massnahmen: string, ersthelfer: string, createdAt?: any | null, updatedAt?: any | null, eintragType: string, verbandbuch: { id: string, name: string } } };

export type EintraegeForUnitsQueryVariables = Exact<{
  unitIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  order_criteria?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EintragFilterArgs>;
}>;


export type EintraegeForUnitsQuery = { getAllEintraegeForUnits: Array<{ unfallzeitpunkt: any, erstehilfezeitpunkt: any, revision?: number | null, id: string, verletzter: string, arbeitsbereich: string, unfallort: string, hergang: string, verletzung: string, zeugen: string, umfang: string, massnahmen: string, ersthelfer: string, createdAt?: any | null, updatedAt?: any | null, eintragType: string, verbandbuch: { id: string, name: string, unit: { name: string } } }> };

export type EintraegeForVerbandbuchQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  order_criteria?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EintragFilterArgs>;
}>;


export type EintraegeForVerbandbuchQuery = { getEintragForVerbandbuch: Array<{ unfallzeitpunkt: any, erstehilfezeitpunkt: any, revision?: number | null, id: string, verletzter: string, arbeitsbereich: string, unfallort: string, hergang: string, verletzung: string, zeugen: string, umfang: string, massnahmen: string, ersthelfer: string, createdAt?: any | null, updatedAt?: any | null, eintragType: string, verbandbuch: { id: string, name: string } }> };

export type GetEintragRevisionsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetEintragRevisionsQuery = { getEintragRevisions: Array<{ unfallzeitpunkt: any, erstehilfezeitpunkt: any, verbandbuch: string, revision?: number | null, id: string, historyId: string, verletzter: string, arbeitsbereich: string, unfallort: string, hergang: string, verletzung: string, zeugen: string, umfang: string, massnahmen: string, ersthelfer: string, updatedAt?: any | null, createdAt?: any | null, eintragType: string }> };

export type EintragOverviewQueryVariables = Exact<{
  unitIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type EintragOverviewQuery = { countEintrag: number, getAllEintraegeForUnits: Array<{ unfallzeitpunkt: any, erstehilfezeitpunkt: any, revision?: number | null, id: string, verletzter: string, arbeitsbereich: string, unfallort: string, hergang: string, verletzung: string, zeugen: string, umfang: string, massnahmen: string, ersthelfer: string, createdAt?: any | null, updatedAt?: any | null, eintragType: string, verbandbuch: { id: string, name: string } }> };

export type UpdateEintragMutationVariables = Exact<{
  id: Scalars['String']['input'];
  body: UpdateEintragDto;
}>;


export type UpdateEintragMutation = { updateEintrag: { unfallzeitpunkt: any, erstehilfezeitpunkt: any, revision?: number | null, id: string, verletzter: string, arbeitsbereich: string, unfallort: string, hergang: string, verletzung: string, zeugen: string, umfang: string, massnahmen: string, ersthelfer: string, createdAt?: any | null, updatedAt?: any | null, eintragType: string, verbandbuch: { id: string, name: string } } };

export type VerbandbuchCountQueryVariables = Exact<{ [key: string]: never; }>;


export type VerbandbuchCountQuery = { countVerbandbuch: number };

export type CreateVerbandbuchMutationVariables = Exact<{
  body: CreateVerbandbuchDto;
}>;


export type CreateVerbandbuchMutation = { createVerbandbuch: { id: string, name: string, settingsDocument?: string | null, notificationList?: Array<string> | null, exports?: Array<string> | null, archiviert?: boolean | null, unit: { id: string, name: string }, eintraege: Array<{ id: string }>, qrCode?: { svg: string, forIdentifier: string } | null } };

export type DeleteVerbandbuchMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteVerbandbuchMutation = { deleteVerbandbuch: string };

export type VerbandbuchDetailQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type VerbandbuchDetailQuery = { getVerbandbuchById: { id: string, name: string, settingsDocument?: string | null, notificationList?: Array<string> | null, exports?: Array<string> | null, archiviert?: boolean | null, identifier?: string | null, unit: { id: string, name: string }, eintraege: Array<{ id: string }>, qrCode?: { svg: string, forIdentifier: string } | null } };

export type GetDiagramInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDiagramInformationQuery = { getDiagramInformation: Array<{ total: number, date: string }> };

export type ExportVerbandbuchEintraegeMutationVariables = Exact<{
  unitId: Scalars['String']['input'];
}>;


export type ExportVerbandbuchEintraegeMutation = { exportVerbandbuchEintraege: string };

export type VerbandbuchOverviewQueryVariables = Exact<{ [key: string]: never; }>;


export type VerbandbuchOverviewQuery = { countVerbandbuch: number, getVerbandbuecher: Array<{ id: string, name: string, settingsDocument?: string | null, notificationList?: Array<string> | null, exports?: Array<string> | null, archiviert?: boolean | null, identifier?: string | null, unit: { id: string, name: string }, eintraege: Array<{ id: string }>, qrCode?: { svg: string, forIdentifier: string } | null }> };

export type UpdateVerbandbuchMutationVariables = Exact<{
  id: Scalars['String']['input'];
  body: UpdateVerbandbuchDto;
}>;


export type UpdateVerbandbuchMutation = { updateVerbandbuch: { id: string, name: string, settingsDocument?: string | null, notificationList?: Array<string> | null, exports?: Array<string> | null, archiviert?: boolean | null, unit: { id: string, name: string }, eintraege: Array<{ id: string }>, qrCode?: { svg: string, forIdentifier: string } | null } };


export const GetUserByIdDocument = gql`
    query GetUserById($userId: ID!) {
  user(id: $userId) {
    vorname
    nachname
  }
}
    `;
export const GetUsersForUnitDocument = gql`
    query GetUsersForUnit {
  users {
    id
    vorname
    nachname
    accessibleUnits {
      scopes
      unit
    }
  }
}
    `;
export const MeDocument = gql`
    query Me {
  me {
    id
    vorname
    nachname
    email
    telefonnummer
    mobilnummer
    preferredLanguage
  }
}
    `;
export const UpdateMeDocument = gql`
    mutation UpdateMe($updateUser: UpdateUserInput!) {
  updateMe(input: $updateUser) {
    id
    vorname
    nachname
    email
    telefonnummer
    mobilnummer
    preferredLanguage
  }
}
    `;
export const UpdateScopesForUserDocument = gql`
    mutation UpdateScopesForUser($unit: ID!, $user: ID!, $updateScopes: UpdateScopesInput!) {
  updateScopes(unit: $unit, user: $user, input: $updateScopes) {
    id
    vorname
    nachname
    email
    telefonnummer
    mobilnummer
    accessibleUnits {
      default
      scopes
      unit
    }
  }
}
    `;
export const CreateCompanyDocument = gql`
    mutation CreateCompany($units: [ID!], $name: String!) {
  createCompany(units: $units, name: $name) {
    id
    name
    units {
      id
      name
    }
  }
}
    `;
export const DeleteCompanyDocument = gql`
    mutation DeleteCompany($id: ID!) {
  deleteCompany(id: $id)
}
    `;
export const GetAsTreeDocument = gql`
    query GetAsTree {
  getAsTree {
    ... on Company {
      __typename
      id
      name
      units {
        __typename
        id
        name
        ort
      }
    }
    ... on Unit {
      __typename
      id
      name
      ort
    }
  }
}
    `;
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($id: ID!, $units: [ID!], $name: String) {
  updateCompany(id: $id, units: $units, name: $name) {
    id
    name
    units {
      id
      name
    }
  }
}
    `;
export const GetPaymentAccountsDocument = gql`
    query GetPaymentAccounts {
  paymentAccounts {
    units
    id
    name
    invoiceEmail
    plz
    strasse
    ort
    hausnummer
    activated
    quantity
  }
}
    `;
export const UpdatePaymentAccountDocument = gql`
    mutation updatePaymentAccount($accountId: ID!, $updatePaymentAccountDto: UpdatePaymentAccountInput!) {
  updatePaymentAccount(id: $accountId, input: $updatePaymentAccountDto) {
    id
    name
    invoiceEmail
    plz
    strasse
    ort
    hausnummer
    activated
    quantity
    units
  }
}
    `;
export const AcceptInviteDocument = gql`
    mutation AcceptInvite($inviteId: ID!) {
  acceptInvite(id: $inviteId)
}
    `;
export const CreateUnitDocument = gql`
    mutation CreateUnit($createUnit: CreateUnitInput!) {
  createUnit(input: $createUnit) {
    id
    status
    name
    berufsgenossenschaft
    ort
    plz
    hausnummer
    strasse
    anmerkungen
  }
}
    `;
export const GetUnitsDocument = gql`
    query GetUnits {
  units {
    id
    status
    name
    berufsgenossenschaft
    ort
    plz
    hausnummer
    strasse
    invites {
      status
      inviteSent
      inviteAccepted
      email
      inviteId
    }
    anmerkungen
  }
}
    `;
export const InviteUserForUnitDocument = gql`
    mutation InviteUserForUnit($inviteObject: InviteInput!) {
  invite(input: $inviteObject)
}
    `;
export const UpdateUnitByIdDocument = gql`
    mutation UpdateUnitById($updateUnit: UpdateUnitInput!, $unitId: ID!) {
  updateUnit(input: $updateUnit, id: $unitId) {
    id
    status
    name
    berufsgenossenschaft
    ort
    plz
    hausnummer
    strasse
    invites {
      status
      inviteSent
      inviteAccepted
      email
      inviteId
    }
    anmerkungen
  }
}
    `;
export const CountBedienungsanleitungenDocument = gql`
    query CountBedienungsanleitungen($pagination: Pagination, $filter: String, $archiviert: Boolean) {
  countBedienungsanleitungen(pagination: $pagination, filter: $filter, archiviert: $archiviert)
}
    `;
export const CreateBedienungsanleitungDocument = gql`
    mutation CreateBedienungsanleitung($object: BedienungsanleitungInputMutation!) {
  createBedienungsanleitung(object: $object) {
    id
    createdAt
    updatedAt
    name
    unit {
      id
    }
    betriebsmittel {
      id
      name
    }
    dokumente {
      id
      name
      size
    }
  }
}
    `;
export const DeleteBedienungsanleitungDocument = gql`
    mutation DeleteBedienungsanleitung($id: Int!) {
  deleteBedienungsanleitung(id: $id)
}
    `;
export const BedienungsanleitungByIdDocument = gql`
    query BedienungsanleitungById($id: Int!) {
  findBedienungsanleitungById(id: $id) {
    id
    createdAt
    updatedAt
    name
    unit {
      id
    }
    betriebsmittel {
      id
      name
    }
    dokumente {
      id
      name
      size
      updatedAt
      downloadUrl
      uploadUrl
    }
  }
}
    `;
export const BedienungsanleitungOverviewDocument = gql`
    query BedienungsanleitungOverview($pagination: Pagination, $filter: String, $archiviert: Boolean) {
  findBedienungsanleitungen(pagination: $pagination, filter: $filter, archiviert: $archiviert) {
    id
    archiviert
    createdAt
    updatedAt
    name
    unit {
      id
      name
    }
    betriebsmittel {
      id
      name
    }
    dokumente {
      id
      name
      size
    }
  }
  countBedienungsanleitungen(filter: $filter, archiviert: $archiviert)
}
    `;
export const UpdateBedienungsanleitungDocument = gql`
    mutation UpdateBedienungsanleitung($id: Int!, $object: BedienungsanleitungPatchMutation!) {
  updateBedienungsanleitung(id: $id, object: $object) {
    id
    createdAt
    updatedAt
    name
    unit {
      id
    }
    betriebsmittel {
      id
      name
    }
    dokumente {
      id
      name
      size
      updatedAt
      downloadUrl
      uploadUrl
    }
  }
}
    `;
export const CountBetriebsanweisungenDocument = gql`
    query CountBetriebsanweisungen($pagination: Pagination, $filter: String, $archiviert: Boolean) {
  countBetriebsanweisungen(pagination: $pagination, filter: $filter, archiviert: $archiviert)
}
    `;
export const CreateBetriebsanweisungDocument = gql`
    mutation CreateBetriebsanweisung($object: BetriebsanweisungInputMutation!) {
  createBetriebsanweisung(object: $object) {
    id
    createdAt
    updatedAt
    name
    anmerkungen
    archiviert
    letztesReview
    reviewIntervall
    naechstesReview
    status
    unit {
      id
    }
    artId
    art {
      id
      name
    }
    raumId
    raum {
      id
      name
    }
    kategorien {
      id
      name
    }
    gefahrstoffe {
      id
      name
    }
    betriebsmittel {
      id
      name
    }
    dokumente {
      id
      name
      size
    }
  }
}
    `;
export const DeleteBetriebsanweisungDocument = gql`
    mutation DeleteBetriebsanweisung($id: Int!) {
  deleteBetriebsanweisung(id: $id)
}
    `;
export const BetriebsanweisungByIdDocument = gql`
    query BetriebsanweisungById($id: Int!) {
  findBetriebsanweisungById(id: $id) {
    id
    createdAt
    updatedAt
    name
    anmerkungen
    archiviert
    letztesReview
    reviewIntervall
    naechstesReview
    status
    unit {
      id
    }
    artId
    art {
      id
      name
    }
    raumId
    raum {
      id
      name
    }
    freigebender {
      vorname
      nachname
    }
    kategorien {
      id
      name
    }
    gefahrstoffe {
      id
      name
    }
    betriebsmittel {
      id
      name
    }
    dokumente {
      id
      name
      size
      updatedAt
      downloadUrl
      uploadUrl
    }
  }
}
    `;
export const ExportBetriebsanweisungenDocument = gql`
    mutation ExportBetriebsanweisungen($unitId: String!, $filter: String) {
  exportBetriebsanweisungen(unitId: $unitId, filter: $filter)
}
    `;
export const BetriebsanweisungOverviewDocument = gql`
    query BetriebsanweisungOverview($pagination: Pagination, $filter: String, $archiviert: Boolean) {
  findBetriebsanweisungen(pagination: $pagination, filter: $filter, archiviert: $archiviert) {
    id
    name
    anmerkungen
    archiviert
    letztesReview
    reviewIntervall
    naechstesReview
    status
    unit {
      id
      name
    }
    artId
    art {
      id
      name
    }
    dokumente {
      id
      name
      size
    }
  }
  countBetriebsanweisungen(filter: $filter, archiviert: $archiviert)
}
    `;
export const UpdateBetriebsanweisungDocument = gql`
    mutation UpdateBetriebsanweisung($id: Int!, $object: BetriebsanweisungPatchMutation!) {
  updateBetriebsanweisung(id: $id, object: $object) {
    id
    createdAt
    updatedAt
    name
    anmerkungen
    archiviert
    letztesReview
    reviewIntervall
    naechstesReview
    status
    unit {
      id
    }
    artId
    art {
      id
      name
    }
    raumId
    raum {
      id
      name
    }
    kategorien {
      id
      name
    }
    gefahrstoffe {
      id
      name
    }
    betriebsmittel {
      id
      name
    }
    dokumente {
      id
      name
      size
      updatedAt
      downloadUrl
      uploadUrl
    }
  }
}
    `;
export const CreateBetriebsanweisungArtDocument = gql`
    mutation CreateBetriebsanweisungArt($object: BetriebsanweisungArtInputMutation!) {
  createBetriebsanweisungArt(object: $object) {
    id
    name
    unit {
      id
    }
  }
}
    `;
export const DeleteBetriebsanweisungArtDocument = gql`
    mutation DeleteBetriebsanweisungArt($id: Int!) {
  deleteBetriebsanweisungArt(id: $id)
}
    `;
export const BetriebsanweisungArtByIdDocument = gql`
    query BetriebsanweisungArtById($id: Int!) {
  findBetriebsanweisungArtById(id: $id) {
    id
    name
    unit {
      id
    }
  }
}
    `;
export const BetriebsanweisungArtOverviewDocument = gql`
    query BetriebsanweisungArtOverview($pagination: Pagination, $filter: String, $archiviert: Boolean) {
  findBetriebsanweisungArten(pagination: $pagination, filter: $filter, archiviert: $archiviert) {
    id
    name
    unit {
      id
    }
  }
}
    `;
export const UpdateBetriebsanweisungArtDocument = gql`
    mutation UpdateBetriebsanweisungArt($id: Int!, $object: BetriebsanweisungArtPatchMutation!) {
  updateBetriebsanweisungArt(id: $id, object: $object) {
    id
    name
    unit {
      id
    }
  }
}
    `;
export const CountBetriebsmittelDocument = gql`
    query CountBetriebsmittel($pagination: Pagination, $filter: String, $archiviert: Boolean) {
  countBetriebsmittel(pagination: $pagination, filter: $filter, archiviert: $archiviert)
}
    `;
export const CreateBetriebsmittelDocument = gql`
    mutation CreateBetriebsmittel($object: BetriebsmittelInputMutation!) {
  createBetriebsmittel(object: $object) {
    id
    createdAt
    updatedAt
    name
    inventarnummer
    seriennummer
    anmerkungen
    archiviert
    letztePruefung
    pruefintervall
    naechstePruefung
    raumId
    raum {
      id
      name
    }
    status
    unit {
      id
    }
    kategorien {
      id
      name
    }
    pruefberichte {
      id
      name
    }
    betriebsanweisungen {
      id
      name
    }
    bedienungsanleitungen {
      id
      name
    }
    gefaehrdungsbeurteilungen {
      id
      name
    }
  }
}
    `;
export const DeleteBetriebsmittelDocument = gql`
    mutation DeleteBetriebsmittel($id: Int!) {
  deleteBetriebsmittel(id: $id)
}
    `;
export const BetriebsmittelByIdDocument = gql`
    query BetriebsmittelById($id: Int!) {
  findBetriebsmittelById(id: $id) {
    id
    createdAt
    updatedAt
    name
    inventarnummer
    seriennummer
    anmerkungen
    archiviert
    letztePruefung
    pruefintervall
    naechstePruefung
    raumId
    raum {
      id
      name
    }
    status
    unit {
      id
    }
    kategorien {
      id
      name
    }
    pruefberichte {
      id
      name
    }
    betriebsanweisungen {
      id
      name
    }
    bedienungsanleitungen {
      id
      name
    }
    gefaehrdungsbeurteilungen {
      id
      name
    }
  }
}
    `;
export const ExportBetriebsmittelDocument = gql`
    mutation ExportBetriebsmittel($unitId: String!, $filter: String) {
  exportBetriebsmittel(unitId: $unitId, filter: $filter)
}
    `;
export const BetriebsmittelOverviewDocument = gql`
    query BetriebsmittelOverview($pagination: Pagination, $filter: String, $archiviert: Boolean) {
  findBetriebsmittel(pagination: $pagination, filter: $filter, archiviert: $archiviert) {
    id
    name
    anmerkungen
    archiviert
    letztePruefung
    pruefintervall
    naechstePruefung
    status
    unit {
      id
      name
    }
    gefaehrdungsbeurteilungCount
    betriebsanweisungCount
    bedienungsanleitungCount
    pruefberichtCount
  }
  countBetriebsmittel(filter: $filter, archiviert: $archiviert)
}
    `;
export const UpdateBetriebsmittelDocument = gql`
    mutation UpdateBetriebsmittel($id: Int!, $object: BetriebsmittelPatchMutation!) {
  updateBetriebsmittel(id: $id, object: $object) {
    id
    createdAt
    updatedAt
    name
    inventarnummer
    seriennummer
    anmerkungen
    archiviert
    letztePruefung
    pruefintervall
    naechstePruefung
    raumId
    raum {
      id
      name
    }
    status
    unit {
      id
    }
    kategorien {
      id
      name
    }
    pruefberichte {
      id
      name
    }
    betriebsanweisungen {
      id
      name
    }
    bedienungsanleitungen {
      id
      name
    }
    gefaehrdungsbeurteilungen {
      id
      name
    }
  }
}
    `;
export const ChartDocument = gql`
    query Chart {
  dashboard {
    title
    view
    labels
    series
  }
}
    `;
export const CountDocumentsDocument = gql`
    query CountDocuments($filter: String, $archiviert: Boolean) {
  countDocuments(filter: $filter, archiviert: $archiviert)
}
    `;
export const CreateDocumentDocument = gql`
    mutation CreateDocument($object: DocumentInputMutation!) {
  createDocument(object: $object) {
    id
    archiviert
    downloadUrl
    uploadUrl
    isPublic
    createdAt
    updatedAt
    name
    mimetype
    size
    notes
    objectKey
    isPublic
    unit {
      id
    }
    tags {
      id
      name
    }
    updatedAt
    gefaehrdungsbeurteilungen {
      id
      name
    }
    betriebsanweisungen {
      id
      name
    }
    bedienungsanleitungen {
      id
      name
    }
    pruefberichte {
      id
      name
    }
    sicherheitsdatenblaetter {
      id
      name
    }
  }
}
    `;
export const DeleteDocumentDocument = gql`
    mutation DeleteDocument($id: Int!) {
  deleteDocument(id: $id)
}
    `;
export const DocumentByIdDocument = gql`
    query DocumentById($id: Int!) {
  findDocumentById(id: $id) {
    id
    archiviert
    downloadUrl
    uploadUrl
    createdAt
    updatedAt
    name
    mimetype
    size
    notes
    objectKey
    isPublic
    unit {
      id
    }
    tags {
      id
      name
    }
    updatedAt
    gefaehrdungsbeurteilungen {
      id
      name
    }
    betriebsanweisungen {
      id
      name
    }
    bedienungsanleitungen {
      id
      name
    }
    pruefberichte {
      id
      name
    }
    sicherheitsdatenblaetter {
      id
      name
    }
  }
}
    `;
export const DocumentOverviewDocument = gql`
    query DocumentOverview($pagination: Pagination, $filter: String, $archiviert: Boolean) {
  findDocuments(pagination: $pagination, filter: $filter, archiviert: $archiviert) {
    id
    archiviert
    downloadUrl
    uploadUrl
    name
    size
    notes
    unit {
      id
    }
    tags {
      id
      name
    }
    updatedAt
    gefaehrdungsbeurteilungen {
      id
    }
    betriebsanweisungen {
      id
    }
    bedienungsanleitungen {
      id
    }
    pruefberichte {
      id
    }
    sicherheitsdatenblaetter {
      id
    }
  }
  countDocuments(filter: $filter, archiviert: $archiviert)
}
    `;
export const UpdateDocumentDocument = gql`
    mutation UpdateDocument($id: Int!, $object: DocumentPatchMutation!) {
  updateDocument(id: $id, object: $object) {
    id
    archiviert
    downloadUrl
    uploadUrl
    createdAt
    updatedAt
    name
    mimetype
    size
    notes
    objectKey
    isPublic
    unit {
      id
    }
    tags {
      id
      name
    }
    updatedAt
    gefaehrdungsbeurteilungen {
      id
      name
    }
    betriebsanweisungen {
      id
      name
    }
    bedienungsanleitungen {
      id
      name
    }
    pruefberichte {
      id
      name
    }
    sicherheitsdatenblaetter {
      id
      name
    }
  }
}
    `;
export const CountGefaehrdungsbeurteilungenDocument = gql`
    query CountGefaehrdungsbeurteilungen($pagination: Pagination, $filter: String, $archiviert: Boolean) {
  countGefaehrdungsbeurteilungen(pagination: $pagination, filter: $filter, archiviert: $archiviert)
}
    `;
export const CreateGefaehrdungsbeurteilungDocument = gql`
    mutation CreateGefaehrdungsbeurteilung($object: GefaehrdungsbeurteilungInputMutation!) {
  createGefaehrdungsbeurteilung(object: $object) {
    id
    createdAt
    updatedAt
    name
    anmerkungen
    archiviert
    letztesReview
    reviewIntervall
    naechstesReview
    status
    unit {
      id
    }
    artId
    art {
      id
      name
    }
    raumId
    raum {
      id
      name
    }
    kategorien {
      id
      name
    }
    gefahrstoffe {
      id
      name
    }
    betriebsmittel {
      id
      name
    }
    dokumente {
      id
      name
      size
    }
  }
}
    `;
export const DeleteGefaehrdungsbeurteilungDocument = gql`
    mutation DeleteGefaehrdungsbeurteilung($id: Int!) {
  deleteGefaehrdungsbeurteilung(id: $id)
}
    `;
export const GefaehrdungsbeurteilungByIdDocument = gql`
    query GefaehrdungsbeurteilungById($id: Int!) {
  findGefaehrdungsbeurteilungById(id: $id) {
    id
    createdAt
    updatedAt
    name
    anmerkungen
    archiviert
    letztesReview
    reviewIntervall
    naechstesReview
    status
    unit {
      id
    }
    artId
    art {
      id
      name
    }
    raumId
    raum {
      id
      name
    }
    freigebender {
      vorname
      nachname
    }
    kategorien {
      id
      name
    }
    gefahrstoffe {
      id
      name
    }
    betriebsmittel {
      id
      name
    }
    dokumente {
      id
      name
      size
      updatedAt
      downloadUrl
      uploadUrl
    }
  }
}
    `;
export const ExportGefaehrdungsbeurteilungenDocument = gql`
    mutation ExportGefaehrdungsbeurteilungen($unitId: String!, $filter: String) {
  exportGefaehrdungsbeurteilungen(unitId: $unitId, filter: $filter)
}
    `;
export const GefaehrdungsbeurteilungenOverviewDocument = gql`
    query GefaehrdungsbeurteilungenOverview($pagination: Pagination, $filter: String, $archiviert: Boolean) {
  findGefaehrdungsbeurteilungen(pagination: $pagination, filter: $filter, archiviert: $archiviert) {
    id
    name
    anmerkungen
    archiviert
    letztesReview
    reviewIntervall
    naechstesReview
    status
    unit {
      id
      name
    }
    artId
    art {
      id
      name
    }
    dokumente {
      id
      name
      size
    }
  }
  countGefaehrdungsbeurteilungen(filter: $filter, archiviert: $archiviert)
}
    `;
export const UpdateGefaehrdungsbeurteilungDocument = gql`
    mutation UpdateGefaehrdungsbeurteilung($id: Int!, $object: GefaehrdungsbeurteilungPatchMutation!) {
  updateGefaehrdungsbeurteilung(id: $id, object: $object) {
    id
    createdAt
    updatedAt
    name
    anmerkungen
    archiviert
    letztesReview
    reviewIntervall
    naechstesReview
    status
    unit {
      id
    }
    artId
    art {
      id
      name
    }
    raumId
    raum {
      id
      name
    }
    kategorien {
      id
      name
    }
    gefahrstoffe {
      id
      name
    }
    betriebsmittel {
      id
      name
    }
    dokumente {
      id
      name
      size
      updatedAt
      downloadUrl
      uploadUrl
    }
  }
}
    `;
export const CreateGefaehrdungsbeurteilungArtDocument = gql`
    mutation CreateGefaehrdungsbeurteilungArt($object: GefaehrdungsbeurteilungArtInputMutation!) {
  createGefaehrdungsbeurteilungArt(object: $object) {
    id
    name
    unit {
      id
    }
  }
}
    `;
export const DeleteGefaehrdungsbeurteilungArtDocument = gql`
    mutation DeleteGefaehrdungsbeurteilungArt($id: Int!) {
  deleteGefaehrdungsbeurteilungArt(id: $id)
}
    `;
export const GefaehrdungsbeurteilungArtByIdDocument = gql`
    query GefaehrdungsbeurteilungArtById($id: Int!) {
  findGefaehrdungsbeurteilungArtById(id: $id) {
    id
    name
    unit {
      id
    }
  }
}
    `;
export const GefaehrdungsbeurteilungArtOverviewDocument = gql`
    query GefaehrdungsbeurteilungArtOverview($pagination: Pagination, $filter: String, $archiviert: Boolean) {
  findGefaehrdungsbeurteilungArten(pagination: $pagination, filter: $filter, archiviert: $archiviert) {
    id
    name
    unit {
      id
    }
  }
}
    `;
export const UpdateGefaehrdungsbeurteilungArtDocument = gql`
    mutation UpdateGefaehrdungsbeurteilungArt($id: Int!, $object: GefaehrdungsbeurteilungArtPatchMutation!) {
  updateGefaehrdungsbeurteilungArt(id: $id, object: $object) {
    id
    name
    unit {
      id
    }
  }
}
    `;
export const CountGefahrstoffeDocument = gql`
    query CountGefahrstoffe($pagination: Pagination, $filter: String, $archiviert: Boolean) {
  countGefahrstoffe(pagination: $pagination, filter: $filter, archiviert: $archiviert)
}
    `;
export const CreateGefahrstoffDocument = gql`
    mutation CreateGefahrstoff($object: GefahrstoffInputMutation!) {
  createGefahrstoff(object: $object) {
    id
    createdAt
    updatedAt
    name
    zweck
    menge
    mengeneinheit
    brandgefahr
    einatemgefahr
    hautkontaktgefahr
    explosionsgefahr
    cmr
    substitutionMoeglich
    anmerkungen
    archiviert
    letzteSubstitutionspruefung
    substitutionspruefungIntervall
    naechsteSubstitutionspruefung
    raumId
    raum {
      id
      name
    }
    zusammenlagerung
    lagerklasse
    status
    signalwort
    unit {
      id
    }
    freigebender {
      vorname
      nachname
    }
    kategorien {
      id
      name
    }
    sicherheitsdatenblattId
    sicherheitsdatenblatt {
      id
      name
    }
    betriebsanweisungen {
      id
      name
    }
    gefaehrdungsbeurteilungen {
      id
      name
    }
    hSaetze {
      id
      satz
      code
    }
    pSaetze {
      id
      satz
      code
    }
    separatlagerungErforderlich {
      id
      name
      lagerklasse
    }
    zusammenlagerungEingeschraenkt {
      id
      name
      lagerklasse
    }
  }
}
    `;
export const DeleteGefahrstoffDocument = gql`
    mutation DeleteGefahrstoff($id: Int!) {
  deleteGefahrstoff(id: $id)
}
    `;
export const GefahrstoffByIdDocument = gql`
    query GefahrstoffById($id: Int!) {
  findGefahrstoffById(id: $id) {
    id
    createdAt
    updatedAt
    name
    zweck
    menge
    mengeneinheit
    brandgefahr
    einatemgefahr
    hautkontaktgefahr
    explosionsgefahr
    cmr
    substitutionMoeglich
    anmerkungen
    archiviert
    letzteSubstitutionspruefung
    substitutionspruefungIntervall
    naechsteSubstitutionspruefung
    raumId
    raum {
      id
      name
    }
    zusammenlagerung
    lagerklasse
    status
    signalwort
    unit {
      id
    }
    freigebender {
      vorname
      nachname
    }
    kategorien {
      id
      name
    }
    sicherheitsdatenblattId
    sicherheitsdatenblatt {
      id
      name
    }
    betriebsanweisungen {
      id
      name
    }
    gefaehrdungsbeurteilungen {
      id
      name
    }
    hSaetze {
      id
      satz
      code
    }
    pSaetze {
      id
      satz
      code
    }
    separatlagerungErforderlich {
      id
      name
      lagerklasse
    }
    zusammenlagerungEingeschraenkt {
      id
      name
      lagerklasse
    }
  }
}
    `;
export const ExportGefahrstoffeDocument = gql`
    mutation ExportGefahrstoffe($unitId: String!, $filter: String) {
  exportGefahrstoffe(unitId: $unitId, filter: $filter)
}
    `;
export const GefahrstoffOverviewDocument = gql`
    query GefahrstoffOverview($pagination: Pagination, $filter: String, $archiviert: Boolean) {
  findGefahrstoffe(pagination: $pagination, filter: $filter, archiviert: $archiviert) {
    id
    name
    anmerkungen
    archiviert
    zusammenlagerung
    lagerklasse
    status
    signalwort
    unit {
      id
      name
    }
    raumId
    raum {
      id
      name
    }
    sicherheitsdatenblattId
    sicherheitsdatenblatt {
      id
      name
    }
    separatlagerungErforderlich {
      id
      name
      lagerklasse
    }
    zusammenlagerungEingeschraenkt {
      id
      name
      lagerklasse
    }
  }
  countGefahrstoffe(filter: $filter, archiviert: $archiviert)
}
    `;
export const UpdateGefahrstoffDocument = gql`
    mutation UpdateGefahrstoff($id: Int!, $object: GefahrstoffPatchMutation!) {
  updateGefahrstoff(id: $id, object: $object) {
    id
    createdAt
    updatedAt
    name
    zweck
    menge
    mengeneinheit
    brandgefahr
    einatemgefahr
    hautkontaktgefahr
    explosionsgefahr
    cmr
    substitutionMoeglich
    anmerkungen
    archiviert
    letzteSubstitutionspruefung
    substitutionspruefungIntervall
    naechsteSubstitutionspruefung
    raumId
    raum {
      id
      name
    }
    zusammenlagerung
    lagerklasse
    status
    unit {
      id
    }
    signalwort
    freigebender {
      vorname
      nachname
    }
    kategorien {
      id
      name
    }
    sicherheitsdatenblattId
    sicherheitsdatenblatt {
      id
      name
    }
    betriebsanweisungen {
      id
      name
    }
    gefaehrdungsbeurteilungen {
      id
      name
    }
    hSaetze {
      id
      satz
      code
    }
    pSaetze {
      id
      satz
      code
    }
    separatlagerungErforderlich {
      id
      name
      lagerklasse
    }
    zusammenlagerungEingeschraenkt {
      id
      name
      lagerklasse
    }
  }
}
    `;
export const HSatzOverviewDocument = gql`
    query HSatzOverview($pagination: Pagination, $filter: String) {
  findHSaetze(pagination: $pagination, filter: $filter) {
    id
    satz
    code
  }
}
    `;
export const CreateKategorieDocument = gql`
    mutation CreateKategorie($object: KategorieInputMutation!) {
  createKategorie(object: $object) {
    id
    createdAt
    updatedAt
    name
    unit {
      id
    }
    gefahrstoffe {
      id
    }
    betriebsmittel {
      id
    }
    gefaehrdungsbeurteilungen {
      id
    }
    betriebsanweisungen {
      id
    }
  }
}
    `;
export const DeleteKategorieDocument = gql`
    mutation DeleteKategorie($id: Int!) {
  deleteKategorie(id: $id)
}
    `;
export const KategorieByIdDocument = gql`
    query KategorieById($id: Int!) {
  findKategorieById(id: $id) {
    id
    createdAt
    updatedAt
    name
    unit {
      id
    }
    gefahrstoffe {
      id
    }
    betriebsmittel {
      id
    }
    gefaehrdungsbeurteilungen {
      id
    }
    betriebsanweisungen {
      id
    }
  }
}
    `;
export const KategorieOverviewDocument = gql`
    query KategorieOverview($pagination: Pagination, $filter: String) {
  findKategorien(pagination: $pagination, filter: $filter) {
    id
    createdAt
    updatedAt
    name
    unit {
      id
    }
    gefahrstoffe {
      id
    }
    betriebsmittel {
      id
    }
    gefaehrdungsbeurteilungen {
      id
    }
    betriebsanweisungen {
      id
    }
  }
}
    `;
export const UpdateKategorieDocument = gql`
    mutation UpdateKategorie($id: Int!, $object: KategoriePatchMutation!) {
  updateKategorie(id: $id, object: $object) {
    id
    createdAt
    updatedAt
    name
    unit {
      id
    }
    gefahrstoffe {
      id
    }
    betriebsmittel {
      id
    }
    gefaehrdungsbeurteilungen {
      id
    }
    betriebsanweisungen {
      id
    }
  }
}
    `;
export const CountProtocolsDocument = gql`
    query CountProtocols($filter: String, $archiviert: Boolean) {
  countProtocols(filter: $filter, archiviert: $archiviert)
}
    `;
export const ProtocolOverviewDocument = gql`
    query ProtocolOverview($pagination: Pagination, $filter: String, $archiviert: Boolean) {
  findProtocols(pagination: $pagination, filter: $filter, archiviert: $archiviert) {
    id
    archiviert
    downloadUrl
    uploadUrl
    name
    size
    notes
    unit {
      id
      name
    }
    tags {
      id
      name
    }
    updatedAt
    gefaehrdungsbeurteilungen {
      id
    }
    betriebsanweisungen {
      id
    }
    bedienungsanleitungen {
      id
    }
    pruefberichte {
      id
    }
    sicherheitsdatenblaetter {
      id
    }
  }
  countProtocols(filter: $filter, archiviert: $archiviert)
}
    `;
export const CountPruefberichteDocument = gql`
    query CountPruefberichte($pagination: Pagination, $filter: String, $archiviert: Boolean) {
  countPruefberichte(pagination: $pagination, filter: $filter, archiviert: $archiviert)
}
    `;
export const CreatePruefberichtDocument = gql`
    mutation CreatePruefbericht($object: PruefberichtInputMutation!) {
  createPruefbericht(object: $object) {
    id
    createdAt
    updatedAt
    name
    unit {
      id
    }
    betriebsmittel {
      id
      name
    }
    dokumente {
      id
      name
      size
    }
  }
}
    `;
export const DeletePruefberichtDocument = gql`
    mutation DeletePruefbericht($id: Int!) {
  deletePruefbericht(id: $id)
}
    `;
export const PruefberichtDetailDocument = gql`
    query PruefberichtDetail($id: Int!) {
  findPruefberichtById(id: $id) {
    id
    createdAt
    updatedAt
    name
    unit {
      id
    }
    betriebsmittel {
      id
      name
    }
    dokumente {
      id
      name
      size
      updatedAt
      downloadUrl
      uploadUrl
    }
  }
}
    `;
export const PruefberichtOverviewDocument = gql`
    query PruefberichtOverview($pagination: Pagination, $filter: String, $archiviert: Boolean) {
  findPruefberichte(pagination: $pagination, filter: $filter, archiviert: $archiviert) {
    id
    archiviert
    createdAt
    updatedAt
    name
    unit {
      id
      name
    }
    betriebsmittel {
      id
      name
    }
    dokumente {
      id
      name
      size
    }
  }
  countPruefberichte(filter: $filter, archiviert: $archiviert)
}
    `;
export const UpdatePruefberichtDocument = gql`
    mutation UpdatePruefbericht($id: Int!, $object: PruefberichtPatchMutation!) {
  updatePruefbericht(id: $id, object: $object) {
    id
    createdAt
    updatedAt
    name
    unit {
      id
    }
    betriebsmittel {
      id
      name
    }
    dokumente {
      id
      name
      size
      updatedAt
      downloadUrl
      uploadUrl
    }
  }
}
    `;
export const PSatzOverviewDocument = gql`
    query PSatzOverview($pagination: Pagination, $filter: String) {
  findPSaetze(pagination: $pagination, filter: $filter) {
    id
    satz
    code
  }
}
    `;
export const CreateRaumDocument = gql`
    mutation CreateRaum($object: RaumInputMutation!) {
  createRaum(object: $object) {
    id
    createdAt
    updatedAt
    name
    unit {
      id
    }
  }
}
    `;
export const DeleteRaumDocument = gql`
    mutation DeleteRaum($id: Int!) {
  deleteRaum(id: $id)
}
    `;
export const RaumByIdDocument = gql`
    query RaumById($id: Int!) {
  findRaumById(id: $id) {
    id
    createdAt
    updatedAt
    name
    unit {
      id
    }
  }
}
    `;
export const RaumOverviewDocument = gql`
    query RaumOverview($pagination: Pagination, $filter: String) {
  findRaeume(pagination: $pagination, filter: $filter) {
    id
    createdAt
    updatedAt
    name
    unit {
      id
    }
  }
}
    `;
export const UpdateRaumDocument = gql`
    mutation UpdateRaum($id: Int!, $object: RaumPatchMutation!) {
  updateRaum(id: $id, object: $object) {
    id
    createdAt
    updatedAt
    name
    unit {
      id
    }
  }
}
    `;
export const GetReportingDisabledUnitsDocument = gql`
    query GetReportingDisabledUnits {
  getReportingDisabledUnits
}
    `;
export const SetUnitReportingStatesDocument = gql`
    mutation SetUnitReportingStates($states: [ReportingState!]!) {
  setUnitReportingStates(states: $states)
}
    `;
export const CountSicherheitsdatenblaetterDocument = gql`
    query CountSicherheitsdatenblaetter($pagination: Pagination, $filter: String, $archiviert: Boolean) {
  countSicherheitsdatenblaetter(pagination: $pagination, filter: $filter, archiviert: $archiviert)
}
    `;
export const CreateSicherheitsdatenblattDocument = gql`
    mutation CreateSicherheitsdatenblatt($object: SicherheitsdatenblattInputMutation!) {
  createSicherheitsdatenblatt(object: $object) {
    id
    createdAt
    updatedAt
    name
    unit {
      id
    }
    gefahrstoffe {
      id
      name
    }
    dokumente {
      id
      name
      size
    }
  }
}
    `;
export const DeleteSicherheitsdatenblattDocument = gql`
    mutation DeleteSicherheitsdatenblatt($id: Int!) {
  deleteSicherheitsdatenblatt(id: $id)
}
    `;
export const SicherheitsdatenblattByIdDocument = gql`
    query SicherheitsdatenblattById($id: Int!) {
  findSicherheitsdatenblattById(id: $id) {
    id
    createdAt
    updatedAt
    name
    unit {
      id
    }
    gefahrstoffe {
      id
      name
    }
    dokumente {
      id
      name
      size
      updatedAt
      downloadUrl
      uploadUrl
    }
  }
}
    `;
export const SicherheitsdatenblattOverviewDocument = gql`
    query SicherheitsdatenblattOverview($pagination: Pagination, $filter: String, $archiviert: Boolean) {
  findSicherheitsdatenblaetter(pagination: $pagination, filter: $filter, archiviert: $archiviert) {
    id
    archiviert
    createdAt
    updatedAt
    name
    unit {
      id
      name
    }
    gefahrstoffe {
      id
      name
    }
    dokumente {
      id
      name
      size
    }
  }
  countSicherheitsdatenblaetter(filter: $filter, archiviert: $archiviert)
}
    `;
export const UpdateSicherheitsdatenblattDocument = gql`
    mutation UpdateSicherheitsdatenblatt($id: Int!, $object: SicherheitsdatenblattPatchMutation!) {
  updateSicherheitsdatenblatt(id: $id, object: $object) {
    id
    createdAt
    updatedAt
    name
    unit {
      id
    }
    gefahrstoffe {
      id
      name
    }
    dokumente {
      id
      name
      size
      updatedAt
      downloadUrl
      uploadUrl
    }
  }
}
    `;
export const CreateTagDocument = gql`
    mutation CreateTag($object: TagInputMutation!) {
  createTag(object: $object) {
    id
    createdAt
    updatedAt
    name
    unit {
      id
    }
  }
}
    `;
export const DeleteTagDocument = gql`
    mutation DeleteTag($id: Int!) {
  deleteTag(id: $id)
}
    `;
export const TagByIdDocument = gql`
    query TagById($id: Int!) {
  findTagById(id: $id) {
    id
    createdAt
    updatedAt
    name
    unit {
      id
    }
  }
}
    `;
export const TagOverviewDocument = gql`
    query TagOverview($pagination: Pagination, $filter: String) {
  findTags(pagination: $pagination, filter: $filter) {
    id
    createdAt
    updatedAt
    name
    unit {
      id
    }
  }
}
    `;
export const UpdateTagDocument = gql`
    mutation UpdateTag($id: Int!, $object: TagPatchMutation!) {
  updateTag(id: $id, object: $object) {
    id
    createdAt
    updatedAt
    name
    unit {
      id
    }
  }
}
    `;
export const CreateEintragDocument = gql`
    mutation CreateEintrag($body: CreateEintragDTO!) {
  createEintrag(createEintrag: $body) {
    unfallzeitpunkt
    erstehilfezeitpunkt
    verbandbuch {
      id
      name
    }
    revision
    id
    verletzter
    arbeitsbereich
    unfallort
    hergang
    verletzung
    zeugen
    umfang
    massnahmen
    ersthelfer
    createdAt
    updatedAt
    eintragType
  }
}
    `;
export const CreatePublicEintragDocument = gql`
    mutation CreatePublicEintrag($body: CreatePublicEintragDTO!) {
  createPublicEintrag(createEintrag: $body) {
    unfallzeitpunkt
    erstehilfezeitpunkt
    revision
    id
    verletzter
    arbeitsbereich
    unfallort
    hergang
    verletzung
    zeugen
    umfang
    massnahmen
    ersthelfer
    createdAt
    updatedAt
    eintragType
  }
}
    `;
export const EintragDetailDocument = gql`
    query EintragDetail($id: ID!) {
  getEintragById(id: $id) {
    unfallzeitpunkt
    erstehilfezeitpunkt
    verbandbuch {
      id
      name
    }
    revision
    id
    verletzter
    arbeitsbereich
    unfallort
    hergang
    verletzung
    zeugen
    umfang
    massnahmen
    ersthelfer
    createdAt
    updatedAt
    eintragType
  }
}
    `;
export const EintraegeForUnitsDocument = gql`
    query EintraegeForUnits($unitIds: [ID!], $order_criteria: String, $order: Boolean, $filter: EintragFilterArgs) {
  getAllEintraegeForUnits(unitIds: $unitIds, order_criteria: $order_criteria, order: $order, filter: $filter) {
    unfallzeitpunkt
    erstehilfezeitpunkt
    verbandbuch {
      id
      name
      unit {
        name
      }
    }
    revision
    id
    verletzter
    arbeitsbereich
    unfallort
    hergang
    verletzung
    zeugen
    umfang
    massnahmen
    ersthelfer
    createdAt
    updatedAt
    eintragType
  }
}
    `;
export const EintraegeForVerbandbuchDocument = gql`
    query EintraegeForVerbandbuch($id: ID!, $order_criteria: String, $order: Boolean, $filter: EintragFilterArgs) {
  getEintragForVerbandbuch(verbandbuch: $id, order_criteria: $order_criteria, order: $order, filter: $filter) {
    unfallzeitpunkt
    erstehilfezeitpunkt
    verbandbuch {
      id
      name
    }
    revision
    id
    verletzter
    arbeitsbereich
    unfallort
    hergang
    verletzung
    zeugen
    umfang
    massnahmen
    ersthelfer
    createdAt
    updatedAt
    eintragType
  }
}
    `;
export const GetEintragRevisionsDocument = gql`
    query GetEintragRevisions($id: String!) {
  getEintragRevisions(id: $id) {
    unfallzeitpunkt
    erstehilfezeitpunkt
    verbandbuch
    revision
    id
    historyId
    verletzter
    arbeitsbereich
    unfallort
    hergang
    verletzung
    zeugen
    umfang
    massnahmen
    ersthelfer
    updatedAt
    createdAt
    eintragType
  }
}
    `;
export const EintragOverviewDocument = gql`
    query EintragOverview($unitIds: [ID!]) {
  getAllEintraegeForUnits(unitIds: $unitIds) {
    unfallzeitpunkt
    erstehilfezeitpunkt
    verbandbuch {
      id
      name
    }
    revision
    id
    verletzter
    arbeitsbereich
    unfallort
    hergang
    verletzung
    zeugen
    umfang
    massnahmen
    ersthelfer
    createdAt
    updatedAt
    eintragType
  }
  countEintrag
}
    `;
export const UpdateEintragDocument = gql`
    mutation UpdateEintrag($id: String!, $body: UpdateEintragDTO!) {
  updateEintrag(id: $id, updateEintrag: $body) {
    unfallzeitpunkt
    erstehilfezeitpunkt
    verbandbuch {
      id
      name
    }
    revision
    id
    verletzter
    arbeitsbereich
    unfallort
    hergang
    verletzung
    zeugen
    umfang
    massnahmen
    ersthelfer
    createdAt
    updatedAt
    eintragType
  }
}
    `;
export const VerbandbuchCountDocument = gql`
    query VerbandbuchCount {
  countVerbandbuch
}
    `;
export const CreateVerbandbuchDocument = gql`
    mutation CreateVerbandbuch($body: CreateVerbandbuchDTO!) {
  createVerbandbuch(createVerbandbuch: $body) {
    id
    name
    unit {
      id
      name
    }
    settingsDocument
    notificationList
    eintraege {
      id
    }
    qrCode {
      svg
      forIdentifier
    }
    exports
    archiviert
  }
}
    `;
export const DeleteVerbandbuchDocument = gql`
    mutation DeleteVerbandbuch($id: String!) {
  deleteVerbandbuch(id: $id)
}
    `;
export const VerbandbuchDetailDocument = gql`
    query VerbandbuchDetail($id: String!) {
  getVerbandbuchById(id: $id) {
    id
    name
    unit {
      id
      name
    }
    settingsDocument
    notificationList
    eintraege {
      id
    }
    qrCode {
      svg
      forIdentifier
    }
    exports
    archiviert
    identifier
  }
}
    `;
export const GetDiagramInformationDocument = gql`
    query GetDiagramInformation {
  getDiagramInformation {
    total
    date
  }
}
    `;
export const ExportVerbandbuchEintraegeDocument = gql`
    mutation ExportVerbandbuchEintraege($unitId: String!) {
  exportVerbandbuchEintraege(unitId: $unitId)
}
    `;
export const VerbandbuchOverviewDocument = gql`
    query VerbandbuchOverview {
  getVerbandbuecher {
    id
    name
    unit {
      id
      name
    }
    settingsDocument
    notificationList
    eintraege {
      id
    }
    qrCode {
      svg
      forIdentifier
    }
    exports
    archiviert
    identifier
  }
  countVerbandbuch
}
    `;
export const UpdateVerbandbuchDocument = gql`
    mutation UpdateVerbandbuch($id: String!, $body: UpdateVerbandbuchDTO!) {
  updateVerbandbuch(id: $id, updateVerbandbuch: $body) {
    id
    name
    unit {
      id
      name
    }
    settingsDocument
    notificationList
    eintraege {
      id
    }
    qrCode {
      svg
      forIdentifier
    }
    exports
    archiviert
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetUserById(variables: GetUserByIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetUserByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetUserByIdQuery>(GetUserByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetUserById', 'query', variables);
    },
    GetUsersForUnit(variables?: GetUsersForUnitQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetUsersForUnitQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetUsersForUnitQuery>(GetUsersForUnitDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetUsersForUnit', 'query', variables);
    },
    Me(variables?: MeQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MeQuery>(MeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Me', 'query', variables);
    },
    UpdateMe(variables: UpdateMeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateMeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateMeMutation>(UpdateMeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateMe', 'mutation', variables);
    },
    UpdateScopesForUser(variables: UpdateScopesForUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateScopesForUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateScopesForUserMutation>(UpdateScopesForUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateScopesForUser', 'mutation', variables);
    },
    CreateCompany(variables: CreateCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateCompanyMutation>(CreateCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateCompany', 'mutation', variables);
    },
    DeleteCompany(variables: DeleteCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteCompanyMutation>(DeleteCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteCompany', 'mutation', variables);
    },
    GetAsTree(variables?: GetAsTreeQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetAsTreeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAsTreeQuery>(GetAsTreeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetAsTree', 'query', variables);
    },
    UpdateCompany(variables: UpdateCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateCompanyMutation>(UpdateCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateCompany', 'mutation', variables);
    },
    GetPaymentAccounts(variables?: GetPaymentAccountsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetPaymentAccountsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetPaymentAccountsQuery>(GetPaymentAccountsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetPaymentAccounts', 'query', variables);
    },
    updatePaymentAccount(variables: UpdatePaymentAccountMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdatePaymentAccountMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdatePaymentAccountMutation>(UpdatePaymentAccountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updatePaymentAccount', 'mutation', variables);
    },
    AcceptInvite(variables: AcceptInviteMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AcceptInviteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AcceptInviteMutation>(AcceptInviteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AcceptInvite', 'mutation', variables);
    },
    CreateUnit(variables: CreateUnitMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateUnitMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateUnitMutation>(CreateUnitDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateUnit', 'mutation', variables);
    },
    GetUnits(variables?: GetUnitsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetUnitsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetUnitsQuery>(GetUnitsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetUnits', 'query', variables);
    },
    InviteUserForUnit(variables: InviteUserForUnitMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<InviteUserForUnitMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<InviteUserForUnitMutation>(InviteUserForUnitDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'InviteUserForUnit', 'mutation', variables);
    },
    UpdateUnitById(variables: UpdateUnitByIdMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateUnitByIdMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateUnitByIdMutation>(UpdateUnitByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateUnitById', 'mutation', variables);
    },
    CountBedienungsanleitungen(variables?: CountBedienungsanleitungenQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CountBedienungsanleitungenQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CountBedienungsanleitungenQuery>(CountBedienungsanleitungenDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CountBedienungsanleitungen', 'query', variables);
    },
    CreateBedienungsanleitung(variables: CreateBedienungsanleitungMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateBedienungsanleitungMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateBedienungsanleitungMutation>(CreateBedienungsanleitungDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateBedienungsanleitung', 'mutation', variables);
    },
    DeleteBedienungsanleitung(variables: DeleteBedienungsanleitungMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteBedienungsanleitungMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteBedienungsanleitungMutation>(DeleteBedienungsanleitungDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteBedienungsanleitung', 'mutation', variables);
    },
    BedienungsanleitungById(variables: BedienungsanleitungByIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<BedienungsanleitungByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<BedienungsanleitungByIdQuery>(BedienungsanleitungByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'BedienungsanleitungById', 'query', variables);
    },
    BedienungsanleitungOverview(variables?: BedienungsanleitungOverviewQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<BedienungsanleitungOverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<BedienungsanleitungOverviewQuery>(BedienungsanleitungOverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'BedienungsanleitungOverview', 'query', variables);
    },
    UpdateBedienungsanleitung(variables: UpdateBedienungsanleitungMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateBedienungsanleitungMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateBedienungsanleitungMutation>(UpdateBedienungsanleitungDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateBedienungsanleitung', 'mutation', variables);
    },
    CountBetriebsanweisungen(variables?: CountBetriebsanweisungenQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CountBetriebsanweisungenQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CountBetriebsanweisungenQuery>(CountBetriebsanweisungenDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CountBetriebsanweisungen', 'query', variables);
    },
    CreateBetriebsanweisung(variables: CreateBetriebsanweisungMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateBetriebsanweisungMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateBetriebsanweisungMutation>(CreateBetriebsanweisungDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateBetriebsanweisung', 'mutation', variables);
    },
    DeleteBetriebsanweisung(variables: DeleteBetriebsanweisungMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteBetriebsanweisungMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteBetriebsanweisungMutation>(DeleteBetriebsanweisungDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteBetriebsanweisung', 'mutation', variables);
    },
    BetriebsanweisungById(variables: BetriebsanweisungByIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<BetriebsanweisungByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<BetriebsanweisungByIdQuery>(BetriebsanweisungByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'BetriebsanweisungById', 'query', variables);
    },
    ExportBetriebsanweisungen(variables: ExportBetriebsanweisungenMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ExportBetriebsanweisungenMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ExportBetriebsanweisungenMutation>(ExportBetriebsanweisungenDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ExportBetriebsanweisungen', 'mutation', variables);
    },
    BetriebsanweisungOverview(variables?: BetriebsanweisungOverviewQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<BetriebsanweisungOverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<BetriebsanweisungOverviewQuery>(BetriebsanweisungOverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'BetriebsanweisungOverview', 'query', variables);
    },
    UpdateBetriebsanweisung(variables: UpdateBetriebsanweisungMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateBetriebsanweisungMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateBetriebsanweisungMutation>(UpdateBetriebsanweisungDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateBetriebsanweisung', 'mutation', variables);
    },
    CreateBetriebsanweisungArt(variables: CreateBetriebsanweisungArtMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateBetriebsanweisungArtMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateBetriebsanweisungArtMutation>(CreateBetriebsanweisungArtDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateBetriebsanweisungArt', 'mutation', variables);
    },
    DeleteBetriebsanweisungArt(variables: DeleteBetriebsanweisungArtMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteBetriebsanweisungArtMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteBetriebsanweisungArtMutation>(DeleteBetriebsanweisungArtDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteBetriebsanweisungArt', 'mutation', variables);
    },
    BetriebsanweisungArtById(variables: BetriebsanweisungArtByIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<BetriebsanweisungArtByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<BetriebsanweisungArtByIdQuery>(BetriebsanweisungArtByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'BetriebsanweisungArtById', 'query', variables);
    },
    BetriebsanweisungArtOverview(variables?: BetriebsanweisungArtOverviewQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<BetriebsanweisungArtOverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<BetriebsanweisungArtOverviewQuery>(BetriebsanweisungArtOverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'BetriebsanweisungArtOverview', 'query', variables);
    },
    UpdateBetriebsanweisungArt(variables: UpdateBetriebsanweisungArtMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateBetriebsanweisungArtMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateBetriebsanweisungArtMutation>(UpdateBetriebsanweisungArtDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateBetriebsanweisungArt', 'mutation', variables);
    },
    CountBetriebsmittel(variables?: CountBetriebsmittelQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CountBetriebsmittelQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CountBetriebsmittelQuery>(CountBetriebsmittelDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CountBetriebsmittel', 'query', variables);
    },
    CreateBetriebsmittel(variables: CreateBetriebsmittelMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateBetriebsmittelMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateBetriebsmittelMutation>(CreateBetriebsmittelDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateBetriebsmittel', 'mutation', variables);
    },
    DeleteBetriebsmittel(variables: DeleteBetriebsmittelMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteBetriebsmittelMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteBetriebsmittelMutation>(DeleteBetriebsmittelDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteBetriebsmittel', 'mutation', variables);
    },
    BetriebsmittelById(variables: BetriebsmittelByIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<BetriebsmittelByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<BetriebsmittelByIdQuery>(BetriebsmittelByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'BetriebsmittelById', 'query', variables);
    },
    ExportBetriebsmittel(variables: ExportBetriebsmittelMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ExportBetriebsmittelMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ExportBetriebsmittelMutation>(ExportBetriebsmittelDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ExportBetriebsmittel', 'mutation', variables);
    },
    BetriebsmittelOverview(variables?: BetriebsmittelOverviewQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<BetriebsmittelOverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<BetriebsmittelOverviewQuery>(BetriebsmittelOverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'BetriebsmittelOverview', 'query', variables);
    },
    UpdateBetriebsmittel(variables: UpdateBetriebsmittelMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateBetriebsmittelMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateBetriebsmittelMutation>(UpdateBetriebsmittelDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateBetriebsmittel', 'mutation', variables);
    },
    Chart(variables?: ChartQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ChartQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ChartQuery>(ChartDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Chart', 'query', variables);
    },
    CountDocuments(variables?: CountDocumentsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CountDocumentsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CountDocumentsQuery>(CountDocumentsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CountDocuments', 'query', variables);
    },
    CreateDocument(variables: CreateDocumentMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateDocumentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateDocumentMutation>(CreateDocumentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateDocument', 'mutation', variables);
    },
    DeleteDocument(variables: DeleteDocumentMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteDocumentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteDocumentMutation>(DeleteDocumentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteDocument', 'mutation', variables);
    },
    DocumentById(variables: DocumentByIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DocumentByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DocumentByIdQuery>(DocumentByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DocumentById', 'query', variables);
    },
    DocumentOverview(variables?: DocumentOverviewQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DocumentOverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DocumentOverviewQuery>(DocumentOverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DocumentOverview', 'query', variables);
    },
    UpdateDocument(variables: UpdateDocumentMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateDocumentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateDocumentMutation>(UpdateDocumentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateDocument', 'mutation', variables);
    },
    CountGefaehrdungsbeurteilungen(variables?: CountGefaehrdungsbeurteilungenQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CountGefaehrdungsbeurteilungenQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CountGefaehrdungsbeurteilungenQuery>(CountGefaehrdungsbeurteilungenDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CountGefaehrdungsbeurteilungen', 'query', variables);
    },
    CreateGefaehrdungsbeurteilung(variables: CreateGefaehrdungsbeurteilungMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateGefaehrdungsbeurteilungMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateGefaehrdungsbeurteilungMutation>(CreateGefaehrdungsbeurteilungDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateGefaehrdungsbeurteilung', 'mutation', variables);
    },
    DeleteGefaehrdungsbeurteilung(variables: DeleteGefaehrdungsbeurteilungMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteGefaehrdungsbeurteilungMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteGefaehrdungsbeurteilungMutation>(DeleteGefaehrdungsbeurteilungDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteGefaehrdungsbeurteilung', 'mutation', variables);
    },
    GefaehrdungsbeurteilungById(variables: GefaehrdungsbeurteilungByIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GefaehrdungsbeurteilungByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GefaehrdungsbeurteilungByIdQuery>(GefaehrdungsbeurteilungByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GefaehrdungsbeurteilungById', 'query', variables);
    },
    ExportGefaehrdungsbeurteilungen(variables: ExportGefaehrdungsbeurteilungenMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ExportGefaehrdungsbeurteilungenMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ExportGefaehrdungsbeurteilungenMutation>(ExportGefaehrdungsbeurteilungenDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ExportGefaehrdungsbeurteilungen', 'mutation', variables);
    },
    GefaehrdungsbeurteilungenOverview(variables?: GefaehrdungsbeurteilungenOverviewQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GefaehrdungsbeurteilungenOverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GefaehrdungsbeurteilungenOverviewQuery>(GefaehrdungsbeurteilungenOverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GefaehrdungsbeurteilungenOverview', 'query', variables);
    },
    UpdateGefaehrdungsbeurteilung(variables: UpdateGefaehrdungsbeurteilungMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateGefaehrdungsbeurteilungMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateGefaehrdungsbeurteilungMutation>(UpdateGefaehrdungsbeurteilungDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateGefaehrdungsbeurteilung', 'mutation', variables);
    },
    CreateGefaehrdungsbeurteilungArt(variables: CreateGefaehrdungsbeurteilungArtMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateGefaehrdungsbeurteilungArtMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateGefaehrdungsbeurteilungArtMutation>(CreateGefaehrdungsbeurteilungArtDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateGefaehrdungsbeurteilungArt', 'mutation', variables);
    },
    DeleteGefaehrdungsbeurteilungArt(variables: DeleteGefaehrdungsbeurteilungArtMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteGefaehrdungsbeurteilungArtMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteGefaehrdungsbeurteilungArtMutation>(DeleteGefaehrdungsbeurteilungArtDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteGefaehrdungsbeurteilungArt', 'mutation', variables);
    },
    GefaehrdungsbeurteilungArtById(variables: GefaehrdungsbeurteilungArtByIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GefaehrdungsbeurteilungArtByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GefaehrdungsbeurteilungArtByIdQuery>(GefaehrdungsbeurteilungArtByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GefaehrdungsbeurteilungArtById', 'query', variables);
    },
    GefaehrdungsbeurteilungArtOverview(variables?: GefaehrdungsbeurteilungArtOverviewQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GefaehrdungsbeurteilungArtOverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GefaehrdungsbeurteilungArtOverviewQuery>(GefaehrdungsbeurteilungArtOverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GefaehrdungsbeurteilungArtOverview', 'query', variables);
    },
    UpdateGefaehrdungsbeurteilungArt(variables: UpdateGefaehrdungsbeurteilungArtMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateGefaehrdungsbeurteilungArtMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateGefaehrdungsbeurteilungArtMutation>(UpdateGefaehrdungsbeurteilungArtDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateGefaehrdungsbeurteilungArt', 'mutation', variables);
    },
    CountGefahrstoffe(variables?: CountGefahrstoffeQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CountGefahrstoffeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CountGefahrstoffeQuery>(CountGefahrstoffeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CountGefahrstoffe', 'query', variables);
    },
    CreateGefahrstoff(variables: CreateGefahrstoffMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateGefahrstoffMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateGefahrstoffMutation>(CreateGefahrstoffDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateGefahrstoff', 'mutation', variables);
    },
    DeleteGefahrstoff(variables: DeleteGefahrstoffMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteGefahrstoffMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteGefahrstoffMutation>(DeleteGefahrstoffDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteGefahrstoff', 'mutation', variables);
    },
    GefahrstoffById(variables: GefahrstoffByIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GefahrstoffByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GefahrstoffByIdQuery>(GefahrstoffByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GefahrstoffById', 'query', variables);
    },
    ExportGefahrstoffe(variables: ExportGefahrstoffeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ExportGefahrstoffeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ExportGefahrstoffeMutation>(ExportGefahrstoffeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ExportGefahrstoffe', 'mutation', variables);
    },
    GefahrstoffOverview(variables?: GefahrstoffOverviewQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GefahrstoffOverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GefahrstoffOverviewQuery>(GefahrstoffOverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GefahrstoffOverview', 'query', variables);
    },
    UpdateGefahrstoff(variables: UpdateGefahrstoffMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateGefahrstoffMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateGefahrstoffMutation>(UpdateGefahrstoffDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateGefahrstoff', 'mutation', variables);
    },
    HSatzOverview(variables?: HSatzOverviewQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<HSatzOverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<HSatzOverviewQuery>(HSatzOverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'HSatzOverview', 'query', variables);
    },
    CreateKategorie(variables: CreateKategorieMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateKategorieMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateKategorieMutation>(CreateKategorieDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateKategorie', 'mutation', variables);
    },
    DeleteKategorie(variables: DeleteKategorieMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteKategorieMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteKategorieMutation>(DeleteKategorieDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteKategorie', 'mutation', variables);
    },
    KategorieById(variables: KategorieByIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<KategorieByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<KategorieByIdQuery>(KategorieByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'KategorieById', 'query', variables);
    },
    KategorieOverview(variables?: KategorieOverviewQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<KategorieOverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<KategorieOverviewQuery>(KategorieOverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'KategorieOverview', 'query', variables);
    },
    UpdateKategorie(variables: UpdateKategorieMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateKategorieMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateKategorieMutation>(UpdateKategorieDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateKategorie', 'mutation', variables);
    },
    CountProtocols(variables?: CountProtocolsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CountProtocolsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CountProtocolsQuery>(CountProtocolsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CountProtocols', 'query', variables);
    },
    ProtocolOverview(variables?: ProtocolOverviewQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ProtocolOverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProtocolOverviewQuery>(ProtocolOverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ProtocolOverview', 'query', variables);
    },
    CountPruefberichte(variables?: CountPruefberichteQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CountPruefberichteQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CountPruefberichteQuery>(CountPruefberichteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CountPruefberichte', 'query', variables);
    },
    CreatePruefbericht(variables: CreatePruefberichtMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreatePruefberichtMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreatePruefberichtMutation>(CreatePruefberichtDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreatePruefbericht', 'mutation', variables);
    },
    DeletePruefbericht(variables: DeletePruefberichtMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeletePruefberichtMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeletePruefberichtMutation>(DeletePruefberichtDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeletePruefbericht', 'mutation', variables);
    },
    PruefberichtDetail(variables: PruefberichtDetailQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PruefberichtDetailQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PruefberichtDetailQuery>(PruefberichtDetailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PruefberichtDetail', 'query', variables);
    },
    PruefberichtOverview(variables?: PruefberichtOverviewQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PruefberichtOverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PruefberichtOverviewQuery>(PruefberichtOverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PruefberichtOverview', 'query', variables);
    },
    UpdatePruefbericht(variables: UpdatePruefberichtMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdatePruefberichtMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdatePruefberichtMutation>(UpdatePruefberichtDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdatePruefbericht', 'mutation', variables);
    },
    PSatzOverview(variables?: PSatzOverviewQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PSatzOverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PSatzOverviewQuery>(PSatzOverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PSatzOverview', 'query', variables);
    },
    CreateRaum(variables: CreateRaumMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateRaumMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateRaumMutation>(CreateRaumDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateRaum', 'mutation', variables);
    },
    DeleteRaum(variables: DeleteRaumMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteRaumMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteRaumMutation>(DeleteRaumDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteRaum', 'mutation', variables);
    },
    RaumById(variables: RaumByIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RaumByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<RaumByIdQuery>(RaumByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RaumById', 'query', variables);
    },
    RaumOverview(variables?: RaumOverviewQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RaumOverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<RaumOverviewQuery>(RaumOverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RaumOverview', 'query', variables);
    },
    UpdateRaum(variables: UpdateRaumMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateRaumMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateRaumMutation>(UpdateRaumDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateRaum', 'mutation', variables);
    },
    GetReportingDisabledUnits(variables?: GetReportingDisabledUnitsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetReportingDisabledUnitsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetReportingDisabledUnitsQuery>(GetReportingDisabledUnitsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetReportingDisabledUnits', 'query', variables);
    },
    SetUnitReportingStates(variables: SetUnitReportingStatesMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<SetUnitReportingStatesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SetUnitReportingStatesMutation>(SetUnitReportingStatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SetUnitReportingStates', 'mutation', variables);
    },
    CountSicherheitsdatenblaetter(variables?: CountSicherheitsdatenblaetterQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CountSicherheitsdatenblaetterQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CountSicherheitsdatenblaetterQuery>(CountSicherheitsdatenblaetterDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CountSicherheitsdatenblaetter', 'query', variables);
    },
    CreateSicherheitsdatenblatt(variables: CreateSicherheitsdatenblattMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateSicherheitsdatenblattMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateSicherheitsdatenblattMutation>(CreateSicherheitsdatenblattDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateSicherheitsdatenblatt', 'mutation', variables);
    },
    DeleteSicherheitsdatenblatt(variables: DeleteSicherheitsdatenblattMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteSicherheitsdatenblattMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteSicherheitsdatenblattMutation>(DeleteSicherheitsdatenblattDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteSicherheitsdatenblatt', 'mutation', variables);
    },
    SicherheitsdatenblattById(variables: SicherheitsdatenblattByIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<SicherheitsdatenblattByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SicherheitsdatenblattByIdQuery>(SicherheitsdatenblattByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SicherheitsdatenblattById', 'query', variables);
    },
    SicherheitsdatenblattOverview(variables?: SicherheitsdatenblattOverviewQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<SicherheitsdatenblattOverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SicherheitsdatenblattOverviewQuery>(SicherheitsdatenblattOverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SicherheitsdatenblattOverview', 'query', variables);
    },
    UpdateSicherheitsdatenblatt(variables: UpdateSicherheitsdatenblattMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateSicherheitsdatenblattMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateSicherheitsdatenblattMutation>(UpdateSicherheitsdatenblattDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateSicherheitsdatenblatt', 'mutation', variables);
    },
    CreateTag(variables: CreateTagMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateTagMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateTagMutation>(CreateTagDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateTag', 'mutation', variables);
    },
    DeleteTag(variables: DeleteTagMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteTagMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteTagMutation>(DeleteTagDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteTag', 'mutation', variables);
    },
    TagById(variables: TagByIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<TagByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<TagByIdQuery>(TagByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TagById', 'query', variables);
    },
    TagOverview(variables?: TagOverviewQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<TagOverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<TagOverviewQuery>(TagOverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TagOverview', 'query', variables);
    },
    UpdateTag(variables: UpdateTagMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateTagMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateTagMutation>(UpdateTagDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateTag', 'mutation', variables);
    },
    CreateEintrag(variables: CreateEintragMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateEintragMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateEintragMutation>(CreateEintragDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateEintrag', 'mutation', variables);
    },
    CreatePublicEintrag(variables: CreatePublicEintragMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreatePublicEintragMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreatePublicEintragMutation>(CreatePublicEintragDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreatePublicEintrag', 'mutation', variables);
    },
    EintragDetail(variables: EintragDetailQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<EintragDetailQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<EintragDetailQuery>(EintragDetailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'EintragDetail', 'query', variables);
    },
    EintraegeForUnits(variables?: EintraegeForUnitsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<EintraegeForUnitsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<EintraegeForUnitsQuery>(EintraegeForUnitsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'EintraegeForUnits', 'query', variables);
    },
    EintraegeForVerbandbuch(variables: EintraegeForVerbandbuchQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<EintraegeForVerbandbuchQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<EintraegeForVerbandbuchQuery>(EintraegeForVerbandbuchDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'EintraegeForVerbandbuch', 'query', variables);
    },
    GetEintragRevisions(variables: GetEintragRevisionsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetEintragRevisionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetEintragRevisionsQuery>(GetEintragRevisionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetEintragRevisions', 'query', variables);
    },
    EintragOverview(variables?: EintragOverviewQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<EintragOverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<EintragOverviewQuery>(EintragOverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'EintragOverview', 'query', variables);
    },
    UpdateEintrag(variables: UpdateEintragMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateEintragMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateEintragMutation>(UpdateEintragDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateEintrag', 'mutation', variables);
    },
    VerbandbuchCount(variables?: VerbandbuchCountQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<VerbandbuchCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VerbandbuchCountQuery>(VerbandbuchCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'VerbandbuchCount', 'query', variables);
    },
    CreateVerbandbuch(variables: CreateVerbandbuchMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateVerbandbuchMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateVerbandbuchMutation>(CreateVerbandbuchDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateVerbandbuch', 'mutation', variables);
    },
    DeleteVerbandbuch(variables: DeleteVerbandbuchMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteVerbandbuchMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteVerbandbuchMutation>(DeleteVerbandbuchDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteVerbandbuch', 'mutation', variables);
    },
    VerbandbuchDetail(variables: VerbandbuchDetailQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<VerbandbuchDetailQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VerbandbuchDetailQuery>(VerbandbuchDetailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'VerbandbuchDetail', 'query', variables);
    },
    GetDiagramInformation(variables?: GetDiagramInformationQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetDiagramInformationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetDiagramInformationQuery>(GetDiagramInformationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetDiagramInformation', 'query', variables);
    },
    ExportVerbandbuchEintraege(variables: ExportVerbandbuchEintraegeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ExportVerbandbuchEintraegeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ExportVerbandbuchEintraegeMutation>(ExportVerbandbuchEintraegeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ExportVerbandbuchEintraege', 'mutation', variables);
    },
    VerbandbuchOverview(variables?: VerbandbuchOverviewQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<VerbandbuchOverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VerbandbuchOverviewQuery>(VerbandbuchOverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'VerbandbuchOverview', 'query', variables);
    },
    UpdateVerbandbuch(variables: UpdateVerbandbuchMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateVerbandbuchMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateVerbandbuchMutation>(UpdateVerbandbuchDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateVerbandbuch', 'mutation', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;