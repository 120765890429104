import { IColumnDefinition } from "./types.data";
import PruefberichtTabWrapper from "../../components/Pruefberichte/tabs/TabWrapper.vue";
import PruefberichtCellName from "../../components/Pruefberichte/cells/pruefbericht-cell-name.vue";
import PruefberichtCellDokumente from "../../components/Pruefberichte/cells/pruefbericht-cell-dokumente.vue";
import PruefberichtCellStandort from "../../components/Pruefberichte/cells/pruefbericht-cell-standort.vue";
import { usePruefbericht } from "../../services/pruefbericht.service";

export enum pruefberichteModel {
  SINGULAR = "Prüfbericht",
  PLURAL = "Prüfberichte",
  ROUTE = "/pruefberichte",
  ICON = "mdi-file-find-outline",
  ITEM_KEY = "pruefbericht",
  ITEM_KEY_UPDATE = "pruefberichte",
}

export const defaultPruefberichtHeader: string[] = ["bezeichnung", "dokumente"];

export const pruefberichtHeader: IColumnDefinition<any>[] = [
  {
    name: "bezeichnung",
    label: "Bezeichnung",
    field: "name",
    required: false,
    align: "left",
    sortable: true,
    cell: PruefberichtCellName,
  },
  {
    name: "dokumente",
    label: "Dokumente",
    field: "dokumente",
    required: false,
    align: "left",
    sortable: false,
    cell: PruefberichtCellDokumente,
  },
  {
    name: "standort",
    label: "Standort",
    required: false,
    align: "left",
    sortable: false,
    cell: PruefberichtCellStandort,
  },
];

export const pruefberichtData = {
  model: pruefberichteModel,
  api: usePruefbericht(),
  header: pruefberichtHeader,
  defaultHeader: defaultPruefberichtHeader,
  tabWrapper: PruefberichtTabWrapper,
};
