<template>
  <div>
    <q-chip
      v-if="kategorien.length === 0"
      outline
      color="grey-7"
      icon="mdi-plus"
      dense
      clickable
      @click.stop="openKategorieManager"
    >
      {{ $t("input.hinzufuegen") }}
    </q-chip>
    <q-chip
      v-if="kategorieValue"
      outline
      color="grey-7"
      icon="mdi-bookmark"
      dense
      clickable
      @click.stop="openKategorieManager"
    >
      {{ kategorieValue }}
      <q-tooltip>{{ kategorieValue }}</q-tooltip>
    </q-chip>
    <q-chip
      v-if="moreValue"
      outline
      color="grey-7"
      icon="mdi-bookmark"
      dense
      clickable
      @click.stop="openKategorieManager"
    >
      {{ moreValue }}
      <q-tooltip>{{ tooltipValue }}</q-tooltip>
    </q-chip>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import RelationManagerDialog from "../../components/RelationManager/RelationManagerDialog.vue";
import { Dialog, Notify } from "quasar";
import { useKategorie } from "../../services/kategorie.service";
import { kategorienModel } from "../../data";

export default defineComponent({
  props: {
    props: {
      type: Object,
      required: true,
    },
    api: {
      type: Object,
      required: true,
    },
    hasWritePermission: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const kategorien = computed(() => props.props.row.kategorien || []);

    const kategorieValue = computed(() =>
      kategorien.value.length ? kategorien.value[0].name : null
    );

    const moreValue = computed(() => {
      if (kategorien.value.length === 2) {
        return kategorien.value[1].name;
      } else if (kategorien.value.length > 2) {
        return `${kategorien.value.length - 1} weitere`;
      }
      return null;
    });

    const tooltipValue = computed(() =>
      kategorien.value.map((k: any) => k.name).join(", ")
    );

    const openKategorieManager = () => {
      Dialog.create({
        component: RelationManagerDialog,
        componentProps: {
          api: useKategorie(),
          model: kategorienModel,
          preSelected: kategorien.value,
          unitId: props.props.row.unit?.id,
          hasWritePermission: props.hasWritePermission,
          save: true,
        },
      }).onOk(async (kategorien: any[]) => {
        const { result: item } = await props.api.update({
          id: props.props.row.id,
          object: {
            relations: {
              kategorien: kategorien.map((k) => k.id),
            },
          },
        });

        emit("updateData", item);

        Notify.create({
          type: "ams-success",
          message: "Kategorien aktualisiert!",
        });
      });
    };

    return {
      kategorien,
      kategorieValue,
      moreValue,
      tooltipValue,
      openKategorieManager,
    };
  },
});
</script>
