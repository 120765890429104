<template>
  <q-input
    :label="label"
    :model-value="value"
    dense
    outlined
    color="primary"
    :label-color="labelColor"
    :type="type"
    :min="0"
    :autogrow="type === 'textarea'"
    :readonly="readonly"
    :input-style="inputStyle"
    @update:model-value="input"
  >
    <template #before>
      <q-icon :name="icon" />
    </template>
  </q-input>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
    },
    icon: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    inputStyle: {
      type: String,
      default: "",
    },
    labelColor: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const input = (event: string) => {
      if (props.type === "number" && parseInt(event) < 0) {
        emit("input", "0");
      } else {
        emit("input", event);
      }
    };

    return {
      input,
    };
  },
});
</script>
