<template>
  <q-td :props="props" :key="colKey">{{ pSatzValues }}</q-td>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { trim } from "../../../utils";

export default defineComponent({
  props: {
    props: {
      type: Object,
      required: true,
    },
    colKey: {
      type: String,
      required: true,
    },
  },
  setup({ props }) {
    const pSatzValues = computed(() =>
      props.row.psaetze && props.row.psaetze.length
        ? trim(props.row.psaetze.map((p: any) => p.satz).join(", "), 40)
        : "-"
    );

    return {
      pSatzValues,
    };
  },
});
</script>
