<template>
  <div class="q-mt-xl q-mx-lg">
    <div class="row items-center">
      <div class="q-mr-xl ams-verwaltung__title">{{ $t('verwaltung.name') }}</div>
      <q-input
        outlined
        autocomplete="off"
        class="full-width"
        style="max-width: 300px"
        :placeholder="$t('input.suche')"
        v-model="search"
        color="primary"
        dense
        clearable
        type="search"
      >
        <template #prepend>
          <q-icon name="mdi-magnify" />
        </template>
      </q-input>
    </div>
  </div>

  <div class="flex q-ma-lg" style="flex-wrap: nowrap">
    <div style="max-width: 350px">
      <q-list bordered separator class="bg-white">
        <q-item v-if="!filteredSettings.length" v-ripple>
          <q-item-section class="text-center text-bold">
            - Keine Ergebnisse -
          </q-item-section>
        </q-item>
        <q-item
          v-for="einstellung in filteredSettings"
          :key="einstellung.label"
          clickable
          v-ripple
          :active="active(einstellung.route)"
          @click="route(einstellung.route)"
        >
          <q-item-section avatar>
            <q-icon :name="einstellung.icon" />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ $t(`verwaltung.${einstellung.route}.name`) }}</q-item-label>
            <q-item-label caption>{{ $t(`verwaltung.${einstellung.route}.description`) }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </div>

    <Suspense>
      <router-view
        class="q-ml-lg bg-white q-pa-md"
        style="flex-grow: 1; border: 1px solid rgba(0, 0, 0, 0.12)"
      />
      <template #fallback>
        <q-spinner class="q-ma-xl" size="32px" />
      </template>
    </Suspense>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { verwaltungModel, settings } from "../data";
import { searchKey } from "../utils";
import { useRouter } from "vue-router";

const router = useRouter();
const search = ref("");
const currentRoute = router.currentRoute.value.fullPath.substring(12);

const filteredSettings = computed(() =>
  searchKey(search.value, "label", settings(currentRoute))
);

const active = (path: string) =>
  router.currentRoute.value.path === `${verwaltungModel.ROUTE}/${path}`;

const route = (path: string) => {
  if (active(path)) {
    router.replace(`${verwaltungModel.ROUTE}`);
  } else {
    router.replace(`${verwaltungModel.ROUTE}/${path}`);
  }
};
</script>

<style scoped>
.ams-verwaltung__title {
  font-size: 1.6rem;
  font-weight: 420;
  line-height: 1.25rem;
}
</style>
