import {
  CountSicherheitsdatenblaetterQueryVariables,
  SicherheitsdatenblattOverviewQueryVariables,
  CreateSicherheitsdatenblattMutationVariables,
  DeleteSicherheitsdatenblattMutationVariables,
  UpdateSicherheitsdatenblattMutationVariables,
} from "../types/graphql.types";
import client from "./index";
import { hashids } from "./hashids.service";

export const useSicherheitsdatenblatt = () => {
  return {
    fetchAll: async (
      variables: SicherheitsdatenblattOverviewQueryVariables
    ) => {
      const { findSicherheitsdatenblaetter, countSicherheitsdatenblaetter } =
        await client.SicherheitsdatenblattOverview(variables);

      return {
        result: findSicherheitsdatenblaetter,
        count: countSicherheitsdatenblaetter,
      };
    },
    count: async (variables: CountSicherheitsdatenblaetterQueryVariables) => {
      const { countSicherheitsdatenblaetter } =
        await client.CountSicherheitsdatenblaetter(variables);

      return {
        result: countSicherheitsdatenblaetter,
      };
    },
    fetchById: async (id: string | number) => {
      if (typeof id === "string") {
        const [parsed] = hashids.decode(id) as number[];
        id = parsed;
      }

      const { findSicherheitsdatenblattById } =
        await client.SicherheitsdatenblattById({
          id,
        });

      return {
        result: findSicherheitsdatenblattById,
      };
    },
    create: async (variables: CreateSicherheitsdatenblattMutationVariables) => {
      const { createSicherheitsdatenblatt } =
        await client.CreateSicherheitsdatenblatt(variables);

      return { result: createSicherheitsdatenblatt };
    },
    update: async (variables: UpdateSicherheitsdatenblattMutationVariables) => {
      const { updateSicherheitsdatenblatt } =
        await client.UpdateSicherheitsdatenblatt(variables);

      return { result: updateSicherheitsdatenblatt };
    },
    delete: async (variables: DeleteSicherheitsdatenblattMutationVariables) => {
      const { deleteSicherheitsdatenblatt } =
        await client.DeleteSicherheitsdatenblatt(variables);

      return { result: deleteSicherheitsdatenblatt };
    },
  };
};
