import { AccessController } from "@ams-pro/scope-utils";
import { useStore } from "../store";

export const useScopes = (model: any) => {
  const accessController = new AccessController("");

  const hasPermission = (
    unitId: string | null,
    permission: string
  ): boolean => {
    const store = useStore();

    // exclude default modules
    if (
      model.ITEM_KEY === "bedienungsanleitung" ||
      model.ITEM_KEY === "pruefbericht" ||
      model.ITEM_KEY === "sicherheitsdatenblatt" ||
      model.ITEM_KEY === "dokument" ||
      model.ITEM_KEY === "protokoll"
    ) {
      return true;
    }

    if (!unitId) {
      return false;
    }

    const accessibleUnit = store.accessibleUnits?.find(
      (a: any) => a.unit === unitId
    );
    if (accessibleUnit) {
      accessController.updateUserScopes(accessibleUnit?.scopes ?? "");
      return accessController.can(`ams:${model.ITEM_KEY}:${permission}`);
    }

    return false;
  };

  return {
    hasPermission,
  };
};
