import hsaetze from "./hsaetze.json";
import psaetze from "./psaetze.json";
import lagerklassen from "./lagerklassen.json";

export default {
  input: {
    suche: "Suche",
    abbrechen: "Abbrechen",
    schliessen: "Schließen",
    speichern: "Speichern",
    archivieren: "Archivieren",
    wiederherstellen: "Wiederherstellen",
    loeschen: "Löschen",
    unwiderruflichLoeschen: "Unwiderruflich löschen",
    aktualisieren: "Aktualisieren",
    erstellen: "Erstellen",
    auswaehlen: "Auswählen",
    bearbeiten: "Bearbeiten",
    hinzufuegen: "Hinzufügen",
    zurzeitArchiviert: "Zurzeit archiviert",
    monat: "Monat | Monate",
    automatischGeneriert: "automatisch generiert",
    imTabOeffnen: "{0} in neuem Tab öffnen",
    herunterladen: "Herunterladen",
    hochladen: "Hochladen",
    hinterlegt: "Added",
    nichtHinterlegt: "Not added",
    verknuepft: "Verknüpft",
    zurueck: "Zurück",
    weiter: "Weiter",
    absenden: "Absenden",
    oeffnen: "Öffnen",
    uhrzeit: "Uhrzeit",
    aktualisierenFehlgeschlagen: "Aktualisieren fehlgeschlagen",
  },
  filter: {
    text: "{0} filtern",
    verfuegbar: "{count} Filter verfügbar",
    zuruecksetzen: "Alle Filter zurücksetzen",
    archiviert: "Archiviert",
    keineOptionen: "Keine Optionen gefunden",
    ab: "Ab",
    von: "Von",
    bis: "Bis",
    schnellsuche: "Schnellsuche",
    monat: "{count} Monate | {count} Monat | {count} Monate",
    faelligkeit: "Fälligkeit",
    gefahren: "Gefahren",
  },
  status: {
    "Keine Mängel": "Keine Mängel",
    "Geringe Mängel": "Geringe Mängel",
    "Erhebliche Mängel": "Erhebliche Mängel",
    "Prüfung erforderlich": "Prüfung erforderlich",
    "Kein Ergebnis": "Kein Ergebnis",
    Freigegeben: "Freigegeben",
    "Bedingt freigegeben": "Bedingt freigegeben",
    Gesperrt: "Gesperrt",
    "In Arbeit": "In Arbeit",
    Offen: "Offen",
    "Zusammenlagerung erlaubt": "Zusammenlagerung erlaubt",
    "Zusammenlagerung eingeschränkt erlaubt":
      "Zusammenlagerung eingeschränkt erlaubt",
    "Separatlagerung erforderlich": "Separatlagerung erforderlich",
    "Raum oder Lagerklasse unbekannt": "Raum oder Lagerklasse unbekannt",
    ACHTUNG: "ACHTUNG",
    GEFAHR: "GEFAHR",
  },
  drawer: {
    weitereAnlagen: "Weitere Anlagen",
  },
  verbandbuch: {
    name: "Verbandbuch | Verbandbücher",
    erstellen: "@:verbandbuch.name erstellen",
    archivieren: "@:verbandbuch.name archivieren",
    wiederherstellen: "@:verbandbuch.name wiederherstellen",
    loeschen: "@:verbandbuch.name löschen",
    teilen: "@:verbandbuch.name teilen",
    exportieren: "@:verbandbuch.name exportieren",
    bezeichnung: "Bezeichnung",
    eintrag: "Verbandbucheintrag | Verbandbucheinträge",
    standort: "Standort",
    benachrichtigende: "Zu Benachrichtigende",
    benachrichtigendePersonen: "Zu Benachrichtigende Personen",
    aushang: "Verbandbuchaushang",
    aushangHerunterladen: "Verbandbuchaushang im PDF Format herunterladen",
    informationen: "Verbandbuchinformationen",
    urlBearbeiten:
      "Bearbeiten Sie hier die öffentliche URL, an der man Einträge für dieses Verbandbuch erstellen kann",
    scanQRCode:
      "Scannen Sie diesen QR-Code ein und folgen Sie der URL um neue Einträge zu erstellen",
    verletzter: "Verletzter",
    unfallzeitpunkt: "Unfallzeitpunkt",
    unfallort: "Unfallort",
    arbeitsbereich: "Arbeitsbereich",
    artDerVerletzung: "Art der Verletzung",
    hergang: "Hergang",
    umfangDerVerletzung: "Umfang der Verletzung",
    namenDerZeugen: "Namen der Zeugen",
    ersteHilfeZeitpunkt: "Erste-Hilfe Zeitpunkt",
    namenDerErsthelfer: "Namen der Ersthelfer",
    ersteHilfeMassnahmen: "Art und Weise der Erste-Hilfe Maßnahmen",
    ersteHilfeLeistungen: "Erste-Hilfe Leistungen",
    action: "Aktion",
    aenderungshistorie: "Änderungshistorie",
    besitztRevisionen: "Dieser Eintrag besitzt folgende Revisionen",
    revision: "Revision | Revisionen",
    versionVom: "Version vom",
    aktuelleVersion: "Aktuelle Version",
    aktuellerStand: "Dies ist der aktuelle Stand des Eintrags",
    angabenUnfallhergang:
      "Angaben zum Hergang des Unfalls bzw. des Gesundheitsschadens",
    orange:
      'Alle Änderungen zur aktuellen Version sind <span class="text-orange-14 q-mx-xs">orange</span> markiert',
    alleFelder: "Es müssen alle Felder ausgefüllt werden",
    eintragErstellen: "Verbandbucheintrag erstellen",
    erstellenText:
      "Wählen Sie zuerst den Standort aus estellen Sie dannach das Verbandbuch",
    eintragType: "Art",
    accident: "Unfall",
    nearmiss: "Beinaheunfall",
  },
  eintrag: {
    name: "Verbandbucheintrag | Verbandbucheinträge",
    erstellen: "@:eintrag.name erstellen",
    archivieren: "@:eintrag.name archivieren",
    wiederherstellen: "@:eintrag.name wiederherstellen",
    loeschen: "@:eintrag.name löschen",
    treffer: "Einträge ({count} Treffer)",
    trefferArchiviert: "Archivierte Einträge ({count} Treffer)",
    filter: "Einträge filtern",
  },
  dashboard: {
    name: "Dashboard",
    laden: "Lade Dashboard...",
    fehler: "Fehler beim Laden des Dashboards",
    daten: "Die Daten konnten nicht geladen werden",
    erneutLaden: "Erneut laden",
    donutchart: "Donut Chart Ansicht",
    barchart: "Bar Chart Ansicht",
  },
  role: {
    name: "Berechtigung anderer Nutzer",
  },
  betriebsmittel: {
    name: "Betriebsmittel | Betriebsmittel",
    erstellen: "@:betriebsmittel.name erstellen",
    archivieren: "@:betriebsmittel.name archivieren",
    wiederherstellen: "@:betriebsmittel.name wiederherstellen",
    loeschen: "@:betriebsmittel.name löschen",
    teilen: "@:betriebsmittel.name teilen",
    exportieren: "@:betriebsmittel.name exportieren",
    bezeichnung: "Bezeichnung",
    kategorien: "Kategorien",
    seriennummer: "Seriennummer",
    raum: "Raum",
    inventarnummer: "Inventarnummer",
    letztePruefung: "Letzte Prüfung",
    pruefintervall: "Prüfintervall",
    naechstePruefung: "Nächste Prüfung",

    status: "Prüfergebnis",
    anmerkungen: "Anmerkungen",
    anlagen: "Anlagen",
    standort: "Standort",
    informationen: {
      titel: "Geräteinformationen",
      allgemein: "Allgemeine Betriebsmittelinformationen",
      pruefung: "Prüfinformationen",
    },
  },
  login: {
    amsLogin: "{company} Login",
    signInWithEmail: "Melden Sie sich mit Ihrer E-Mail Adresse an",
    email: "E-Mail",
    password: "Passwort",
    forgotPassword: "Passwort vergessen",
    welcome: "Willkommen!",
    noAccount:
      "Sie haben noch keinen {company} Account? Registrieren Sie sich jetzt kostenlos!",
    alreadyHaveAccount:
      "Sie besitzen bereits einen {company} Account? Loggen Sie sich hier ein",
    register: "Registrieren",
    gdpr: "Datenschutzerklärung",
    passwordReset: "Passwort zurücksetzen",
    enterYourEmail: "Geben Sie Ihre E-Mail Adresse an",
    resetInfo:
      "Nachdem Sie Ihre E-Mail Adresse angegeben und abgeschickt haben erhalten Sie weitere Informationen per Mail",
    resetDescription:
      "Dies kann einige Minuten dauern, überprüfen Sie zusätzlich Ihren Spam Ordner",
  },
  kategorie: {
    name: "Kategorie | Kategorien",
    erstellen: "@:kategorie.name erstellen",
    archivieren: "@:kategorie.name archivieren",
    loeschen: "@:kategorie.name löschen",
    teilen: "@:kategorie.name teilen",
    bearbeiten: "@:kategorie.name bearbeiten",
    bezeichnung: "Bezeichnung",
  },
  raum: {
    name: "Raum | Räume",
    erstellen: "@:raum.name erstellen",
    archivieren: "@:raum.name archivieren",
    loeschen: "@:raum.name löschen",
    teilen: "@:raum.name teilen",
    bearbeiten: "@:raum.name bearbeiten",
    bezeichnung: "Bezeichnung",
  },
  gefaehrdungsbeurteilungArt: {
    name: "GBU-Art | GBU-Arten",
    erstellen: "@:gefaehrdungsbeurteilungArt.name erstellen",
    archivieren: "@:gefaehrdungsbeurteilungArt.name archivieren",
    loeschen: "@:gefaehrdungsbeurteilungArt.name löschen",
    teilen: "@:gefaehrdungsbeurteilungArt.name teilen",
    bearbeiten: "@:gefaehrdungsbeurteilungArt.name bearbeiten",
    bezeichnung: "Bezeichnung",
  },
  betriebsanweisungArt: {
    name: "BA-Art | BA-Arten",
    erstellen: "@:betriebsanweisungArt.name erstellen",
    archivieren: "@:betriebsanweisungArt.name archivieren",
    loeschen: "@:betriebsanweisungArt.name löschen",
    teilen: "@:betriebsanweisungArt.name teilen",
    bearbeiten: "@:betriebsanweisungArt.name bearbeiten",
    bezeichnung: "Bezeichnung",
  },
  tag: {
    name: "Tag | Tags",
    erstellen: "@:tag.name erstellen",
    archivieren: "@:tag.name archivieren",
    loeschen: "@:tag.name löschen",
    teilen: "@:tag.name teilen",
    bearbeiten: "@:tag.name bearbeiten",
    bezeichnung: "Bezeichnung",
  },
  hsatz: {
    name: "H-Satz | H-Sätze",
    saetze: hsaetze,
  },
  psatz: {
    name: "P-Satz | P-Sätze",
    saetze: psaetze,
  },
  freigegeben: {
    freigegebenDurch: "Freigegeben durch",
    text: 'Freigebender ist derjenige, der den Status auf "Freigegeben" gesetzt hat',
  },
  gefahrstoff: {
    name: "Gefahrstoff | Gefahrstoffe",
    erstellen: "@:gefahrstoff.name erstellen",
    archivieren: "@:gefahrstoff.name archivieren",
    wiederherstellen: "@:gefahrstoff.name wiederherstellen",
    loeschen: "@:gefahrstoff.name löschen",
    teilen: "@:gefahrstoff.name teilen",
    exportieren: "@:gefahrstoff.name exportieren",
    bezeichnung: "Bezeichnung",
    kategorien: "Kategorien",
    zweck: "Verwendungszweck",
    lagerklasse: "Lagerklasse",
    zusammenlagerung: "Zusammenlagerung",
    zusammenlagerungTabelle:
      "Zusammenlagerung ermittelt nach TRGS510 Zusammenlagerungstabelle",
    menge: "Menge",
    signalwort: "Signalwort",
    anmerkungen: "Anmerkungen",
    anlagen: "Anlagen",
    raum: "Lagerort",
    standort: "Standort",
    status: "Status",
    mengeneinheit: "Mengeneinheit",
    verwendungszweck: "Verwendungszweck",
    sicherheitsdatenblatt: "Sicherheitsdatenblatt",
    letzteSubstitutionspruefung: "Letzte Substitutionsprüfung",
    pruefintervall: "Prüfintervall",
    naechsteSubstitutionspruefung: "Nächste Substitutionsprüfung",
    substitutionErforderlich: "Substitution erforderlich",
    hSaetze: "H-Sätze",
    pSaetze: "P-Sätze",
    brandgefahr: "Brandgefahr",
    explosionsgefahr: "Explosionsgefahr",
    einatemgefahr: "Einatemgefahr",
    hautkontaktgefahr: "Hautkontaktgefahr",
    cmr: "KMR",
    informationen: {
      titel: "Gefahrstoffinformationen",
      titel2: "Gefahr & Substitutionsprüfung",
      allgemein: "Allgemeine Gefahrstoffinformationen",
      verbrauchsmengen: "Verbrauchsmengen / Verwendungszweck",
      freigabe: "Freigabe",
      substitutionspruefung: "Substitutionsprüfung",
      hUndPSaetze: "H & P-Sätze",
      gefahren: "Dangers",
    },
    zusammenlagerungErlaubt: 'Zusammenlagerung in "{0}" erlaubt',
    zusammenlagerungEingeschraenkt: 'Zusammenlagerung in "{0}" eingeschränkt',
    separatlagerungErforderlich: 'Separatlagerung in "{0}" erforderlich',
    lagerklassen,
  },
  gefaehrdungsbeurteilung: {
    name: "Gefährdungsbeurteilung | Gefährdungsbeurteilungen",
    erstellen: "@:gefaehrdungsbeurteilung.name erstellen",
    archivieren: "@:gefaehrdungsbeurteilung.name archivieren",
    wiederherstellen: "@:gefaehrdungsbeurteilung.name wiederherstellen",
    loeschen: "@:gefaehrdungsbeurteilung.name löschen",
    teilen: "@:gefaehrdungsbeurteilung.name teilen",
    exportieren: "@:gefaehrdungsbeurteilung.name exportieren",
    bezeichnung: "Bezeichnung",
    kategorien: "Kategorien",
    art: "Art",
    raum: "Raum",
    letztesReview: "Letztes Review",
    reviewIntervall: "Überprüfen in",
    naechstesReview: "Nächstes Review",
    anmerkungen: "Anmerkungen",
    dokumente: "Dokumente",
    standort: "Standort",
    status: "Status",
    informationen: {
      titel: "GBU Informationen",
      allgemein: "Allgemeine GBU Informationen",
      pruefinformationen: "Prüfinformationen",
      freigabe: "Freigabe",
    },
  },
  betriebsanweisung: {
    name: "Betriebsanweisung | Betriebsanweisungen",
    erstellen: "@:betriebsanweisung.name erstellen",
    archivieren: "@:betriebsanweisung.name archivieren",
    wiederherstellen: "@:betriebsanweisung.name wiederherstellen",
    loeschen: "@:betriebsanweisung.name löschen",
    teilen: "@:betriebsanweisung.name teilen",
    exportieren: "@:betriebsanweisung.name exportieren",
    bezeichnung: "Bezeichnung",
    kategorien: "Kategorien",
    art: "Art",
    raum: "Raum",
    letztesReview: "Letztes Review",
    reviewIntervall: "Überprüfen in",
    naechstesReview: "Nächstes Review",
    anmerkungen: "Anmerkungen",
    dokumente: "Dokumente",
    standort: "Standort",
    status: "Status",
    informationen: {
      titel: "BA Informationen",
      allgemein: "Allgemeine BA Informationen",
      pruefinformationen: "Prüfinformationen",
      freigabe: "Freigabe",
    },
  },
  bedienungsanleitung: {
    name: "Bedienungsanleitung | Bedienungsanleitungen",
    erstellen: "@:bedienungsanleitung.name erstellen",
    archivieren: "@:bedienungsanleitung.name archivieren",
    wiederherstellen: "@:bedienungsanleitung.name wiederherstellen",
    loeschen: "@:bedienungsanleitung.name löschen",
    teilen: "@:bedienungsanleitung.name teilen",
    exportieren: "@:bedienungsanleitung.name exportieren",
    bezeichnung: "Bezeichnung",
    dokumente: "Dokumente",
    standort: "Standort",
    informationen: {
      titel: "Informationen",
      allgemein: "Allgemeine Bedienungsanleitungsinformationen",
    },
  },
  pruefbericht: {
    name: "Prüfbericht | Prüfberichte",
    erstellen: "@:pruefbericht.name erstellen",
    archivieren: "@:pruefbericht.name archivieren",
    wiederherstellen: "@:pruefbericht.name wiederherstellen",
    loeschen: "@:pruefbericht.name löschen",
    teilen: "@:pruefbericht.name teilen",
    exportieren: "@:pruefbericht.name exportieren",
    bezeichnung: "Bezeichnung",
    dokumente: "Dokumente",
    standort: "Standort",
    informationen: {
      titel: "Informationen",
      allgemein: "Allgemeine Prüfberichtinformationen",
    },
  },
  sicherheitsdatenblatt: {
    name: "Sicherheitsdatenblatt | Sicherheitsdatenblätter",
    erstellen: "@:sicherheitsdatenblatt.name erstellen",
    archivieren: "@:sicherheitsdatenblatt.name archivieren",
    wiederherstellen: "@:sicherheitsdatenblatt.name wiederherstellen",
    loeschen: "@:sicherheitsdatenblatt.name löschen",
    teilen: "@:sicherheitsdatenblatt.name teilen",
    exportieren: "@:sicherheitsdatenblatt.name exportieren",
    bezeichnung: "Bezeichnung",
    dokumente: "Dokumente",
    standort: "Standort",
    informationen: {
      titel: "Informationen",
      allgemein: "Allgemeine Sicherheitsdatenblattinformationen",
    },
  },
  dokument: {
    name: "Dokument | Dokumente",
    erstellen: "@:dokument.name erstellen",
    archivieren: "@:dokument.name archivieren",
    wiederherstellen: "@:dokument.name wiederherstellen",
    loeschen: "@:dokument.name löschen",
    teilen: "@:dokument.name teilen",
    exportieren: "@:dokument.name exportieren",
    status: "Verknüpfungsstatus",
    tags: "Tags",
    updatedAt: "Zuletzt geändert",
    anmerkungen: "Anmerkungen",
    herunterladen: "Herunterladen",
    standort: "Standort",
    dateigroesse: "Dateigröße",
    informationen: {
      titel: "Dokumenteninformationen",
      allgemein: "Allgemeine Dokumenteninformationen",
    },
  },
  protokoll: {
    name: "Protokoll | Protokolle",
    erstellen: "@:protokoll.name erstellen",
    archivieren: "@:protokoll.name archivieren",
    wiederherstellen: "@:protokoll.name wiederherstellen",
    loeschen: "@:protokoll.name löschen",
    teilen: "@:protokoll.name teilen",
    exportieren: "@:protokoll.name exportieren",
    status: "Verknüpfungsstatus",
    dateiname: "Dateiname",
    tags: "Tags",
    updatedAt: "Zuletzt geändert",
    anmerkungen: "Anmerkungen",
    herunterladen: "Herunterladen",
    standort: "Standort",
    dateigroesse: "Dateigröße",
    informationen: {
      titel: "Protokollinformationen",
      allgemein: "Allgemeine Protokollinformationen",
    },
  },
  moduleHeader: {
    treffer: "{count} Treffer",
    mehr: "Mehr",
    suche: "Suche",
    gesetzesgrundlage: "Gesetzesgrundlage",
    symbolbibliothek: "Symbolbibliothek",
    archivieren: {
      text: "{count} {0} archivieren? Archivierte {1} können wiederhergestellt werden.",
    },
    loeschen: {
      text: "{count} {0} unwiderruflich löschen?",
    },
  },
  detailsHeader: {
    archivieren: {
      text: "{0} archivieren? Archivierte {1} können wiederhergestellt werden.",
    },
    loeschen: {
      text: "{0} unwiderruflich löschen?",
    },
  },
  relationManager: {
    auswaehlen: "{count} Auswählen",
    ausgewaehlt: "Nur ausgewählte zeigen",
    undSpeichern: "und speichern",
    nichtGefunden: "Keine {0} gefunden",
    bezeichnung: "Bezeichnung",
    aktionen: "Aktionen",
  },
  saetzeManager: {
    titel: "Sätze",
    auswaehlen: "{count} Auswählen",
    ausgewaehlt: "Nur ausgewählte Sätze zeigen",
    nichtGefunden: "Keine Sätze gefunden",
  },
  standortManager: {
    ladeStandorte: "Lade verfügbare Standorte...",
    standortAuswaehlen: "Standort auswählen",
    standortAuswaehlenText:
      "Wählen Sie den Standort aus, an dem das Objekt erstellt werden soll",
    standortRechte: "Sie besitzen keine Schreibrechte in diesem Standort",
    standortNichtErstellt: "{0} kann hier nicht erstellt werden",
    aktiveStandorte:
      "Sie sehen Ihren Standort nicht? Wählen Sie Ihre aktiven Standorte oben im Standort-Menü aus, damit diese auswählbar sind",
  },
  erstellenManager: {
    informationen: "Informationen",
    erstellen: "{0} erstellen",
    erstellenAmStandort: "{0} am Standort {1} erstellen",
    weitereAnlagen: "Weitere Anlagen hinzufügen",
    nameBenoetigt: "Name wird benötigt",
    wirdErstellt: "{0} wird erstellt...",
    wurdeErstellt: "{0} erfolgreich erstellt",
    nichtHinzugefuegt: "{0} konnte nicht hinzugefügt werden",
  },
  exportManager: {
    exportText:
      "Wählen Sie den Standort an dem Sie Ihre {0} in eine CSV Datei exportieren möchten, dabei werden die Filter beachtet.",
    exportieren: "Exportieren",
  },
  dokumenteManager: {
    anlageDokumente: "Die Anlage {0} enthält {1} Dokumente",
  },
  linkTab: {
    dateien: "Ziehen Sie hier Dateien hinein, um {0} automatisch zu erstellen",
    entfernen: "Verknüpfung von {0} mit {1} aufheben",
    keineVorhanden: "Keine {0} vorhanden",
    keineVerknuepft: "Keine {0} verknüpft",
  },
  nachricht: {
    standortAuswaehlen: "Wählen Sie zuerst einen Standort aus",
    woStandortAuswaehlen: "Standorte können ganz oben rechts ausgewählt werden",
    keineAusgewaehlt: "Keine {0} ausgewählt",
    archiviert: "{0} archiviert",
    archiviertFehler: "{0} konnten nicht archiviert werden",
    archiviertSchreibrechte:
      "Aufgrund fehlender Schreibrechte konnten nicht alle {0} archiviert werden",
    geloescht: "{0} gelöscht",
    geloeschtFehler: "{0} konnten nicht gelöscht werden",
    geloeschtSchreibrechte:
      "Aufgrund fehlender Schreibrechte konnten nicht alle {0} gelöscht werden",
    csvGeneriert: "CSV Datei wird generiert...",
    csvFehler: "CSV Export fehlgeschlagen",
    aktualisiert: "{0} aktualisiert",
    aktualisiertFehler: "Aktualisieren von {0} fehlgeschlagen",
    wiederhergestellt: "{0} wiederhergestellt",
    wiederhergestelltFehler: "{0} konnten nicht wiederhergestellt werden",
    wiederhergestelltSchreibrechte:
      "Aufgrund fehlender Schreibrechte konnten nicht alle {0} wiederhergestellt werden",
    erstellt: "{0} erstellt",
    erstelltFehler: "{0} konnte nicht erstellt werden",
    ungespeichert: "Ungespeicherte Änderungen",
    ungespeichertWarnung:
      "Sie besitzen ungespeicherte Änderungen, wollen Sie diese speichern bevor Sie das Fenster schließen?",
    ungespeichertSpeichern: "Änderungen speichern",
    fensterSchliessen: "Fenster schließen",
    hochgeladen: "Dokumente wurden hochgeladen",
    nichtHochgeladen: "Dokumente konnten nicht hochgeladen werden",
    alleFelder: "Es müssen alle Felder ausgefüllt werden",
  },
  willkommen: {
    titel1: "Herzlich willkommen bei {company},",
    titel2: "Ihrer modernen Arbeitsschutzsoftware!",
    text1:
      "Zur kompletten Einrichtung der Software benötigen Sie lediglich eine Einladung zu einem Standort.",
    text2:
      "Senden Sie Ihrem {company} Ansprechpartner in Ihrem Unternehmen Ihre Email Addresse mit der Bitte Sie im System freizuschalten.",
    profilVerwalten: "Profil verwalten",
  },
  createNew: "Anlegen",
  profile: "Profil",
  website: "Webseite",
  logout: "Logout",
  standort: {
    name: "Standort | Standorte",
    select: "@:standort.name auswählen",
    appswitcher: {
      header: "Ihre Standorte",
      description:
        "Wählen Sie hier die Standorte aus, mit denen Sie arbeiten wollen.",
      companyDescription: "Gruppieren Sie Ihre Standorte mit Firmen.",
      search: "Standortsuche",
      createCompany: "Firma erstellen",
      editCompany: "Firma bearbeiten",
      deleteCompany: "Firma löschen",
      companyName: "Firmenname",
      dragAndDrop: "Standorte durch Drag & Drop hinzufügen",
      noUnits: "Keine Standorte gefunden!",
      createUnit: "Standort erstellen",
      auswaehlen: "Alle Standorte auswählen",
    },
    notifications: {
      errorLoadingCompanies: "Laden von Firmen fehlgeschlagen!",
      successfulCreateCompany: "Firma erfolgreich erstellt!",
      successfulUpdateCompany: "Firma aktualisiert!",
      successfulDeleteCompany: "Firma gelöscht!",
      errorCreation: "Erstellen fehlgeschlagen!",
      errorUpdate: "Aktualisieren fehlgeschlagen!",
      errorDelete: "Löschen fehlgeschlagen!",
      deleteCompany: "Firma löschen",
      deleteCompanyConfirmation:
        "Wollen Sie diese Firma löschen? Die enthaltenden Standorte bleiben bestehen.",
    },
  },
  unit: {
    bezeichnung: "Bezeichnung",
    berufsgenossenschaft: "Berufsgenossenschaft",
    ort: "Ort",
    plz: "PLZ",
    strasse: "Straße",
    hausnummer: "Hausnummer",
    anmerkungen: "Anmerkungen",
    numberOfUnits: "Abonnierte Standorte",
    unitsRemaining: "Freies Kontingent",
  },
  paymentAccount: {
    name: "Rechnungsadresse | Rechnungsadressen",
    yourPaymentAccounts: "Ihre Rechnungsadressen ({0})",
    edit: "Rechnungsadresse bearbeiten",
    bezeichnung: "Name der Rechnungsadresse",
    invoiceMail: "Rechnungsemail",
    ort: "Ort",
    plz: "PLZ",
    strasse: "Straße",
    hausnummer: "Hausnummer",
  },
  verwaltung: {
    name: "Verwaltung",
    standorterstellen: {
      name: "Standort erstellen",
      description: "Erstellen Sie weitere Standorte",
      selectPaymentAccount: "Rechnungsadresse auswählen",
      noPaymentAccount: "Sie besitzen noch keine Rechnungsadressen",
      chooseAccount: "Rechnungsadresse wählen",
    },
    standort: {
      name: "Standort bearbeiten",
      description: "Verwalten Sie Ihre Standortdaten",
      treffer:
        "Keine Standorte gefunden | {count} Standort gefunden | {count} Standorte gefunden",
      choose: "Wählen Sie einen Standort aus um diesen zu beabeiten",
    },
    nutzer: {
      name: "Nutzer zu Standort hinzufügen",
      description: "Weitere Nutzer zu Ihren Standorten einladen",
      firstStep:
        "1. Geben Sie die E-Mail Adressen der Nutzer an, die Sie einladen möchten.",
      secondStep:
        " 2. Wählen Sie nun die Standorte aus, an denen die Nutzer hinzugefügt werden sollen.",
      thirdStep: "3. Wählen Sie schließlich die Berechtigungen der Nutzer aus",
      invite: "Nutzer einladen",
      viewInvites: "Gesendete Einladungen",
    },
    reports: {
      name: "Benachrichtigungen Arbeitsschutzreport",
      description: "Verwalten der Benachrichtigung des Arbeitsschutzreport",
      detailedDescription:
        "Wählen Sie aus, für welche Standorte Sie Arbeitsschutzreports per E-Mail erhalten möchten",
    },
    profildaten: {
      name: "Profildaten",
      description:
        "Verwalten Sie Ihre Profildaten wie Name oder E-Mail Adresse",
      vorname: "Vorname",
      nachname: "Nachname",
      email: "Email",
      telefonnummer: "Telefonnummer",
      mobilnummer: "Mobilnummer",
      language: "Sprache",
    },
    passwortaendern: {
      name: "Passwort ändern",
      description: "Ändern Sie Ihr {company} Passwort",
      requirements: "Das Passwort muss",
      atLeast8Chars: "mindestens 8 Zeichen",
      atLeast1Letter: "mindestens einen Buchstaben",
      atLeast1Number: "mindestens eine Zahl enthalten",
      password: "Passwort",
      repeat: "@:verwaltung.passwortaendern.password wiederholen",
    },
    zahlungen: {
      name: "Zahlungsdaten",
      description:
        "Verwalten Sie Ihre hinterlegten Zahlungsdaten und Rechnungsadressen",
      needMore: "Mehr Standorte benötigt?",
      needMoreDescription:
        "Wenden Sie sich per Mail an info{'@'}{company}.de, um das Kontingent zu erhöhen.",
    },
    berechtigungen: {
      name: "Berechtigungen",
      description: "Verwalten Sie die Berechtigungen Ihrer Standortnutzer",
      nutzer: "Nutzer",
      you: "(Sie)",
      chooseUnit:
        "Wählen Sie einen Nutzer und einen Standort aus, an dem Sie die Berechtigungen aktualisieren möchten",
      noReadPermissions: "Keine Leserechte",
      noWritePermissions: "Keine Schreibrechte",
      editPermissions:
        "Bearbeiten Sie die Berechtigungen des Nutzers für diesen Standort",
      module: "Module",
      read: "Lesen",
      write: "Bearbeiten",
    },
  },
};
