<template>
  <q-td :props="props" :key="colKey">
    <ams-trim :text="props.row.notes" :length="40" />
  </q-td>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    props: {
      type: Object,
      required: true,
    },
    colKey: {
      type: String,
      required: true,
    },
  },
});
</script>
