<template>
  <div>
    <ams-tab-informationen
      :data="data"
      :hasWritePermission="hasWritePermission"
      @update:data="$emit('update:data', $event)"
      @updateInput="$emit('updateInput', $event)"
      @updateVariables="$emit('updateVariables', $event)"
    />
    <ams-tab-dokument-upload
      class="q-mt-lg"
      :data="data"
      :hasWritePermission="hasWritePermission"
      @update:data="$emit('update:data', $event)"
      @updateVariables="$emit('updateVariables', $event)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TabInformationen from "./TabInformationen.vue";
import TabDokumentUpload from "../../../components/Dokumente/tabs/TabDokumentUpload.vue";

export default defineComponent({
  components: {
    "ams-tab-informationen": TabInformationen,
    "ams-tab-dokument-upload": TabDokumentUpload,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    hasWritePermission: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
