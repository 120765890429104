<template>
  <q-input
    dense
    color="primary"
    :model-value="freigebender"
    outlined
    readonly
    :label="`${$t('freigegeben.freigegebenDurch')} (${$t(
      'input.automatischGeneriert'
    )})`"
  >
    <template #before>
      <q-icon name="mdi-account-circle" />
    </template>
    <template #append>
      <q-btn round flat dense size="12px" @click.stop>
        <q-icon name="mdi-information-outline" size="24px" />
        <q-tooltip>
          {{ $t("freigegeben.text") }}
        </q-tooltip>
      </q-btn>
    </template>
  </q-input>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps<{
  freigebender?: { vorname: String; nachname: String };
}>();

const freigebender = computed(() => {
  if (props.freigebender) {
    return `${props.freigebender.vorname ?? ""} ${
      props.freigebender.nachname ?? ""
    }`;
  }

  return "";
});
</script>
