<template>
  <q-dialog
    ref="dialog"
    transition-show="slide-up"
    transition-hide="slide-down"
    @hide="hide"
  >
    <q-card class="q-dialog-plugin" style="min-width: 800px">
      <q-card-section>
        <q-card-section class="row items-center q-pa-none q-mb-sm">
          <div class="q-pa-none text-grey-10 text-h5">
            {{ $t("paymentAccount.edit") }}
          </div>
          <q-space />
          <q-btn flat round @click="hide">
            <q-icon name="mdi-close" />
          </q-btn>
        </q-card-section>
        <q-separator />
      </q-card-section>
      <q-card-section>
        <div class="q-px-md q-mt-lg">
          <div class="row q-gutter-x-md">
            <ams-input
              class="col"
              :label="$t('paymentAccount.bezeichnung')"
              v-model="account.name"
              icon="mdi-credit-card-multiple"
            />
            <ams-input
              class="col"
              :label="$t('paymentAccount.invoiceMail')"
              v-model="account.invoiceEmail"
              icon="mdi-email"
            />
          </div>
          <div class="row q-gutter-x-md q-mt-lg">
            <ams-input
              class="col"
              :label="$t('paymentAccount.ort')"
              v-model="account.ort"
              icon="mdi-map-marker"
            />
            <ams-input
              class="col"
              :label="$t('paymentAccount.plz')"
              v-model="account.plz"
              icon="mdi-mailbox-open-outline"
            />
          </div>
          <div class="row q-gutter-x-md q-mt-md">
            <ams-input
              class="col"
              :label="$t('paymentAccount.strasse')"
              v-model="account.strasse"
              icon="mdi-truck-outline"
            />
            <ams-input
              class="col"
              :label="$t('paymentAccount.hausnummer')"
              v-model="account.hausnummer"
              icon="mdi-counter"
            />
          </div>
        </div>
        <div class="text-center">
          <div class="q-mt-lg q-mb-sm text-grey-9 text-h6 text-bold">
            {{ $t("verwaltung.zahlungen.needMore") }}
          </div>
          <div class="q-mb-md text-grey-7 text-bold">
            {{
              $t("verwaltung.zahlungen.needMoreDescription", {
                company: branding.company,
              })
            }}
          </div>
        </div>
      </q-card-section>
      <q-card-section>
        <div class="row items-center justify-center q-gutter-x-sm q-mt-lg">
          <q-btn color="grey-7" outline no-caps @click="hide">{{
            $t("input.abbrechen")
          }}</q-btn>
          <q-btn color="primary" no-caps @click="updateAccount">{{
            $t("input.speichern")
          }}</q-btn>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { QDialog, Notify } from "quasar";
import AmsInput from "../../components/forms/AmsInput.vue";
import client from "../../services";
import { useBrandingStore } from "../../store/branding";

export default defineComponent({
  components: {
    "ams-input": AmsInput,
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const dialog = ref<QDialog | null>(null);
    const branding = useBrandingStore();

    const show = () => dialog.value!.show();
    const hide = () => dialog.value!.hide();

    const updateAccount = async () => {
      try {
        await client.updatePaymentAccount({
          accountId: props.account.id,
          updatePaymentAccountDto: {
            name: props.account.name,
            ort: props.account.ort || null,
            plz: props.account.plz || null,
            strasse: props.account.strasse || null,
            hausnummer: props.account.hausnummer || null,
          },
        });

        Notify.create({
          type: "ams-success",
          message: "Rechnungsadresse wurde aktualisiert!",
        });

        hide();
      } catch {
        Notify.create({
          type: "ams-error",
          message: "Aktualisieren fehlgeschlagen!",
        });
      }
    };

    return {
      branding,
      dialog,
      show,
      hide,
      updateAccount,
    };
  },
});
</script>
