<template>
  <q-td :props="props" :key="colKey">
    <q-btn
      outline
      color="primary"
      size="sm"
      dense
      style="width: 100px"
      @click.stop="downloadFile(props.row)"
    >
      <q-icon left name="mdi-download" size="22px" />
      <div>{{ humanFileSize(props.row.size) }}</div>
    </q-btn>
  </q-td>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { downloadFile, humanFileSize } from "../../../utils";

export default defineComponent({
  props: {
    props: {
      type: Object,
      required: true,
    },
    colKey: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      downloadFile,
      humanFileSize,
    };
  },
});
</script>
