<template>
  <q-splitter
    v-model="splitter"
    :limits="[25, 70]"
    style="height: calc(100vh - 300px)"
  >
    <template #before>
      <div class="q-pr-md">
        <div v-if="selected.length">
          <div class="text-bold text-grey-9">
            {{ $t("input.verknuepft") }}
            {{ $t(`${model.ITEM_KEY}.name`, 2) }} ({{ selected.length }})
          </div>
          <q-virtual-scroll
            class="q-mt-md"
            :items="selected"
            style="max-height: calc(100vh - 410px)"
            separator
          >
            <template v-slot="{ item }">
              <q-item>
                <q-item-section avatar>
                  <q-btn round flat size="12px" @click="openNewTab(item.id)">
                    <q-icon :name="model.ICON" size="24px" />
                  </q-btn>
                </q-item-section>
                <q-item-section>{{ item.name }}</q-item-section>
                <q-item-section side>
                  <div class="row items-center q-gutter-x-xs">
                    <q-btn outline round size="sm" @click="openNewTab(item.id)">
                      <q-icon name="mdi-open-in-new" />
                      <q-tooltip>{{
                        $t(`input.imTabOeffnen`, [$t(`${model.ITEM_KEY}.name`)])
                      }}</q-tooltip>
                    </q-btn>
                    <q-btn outline round size="sm" @click="remove(item.id)">
                      <q-icon name="mdi-close" />
                      <q-tooltip>
                        {{
                          $t("linkTab.entfernen", [
                            $t(`${model.ITEM_KEY}.name`),
                            $t(`${parentModel.ITEM_KEY}.name`),
                          ])
                        }}
                      </q-tooltip>
                    </q-btn>
                  </div>
                </q-item-section>
              </q-item>
            </template>
          </q-virtual-scroll>
        </div>
        <div v-else class="text-center text-grey-7 text-bold q-mt-xl">
          {{ $t("linkTab.keineVerknuepft", [$t(`${model.ITEM_KEY}.name`, 2)]) }}
        </div>
      </div>
    </template>
    <template #after>
      <div class="q-pl-md">
        <div class="text-bold text-grey-9">
          {{ $t(`${model.ITEM_KEY}.name`, 2) }}
        </div>
        <div class="row items-center q-mt-sm">
          <q-input
            :label="$t('input.suche')"
            clearable
            outlined
            color="primary"
            dense
            style="max-width: 300px"
            class="full-width"
            debounce="400"
            v-model="search"
            @update:model-value="updateSearch"
          >
            <template #prepend>
              <q-icon name="mdi-magnify" />
            </template>
          </q-input>
          <div class="q-mx-lg text-subtitle1 text-bold text-grey-9">
            {{ $t("moduleHeader.treffer", rowsNumber || 0) }}
          </div>
          <q-space />
          <q-btn outline no-caps color="primary" @click="createModule">
            <q-icon name="mdi-plus" left />{{ $t("input.erstellen") }}
          </q-btn>
        </div>
        <q-table
          virtual-scroll
          flat
          bordered
          class="ams-data-table q-mt-sm"
          style="max-height: calc(100vh - 350px)"
          selection="multiple"
          :columns="header"
          :visible-columns="defaultHeader"
          :rows="result"
          v-model:selected="selected"
          :pagination="pagination"
          :virtual-scroll-sticky-size-start="48"
          :loading="loading"
          :hide-bottom="!!result.length"
          :no-data-label="
            $t(`linkTab.keineVorhanden`, [$t(`${model.ITEM_KEY}.name`, 2)])
          "
          @request="sort($event.pagination)"
          @update:selected="updateModules"
          @virtual-scroll="fetchMore"
        >
          <template #header="props">
            <q-tr :props="props">
              <q-th class="text-left">
                <q-checkbox
                  class="q-ml-sm"
                  color="grey-7"
                  v-model="props.selected"
                  dense
                />
              </q-th>
              <q-th v-for="col in props.cols" :key="col.name" :props="props">{{
                $t(`${model.ITEM_KEY}.${col.name}`)
              }}</q-th>
            </q-tr>
          </template>
          <template #body="props">
            <q-tr :props="props" @click="props.selected = !props.selected">
              <q-td key="selected">
                <q-checkbox
                  class="q-ml-sm"
                  color="blue"
                  v-model="props.selected"
                  dense
                />
              </q-td>
              <component
                v-for="col in props.cols"
                :key="col.name"
                :is="col.cell"
                :props="props"
                :colKey="col.name"
                :hasWritePermission="hasWritePermission"
                @updateData="updateData"
              />
            </q-tr>
          </template>
        </q-table>
      </div>
    </template>
  </q-splitter>
</template>

<script lang="ts">
import { defineComponent, computed, watch, ref } from "vue";
import CheckListLoading from "../../components/loading/CheckListLoading.vue";
import { missingWritePermission } from "../../utils";
import { Dialog } from "quasar";
import { hashids } from "../../services/hashids.service";
import { useVirtualScroll } from "../../hooks";
import CreateAnlageDialog from "../../components/AnlagenLinkTab/CreateAnlageDialog.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    "ams-check-list-loading": CheckListLoading,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    parentModel: {
      type: Object,
      required: true,
    },
    api: {
      type: Object,
      required: true,
    },
    header: {
      type: Array,
      required: true,
    },
    defaultHeader: {
      type: Array,
      required: true,
    },
    modules: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      required: true,
    },
    tabWrapper: {
      required: true,
    },
    hasWritePermission: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();

    const splitter = ref<number>(30);
    const panel = ref<string>("main");

    const id = router.currentRoute.value.params.id;
    const decodedId = computed(() => hashids.decode(id as string)[0]);

    const selected = ref<any[]>([]);
    const search = ref<string>("");

    watch(
      () => props.modules,
      () => (selected.value = [...props.modules]),
      {
        deep: true,
        immediate: true,
      }
    );

    const {
      data: result,
      loading,
      rowsNumber,
      pagination,
      filter,
      fetchMore,
      updateData,
      refetchData,
      sort,
    } = useVirtualScroll(props.api, 50, props.data.unitId);

    const updateSearch = (searchTerm: string) => {
      if (searchTerm) {
        const formattedSearch = search.value
          .split("")
          .map((s) => `${s}%`)
          .join("");

        filter.value = {
          val: {
            ...filter.value.val,
            search: {
              like: {
                name: `%${formattedSearch}`,
              },
            },
          },
          meta: {
            ...filter.value.meta,
            search: search.value,
          },
        };
      } else {
        const { search: _, ...val } = filter.value.val;
        const { search: __, ...meta } = filter.value.meta;
        filter.value = { val, meta };
      }
    };

    const createModule = async () => {
      if (props.hasWritePermission) {
        Dialog.create({
          component: CreateAnlageDialog,
          componentProps: {
            model: props.model,
            parentModel: props.parentModel,
            api: props.api,
            id: decodedId.value,
            unitId: props.data.unitId,
            tabWrapper: props.tabWrapper,
            hasWritePermission: true,
          },
        }).onOk(async (item: any) => {
          await refetchData();
          updateModules(
            [...selected.value, item].map((s) => ({
              id: s.id,
              name: s.name,
            }))
          );
        });
      } else {
        missingWritePermission();
      }
    };

    const updateModules = (modules: any[]) => {
      emit("update:data", {
        ...props.data,
        [props.model.ITEM_KEY_UPDATE]: modules,
      });

      emit("updateVariables", {
        origin: props.model.ITEM_KEY_UPDATE,
        val: modules.map((a: any) => a.id),
      });
    };

    const openNewTab = (id: string) => {
      window.open(
        `${window.location.origin}${props.model.ROUTE}/${hashids.encode(id)}`,
        "_blank"
      );
    };

    const remove = (id: string) => {
      selected.value = selected.value.filter((s) => s.id !== id);
      updateModules(selected.value);
    };

    return {
      splitter,
      panel,
      selected,
      result,
      loading,
      rowsNumber,
      pagination,
      fetchMore,
      sort,
      updateData,
      updateModules,
      remove,
      createModule,
      openNewTab,
      search,
      updateSearch,
    };
  },
});
</script>
