<template>
  <q-td :props="props" :key="colKey">
    {{ `${props.row.pruefintervall} Monate` }}
  </q-td>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    props: {
      type: Object,
      required: true,
    },
    colKey: {
      type: String,
      required: true,
    },
  },
});
</script>
