import { IAuth } from "../data/views/types.data";
import { isTokenExpired } from "../router/guards";
import { AUTH_TOKEN, REFRESH_TOKEN } from "../utils/constants";
import { defineStore } from "pinia";
import { LocalStorage } from "quasar";
import * as Sentry from '@sentry/vue'

export const decodeJWT = <T extends Object = any>(jwt: string): T =>
  JSON.parse(atob(jwt.split(".")[1]));

export function getTokenExpireDate(token: string) {
  const decodedToken = decodeJWT<{ exp: number }>(token);

  return new Date(decodedToken.exp * 1000);
}

export const useStore = defineStore("store", {
  state: () => ({ auth: {} as IAuth, unit: 0 }),
  actions: {
    /**
     * Inits auth by fetching tokens from localstorage
     */
    init() {
      try {
        const payload = LocalStorage.getItem(AUTH_TOKEN) as IAuth;
        this.auth = payload || {};

        Sentry.setUser({
          email: this.auth.email
        })

      } catch {
        this.auth = {} as IAuth;
      }
    },
    /**
     * Writes payload into localStorage and sets the sentry user
     */
    setAuth(payload: IAuth) {
      const newPayload = { ...this.auth, ...payload };

      if (payload.refresh_token) {
        LocalStorage.set(REFRESH_TOKEN, payload.refresh_token);
      }

      this.auth = newPayload;
      Sentry.setUser({
        email: this.auth.email,
      })

      LocalStorage.set(AUTH_TOKEN, newPayload);
    },
    /**
     * Clears the entire localStorage and empties the stores auth state
     */
    clearAuth() {
      LocalStorage.clear();
      this.auth = {} as IAuth;
      Sentry.setUser(null)
    },
    changeUnit() {
      this.unit++;
    },
  },
  getters: {
    fullyAuthenticated: (state) =>
      !isTokenExpired(state.auth?.id_token) &&
      !!state.auth.accessibleUnits?.length,
    authenticated: (state) => !isTokenExpired(state.auth?.id_token),
    token: (state) => state.auth?.id_token || null,
    currentUnitIds: (state) =>
      state.auth.accessibleUnits
        .filter((au) => au.default)
        .map((u) => u.unit) || [],
    accessibleUnits: (state) => state.auth.accessibleUnits || [],
    unitMap: (state) => state.auth.unitMap || {},
    email: (state) => state.auth.email,
    name: (state) => `${state.auth.vorname} ${state.auth.nachname}`,
    userId: (state) =>
      state.auth.id_token
        ? decodeJWT<{ sub: string }>(state.auth.id_token).sub
        : null,
    unitChange: (state) => state.unit,
  },
});
