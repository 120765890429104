<template>
  <q-header elevated class="bg-white text-grey-8 q-py-xs" height-hint="58">
    <q-toolbar>
      <q-btn
        v-if="authenticated"
        class="q-mx-sm"
        flat
        dense
        round
        aria-label="Menu"
        icon="mdi-menu"
        @click="$emit('toggle')"
      />
      <q-btn
        flat
        no-caps
        no-wrap
        class="q-ml-xs q-mr-xl"
        :class="{ transform: branding.isLogoAnimated }"
        :to="{ name: 'Dashboard' }"
      >
        <q-icon size="30px" color="primary">
          <img
            id="logo"
            :src="branding.logo"
            alt="logo"
            style="height: 30px" />
        </q-icon>
        <q-toolbar-title shrink>{{ branding.company }}</q-toolbar-title>
      </q-btn>
      <q-space />
      <q-btn-dropdown
        v-if="fullyAuthenticated"
        flat
        no-wrap
        color="primary"
        icon="mdi-plus"
        no-caps
        :label="$t('createNew')"
        style="height: 40px"
      >
        <q-list style="min-width: 300px">
          <q-item
            v-for="item in createItems"
            :key="item.data.model.ITEM_KEY"
            clickable
            v-close-popup
            aria-hidden="true"
            @click="createItem(item)"
          >
            <q-item-section avatar>
              <q-icon color="grey-7" :name="item.data.model.ICON" />
            </q-item-section>
            <q-item-section class="text-grey-10">
              {{ $t(`${item.data.model.ITEM_KEY}.name`) }}
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
      <div class="q-gutter-sm row items-center no-wrap">
        <ams-app-switcher v-if="fullyAuthenticated" />
        <q-btn v-if="authenticated" round dense flat color="text-grey-7">
          <q-avatar size="36px">
            <img
              :src="`https://www.gravatar.com/avatar/${profileSHA}?d=retro`"
              alt=""
            />
          </q-avatar>
          <q-tooltip v-if="!menu">{{ $t("profile") }}</q-tooltip>
          <q-menu v-model="menu">
            <q-list separator style="min-width: 250px">
              <q-item
                v-if="fullyAuthenticated"
                data-test-id="header__account--account"
                clickable
                v-close-popup
                @click="$router.push('/verwaltung/profildaten')"
              >
                <q-item-section avatar>
                  <q-icon name="mdi-account-circle" />
                </q-item-section>
                <q-item-section>{{ $t("profile") }}</q-item-section>
              </q-item>
              <q-item
                data-test-id="header__account--website"
                clickable
                v-close-popup
                @click="openURL('https://ams-pro.de')"
              >
                <q-item-section avatar>
                  <q-icon name="mdi-web" />
                </q-item-section>
                <q-item-section>
                  <div class="row items-center">
                    {{ $t("website") }}
                    <q-icon name="mdi-open-in-new" right />
                  </div>
                </q-item-section>
              </q-item>
              <q-item
                data-test-id="header__account--logout"
                clickable
                @click="logout"
              >
                <q-item-section avatar>
                  <q-icon name="mdi-logout-variant" />
                </q-item-section>
                <q-item-section>{{ $t("logout") }}</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </div>
    </q-toolbar>
  </q-header>
</template>

<script lang="ts" setup>
import { ref, computed, watchEffect } from "vue";
import { useLogout } from "../../hooks";
import { Dialog, Notify, openURL } from "quasar";
import { useStore } from "../../store";
import { useBrandingStore } from "../../store/branding";
import { useRouter } from "vue-router";
import AmsAppSwitcher from "../../layouts/app/AppSwitcher.vue";
import {
  bedienungsanleitungData,
  betriebsanweisungData,
  betriebsmittelData,
  gefaehrdungsbeurteilungData,
  gefahrstoffData,
  pruefberichtData,
  sicherheitsdatenblattData,
} from "../../data";
import CreateDialog from "../../components/CreateDialog.vue";
import { useI18n } from 'vue-i18n';

const store = useStore();
const branding = useBrandingStore();
const router = useRouter();

const menu = ref<boolean>(false);

const fullyAuthenticated = computed<boolean>(() => store.fullyAuthenticated);

const authenticated = computed<boolean>(() => store.authenticated);

const unitsSelected = computed<boolean>(() => !!store.currentUnitIds.length);

const createItems = ref([
  {
    data: betriebsmittelData,
    anlagenTabs: [
      gefaehrdungsbeurteilungData,
      betriebsanweisungData,
      bedienungsanleitungData,
      pruefberichtData,
    ],
    moduleTabs: [],
  },
  {
    data: gefahrstoffData,
    anlagenTabs: [gefaehrdungsbeurteilungData, betriebsanweisungData],
    moduleTabs: [],
  },
  {
    data: gefaehrdungsbeurteilungData,
    anlagenTabs: [],
    moduleTabs: [betriebsmittelData, gefahrstoffData],
  },
  {
    data: betriebsanweisungData,
    anlagenTabs: [],
    moduleTabs: [betriebsmittelData, gefahrstoffData],
  },
  {
    data: bedienungsanleitungData,
    anlagenTabs: [],
    moduleTabs: [betriebsmittelData],
  },
  {
    data: pruefberichtData,
    anlagenTabs: [],
    moduleTabs: [betriebsmittelData],
  },
  {
    data: sicherheitsdatenblattData,
    anlagenTabs: [],
    moduleTabs: [gefahrstoffData],
  },
]);

const createItem = (item: any) => {
  if (unitsSelected.value) {
    Dialog.create({
      component: CreateDialog,
      componentProps: {
        model: item.data.model,
        api: item.data.api,
        tabWrapper: item.data.tabWrapper,
        anlagenTabs: item.anlagenTabs,
        moduleTabs: item.moduleTabs,
      },
    });
  } else {
    Notify.create({
      type: "ams-warning",
      message: "Wählen Sie zuerst einen Standort aus!",
      caption: "Standorte können ganz oben rechts ausgewählt werden",
    });
  }
};

const { logout } = useLogout(menu, router);

async function digestMessage(message: string): Promise<string> {
  const hashBuffer = await crypto.subtle.digest(
    "SHA-256",
    new TextEncoder().encode(message)
  );

  const hashArray = Array.from(new Uint8Array(hashBuffer));

  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
}

const profileSHA = ref("");

watchEffect(async () => {
  if (!store.authenticated) {
    return;
  }

  profileSHA.value = await digestMessage(store.email);
});

</script>

<style scoped lang="scss">
#logo {
  transition: transform 0.45s ease-in-out;
}

.transform:hover #logo {
  transform: rotate(360deg);
}
</style>
