<template>
  <q-dialog
    ref="dialog"
    transition-show="slide-up"
    transition-hide="slide-down"
    @hide="hide"
  >
    <q-card class="q-dialog-plugin" style="min-width: 500px">
      <q-card-section>
        <q-card-section class="row items-center q-pa-none q-mb-sm">
          <div class="q-pa-none text-grey-10 text-h5">
            {{ $t("input.hochladen") }}
          </div>
          <q-space />
          <q-btn flat round @click="hide">
            <q-icon name="mdi-close" />
          </q-btn>
        </q-card-section>
        <q-separator />
      </q-card-section>
      <q-card-section>
        <ams-tab-dokument-upload v-model:data="data" :relations="relations" />
      </q-card-section>
      <q-card-section class="row justify-end">
        <q-btn
          no-caps
          color="primary"
          :disable="data.dokumente ? !data.dokumente.length : true"
          @click="select"
          >{{ $t("input.auswaehlen") }}</q-btn
        >
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { QDialog } from "quasar";
import TabDokumentUpload from "../../components/Dokumente/tabs/TabDokumentUpload.vue";

export default defineComponent({
  components: {
    "ams-tab-dokument-upload": TabDokumentUpload,
  },
  props: {
    unitId: {
      type: String,
      required: true,
    },
    relations: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const dialog = ref<QDialog | null>(null);

    const show = () => dialog.value!.show();
    const hide = () => dialog.value!.hide();

    const data = ref<any>({ unitId: props.unitId });

    const select = () => {
      hide();
      emit("ok", data.value.dokumente || []);
    };

    return {
      dialog,
      show,
      hide,
      data,
      select,
    };
  },
});
</script>
