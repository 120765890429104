import BetriebsmittelTabWrapper from "../../components/Betriebsmittel/tabs/TabWrapper.vue";
import BetriebsmittelCellStatus from "../../components/Betriebsmittel/cells/betriebsmittel-cell-status.vue";
import BetriebsmittelCellName from "../../components/Betriebsmittel/cells/betriebsmittel-cell-name.vue";
import BetriebsmittelCellKategorien from "../../components/Betriebsmittel/cells/betriebsmittel-cell-kategorien.vue";
import BetriebsmittelCellInventarnummer from "../../components/Betriebsmittel/cells/betriebsmittel-cell-inventarnummer.vue";
import BetriebsmittelCellSeriennummer from "../../components/Betriebsmittel/cells/betriebsmittel-cell-seriennummer.vue";
import BetriebsmittelCellLetztePruefung from "../../components/Betriebsmittel/cells/betriebsmittel-cell-letztePruefung.vue";
import BetriebsmittelCellPruefintervall from "../../components/Betriebsmittel/cells/betriebsmittel-cell-pruefintervall.vue";
import BetriebsmittelCellNaechstePruefung from "../../components/Betriebsmittel/cells/betriebsmittel-cell-naechstePruefung.vue";
import BetriebsmittelCellRaum from "../../components/Betriebsmittel/cells/betriebsmittel-cell-raum.vue";
import BetriebsmittelCellAnmerkungen from "../../components/Betriebsmittel/cells/betriebsmittel-cell-anmerkungen.vue";
import BetriebsmittelCellAnlagen from "../../components/Betriebsmittel/cells/betriebsmittel-cell-anlagen.vue";
import BetriebsmittelCellStandort from "../../components/Betriebsmittel/cells/betriebsmittel-cell-standort.vue";
import { IColumnDefinition, IStatusDefinition } from "./types.data";
import { useBetriebsmittel } from "../../services/betriebsmittel.service";

export enum betriebsmittelModel {
  SINGULAR = "Betriebsmittel",
  PLURAL = "Betriebsmittel",
  ROUTE = "/betriebsmittel",
  ICON = "mdi-wrench-outline",
  GESETZ = "https://www.gesetze-im-internet.de/betrsichv_2015/",
  ITEM_KEY = "betriebsmittel",
  ITEM_KEY_UPDATE = "betriebsmittel",
  MODULE = "true",
}

export const defaultBetriebsmittelHeader: string[] = [
  "status",
  "bezeichnung",
  "naechstePruefung",
  "anmerkungen",
  "anlagen",
];

export const betriebsmittelHeader: IColumnDefinition<any>[] = [
  {
    name: "status",
    label: "Prüfergebnis",
    field: "status",
    required: false,
    align: "left",
    sortable: true,
    cell: BetriebsmittelCellStatus,
  },
  {
    name: "bezeichnung",
    label: "Bezeichnung",
    field: "bezeichnung",
    required: false,
    align: "left",
    sortable: true,
    cell: BetriebsmittelCellName,
  },
  {
    name: "kategorien",
    label: "Kategorien",
    field: "kategorien",
    required: false,
    align: "left",
    sortable: false,
    cell: BetriebsmittelCellKategorien,
  },
  {
    name: "inventarnummer",
    label: "Inventarnummer",
    field: "inventarnummer",
    required: false,
    align: "left",
    sortable: true,
    cell: BetriebsmittelCellInventarnummer,
  },
  {
    name: "seriennummer",
    label: "Seriennummer",
    field: "seriennummer",
    required: false,
    align: "left",
    sortable: true,
    cell: BetriebsmittelCellSeriennummer,
  },
  {
    name: "letztePruefung",
    label: "Letzte Prüfung",
    field: "letztePruefung",
    required: false,
    align: "left",
    sortable: true,
    cell: BetriebsmittelCellLetztePruefung,
  },
  {
    name: "pruefintervall",
    label: "Prüfintervall",
    field: "pruefintervall",
    required: false,
    align: "left",
    sortable: true,
    cell: BetriebsmittelCellPruefintervall,
  },
  {
    name: "naechstePruefung",
    label: "Nächste Prüfung",
    field: "letztePruefung",
    required: false,
    align: "left",
    sortable: true,
    cell: BetriebsmittelCellNaechstePruefung,
  },
  {
    name: "raum",
    label: "Organisationseinheit",
    field: "raum",
    required: false,
    align: "left",
    sortable: false,
    cell: BetriebsmittelCellRaum,
  },
  {
    name: "anmerkungen",
    label: "Anmerkungen",
    field: "anmerkungen",
    required: false,
    align: "left",
    sortable: true,
    cell: BetriebsmittelCellAnmerkungen,
  },
  {
    name: "anlagen",
    label: "Anlagen",
    required: false,
    align: "left",
    sortable: false,
    cell: BetriebsmittelCellAnlagen,
  },
  {
    name: "standort",
    label: "Standort",
    required: false,
    align: "left",
    sortable: false,
    cell: BetriebsmittelCellStandort,
  },
];

export enum BetriebsmittelStatus {
  KEINE_MAENGEL = "Keine Mängel",
  GERINGE_MAENGEL = "Geringe Mängel",
  ERHEBLICHE_MAENGEL = "Erhebliche Mängel",
  PRUEFUNG_ERFORDERLICH = "Prüfung erforderlich",
  KEIN_ERGEBNIS = "Kein Ergebnis",
}

export const betriebsmittelStatus: {
  [P in BetriebsmittelStatus]: IStatusDefinition<BetriebsmittelStatus>;
} = {
  [BetriebsmittelStatus.KEINE_MAENGEL]: {
    label: BetriebsmittelStatus.KEINE_MAENGEL,
    icon: "mdi-check-outline",
    color: "green",
    val: BetriebsmittelStatus.KEINE_MAENGEL,
  },
  [BetriebsmittelStatus.GERINGE_MAENGEL]: {
    label: BetriebsmittelStatus.GERINGE_MAENGEL,
    icon: "mdi-alert-outline",
    color: "orange",
    val: BetriebsmittelStatus.GERINGE_MAENGEL,
  },
  [BetriebsmittelStatus.ERHEBLICHE_MAENGEL]: {
    label: BetriebsmittelStatus.ERHEBLICHE_MAENGEL,
    icon: "mdi-alert-circle-outline",
    color: "red",
    val: BetriebsmittelStatus.ERHEBLICHE_MAENGEL,
  },
  [BetriebsmittelStatus.PRUEFUNG_ERFORDERLICH]: {
    label: BetriebsmittelStatus.PRUEFUNG_ERFORDERLICH,
    icon: "mdi-clock-outline",
    color: "primary",
    val: BetriebsmittelStatus.PRUEFUNG_ERFORDERLICH,
  },
  [BetriebsmittelStatus.KEIN_ERGEBNIS]: {
    label: BetriebsmittelStatus.KEIN_ERGEBNIS,
    icon: "mdi-close-outline",
    color: "grey",
    val: BetriebsmittelStatus.KEIN_ERGEBNIS,
  },
};

export const betriebsmittelData = {
  model: betriebsmittelModel,
  api: useBetriebsmittel(),
  header: betriebsmittelHeader,
  defaultHeader: defaultBetriebsmittelHeader,
  tabWrapper: BetriebsmittelTabWrapper,
};
