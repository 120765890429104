<template>
  <q-td :props="props" :key="colKey">
    <ams-base-cell-dokumente
      :props="props"
      :model="model"
      :api="api"
      @updateData="$emit('updateData', $event)"
    />
  </q-td>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useSicherheitsdatenblatt } from "../../../services/sicherheitsdatenblatt.service";
import BaseCellDokumente from "../../../components/BaseCells/BaseCellDokumente.vue";
import { sicherheitsdatenblaetterModel } from "../../../data";

export default defineComponent({
  components: {
    "ams-base-cell-dokumente": BaseCellDokumente,
  },
  props: {
    props: {
      type: Object,
      required: true,
    },
    colKey: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      api: useSicherheitsdatenblatt(),
      model: sicherheitsdatenblaetterModel,
    };
  },
});
</script>
