import {
  CountBetriebsmittelQueryVariables,
  BetriebsmittelOverviewQueryVariables,
  CreateBetriebsmittelMutationVariables,
  DeleteBetriebsmittelMutationVariables,
  ExportBetriebsmittelMutationVariables,
  UpdateBetriebsmittelMutationVariables,
} from "../types/graphql.types";
import client from "./index";
import { hashids } from "./hashids.service";

export const useBetriebsmittel = () => {
  return {
    fetchAll: async (variables: BetriebsmittelOverviewQueryVariables) => {
      const { findBetriebsmittel, countBetriebsmittel } =
        await client.BetriebsmittelOverview(variables);

      return {
        result: findBetriebsmittel,
        count: countBetriebsmittel,
      };
    },
    count: async (variables: CountBetriebsmittelQueryVariables) => {
      const { countBetriebsmittel } = await client.CountBetriebsmittel(
        variables
      );

      return {
        result: countBetriebsmittel,
      };
    },
    fetchById: async (id: string | number) => {
      if (typeof id === "string") {
        const [parsed] = hashids.decode(id) as number[];
        id = parsed;
      }

      const { findBetriebsmittelById } = await client.BetriebsmittelById({
        id,
      });

      return {
        result: findBetriebsmittelById,
      };
    },
    create: async (variables: CreateBetriebsmittelMutationVariables) => {
      const { createBetriebsmittel } = await client.CreateBetriebsmittel(
        variables
      );

      return { result: createBetriebsmittel };
    },
    update: async (variables: UpdateBetriebsmittelMutationVariables) => {
      const { updateBetriebsmittel } = await client.UpdateBetriebsmittel(
        variables
      );

      return { result: updateBetriebsmittel };
    },
    delete: async (variables: DeleteBetriebsmittelMutationVariables) => {
      const { deleteBetriebsmittel } = await client.DeleteBetriebsmittel(
        variables
      );

      return { result: deleteBetriebsmittel };
    },
    export: async (variables: ExportBetriebsmittelMutationVariables) => {
      const { exportBetriebsmittel } = await client.ExportBetriebsmittel(
        variables
      );

      return { result: exportBetriebsmittel };
    },
  };
};
