import { IColumnDefinition } from "./types.data";
import DokumentCellStatus from "../../components/Dokumente/cells/dokument-cell-status.vue";
import DokumentCellName from "../../components/Dokumente/cells/dokument-cell-name.vue";
import DokumentCellTags from "../../components/Dokumente/cells/dokument-cell-tags.vue";
import DokumentCellUpdated from "../../components/Dokumente/cells/dokument-cell-updated.vue";
import DokumentCellAnmerkungen from "../../components/Dokumente/cells/dokument-cell-anmerkungen.vue";
import DokumentCellDownload from "../../components/Dokumente/cells/dokument-cell-download.vue";
import DokumentCellStandort from "../../components/Dokumente/cells/dokument-cell-standort.vue";
import { useDokument } from "../../services/dokument.service";

export enum dokumenteModel {
  SINGULAR = "Dokument",
  PLURAL = "Dokumente",
  ROUTE = "/dokumente",
  ICON = "mdi-paperclip",
  ITEM_KEY = "dokument",
  ITEM_KEY_UPDATE = "dokumente",
}

export const defaultDokumenteHeader: string[] = [
  "status",
  "name",
  "tags",
  "updatedAt",
  "anmerkungen",
  "herunterladen",
];

export const dokumenteHeader: IColumnDefinition<any>[] = [
  {
    name: "status",
    label: "Verknüpfungsstatus",
    field: "status",
    required: false,
    align: "left",
    sortable: false,
    cell: DokumentCellStatus,
  },
  {
    name: "name",
    label: "Dateiname",
    field: "name",
    required: false,
    align: "left",
    sortable: true,
    cell: DokumentCellName,
  },
  {
    name: "tags",
    label: "Tags",
    field: "tags",
    required: false,
    align: "left",
    sortable: false,
    cell: DokumentCellTags,
  },
  {
    name: "updatedAt",
    label: "Zuletzt geändert",
    field: "updatedAt",
    required: false,
    align: "left",
    sortable: false,
    cell: DokumentCellUpdated,
  },
  {
    name: "anmerkungen",
    label: "Anmerkungen",
    field: "notes",
    required: false,
    align: "left",
    sortable: true,
    cell: DokumentCellAnmerkungen,
  },
  {
    name: "herunterladen",
    label: "Herunterladen",
    field: "objectKey",
    required: false,
    align: "left",
    sortable: false,
    cell: DokumentCellDownload,
  },
  {
    name: "standort",
    label: "Standort",
    required: false,
    align: "left",
    sortable: false,
    cell: DokumentCellStandort,
  },
];

export const dokumenteData = {
  model: dokumenteModel,
  api: useDokument(),
  header: dokumenteHeader,
  defaultHeader: defaultDokumenteHeader,
};
