import {
  RaumOverviewQueryVariables,
  CreateRaumMutationVariables,
  DeleteRaumMutationVariables,
  UpdateRaumMutationVariables,
} from "../types/graphql.types";
import client from "./index";
import { hashids } from "./hashids.service";

export const useRaum = () => {
  return {
    fetchAll: async (variables: RaumOverviewQueryVariables) => {
      const { findRaeume } = await client.RaumOverview(variables);

      return {
        result: findRaeume,
      };
    },
    fetchById: async (id: string | number) => {
      if (typeof id === "string") {
        const [parsed] = hashids.decode(id) as number[];
        id = parsed;
      }

      const { findRaumById } = await client.RaumById({
        id,
      });

      return {
        result: findRaumById,
      };
    },
    create: async (variables: CreateRaumMutationVariables) => {
      const { createRaum } = await client.CreateRaum(variables);

      return { result: createRaum };
    },
    update: async (variables: UpdateRaumMutationVariables) => {
      const { updateRaum } = await client.UpdateRaum(variables);

      return { result: updateRaum };
    },
    delete: async (variables: DeleteRaumMutationVariables) => {
      const { deleteRaum } = await client.DeleteRaum(variables);

      return { result: deleteRaum };
    },
    queryKey: "findRaum",
    mutateKey: "updateRaum",
    createKey: "createRaum",
    exportKey: "exportRaum",
  };
};
