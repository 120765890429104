<template>
  <div>
    <q-btn
      outline
      round
      :color="props.row.dokumente?.length ? 'primary' : 'grey-7'"
      dense
      size="12px"
      class="q-mr-xs"
      @click.stop="openDokumenteDownload(props.row)"
    >
      <q-icon name="mdi-download" size="18px" />
      <q-tooltip>{{ $t("input.herunterladen") }}</q-tooltip>
    </q-btn>
    <q-btn
      outline
      round
      color="grey-7"
      dense
      size="12px"
      class="q-mr-xs"
      @click.stop="uploadDokumente"
    >
      <q-icon name="mdi-upload" size="18px" />
      <q-tooltip>{{ $t("input.hochladen") }}</q-tooltip>
    </q-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DokumentDownloadDialog from "../../components/AnlagenLinkTab/DokumentDownloadDialog.vue";
import { Dialog, Notify } from "quasar";
import DokumenteCreateDialogUnitId from "../../components/Dokumente/DokumenteCreateDialogUnitId.vue";

export default defineComponent({
  props: {
    props: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    api: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const openDokumenteDownload = (item: any) => {
      Dialog.create({
        component: DokumentDownloadDialog,
        componentProps: {
          model: props.model,
          api: props.api,
          id: item.id,
          name: item.name,
        },
      });
    };

    const uploadDokumente = () => {
      Dialog.create({
        component: DokumenteCreateDialogUnitId,
        componentProps: {
          unitId: props.props.row.unit?.id,
          relations: {
            [props.model.ITEM_KEY_UPDATE]: [props.props.row.id],
          },
        },
      }).onOk(async (dokumente: any[]) => {
        emit("updateData", {
          ...props.props.row,
          dokumente: [...props.props.row.dokumente, ...dokumente].map((d) => ({
            id: d.id,
            name: d.name,
            size: d.size,
            updatedAt: d.updatedAt,
            __typename: "Dokument",
          })),
        });

        Notify.create({
          type: "ams-success",
          message: "Dokumente wurden hochgeladen!",
        });
      });
    };

    return {
      openDokumenteDownload,
      uploadDokumente,
    };
  },
});
</script>
