<template>
  <q-stepper
    ref="stepper"
    :header-nav="!!step"
    v-model="step"
    color="primary"
    animated
  >
    <q-step
      :name="0"
      bordered
      icon="mdi-factory"
      :title="$t('standortManager.standortAuswaehlen')"
      :done="step > 0"
      done-color="green"
    >
      <div style="height: calc(100vh - 355px)">
        <q-inner-loading :showing="unitLoading">
          <q-spinner-gears size="50px" />
          <div class="q-mt-sm">{{ $t("standortManager.ladeStandorte") }}</div>
        </q-inner-loading>
        <div v-if="!unitLoading" class="q-mt-xl" style="margin: 0 20vw">
          <div class="text-bold text-grey-9 text-subtitle1">
            {{ $t("standortManager.standortAuswaehlenText") }}
          </div>
          <q-input
            style="max-width: 400px"
            outlined
            dense
            label="Suche"
            class="q-mt-md"
            clearable
            v-model="unitSearch"
          >
            <template #prepend>
              <q-icon name="mdi-magnify" />
            </template>
          </q-input>
          <q-virtual-scroll
            class="q-mt-md"
            style="max-height: 500px"
            :items="units"
            separator
          >
            <template v-slot="{ item }">
              <q-item :key="item.id" tag="label">
                <q-item-section avatar>
                  <q-radio
                    :model-value="unitId"
                    :disable="!hasPermission(item.id, 'write')"
                    :val="item.id"
                    color="blue"
                    dense
                    @update:model-value="updateUnit(item)"
                  />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ item.name || "-" }}</q-item-label>
                  <q-item-label caption>{{ item.ort || "-" }}</q-item-label>
                </q-item-section>
                <q-item-section v-if="!hasPermission(item.id, 'write')" side>
                  <q-item-label>
                    <q-icon name="mdi-alert" size="16px" left />{{
                      $t("standortManager.standortRechte")
                    }}
                  </q-item-label>
                  <q-item-label caption>{{
                    $t("standortManager.standortRechte", [
                      $t(`${model.ITEM_KEY}.name`),
                    ])
                  }}</q-item-label>
                </q-item-section>
              </q-item>
            </template>
          </q-virtual-scroll>
          <div class="q-mt-md text-subtitle1 text-grey-9 text-bold">
            {{ $t("standortManager.aktiveStandorte") }}
          </div>
        </div>
      </div>
    </q-step>
    <q-step
      :name="1"
      bordered
      :icon="model.ICON"
      :title="$t('erstellenManager.informationen')"
      :done="step > 1"
      done-color="green"
    >
      <div style="height: calc(100vh - 355px)">
        <component
          :is="tabWrapper"
          :data="data"
          :hasWritePermission="true"
          @update:data="$emit('updateData', $event)"
          @updateInput="$emit('updateInput', $event)"
          @updateVariables="$emit('updateVariables', $event)"
        />
      </div>
    </q-step>
    <q-step
      v-for="(tab, index) in anlagenTabs"
      :key="tab.model.ITEM_KEY"
      :name="index + 2"
      :icon="tab.model.ICON"
      :title="$t(`${tab.model.ITEM_KEY}.name`, 2)"
      :done="step > index + 2"
      done-color="green"
    >
      <ams-anlagen-link-tab
        :model="tab.model"
        :parentModel="model"
        :api="tab.api"
        :header="tab.header"
        :defaultHeader="tab.defaultHeader"
        :anlagen="data[tab.model.ITEM_KEY_UPDATE]"
        :data="data"
        :tabWrapper="tab.tabWrapper"
        :hasWritePermission="true"
        @update:data="$emit('updateData', $event)"
        @updateVariables="$emit('updateVariables', $event)"
      />
    </q-step>
    <q-step
      v-for="(tab, index) in moduleTabs"
      :key="tab.model.ITEM_KEY"
      :name="anlagenTabs + index + 1"
      :icon="tab.model.ICON"
      :title="$t(`${tab.model.ITEM_KEY}.name`, 2)"
      :done="step > anlagenTabs + index + 1"
      done-color="green"
    >
      <ams-module-link-tab
        :model="tab.model"
        :parentModel="model"
        :api="tab.api"
        :header="tab.header"
        :defaultHeader="tab.defaultHeader"
        :data="data"
        :tabWrapper="tab.tabWrapper"
        :hasWritePermission="true"
        @update:data="$emit('updateData', $event)"
        @updateVariables="$emit('updateVariables', $event)"
      />
    </q-step>
    <template #navigation>
      <div>
        <q-separator />
        <q-stepper-navigation
          class="row items-center justify-end q-gutter-sm q-pa-md"
        >
          <q-btn
            :disable="!step"
            text-color="grey-9"
            flat
            no-caps
            color="grey-5"
            :label="$t('input.zurueck')"
            @click="stepper.previous()"
          />
          <q-btn
            v-if="step <= tabsLength"
            :disable="!data.unit?.id"
            no-caps
            color="primary"
            :label="
              step ? $t('erstellenManager.weitereAnlagen') : $t('input.weiter')
            "
            @click="stepper.next()"
          />
          <q-btn
            no-caps
            color="primary"
            :disable="!data.unit?.id || step === 0"
            :label="
              $t('erstellenManager.erstellen', [$t(`${model.ITEM_KEY}.name`)])
            "
            @click="$emit('updateRemote')"
          />
        </q-stepper-navigation>
      </div>
    </template>
  </q-stepper>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from "vue";
import { QStepper } from "quasar";
import AnlagenLinkTab from "../components/AnlagenLinkTab/AnlagenLinkTab.vue";
import { useUnits } from "../hooks";
import ModuleLinkTab from "../components/ModuleLinkTab/ModuleLinkTab.vue";
import { IUnit } from "../data/views/types.data";
import { useScopes } from "../hooks/useScopes";

export default defineComponent({
  components: {
    "ams-anlagen-link-tab": AnlagenLinkTab,
    "ams-module-link-tab": ModuleLinkTab,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    anlagenTabs: {
      type: Array,
      default: () => [],
    },
    moduleTabs: {
      type: Array,
      default: () => [],
    },
    tabWrapper: {
      required: true,
    },
  },
  setup(props, { emit }) {
    const step = ref<number>(0);
    const stepper = ref<QStepper | null>(null);

    const { hasPermission } = useScopes(props.model);

    const { getUnits, unitSearch, unitLoading, units } = useUnits(true);

    getUnits();

    watch(
      units,
      () => {
        if (units.value.length === 1) {
          const unit = units.value[0];
          if (hasPermission(unit.id, "write")) {
            if (step.value === 0) {
              updateUnit(units.value[0]);
              step.value = 1;
            }
          }
        }
      },
      {
        immediate: true,
      }
    );

    const tabsLength = computed(
      () => props.anlagenTabs.length + props.moduleTabs.length
    );

    const updateUnit = (unit: IUnit) => {
      emit("updateData", {
        ...props.data,
        // it seems like both versions are used in the entire app, because whenever i remove one, something fails
        unit: {
          id: unit.id,
        },
        unitId: unit.id,
        unitName: unit.name,
      });
      emit("updateInput", { origin: "unitId", val: unit.id });
    };

    const unitId = computed(() => props.data.unitId);

    return {
      step,
      stepper,
      tabsLength,
      unitSearch,
      unitLoading,
      units,
      updateUnit,
      hasPermission,
      unitId,
    };
  },
});
</script>
