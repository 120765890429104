import {
  TagOverviewQueryVariables,
  CreateTagMutationVariables,
  DeleteTagMutationVariables,
  UpdateTagMutationVariables,
} from "../types/graphql.types";
import client from "./index";
import { hashids } from "./hashids.service";

export const useTag = () => {
  return {
    fetchAll: async (variables: TagOverviewQueryVariables) => {
      const { findTags } = await client.TagOverview(variables);

      return {
        result: findTags,
      };
    },
    fetchById: async (id: string | number) => {
      if (typeof id === "string") {
        const [parsed] = hashids.decode(id) as number[];
        id = parsed;
      }

      const { findTagById } = await client.TagById({
        id,
      });

      return {
        result: findTagById,
      };
    },
    create: async (variables: CreateTagMutationVariables) => {
      const { createTag } = await client.CreateTag(variables);

      return { result: createTag };
    },
    update: async (variables: UpdateTagMutationVariables) => {
      const { updateTag } = await client.UpdateTag(variables);

      return { result: updateTag };
    },
    delete: async (variables: DeleteTagMutationVariables) => {
      const { deleteTag } = await client.DeleteTag(variables);

      return { result: deleteTag };
    },
  };
};
