import {
  CountDocumentsQueryVariables,
  DocumentOverviewQueryVariables,
  CreateDocumentMutationVariables,
  DeleteDocumentMutationVariables,
  UpdateDocumentMutationVariables,
} from "../types/graphql.types";
import client from "./index";
import { hashids } from "./hashids.service";

export const useDokument = () => {
  return {
    fetchAll: async (variables: DocumentOverviewQueryVariables) => {
      const { findDocuments, countDocuments } = await client.DocumentOverview(
        variables
      );

      return {
        result: findDocuments,
        count: countDocuments,
      };
    },
    count: async (variables: CountDocumentsQueryVariables) => {
      const { countDocuments } = await client.CountDocuments(variables);

      return {
        result: countDocuments,
      };
    },
    fetchById: async (id: string | number) => {
      if (typeof id === "string") {
        const [parsed] = hashids.decode(id) as number[];
        id = parsed;
      }

      const { findDocumentById } = await client.DocumentById({
        id,
      });

      return {
        result: findDocumentById,
      };
    },
    create: async (variables: CreateDocumentMutationVariables) => {
      const { createDocument } = await client.CreateDocument(variables);

      return { result: createDocument };
    },
    update: async (variables: UpdateDocumentMutationVariables) => {
      const { updateDocument } = await client.UpdateDocument(variables);

      return { result: updateDocument };
    },
    delete: async (variables: DeleteDocumentMutationVariables) => {
      const { deleteDocument } = await client.DeleteDocument(variables);

      return { result: deleteDocument };
    },
  };
};
