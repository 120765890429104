<template>
  <div>
    <div class="text-h6 text-bold text-grey-9">
      {{ $t(`${protokoll ? "protokoll" : "dokument"}.name`, 2) }}
    </div>
    <div class="row q-gutter-x-md q-mt-md">
      <div class="col q-gutter-y-md">
        <q-file
          :label="$t(`${protokoll ? 'protokoll' : 'dokument'}.name`, 2)"
          :model-value="files"
          outlined
          dense
          multiple
          counter
          :filter="filter"
          :disable="!data.unitId"
          @update:model-value="uploadFiles"
        >
          <template #before>
            <q-icon name="mdi-paperclip" />
          </template>
          <template #file="{ file }">
            <q-chip :color="creating === file.name ? 'grey-5' : 'green-4'">
              <q-avatar>
                <q-spinner v-if="creating === file.name" size="18px" />
                <q-icon v-else name="mdi-check" size="20px" />
              </q-avatar>
              {{ file.name }}
            </q-chip>
          </template>
        </q-file>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useDokument } from "src/services/dokument.service";
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true,
    },
    relations: {
      type: Object,
      default: () => {},
    },
    protokoll: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const api = useDokument();
    const files = ref<any[]>([
      ...(props.data.dokumente || []).map((d: any) => d.file),
    ]);
    const creating = ref<string | null>(null);

    const uploadFiles = async (newFiles: File[]) => {
      for (const file of newFiles) {
        files.value = [...files.value, file];
        creating.value = file.name;

        const { result } = await api.create({
          object: {
            object: {
              unitId: props.data.unitId,
              name: file.name,
              size: file.size,
              protokoll: props.protokoll,
            },
          },
        });

        await fetch(result.uploadUrl, {
          method: "PUT",
          body: file,
        });

        if (props.relations && Object.values(props.relations)) {
          const { result: updateResult } = await api.update({
            id: result.id,
            object: {
              relations: {
                ...props.relations,
              },
            },
          });

          updateDokumente("dokumente", [
            ...(props.data.dokumente || []),
            updateResult,
          ]);
        }

        updateDokumente("dokumente", [...(props.data.dokumente || []), result]);
        creating.value = null;
      }
    };

    const updateDokumente = (origin: string, val: any[]) => {
      emit("updateVariables", { origin, val: val.map((v) => v.id) });
      emit("update:data", { ...props.data, [origin]: val });
    };

    const filter = (newFiles: File[]) =>
      newFiles.filter((file) => !files.value.includes(file));

    return {
      files,
      filter,
      creating,
      uploadFiles,
    };
  },
});
</script>
