import {
  CountGefahrstoffeQueryVariables,
  GefahrstoffOverviewQueryVariables,
  CreateGefahrstoffMutationVariables,
  DeleteGefahrstoffMutationVariables,
  UpdateGefahrstoffMutationVariables,
  ExportGefahrstoffeMutationVariables,
} from "../types/graphql.types";
import client from "./index";
import { hashids } from "./hashids.service";

export const useGefahrstoff = () => {
  return {
    fetchAll: async (variables: GefahrstoffOverviewQueryVariables) => {
      const { findGefahrstoffe, countGefahrstoffe } =
        await client.GefahrstoffOverview(variables);

      return {
        result: findGefahrstoffe,
        count: countGefahrstoffe,
      };
    },
    count: async (variables: CountGefahrstoffeQueryVariables) => {
      const { countGefahrstoffe } = await client.CountGefahrstoffe(variables);

      return {
        result: countGefahrstoffe,
      };
    },
    fetchById: async (id: string | number) => {
      if (typeof id === "string") {
        const [parsed] = hashids.decode(id) as number[];
        id = parsed;
      }

      const { findGefahrstoffById } = await client.GefahrstoffById({
        id,
      });

      return {
        result: findGefahrstoffById,
      };
    },
    create: async (variables: CreateGefahrstoffMutationVariables) => {
      const { createGefahrstoff } = await client.CreateGefahrstoff(variables);

      return { result: createGefahrstoff };
    },
    update: async (variables: UpdateGefahrstoffMutationVariables) => {
      const { updateGefahrstoff } = await client.UpdateGefahrstoff(variables);

      return { result: updateGefahrstoff };
    },
    delete: async (variables: DeleteGefahrstoffMutationVariables) => {
      const { deleteGefahrstoff } = await client.DeleteGefahrstoff(variables);

      return { result: deleteGefahrstoff };
    },
    export: async (variables: ExportGefahrstoffeMutationVariables) => {
      const { exportGefahrstoffe } = await client.ExportGefahrstoffe(variables)

      return { result: exportGefahrstoffe }
    }
  };
};
