<template>
  <q-btn
    class="rounded-borders"
    outline
    :color="currentStatus.color"
    dense
    size="12px"
  >
    <q-icon :name="currentStatus.icon" size="18px" />
    <q-tooltip anchor="center right" self="center left" :offset="[10, 10]">{{
      $t(`status.${currentStatus.val}`)
    }}</q-tooltip>
  </q-btn>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    props: {
      type: Object,
      required: true,
    },
    statusList: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const currentStatus = computed(
      () => props.statusList[props.props.row.status!]
    );

    return {
      currentStatus,
    };
  },
});
</script>
