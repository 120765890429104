<template>
  <div>
    <q-tabs v-model="tab" no-caps dense>
      <q-tab name="invite" :label="$t('verwaltung.nutzer.invite')" />
      <q-tab name="invites" :label="$t('verwaltung.nutzer.viewInvites')" />
    </q-tabs>
    <q-tab-panels :model-value="tab" animated>
      <q-tab-panel name="invite">
        <div class="q-mt-md">
          <div class="text-grey-9 text-bold">
            {{ $t("verwaltung.nutzer.firstStep") }}
          </div>
          <q-separator class="q-my-md" />
          <div class="row items-center no-wrap" style="max-width: 800px">
            <q-input
              v-model.trim="email"
              class="full-width"
              placeholder="Hier weitere E-Mails hinzufügen"
              outlined
              dense
              @keydown.exact.space="addEmail"
              @keydown.enter="addEmail"
            >
              <template #before>
                <q-icon name="mdi-email" />
              </template>
              <template #append>
                <q-btn
                  :disable="!email"
                  dense
                  :label="$t('input.hinzufuegen')"
                  color="primary"
                  no-caps
                  @click="addEmail"
                />
              </template>
            </q-input>
          </div>
          <q-select
            v-model="emails"
            style="max-width: 800px"
            outlined
            dense
            multiple
            counter
            hide-dropdown-icon
            readonly
            class="q-mt-md"
          >
            <template #before>
              <q-icon name="mdi-plus" />
            </template>
            <template #selected-item="scope">
              <q-chip
                outline
                removable
                @remove="scope.removeAtIndex(scope.index)"
                text-color="black"
                class="q-my-none q-ma-xs"
              >
                <q-avatar color="primary" text-color="white" size="22px">
                  <q-icon name="mdi-email" size="14px" />
                </q-avatar>
                {{ scope.opt }}
              </q-chip>
            </template>
          </q-select>
          <q-separator class="q-my-md" />
          <div class="text-grey-9 text-bold q-my-lg">
            {{ $t("verwaltung.nutzer.secondStep") }}
          </div>
          <q-input
            dense
            outlined
            v-model="search"
            clearable
            :placeholder="$t('input.suche')"
            style="min-width: 250px; max-width: 400px"
          >
            <template #prepend>
              <q-icon name="mdi-magnify" />
            </template>
          </q-input>
          <q-separator class="q-my-md" />
          <q-virtual-scroll
            v-if="displayUnits.length"
            class="q-mt-md"
            :items="displayUnits"
            style="max-height: 250px; max-width: 800px"
            separator
          >
            <template v-slot="{ item }">
              <q-item :key="item.id" tag="label">
                <q-item-section avatar>
                  <q-checkbox
                    v-model="selected"
                    :val="item.id"
                    color="blue"
                    dense
                  />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ item.name || "-" }}</q-item-label>
                  <q-item-label caption>{{ item.ort || "-" }}</q-item-label>
                </q-item-section>
              </q-item>
            </template>
          </q-virtual-scroll>
          <div v-else class="text-grey-9 text-bold row items-center">
            Es konnten keine Standorte gefunden werden!
          </div>
          <div class="text-grey-9 text-bold q-my-lg">
            {{ $t("verwaltung.nutzer.thirdStep") }}
          </div>
          <q-markup-table
            class="q-mt-md"
            style="max-width: 800px"
            separator="cell"
            flat
            bordered
          >
            <thead>
              <tr>
                <th class="text-left">
                  {{ $t("verwaltung.berechtigungen.module") }}
                </th>
                <th class="text-left">
                  {{ $t("verwaltung.berechtigungen.read") }}
                </th>
                <th class="text-left">
                  {{ $t("verwaltung.berechtigungen.write") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="scope in scopes" :key="scope.module">
                <td>{{ $t(`${scope.module}.name`) }}</td>
                <td>
                  <q-checkbox dense v-model="scope.read" color="blue" />
                </td>
                <td>
                  <q-checkbox dense v-model="scope.write" color="blue" />
                </td>
              </tr>
            </tbody>
          </q-markup-table>
          <q-separator class="q-my-md" />
          <q-btn
            outline
            :disable="!(emails.length && selected.length)"
            color="primary"
            class="q-mt-lg"
            no-caps
            :loading="inviting"
            @click="inviteUser"
          >
            <q-icon name="mdi-plus" left />{{ $t("verwaltung.nutzer.invite") }}
          </q-btn>
        </div>
      </q-tab-panel>
      <q-tab-panel name="invites">
        <q-table
          :title="$t('verwaltung.nutzer.viewInvites')"
          :rows="invites"
          :columns="columns"
          flat
        >
          <template v-slot:body-cell-status="props">
            <q-td :props="props">
              <q-btn
                class="rounded-borders q-mr-md"
                outline
                :color="currentStatus(props.row.status).color"
                dense
                size="12px"
                @click.stop
              >
                <q-icon
                  :name="currentStatus(props.row.status).icon"
                  size="18px"
                />
              </q-btn>
              {{ currentStatus(props.row.status).label }}
            </q-td>
          </template>
          <template v-slot:body-cell-inviteSent="props">
            <q-td :props="props">
              <ams-format-date
                dateString="DD.MM.YYYY HH:mm"
                :date="props.row.inviteSent"
              />
            </q-td>
          </template>
          <template v-slot:body-cell-inviteAccepted="props">
            <q-td :props="props">
              <ams-format-date
                dateString="DD.MM.YYYY HH:mm"
                :date="props.row.inviteAccepted"
              />
            </q-td>
          </template>
        </q-table>
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";
import { Notify, type QTableProps } from "quasar";
import { useStore } from "../../store";
import {
  betriebsanweisungenModel,
  betriebsmittelModel,
  gefaehrdungsbeurteilungenModel,
  gefahrstoffeModel,
  roleModel,
  verbandbuchModel,
} from "../../data";
import { serializeToUrn } from "@ams-pro/scope-utils";
import client from "../../services";
import { searchKey } from "src/utils";
import { GetUnitsQuery } from "@/types/graphql.types";

const columns: QTableProps["columns"] = [
  {
    name: "status",
    required: true,
    label: "Status",
    align: "left",
    field: "status",
    sortable: true,
  },
  {
    name: "inviteSent",
    required: true,
    label: "Gesendet am",
    align: "left",
    field: "inviteSent",
    sortable: true,
  },
  {
    name: "inviteAccepted",
    required: true,
    label: "Akzeptiert am",
    align: "left",
    field: "inviteAccepted",
    sortable: true,
  },
  {
    name: "email",
    required: true,
    label: "Empfänger E-Mail",
    align: "left",
    field: "email",
    sortable: true,
  },
];

const roleModules = [
  roleModel,
  betriebsmittelModel,
  gefahrstoffeModel,
  gefaehrdungsbeurteilungenModel,
  betriebsanweisungenModel,
  verbandbuchModel,
];

const store = useStore();
const tab = ref<string>("invite");

const email = ref<string>("");
const emails = ref<string[]>([]);
const selected = ref<string[]>([]);
const search = ref<string>("");
const inviting = ref(false);

const scopes = ref<any>(
  roleModules.map((r) => ({
    module: r.ITEM_KEY,
    label: r.PLURAL,
    read: r.ITEM_KEY !== verbandbuchModel.ITEM_KEY,
    write: false,
  }))
);

const units = ref<GetUnitsQuery["units"]>([]);

const fetchUnitResult = async () => {
  const res = await client.GetUnits();
  units.value = res.units;
};

fetchUnitResult();

const invites = computed(() => {
  if (units.value) {
    return units.value
      .filter((unit) => store.currentUnitIds.includes(unit.id))
      .flatMap((unit) => unit.invites || []);
  }

  return [];
});

const currentStatus = (status: string) => {
  switch (status) {
    case "offen":
      return {
        label: "Offen",
        icon: "mdi-alert-outline",
        color: "orange",
      };
    // aufgrund von rechtschreibfehler im backend
    case "aktzeptiert":
      return {
        label: "Akzeptiert",
        icon: "mdi-check-outline",
        color: "green",
      };
    case "akzeptiert":
      return {
        label: "Akzeptiert",
        icon: "mdi-check-outline",
        color: "green",
      };
    default:
      return {
        label: "Unbekannt",
        icon: "mdi-close",
        color: "grey-7",
      };
  }
};

const addEmail = () => {
  if (/^\S+@\S+\.\S+$/.test(email.value)) {
    if (!emails.value.includes(email.value)) {
      emails.value.push(email.value);
      email.value = "";
    } else {
      Notify.create({
        type: "ams-warning",
        message: "E-Mail bereits hinzugefügt!",
      });
    }
  } else {
    Notify.create({
      type: "ams-warning",
      message: "Ungültige E-Mail!",
    });
  }
};

const inviteUser = async () => {
  try {
    inviting.value = true;

    const serializedScopes = serializeToUrn(
      scopes.value.map((s: any) => ({
        module: s.module,
        read: s.read,
        write: s.write,
      }))
    )
      .split(" ")
      .map((s) => (s.slice(-1) === ":" ? `${s}null` : s));

    await client.InviteUserForUnit({
      inviteObject: {
        emails: emails.value,
        unitIds: selected.value,
        scopes: serializedScopes,
      },
    });

    emails.value = [];
    selected.value = [];

    Notify.create({
      type: "ams-success",
      message: "E-Mails werden versendet!",
      caption: "Die kann einige Minuten dauern",
    });
  } catch {
    Notify.create({
      type: "ams-error",
      message: "Einladen der Nutzer fehlgeschlagen!",
    });
  } finally {
    inviting.value = false;
  }
};

const displayUnits = computed(() =>
  searchKey(search.value, "name", units.value)
);
</script>
