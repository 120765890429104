import {
  CountBetriebsanweisungenQueryVariables,
  BetriebsanweisungOverviewQueryVariables,
  CreateBetriebsanweisungMutationVariables,
  DeleteBetriebsanweisungMutationVariables,
  UpdateBetriebsanweisungMutationVariables,
  ExportBetriebsanweisungenMutationVariables,
} from "../types/graphql.types";
import client from "./index";
import { hashids } from "./hashids.service";

export const useBetriebsanweisung = () => {
  return {
    fetchAll: async (variables: BetriebsanweisungOverviewQueryVariables) => {
      const { findBetriebsanweisungen, countBetriebsanweisungen } =
        await client.BetriebsanweisungOverview(variables);

      return {
        result: findBetriebsanweisungen,
        count: countBetriebsanweisungen,
      };
    },
    count: async (variables: CountBetriebsanweisungenQueryVariables) => {
      const { countBetriebsanweisungen } =
        await client.CountBetriebsanweisungen(variables);

      return {
        result: countBetriebsanweisungen,
      };
    },
    fetchById: async (id: string | number) => {
      if (typeof id === "string") {
        const [parsed] = hashids.decode(id) as number[];
        id = parsed;
      }

      const { findBetriebsanweisungById } = await client.BetriebsanweisungById({
        id,
      });

      return {
        result: findBetriebsanweisungById,
      };
    },
    create: async (variables: CreateBetriebsanweisungMutationVariables) => {
      const { createBetriebsanweisung } = await client.CreateBetriebsanweisung(
        variables
      );

      return { result: createBetriebsanweisung };
    },
    update: async (variables: UpdateBetriebsanweisungMutationVariables) => {
      const { updateBetriebsanweisung } = await client.UpdateBetriebsanweisung(
        variables
      );

      return { result: updateBetriebsanweisung };
    },
    delete: async (variables: DeleteBetriebsanweisungMutationVariables) => {
      const { deleteBetriebsanweisung } = await client.DeleteBetriebsanweisung(
        variables
      );

      return { result: deleteBetriebsanweisung };
    },
    export: async (variables: ExportBetriebsanweisungenMutationVariables) => {
      const { exportBetriebsanweisungen } = await client.ExportBetriebsanweisungen(variables)
      return { result: exportBetriebsanweisungen }
    }
  };
};
