import { isTokenExpired } from "../router/guards";
import { useStore } from "../store";
import { AUTH_TOKEN, REFRESH_TOKEN } from "../utils/constants";
import { GraphQLClient, RequestMiddleware } from "graphql-request";
import { LocalStorage } from "quasar";
import { getSdk } from "../types/graphql.types";
import { IAuth } from "src/data/views/types.data";

export async function refreshAuthIfNeeded(
  currentToken: string
): Promise<string> {
  if (currentToken && !isTokenExpired(currentToken)) {
    return currentToken;
  }

  const token = LocalStorage.getItem(REFRESH_TOKEN) as string;

  if (isTokenExpired(token)) {
    return "";
  }

  const res = await fetch(`/auth/login`, {
    method: "POST",
    headers: { authorization: `Bearer ${token}` },
  });

  const store = useStore();
  if (!res.ok) {
    store.clearAuth();
    return "";
  }

  const payload = await res.json();

  store.setAuth(payload);

  return payload.id_token;
}

const requestMiddleware: RequestMiddleware = async (request) => {
  const auth = LocalStorage.getItem(AUTH_TOKEN) as IAuth;

  if (!auth) {
    return request
  }

  let token = auth.id_token;
  token = await refreshAuthIfNeeded(token);

  if (!token) {
    return request
  }

  return {
    ...request,
    headers: {
      ...request.headers,
      Authorization: `Bearer ${token}`,
    },
  };

};

const client = getSdk(
  new GraphQLClient("/graphql", {
    requestMiddleware,
  })
);

export default client;
