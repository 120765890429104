<template>
  <q-select
    :label="label"
    color="primary"
    outlined
    dense
    :model-value="value"
    :options="options"
    @update:model-value="$emit('input', $event)"
  >
    <template #before>
      <q-icon :name="icon" />
    </template>
  </q-select>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
});
</script>
