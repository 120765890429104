import { IColumnDefinition, IStatusDefinition } from "./types.data";
import GefahrstoffTabWrapper from "../../components/Gefahrstoffe/tabs/TabWrapper.vue";
import GefahrstoffeCellStatus from "../../components/Gefahrstoffe/cells/gefahrstoff-cell-status.vue";
import GefahrstoffeCellZusammenlagerung from "../../components/Gefahrstoffe/cells/gefahrstoff-cell-zusammenlagerung.vue";
import GefahrstoffeCellName from "../../components/Gefahrstoffe/cells/gefahrstoff-cell-name.vue";
import GefahrstoffCellKategorien from "../../components/Gefahrstoffe/cells/gefahrstoff-cell-kategorien.vue";
import GefahrstoffeCellVerwendungszweck from "../../components/Gefahrstoffe/cells/gefahrstoff-cell-verwendungszweck.vue";
import GefahrstoffeCellLagerklasse from "../../components/Gefahrstoffe/cells/gefahrstoff-cell-lagerklasse.vue";
import GefahrstoffeCellMenge from "../../components/Gefahrstoffe/cells/gefahrstoff-cell-menge.vue";
import GefahrstoffeCellSignalwort from "../../components/Gefahrstoffe/cells/gefahrstoff-cell-signalwort.vue";
import GefahrstoffeCellAnlagen from "../../components/Gefahrstoffe/cells/gefahrstoff-cell-anlagen.vue";
import GefahrstoffeCellAnmerkungen from "../../components/Gefahrstoffe/cells/gefahrstoff-cell-anmerkungen.vue";
import GefahrstoffeCellLagerraum from "../../components/Gefahrstoffe/cells/gefahrstoff-cell-lagerraum.vue";
import GefahrstoffeCellSubstitutionspruefung from "../../components/Gefahrstoffe/cells/gefahrstoff-cell-substitutionspruefung.vue";
import GefahrstoffeCellHSaetze from "../../components/Gefahrstoffe/cells/gefahrstoff-cell-hsaetze.vue";
import GefahrstoffeCellPSaetze from "../../components/Gefahrstoffe/cells/gefahrstoff-cell-psaetze.vue";
import GefahrstoffeCellPSicherheitsdatenblatt from "../../components/Gefahrstoffe/cells/gefahrstoff-cell-sicherheitsdatenblatt.vue";
import GefahrstoffeCellStandort from "../../components/Gefahrstoffe/cells/gefahrstoff-cell-standort.vue";
import { useGefahrstoff } from "../../services/gefahrstoff.service";

export enum gefahrstoffeModel {
  SINGULAR = "Gefahrstoff",
  PLURAL = "Gefahrstoffe",
  ROUTE = "/gefahrstoffe",
  ICON = "mdi-alert-rhombus-outline",
  GESETZ = "https://www.gesetze-im-internet.de/gefstoffv_2010/index.html",
  ITEM_KEY = "gefahrstoff",
  ITEM_KEY_UPDATE = "gefahrstoffe",
  MODULE = "true",
}

export const defaultGefahrstoffeHeader: string[] = [
  "status",
  "bezeichnung",
  // "kategorien",
  "lagerklasse",
  "zusammenlagerung",
  "signalwort",
  "sicherheitsdatenblatt",
];

export const gefahrstoffeHeader: IColumnDefinition<any>[] = [
  {
    name: "status",
    label: "Status",
    field: "status",
    required: false,
    align: "left",
    sortable: true,
    cell: GefahrstoffeCellStatus,
  },
  {
    name: "bezeichnung",
    label: "Bezeichnung",
    field: "name",
    required: false,
    align: "left",
    sortable: true,
    cell: GefahrstoffeCellName,
  },
  {
    name: "kategorien",
    label: "Kategorien",
    field: "kategorien",
    required: false,
    align: "left",
    sortable: false,
    cell: GefahrstoffCellKategorien,
  },
  {
    name: "verwendungszweck",
    label: "Verwendungszweck",
    field: "zweck",
    required: false,
    align: "left",
    sortable: true,
    cell: GefahrstoffeCellVerwendungszweck,
  },
  {
    name: "lagerklasse",
    label: "Lagerklasse",
    field: "lagerklasse",
    required: false,
    align: "left",
    sortable: true,
    cell: GefahrstoffeCellLagerklasse,
  },
  {
    name: "zusammenlagerung",
    label: "Zusammenlagerung",
    field: "zusammenlagerung",
    required: false,
    align: "left",
    sortable: true,
    cell: GefahrstoffeCellZusammenlagerung,
  },
  {
    name: "menge",
    label: "Menge",
    field: "menge",
    required: false,
    align: "left",
    sortable: true,
    cell: GefahrstoffeCellMenge,
  },
  {
    name: "signalwort",
    label: "Signalwort",
    field: "signalwort",
    required: false,
    align: "left",
    sortable: true,
    cell: GefahrstoffeCellSignalwort,
  },
  {
    name: "sicherheitsdatenblatt",
    label: "Sicherheitsdatenblatt",
    required: false,
    align: "left",
    sortable: false,
    cell: GefahrstoffeCellPSicherheitsdatenblatt,
  },
  {
    name: "anlagen",
    label: "Anlagen",
    required: false,
    align: "left",
    sortable: false,
    cell: GefahrstoffeCellAnlagen,
  },
  {
    name: "anmerkungen",
    label: "Anmerkungen",
    field: "anmerkungen",
    required: false,
    align: "left",
    sortable: true,
    cell: GefahrstoffeCellAnmerkungen,
  },
  {
    name: "raum",
    label: "Organisationseinheit",
    field: "raum",
    required: false,
    align: "left",
    sortable: false,
    cell: GefahrstoffeCellLagerraum,
  },
  {
    name: "letzteSubstitutionspruefung",
    label: "Letzte Substitutionsprüfung",
    field: "letzteSubstitutionspruefung",
    required: false,
    align: "left",
    sortable: true,
    cell: GefahrstoffeCellSubstitutionspruefung,
  },
  {
    name: "hsaetze",
    label: "H-Sätze",
    field: "hsaetze",
    required: false,
    align: "left",
    sortable: false,
    cell: GefahrstoffeCellHSaetze,
  },
  {
    name: "psaetze",
    label: "P-Sätze",
    field: "psaetze",
    required: false,
    align: "left",
    sortable: false,
    cell: GefahrstoffeCellPSaetze,
  },
  {
    name: "standort",
    label: "Standort",
    required: false,
    align: "left",
    sortable: false,
    cell: GefahrstoffeCellStandort,
  },
];

export enum GefahrstoffStatus {
  FREIGEGEBEN = "Freigegeben",
  BEDINGT_FREIGEGEBEN = "Bedingt freigegeben",
  GESPERRT = "Gesperrt",
  PRUEFUNG_ERFORDERLICH = "Prüfung erforderlich",
  KEIN_ERGEBNIS = "Kein Ergebnis",
}

export const gefahrstoffeStatus: {
  [P in GefahrstoffStatus]: IStatusDefinition<GefahrstoffStatus>;
} = {
  [GefahrstoffStatus.FREIGEGEBEN]: {
    label: GefahrstoffStatus.FREIGEGEBEN,
    icon: "mdi-check-outline",
    color: "green",
    val: GefahrstoffStatus.FREIGEGEBEN,
  },
  [GefahrstoffStatus.BEDINGT_FREIGEGEBEN]: {
    label: GefahrstoffStatus.BEDINGT_FREIGEGEBEN,
    icon: "mdi-alert-outline",
    color: "orange",
    val: GefahrstoffStatus.BEDINGT_FREIGEGEBEN,
  },
  [GefahrstoffStatus.GESPERRT]: {
    label: GefahrstoffStatus.GESPERRT,
    icon: "mdi-alert-circle-outline",
    color: "red",
    val: GefahrstoffStatus.GESPERRT,
  },
  [GefahrstoffStatus.PRUEFUNG_ERFORDERLICH]: {
    label: GefahrstoffStatus.PRUEFUNG_ERFORDERLICH,
    icon: "mdi-clock-outline",
    color: "primary",
    val: GefahrstoffStatus.PRUEFUNG_ERFORDERLICH,
  },
  [GefahrstoffStatus.KEIN_ERGEBNIS]: {
    label: GefahrstoffStatus.KEIN_ERGEBNIS,
    icon: "mdi-close-outline",
    color: "grey",
    val: GefahrstoffStatus.KEIN_ERGEBNIS,
  },
};

export enum GefahrstoffZusammenlagerung {
  ERLAUBT = "Zusammenlagerung erlaubt",
  EINGESCHRÄNKT_ERLAUBT = "Zusammenlagerung eingeschränkt erlaubt",
  SEPARATLAGERUNG_ERFORDERLICH = "Separatlagerung erforderlich",
  UNBEKANNT = "Raum oder Lagerklasse unbekannt",
}

export const gefahrstoffeZusammenlagerung: {
  [P in GefahrstoffZusammenlagerung]: IStatusDefinition<GefahrstoffZusammenlagerung>;
} = {
  [GefahrstoffZusammenlagerung.ERLAUBT]: {
    label: "Zusammenlagerung erlaubt",
    icon: "mdi-check-outline",
    color: "green",
    val: GefahrstoffZusammenlagerung.ERLAUBT,
  },
  [GefahrstoffZusammenlagerung.EINGESCHRÄNKT_ERLAUBT]: {
    label: "Zusammenlagerung eingeschränkt erlaubt",
    icon: "mdi-alert-outline",
    color: "orange",
    val: GefahrstoffZusammenlagerung.EINGESCHRÄNKT_ERLAUBT,
  },
  [GefahrstoffZusammenlagerung.SEPARATLAGERUNG_ERFORDERLICH]: {
    label: "Separatlagerung erforderlich",
    icon: "mdi-alert-circle-outline",
    color: "red",
    val: GefahrstoffZusammenlagerung.SEPARATLAGERUNG_ERFORDERLICH,
  },
  [GefahrstoffZusammenlagerung.UNBEKANNT]: {
    label: "Raum oder Lagerklasse unbekannt",
    icon: "mdi-close-outline",
    color: "grey",
    val: GefahrstoffZusammenlagerung.UNBEKANNT,
  },
};

export const gefahrstoffSignalwoerter: string[] = ["ACHTUNG", "GEFAHR"];

export const gefahrstoffLagerklassen = [
  {
    label: "1",
    caption: "Explosive Stoffe",
  },
  {
    label: "2A",
    caption:
      "Verdichtete, verfl\u00fcssigte oder unter Druck gel\u00f6ste Gase",
  },
  {
    label: "2B",
    caption: "Druckgaspackungen (Aerosoldosen/Spraydosen)",
  },
  {
    label: "3",
    caption: "Entz\u00fcndbare Fl\u00fcssigkeiten (Flammpunkt bis 55 \u00b0C)",
  },
  {
    label: "4.1A",
    caption: "Sonstige explosionsgef\u00e4hrliche Gefahrstoffe",
  },
  {
    label: "4.1B",
    caption: "Entz\u00fcndbare feste Gefahrstoffe",
  },
  {
    label: "4.2",
    caption: "Pyrophore oder selbsterhitzungsf\u00e4hige Gefahrstoffe",
  },
  {
    label: "4.3",
    caption:
      "Gefahrstoffe, die in Ber\u00fchrung mit Wasser entz\u00fcndliche Gase bilden",
  },
  {
    label: "5.1A",
    caption: "Stark oxidierende Gefahrstoffe",
  },
  {
    label: "5.1B",
    caption: "Oxidierende Gefahrstoffe",
  },
  {
    label: "5.1C",
    caption:
      "Entz\u00fcndend wirkende Produkte, die in den Gruppen A bis C der TRGS 511 genannt sind.",
  },
  {
    label: "5.2",
    caption: "Organische Peroxide und selbstzersetzliche Gefahrstoffe",
  },
  {
    label: "6.1A",
    caption:
      "Brennbare, akut toxische Kat. 1 und 2 / sehr giftige Gefahrstoffe",
  },
  {
    label: "6.1B",
    caption:
      "Nicht brennbare, akut toxische Kat. 1 und 2/ sehr giftige Gefahrstoffe",
  },
  {
    label: "6.1C",
    caption:
      "Brennbare, akut toxische Kat. 3 / giftige oder chronisch wirkende Gefahrstoffe",
  },
  {
    label: "6.1D",
    caption:
      "Nicht brennbare, akut toxische Kat. 3 / giftige oder chronisch wirkende Gefahrstoffe",
  },
  {
    label: "6.2",
    caption: "Ansteckungsgef\u00e4hrliche Stoffe",
  },
  {
    label: "7",
    caption: "Radioaktive Stoffe",
  },
  {
    label: "8A",
    caption: "Brennbare \u00e4tzende Gefahrstoffe",
  },
  {
    label: "8B",
    caption: "Nicht brennbare \u00e4tzende Gefahrstoffe",
  },
  {
    label: "10",
    caption: "Brennbare Fl\u00fcssigkeiten soweit nicht LGK 3",
  },
  {
    label: "11",
    caption: "Brennbare Feststoffe",
  },
  {
    label: "12",
    caption: "Nicht brandgef\u00e4hrliche Fl\u00fcssigkeiten",
  },
  {
    label: "13",
    caption: "Nicht brandgef\u00e4hrliche Feststoffe",
  },
];

export const gefahrstoffeMengeneinheiten: string[] = [
  "Sonstiges",
  "Stk.",
  "mm",
  "cm",
  "m",
  "mg",
  "g",
  "kg",
  "t",
  "ml",
  "l",
];

export const gefahrstoffData = {
  model: gefahrstoffeModel,
  api: useGefahrstoff(),
  header: gefahrstoffeHeader,
  defaultHeader: defaultGefahrstoffeHeader,
  tabWrapper: GefahrstoffTabWrapper,
};
