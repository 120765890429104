import { IColumnDefinition } from "./types.data";
import SicherheitsdatenblattCellName from "../../components/Sicherheitsdatenblaetter/cells/sicherheitsdatenblatt-cell-name.vue";
import SicherheitsdatenblattCellDokumente from "../../components/Sicherheitsdatenblaetter/cells/sicherheitsdatenblatt-cell-dokumente.vue";
import SicherheitsdatenblattCellStandort from "../../components/Sicherheitsdatenblaetter/cells/sicherheitsdatenblatt-cell-standort.vue";
import { useSicherheitsdatenblatt } from "../../services/sicherheitsdatenblatt.service";
import SicherheitsdatenblattTabWrapper from "../../components/Sicherheitsdatenblaetter/tabs/TabWrapper.vue";

export enum sicherheitsdatenblaetterModel {
  SINGULAR = "Sicherheitsdatenblatt",
  PLURAL = "Sicherheitsdatenblätter",
  ROUTE = "/sicherheitsdatenblaetter",
  ICON = "mdi-file-chart-outline",
  ITEM_KEY = "sicherheitsdatenblatt",
  ITEM_KEY_UPDATE = "sicherheitsdatenblaetter",
}

export const defaultSicherheitsdatenblattHeader: string[] = [
  "bezeichnung",
  "dokumente",
];

export const sicherheitsdatenblattHeader: IColumnDefinition<any>[] = [
  {
    name: "bezeichnung",
    label: "Bezeichnung",
    field: "name",
    required: false,
    align: "left",
    sortable: true,
    cell: SicherheitsdatenblattCellName,
  },
  {
    name: "dokumente",
    label: "Dokumente",
    field: "dokumente",
    required: false,
    align: "left",
    sortable: false,
    cell: SicherheitsdatenblattCellDokumente,
  },
  {
    name: "standort",
    label: "Standort",
    required: false,
    align: "left",
    sortable: false,
    cell: SicherheitsdatenblattCellStandort,
  },
];

export const sicherheitsdatenblattData = {
  model: sicherheitsdatenblaetterModel,
  api: useSicherheitsdatenblatt(),
  header: sicherheitsdatenblattHeader,
  defaultHeader: defaultSicherheitsdatenblattHeader,
  tabWrapper: SicherheitsdatenblattTabWrapper,
};
