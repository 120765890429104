<template>
  <q-td :props="props" :key="colKey">
    <ams-base-cell-name :props="props" />
  </q-td>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseCellName from "../../../components/BaseCells/BaseCellName.vue";

export default defineComponent({
  components: {
    "ams-base-cell-name": BaseCellName,
  },
  props: {
    props: {
      type: Object,
      required: true,
    },
    colKey: {
      type: String,
      required: true,
    },
  },
  setup() {},
});
</script>
