<template>
  <q-td :props="props" :key="colKey">
    <ams-trim :text="raum" :length="40" />
  </q-td>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    props: {
      type: Object,
      required: true,
    },
    colKey: {
      type: String,
      required: true,
    },
  },
  setup({ props }) {
    const raum = computed(() => props.row.raum?.name ?? "-");

    return {
      raum,
    };
  },
});
</script>
