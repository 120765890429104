import {
  betriebsmittelModel,
  gefahrstoffeModel,
  gefaehrdungsbeurteilungenModel,
  betriebsanweisungenModel,
  massnahmenModel,
  verbandbuchModel,
  dokumenteModel,
} from "../data";

export const useIcons = () => {
  return {
    DASHBOARD: "mdi-home-outline",
    BETRIEBSMITTEL: betriebsmittelModel.ICON,
    GEFAHRSTOFF: gefahrstoffeModel.ICON,
    GEFAEHRDUNGSBEURTEILUNG: gefaehrdungsbeurteilungenModel.ICON,
    BETRIEBSANWEISUNG: betriebsanweisungenModel.ICON,
    PRUEFBERICHT: "mdi-clipboard-check",
    SICHERHEITSDATENBLATT: "mdi-clipboard-alert",
    BEDIENUNGSANLEITUNG: "mdi-note-text",
    UNTERWEISUNG: "mdi-folder-search",
    VERBANDBUCH: verbandbuchModel.ICON,
    VERWALTUNG: "mdi-settings-outline",
    STANDORT: "mdi-factory",
    PROFIL: "mdi-account-circle",
    DOKUMENT: dokumenteModel.ICON,
    MODULDATEN: "mdi-database",
    EXPORT: "mdi-folder-download",
    KUNDE: "mdi-account-tie",
    BERICHT: "mdi-file-document-edit",
    SIFA: "mdi-shield-account",
    ANSPRECHPERSON: "mdi-tooltip-account",
    MASSNAHME: massnahmenModel.ICON,
  };
};
