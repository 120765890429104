<template>
  <div>
    <ams-tab-informationen
      :data="data"
      :hasWritePermission="hasWritePermission"
      @update:data="$emit('update:data', $event)"
      @updateInput="$emit('updateInput', $event)"
      @updateVariables="$emit('updateVariables', $event)"
    />
    <ams-tab-gefahr-substitutionspruefung
      class="q-mt-lg"
      :data="data"
      :hasWritePermission="hasWritePermission"
      @update:data="$emit('update:data', $event)"
      @updateInput="$emit('updateInput', $event)"
      @updateVariables="$emit('updateVariables', $event)"
    />
    <div class="text-h6 text-bold text-grey-9 q-mt-lg">
      Sicherheitsdatenblatt
    </div>
    <div class="q-my-sm">
      Fügen Sie das Sicherheitsdatenblatt hinzu, nachdem der Gefahrstoff
      erstellt wurde
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TabInformationen from "./TabInformationen.vue";
import TabGefahrSubstitutionspruefung from "./TabGefahrSubstitutionspruefung.vue";

export default defineComponent({
  components: {
    "ams-tab-informationen": TabInformationen,
    "ams-tab-gefahr-substitutionspruefung": TabGefahrSubstitutionspruefung,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    hasWritePermission: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
