<template>
  <q-td :props="props" :key="colKey">
    <q-btn
      class="rounded-borders"
      outline
      :color="linksAmount ? 'green' : 'red'"
      dense
      size="12px"
      @click.stop
    >
      <q-icon
        :name="linksAmount ? 'mdi-check-outline' : 'mdi-close-outline'"
        size="18px"
      />
      <q-tooltip>{{ `Verknüpfungen (${linksAmount})` }}</q-tooltip>
      <q-menu auto-close>
        <q-list separator style="min-width: 325px">
          <q-item
            v-for="link in links"
            :key="link.model.ITEM_KEY"
            clickable
            @click="route(link)"
          >
            <q-item-section avatar>
              <q-btn
                size="10px"
                round
                outline
                :color="link.amount ? 'green' : 'grey-7'"
              >
                <q-icon size="20px" :name="link.model.ICON" />
              </q-btn>
            </q-item-section>
            <q-item-section
              >{{ $t(`${link.model.ITEM_KEY}.name`, 2) }} ({{
                link.amount
              }})</q-item-section
            >
          </q-item>
        </q-list>
      </q-menu>
    </q-btn>
  </q-td>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRouter } from "vue-router";
import {
  gefaehrdungsbeurteilungenModel,
  betriebsanweisungenModel,
  bedienungsanleitungenModel,
  pruefberichteModel,
  sicherheitsdatenblaetterModel,
  dokumenteModel,
} from "../../../data";
import { hashids } from "../../../services/hashids.service";

export default defineComponent({
  props: {
    props: {
      type: Object,
      required: true,
    },
    colKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();

    const links = computed(() => [
      {
        model: gefaehrdungsbeurteilungenModel,
        amount: props.props.row.gefaehrdungsbeurteilungen?.length ?? 0,
      },
      {
        model: betriebsanweisungenModel,
        amount: props.props.row.betriebsanweisungen?.length ?? 0,
      },
      {
        model: bedienungsanleitungenModel,
        amount: props.props.row.bedienungsanleitungen?.length ?? 0,
      },
      {
        model: pruefberichteModel,
        amount: props.props.row.pruefberichte?.length ?? 0,
      },
      {
        model: sicherheitsdatenblaetterModel,
        amount: props.props.row.sicherheitsdatenblaetter?.length ?? 0,
      },
    ]);

    const linksAmount = computed<number>(() =>
      links.value.reduce((a, b) => a + (b.amount || 0), 0)
    );

    const route = (anlage: any) =>
      router.push(
        `${dokumenteModel.ROUTE}/${hashids.encode(props.props.row.id)}/${
          anlage.model.ITEM_KEY_UPDATE
        }`
      );

    return {
      links,
      linksAmount,
      route,
    };
  },
});
</script>
