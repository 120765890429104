import hsaetze from "./hsaetze.json";
import psaetze from "./psaetze.json";
import lagerklassen from "./lagerklassen.json";

export default {
  input: {
    suche: "Search",
    abbrechen: "Cancel",
    schliessen: "Close",
    speichern: "Save",
    archivieren: "Archive",
    wiederherstellen: "Restore",
    loeschen: "Delete",
    unwiderruflichLoeschen: "Permanently delete",
    aktualisieren: "Update",
    erstellen: "Create",
    auswaehlen: "Select",
    bearbeiten: "Edit",
    hinzufuegen: "Add",
    zurzeitArchiviert: "Currently archived",
    monat: "month | months",
    automatischGeneriert: "automatically generated",
    imTabOeffnen: "Open {0} in new tab",
    herunterladen: "Download",
    hochladen: "Upload",
    hinterlegt: "Added",
    nichtHinterlegt: "Not added",
    verknuepft: "Linked",
    zurueck: "Back",
    weiter: "Next",
    absenden: "Send",
    oeffnen: "Open",
    uhrzeit: "Time",
    aktualisierenFehlgeschlagen: "Update failed",
  },
  drawer: {
    weitereAnlagen: "Additional Modules",
  },
  verbandbuch: {
    name: "Accident Report | Accident Reports",
    erstellen: "Create @:verbandbuch.name",
    archivieren: "Archive @:verbandbuch.name",
    wiederherstellen: "Restore @:verbandbuch.name",
    loeschen: "Delete @:verbandbuch.name",
    teilen: "Share @:verbandbuch.name",
    exportieren: "Export @:verbandbuch.name",
    bezeichnung: "Name",
    eintrag: "Accident Report Entry | Accident Report Entries",
    standort: "Unit",
    benachrichtigende: "To be notified",
    benachrichtigendePersonen: "To be notified Persons",
    aushang: "Accident Report Notice",
    aushangHerunterladen: "Download Accident Report Notice in PDF format",
    informationen: "Accident Report information",
    urlBearbeiten:
      "Edit the public URL here where Accident Report Entries can be created from",
    scanQRCode:
      "Scan this QR-Code and follow the URL to create Accident Report Entries",
    verletzter: "Injured Person",
    unfallzeitpunkt: "Time of accident",
    unfallort: "Place of accident",
    arbeitsbereich: "Workspace",
    artDerVerletzung: "Type of injury",
    hergang: "Process of injury",
    umfangDerVerletzung: "Extend of injury",
    namenDerZeugen: "Names of witnesses",
    ersteHilfeZeitpunkt: "Time of first aid",
    namenDerErsthelfer: "Names of first aider",
    ersteHilfeMassnahmen: "Type and extend of first aid",
    ersteHilfeLeistungen: "First aid",
    action: "Action",
    aenderungshistorie: "Change history",
    besitztRevisionen:
      "This Accident Report Entry contains the following Revisions",
    revision: "Revision | Revisions",
    versionVom: "Version from",
    aktuelleVersion: "Current Version",
    aktuellerStand: "This is the current state of the Revision",
    angabenUnfallhergang:
      "Information about the accident and the state of health",
    orange:
      'All changes to the current version are marked with <span class="text-orange-14 q-mx-xs">orange</span>',
    alleFelder: "All fields are required",
    eintragErstellen: "Create Accident Report Entry",
    erstellenText: "Select the unit first and then the Accident Report",
    eintragType: "Type",
    accident: "Accident",
    nearmiss: "Near Miss",
  },
  eintrag: {
    name: "Accident Report Entry | Accident Report Entries",
    erstellen: "Create @:eintrag.name",
    archivieren: "Archive @:eintrag.name",
    wiederherstellen: "Restore @:eintrag.name",
    loeschen: "Delete @:eintrag.name",
    treffer: "Accident Report Entries ({count} results)",
    trefferArchiviert: "Archived Accident Report Entries ({count} results)",
    filter: "Filter Entries",
  },
  dashboard: {
    name: "Dashboard",
    laden: "Loading dashboard...",
    fehler: "Error while loading the dashboard",
    daten: "Data could not be loaded",
    erneutLaden: "Reload",
    donutchart: "Donut Chart View",
    barchart: "Bar Chart View",
  },
  filter: {
    text: "Filter {0}",
    verfuegbar: "{count} filters available",
    zuruecksetzen: "Reset all filters",
    archiviert: "Archived",
    keineOptionen: "No options found",
    ab: "From",
    von: "From",
    bis: "To",
    schnellsuche: "Quicksearch",
    monat: "{count} months | {count} month | {count} months",
    faelligkeit: "Due date",
    gefahren: "Hazards",
  },
  status: {
    "Keine Mängel": "No defects",
    "Geringe Mängel": "Minor defects",
    "Erhebliche Mängel": "Substantial defects",
    "Prüfung erforderlich": "Inspection required",
    "Kein Ergebnis": "No result",
    Freigegeben: "Approved",
    "Bedingt freigegeben": "Limited approved", // TODO i18n
    Gesperrt: "Restricted",
    "In Arbeit": "In progress",
    Offen: "Open",
    "Zusammenlagerung erlaubt": "Joint storage allowed",
    "Zusammenlagerung eingeschränkt erlaubt":
      "Joint storage allowed with restrictions",
    "Separatlagerung erforderlich": "Separate storage required",
    "Raum oder Lagerklasse unbekannt": "Room or classification unknown",
    ACHTUNG: "DANGER", // TODO i18n
    GEFAHR: "HAZARD", // TODO i18n
  },
  role: {
    name: "Permissions of other Users",
  },
  betriebsmittel: {
    name: "Resource | Resources",
    erstellen: "Create @:betriebsmittel.name",
    archivieren: "Archive @:betriebsmittel.name",
    wiederherstellen: "Restore @:betriebsmittel.name",
    loeschen: "Delete @:betriebsmittel.name",
    teilen: "Share @:betriebsmittel.name",
    exportieren: "Export @:betriebsmittel.name",
    bezeichnung: "Name",
    kategorien: "Categories",
    seriennummer: "Serial number",
    raum: "Room",
    inventarnummer: "Invetory number",
    letztePruefung: "Last inspection",
    pruefintervall: "Inspection interval",
    naechstePruefung: "Next inspection",
    status: "Inspection result",
    anmerkungen: "Notes",
    anlagen: "Attachements",
    standort: "Unit",
    informationen: {
      titel: "Device Information",
      allgemein: "General Resource Information",
      pruefung: "Inspection Information",
    },
  },
  login: {
    amsLogin: "{company} Login",
    signInWithEmail: "Sign In with your Email address",
    email: "EMail",
    password: "Password",
    forgotPassword: "Forgot your password?",
    welcome: "Welcome!",
    noAccount: "You don't have an {company} account? Register now for free!",
    alreadyHaveAccount: "You already have an {company} account? Log in here",
    register: "Register",
    gdpr: "Data protection statement",
    passwordReset: "Reset your password",
    enterYourEmail: "Enter your Email address",
    resetInfo:
      "After you entered your Email adress you will receive further informations per mail",
    resetDescription:
      "It can take a few minutes until you receive the Email. Make sure to als check your spam folder",
  },
  kategorie: {
    name: "Category | Categories",
    erstellen: "Create @:kategorie.name",
    archivieren: "Archive @:kategorie.name",
    loeschen: "Delete @:kategorie.name",
    teilen: "Share @:kategorie.name",
    bearbeiten: "Edit @:kategorie.name",
    bezeichnung: "Name",
  },
  raum: {
    name: "Room | Rooms",
    erstellen: "Create @:raum.name",
    archivieren: "Archive @:raum.name",
    loeschen: "Delete @:raum.name",
    teilen: "Share @:raum.name",
    bearbeiten: "Edit @:raum.name",
    bezeichnung: "Name",
  },
  gefaehrdungsbeurteilungArt: {
    name: "Risk Assessment Type | Risk Assessment Types",
    erstellen: "Create @:gefaehrdungsbeurteilungArt.name",
    archivieren: "Archive @:gefaehrdungsbeurteilungArt.name",
    loeschen: "Delete @:gefaehrdungsbeurteilungArt.name",
    teilen: "Share @:gefaehrdungsbeurteilungArt.name",
    bearbeiten: "Edit @:gefaehrdungsbeurteilungArt.name",
    bezeichnung: "Name",
  },
  betriebsanweisungArt: {
    name: "Operation Instruction Type | Operation Instruction Types",
    erstellen: "Create @:betriebsanweisungArt.name",
    archivieren: "Archive @:betriebsanweisungArt.name",
    loeschen: "Delete @:betriebsanweisungArt.name",
    teilen: "Share @:betriebsanweisungArt.name",
    bearbeiten: "Edit @:betriebsanweisungArt.name",
    bezeichnung: "Name",
  },
  tag: {
    name: "Tag | Tags",
    erstellen: "Create @:tag.name",
    archivieren: "Archive @:tag.name",
    loeschen: "Delete @:tag.name",
    teilen: "Share @:tag.name",
    bearbeiten: "Edit @:tag.name",
    bezeichnung: "Name",
  },
  hsatz: {
    name: "H-Phrase | H-Phrases",
    saetze: hsaetze,
  },
  psatz: {
    name: "P-Phrase | P-Phrases",
    saetze: psaetze,
  },
  freigegeben: {
    freigegebenDurch: "Approved by",
    text: 'The approver is the person who has set the status to "Approved"',
  },
  gefahrstoff: {
    name: "Hazardous Substance | Hazardous Substances", // TODO i18n
    erstellen: "Create @:gefahrstoff.name",
    archivieren: "Archive @:gefahrstoff.name",
    wiederherstellen: "Restore @:gefahrstoff.name",
    loeschen: "Delete @:gefahrstoff.name",
    teilen: "Share @:gefahrstoff.name",
    exportieren: "Export @:gefahrstoff.name",
    bezeichnung: "Name",
    kategorien: "Categories",
    verwendungszweck: "Purpose of use",
    lagerklasse: "Classification",
    zusammenlagerung: "Joint storage", // TODO i18n
    zusammenlagerungTabelle:
      "Joint storage from TRGS510 Technical Rules for Hazardous Substances",
    menge: "Quantities",
    signalwort: "Signal word", // TODO i18n
    anmerkungen: "Notes",
    anlagen: "Attachements",
    raum: "Storage location",
    standort: "Unit",
    status: "Status",
    mengeneinheit: "Unit of measure",
    sicherheitsdatenblatt: "Safety data sheet", // TODO i18n
    letzteSubstitutionspruefung: "Last Substitute Inspection", // TODO i18n
    pruefintervall: "Inspection interval",
    naechsteSubstitutionspruefung: "Next Substitute Inspection",
    substitutionErforderlich: "Substitution required",
    hSaetze: "H-Phrases",
    pSaetze: "P-Phrases",
    brandgefahr: "Fire Hazard",
    explosionsgefahr: "Explosion Hazard",
    einatemgefahr: "Inhalation Hazard",
    hautkontaktgefahr: "Skin contact Hazard",
    cmr: "CMR",
    informationen: {
      titel: "Hazardous Substance Information",
      titel2: "Hazards & Substitution Inspections",
      allgemein: "General Hazardous Substance Information",
      verbrauchsmengen: "Consumption quantities / Purpose of use",
      freigabe: "Approval",
      substitutionspruefung: "Substitute Inspection",
      hUndPSaetze: "H & P-Phrases",
      gefahren: "Hazards",
    },
    zusammenlagerungErlaubt: 'Joint storage allowed in "{0}"',
    zusammenlagerungEingeschraenkt:
      'Joint storage allowed with restrictions in "{0}"',
    separatlagerungErforderlich: 'Seperate storage required in "{0}"',
    lagerklassen,
  },
  gefaehrdungsbeurteilung: {
    name: "Risk Assessment | Risk Assessments",
    erstellen: "Create @:gefaehrdungsbeurteilung.name",
    archivieren: "Archive @:gefaehrdungsbeurteilung.name",
    wiederherstellen: "Restore @:gefaehrdungsbeurteilung.name",
    loeschen: "Delete @:gefaehrdungsbeurteilung.name",
    teilen: "Share @:gefaehrdungsbeurteilung.name",
    exportieren: "Export @:gefaehrdungsbeurteilung.name",
    bezeichnung: "Name",
    kategorien: "Categories",
    art: "Type",
    raum: "Room",
    letztesReview: "Last Review",
    reviewIntervall: "Review in",
    naechstesReview: "Next Review",
    anmerkungen: "Notes",
    dokumente: "Documents",
    standort: "Unit",
    status: "Status",
    informationen: {
      titel: "Risk Assessment Information",
      allgemein: "General Risk Assessment Information",
      pruefinformationen: "Review Information",
      freigabe: "Approval",
    },
  },
  betriebsanweisung: {
    name: "Operation Instruction | Operation Instructions",
    erstellen: "Create @:betriebsanweisung.name",
    archivieren: "Archive @:betriebsanweisung.name",
    wiederherstellen: "Restore @:betriebsanweisung.name",
    loeschen: "Delete @:betriebsanweisung.name",
    teilen: "Share @:betriebsanweisung.name",
    exportieren: "Export @:betriebsanweisung.name",
    bezeichnung: "Name",
    kategorien: "Categories",
    art: "Type",
    raum: "Room",
    letztesReview: "Last Review",
    reviewIntervall: "Review in",
    naechstesReview: "Next Review",
    anmerkungen: "Notes",
    dokumente: "Documents",
    standort: "Unit",
    status: "Status",
    informationen: {
      titel: "Operation Instruction Information",
      allgemein: "General Operation Instruction Information",
      pruefinformationen: "Review Information",
      freigabe: "Approval",
    },
  },
  bedienungsanleitung: {
    name: "Instruction Manual | Instruction Manuals",
    erstellen: "Create @:bedienungsanleitung.name",
    archivieren: "Archive @:bedienungsanleitung.name",
    wiederherstellen: "Restore @:bedienungsanleitung.name",
    loeschen: "Delete @:bedienungsanleitung.name",
    teilen: "Share @:bedienungsanleitung.name",
    exportieren: "Export @:bedienungsanleitung.name",
    bezeichnung: "Name",
    dokumente: "Documents",
    standort: "Unit",
    informationen: {
      titel: "Informationen",
      allgemein: "General Instruction Manual Information",
    },
  },
  pruefbericht: {
    name: "Inspection Report | Inspection Reports",
    erstellen: "Create @:pruefbericht.name",
    archivieren: "Archive @:pruefbericht.name",
    wiederherstellen: "Restore @:pruefbericht.name",
    loeschen: "Delete @:pruefbericht.name",
    teilen: "Share @:pruefbericht.name",
    exportieren: "Export @:pruefbericht.name",
    bezeichnung: "Name",
    dokumente: "Documents",
    standort: "Unit",
    informationen: {
      titel: "Informationen",
      allgemein: "General Inspection Report Information",
    },
  },
  sicherheitsdatenblatt: {
    name: "Safety Data Sheet | Safety Data Sheets",
    erstellen: "Create @:sicherheitsdatenblatt.name",
    archivieren: "Archive @:sicherheitsdatenblatt.name",
    wiederherstellen: "Restore @:sicherheitsdatenblatt.name",
    loeschen: "Delete @:sicherheitsdatenblatt.name",
    teilen: "Share @:sicherheitsdatenblatt.name",
    exportieren: "Export @:sicherheitsdatenblatt.name",
    bezeichnung: "Name",
    dokumente: "Documents",
    standort: "Unit",
    informationen: {
      titel: "Informationen",
      allgemein: "General Safety Data Sheet Information",
    },
  },
  dokument: {
    name: "Document | Documents",
    erstellen: "Create @:dokument.name",
    archivieren: "Archive @:dokument.name",
    wiederherstellen: "Restore @:dokument.name",
    loeschen: "Delete @:dokument.name",
    teilen: "Share @:dokument.name",
    exportieren: "Export @:dokument.name",
    status: "Link Status",
    tags: "Tags",
    updatedAt: "Last updated",
    anmerkungen: "Notes",
    herunterladen: "Download",
    standort: "Unit",
    dateigroesse: "Filesize",
    informationen: {
      titel: "Document Information",
      allgemein: "General Document Information",
    },
  },
  protokoll: {
    name: "Protocol | Protocols",
    erstellen: "Create @:protokoll.name",
    archivieren: "Archive @:protokoll.name",
    wiederherstellen: "Restore @:protokoll.name",
    loeschen: "Delete @:protokoll.name",
    teilen: "Share @:protokoll.name",
    exportieren: "Export @:protokoll.name",
    status: "Link Status",
    dateiname: "Filename",
    tags: "Tags",
    updatedAt: "Last updated",
    anmerkungen: "Notes",
    herunterladen: "Download",
    standort: "Unit",
    dateigroesse: "Filesize",
    informationen: {
      titel: "Protocol Information",
      allgemein: "General Protocol Information",
    },
  },
  moduleHeader: {
    treffer: "no results | result | {count} results",
    mehr: "More",
    suche: "Search",
    gesetzesgrundlage: "Legal Basis",
    symbolbibliothek: "Symbolic References",
    archivieren: {
      text: "Archive {count} {0}? Archived {1} can be restored.",
    },
    loeschen: {
      text: "Permanently delete {count} {0}?",
    },
  },
  detailsHeader: {
    archivieren: {
      text: "Archive {0}? Archived {1} can be restored.",
    },
    loeschen: {
      text: "Permanently delete {0}?",
    },
  },
  relationManager: {
    auswaehlen: "Select {count}",
    ausgewaehlt: "Only show selected",
    undSpeichern: "and save",
    nichtGefunden: "No {0} found",
    bezeichnung: "Name",
    aktionen: "Actions",
  },
  saetzeManager: {
    titel: "Phrases",
    auswaehlen: "Select {count}",
    ausgewaehlt: "Only show selected phrases",
    nichtGefunden: "No phrases found",
  },
  standortManager: {
    ladeStandorte: "Loading available Units...",
    standortAuswaehlen: "Select Unit",
    standortAuswaehlenText:
      "Please select the Unit where the object should be created",
    standortRechte: "You have on write permissions in this Unit",
    standortNichtErstellt: "{0} can not be created here",
    aktiveStandorte:
      "You don't see your Unit? Select your active units on the top right in the unit manager so they are available here",
  },
  erstellenManager: {
    informationen: "Information",
    erstellen: "Create {0}",
    erstellenAmStandort: "Create {0} in Unit {1}",
    weitereAnlagen: "Add more attachements",
    nameBenoetigt: "Name is required",
    wirdErstellt: "{0} is being created...",
    wurdeErstellt: "{0} has been created",
    nichtHinzugefuegt: "{0} could not be added",
  },
  exportManager: {
    exportText:
      "Select your units where you want to export your {0} into a CSV file. Active filters will be considered.",
    exportieren: "Export",
  },
  dokumenteManager: {
    anlageDokumente: "The module {0} contains {1} documents",
  },
  linkTab: {
    dateien: "Drag and drop files here to automatically create {0}",
    entfernen: "Unlink {0} from {1}",
    keineVorhanden: "No {0} available",
    keineVerknuepft: "No {0} linked",
  },
  nachricht: {
    standortAuswaehlen: "Please select a unit before continuing",
    woStandortAuswaehlen: "Units can be selected in the top right",
    keineAusgewaehlt: "No {0} selected",
    archiviert: "{0} archived",
    archiviertFehler: "{0} could not be archived",
    archiviertSchreibrechte:
      "Due to missing write permissions not all {0} could be archived",
    geloescht: "{0} deleted",
    geloeschtFehler: "{0} could not be deleted",
    geloeschtSchreibrechte:
      "Due to missing write permissions not all {0} could be deleted",
    csvGeneriert: "Generating CSV file...",
    csvFehler: "CSV export failed",
    aktualisiert: "{0} updated",
    aktualisiertFehler: "Updating {0} failed",
    wiederhergestellt: "{0} restored",
    wiederhergestelltFehler: "{0} could not be restored",
    wiederhergestelltSchreibrechte:
      "Due to missing write permissions not all {0} could be restored",
    erstellt: "{0} created",
    erstelltFehler: "{0} could not be created",
    ungespeichert: "Unsaved changes",
    ungespeichertWarnung:
      "You have unsaved changes, do you want to save them before leaving this window?",
    ungespeichertSpeichern: "Save changes",
    fensterSchliessen: "Close window",
    hochgeladen: "Dokumente wurden hochgeladen",
    nichtHochgeladen: "Documents could not be uploaded",
    alleFelder: "All fields are required",
  },
  willkommen: {
    titel1: "Welcome to {company},",
    titel2: "your modern work safety software!",
    text1:
      "For the complete setup of {company} you need an invitation to a unit.",
    text2:
      "Send your {company} contact person in your company your E-Mail address so your account can be activated.",
    profilVerwalten: "Manaage profile",
  },
  createNew: "Create",
  profile: "Profile",
  website: "Website",
  logout: "Logout",
  standort: {
    name: "Unit | Units",
    select: "Select @:standort.name",
    appswitcher: {
      header: "Your @:standort.name",
      description: "Select the units you want to work with.",
      companyDescription: "Group your units with companies.",
      search: "Search",
      createCompany: "Create Company",
      editCompany: "Edit Company",
      deleteCompany: "Delete Company",
      companyName: "Company name",
      dragAndDrop: "Add units via drag & drop",
      noUnits: "No units found!",
      createUnit: "Create Unit",
      auswaehlen: "Select all units",
    },
    notifications: {
      errorLoadingCompanies: "Error loading companies!",
      successfulCreateCompany: "Successfully created company!",
      successfulUpdateCompany: "Successfully updated company!",
      successfulDeleteCompany: "Company deleted!",
      errorCreation: "Creation failed!",
      errorUpdate: "Editing failed!",
      errorDelete: "Deleting failed!",
      deleteCompany: "Delete Company",
      deleteCompanyConfirmation:
        "Do you want to delete this company? The units won't be deleted.",
    },
  },
  unit: {
    bezeichnung: "Name",
    berufsgenossenschaft: "Association",
    ort: "Location",
    plz: "Postal Code",
    strasse: "Street",
    hausnummer: "House number",
    anmerkungen: "Notes",
    numberOfUnits: "Number of Units",
    unitsRemaining: "Units remaining",
  },
  paymentAccount: {
    name: "Payment Account | Payment Accounts",
    yourPaymentAccounts: "Your Payment Accounts ({0})",
    edit: "Edit Payment Account",
    bezeichnung: "Name of the Payment Account",
    invoiceMail: "Invoice Email",
    ort: "Location",
    plz: "Postal Code",
    strasse: "Street",
    hausnummer: "House Number",
  },
  verwaltung: {
    name: "Management",
    standorterstellen: {
      name: "Create a unit",
      description: "Create additional units",
      selectPaymentAccount: "Select payment account",
      noPaymentAccount: "No payment account available",
      chooseAccount: "Use payment account",
    },
    standort: {
      name: "Edit a unit",
      description: "Manage unit information",
      treffer: "no units found | 1 unit found | {count} units found",
      choose: "Choose a unit to edit its information",
    },
    nutzer: {
      name: "Add a user to a unit",
      description: "Invite more users to your units",
      firstStep: "1. Enter the Email adress of the user you want to invite",
      secondStep: "2. Now select the units where the users should be added.",
      thirdStep: "3. Finally select the permissions for the users",
      invite: "Invite Users",
      viewInvites: "View invites",
    },
    reports: {
      name: "Notification settings",
      description: "Manage your notification preferences",
      detailedDescription:
        "Choose for which Unit you would like to receive safety reports via Email",
    },
    profildaten: {
      name: "Profile",
      description: "Change your profile information",
      vorname: "First Name",
      nachname: "Name",
      email: "Email",
      telefonnummer: "Phone Number",
      mobilnummer: "Mobile Number",
      language: "Language",
    },
    passwortaendern: {
      name: "Change Password",
      description: "Change your {company} password",
      requirements: "The password needs to have",
      atLeast8Chars: "at least 8 characters",
      atLeast1Letter: "at least 1 letter",
      atLeast1Number: "at least 1 number",
      password: "Password",
      repeat: "Repeat @:verwaltung.passwortaendern.password",
    },
    zahlungen: {
      name: "Payment Information",
      description: "Mange payment related information e.g. invoice adresses",
      needMore: "Want more Units?",
      needMoreDescription:
        "Just drop an EMail to info{'@'}{company}.de to increase your available units.",
    },
    berechtigungen: {
      name: "Permissions",
      description: "Give users permissions inside your system",
      nutzer: "User",
      you: "(You)",
      chooseUnit:
        "Select a user and a unit where you want to update permissions",
      noReadPermissions: "No read permissions",
      noWritePermissions: "No write permissions",
      editPermissions: "Edit permissions for this user in the unit",
      module: "Module",
      read: "Read",
      write: "Write",
    },
  },
};
