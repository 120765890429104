<template>
  <q-dialog
    ref="dialog"
    transition-show="slide-up"
    transition-hide="slide-down"
    no-refocus
    @hide="hide"
  >
    <q-card class="q-dialog-plugin" style="min-width: 1000px">
      <q-card-section>
        <q-card-section class="row items-center q-pa-none q-mb-sm">
          <div class="q-pa-none text-grey-10 text-h5">
            {{ $t(`${model.ITEM_KEY}.name`, 2) }}
          </div>
          <q-space />
          <q-btn flat round @click="hide">
            <q-icon name="mdi-close" />
          </q-btn>
        </q-card-section>
        <q-separator />
      </q-card-section>
      <q-card-section>
        <q-table
          flat
          row-key="id"
          style="height: 400px"
          hide-bottom
          virtual-scroll
          :pagination="{ rowsPerPage: 0 }"
          :selection="selection"
          :columns="columns"
          v-model:selected="selected"
          :rows="data"
          :loading="loading"
          dense
          :filter="search"
          :no-data-label="
            $t(`relationManager.nichtGefunden`, [
              $t(`${model.ITEM_KEY}.name`, 2),
            ])
          "
        >
          <template #top>
            <div class="full-width row items-center q-mb-md">
              <q-input
                dense
                outlined
                v-model="search"
                :placeholder="$t('input.suche')"
                style="min-width: 250px"
              >
                <template #prepend>
                  <q-icon name="mdi-magnify" />
                </template>
              </q-input>
              <q-space />
              <q-checkbox
                v-model="filter"
                dense
                color="primary"
                :label="$t(`relationManager.ausgewaehlt`)"
              />
              <q-space />
              <q-btn outline color="primary" no-caps @click="createItem">
                <q-icon name="mdi-plus" left />
                {{ $t(`${model.ITEM_KEY}.erstellen`) }}
              </q-btn>
            </div>
          </template>
          <template #body="props">
            <q-tr :props="props" @click="props.selected = !props.selected">
              <q-td key="selected">
                <q-checkbox
                  color="blue"
                  v-model="props.selected"
                  dense
                  :checked-icon="
                    selection === 'single' ? 'mdi-radiobox-marked' : ''
                  "
                  :unchecked-icon="
                    selection === 'single' ? 'mdi-radiobox-blank' : ''
                  "
                />
              </q-td>
              <q-td :props="props" key="name">{{ props.row.name }}</q-td>
              <q-td :props="props" key="edit">
                <div class="row items-center justify-end q-gutter-x-sm">
                  <q-chip
                    dense
                    outline
                    color="grey-7"
                    clickable
                    @click.stop="editItem(props.row)"
                  >
                    <q-icon name="mdi-pencil" left />{{
                      $t("input.bearbeiten")
                    }}
                  </q-chip>
                  <q-chip
                    dense
                    outline
                    color="red"
                    clickable
                    @click.stop="deleteItem(props.row.id)"
                  >
                    <q-icon name="mdi-delete" left />{{ $t("input.loeschen") }}
                  </q-chip>
                </div>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
      <q-card-section
        class="row items-center q-gutter-x-sm justify-center q-mt-md"
      >
        <q-btn outline no-caps color="grey-7" @click="hide">{{
          $t("input.abbrechen")
        }}</q-btn>
        <q-btn color="primary" no-caps @click="selectItemsAndSave">
          {{ $t("relationManager.auswaehlen", selected.length) }}
          {{ save ? $t("relationManager.undSpeichern") : "" }}</q-btn
        >
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";
import { QDialog, Notify, Dialog, QTableProps } from "quasar";
import { confirmDelete, missingWritePermission } from "../../utils";
import i18n from "src/i18n";

const columns: QTableProps["columns"] = [
  {
    name: "name",
    label: i18n.global.t("relationManager.bezeichnung"),
    field: "name",
    align: "left",
    sortable: true,
  },
  {
    name: "edit",
    label: i18n.global.t("relationManager.aktionen"),
    field: "edit",
    align: "right",
    sortable: false,
  },
];

const props = withDefaults(
  defineProps<{
    api: any;
    model: any;
    unitId: string;
    preSelected?: string[];
    selection?: QTableProps["selection"];
    hasWritePermission?: boolean;
    save?: boolean;
  }>(),
  {
    preSelected: () => [],
    selection: "multiple",
    hasWritePermission: false,
    save: false,
  }
);
const emit = defineEmits(["ok"]);

const dialog = ref<QDialog | null>(null);

const hide = () => dialog.value!.hide();

const selected = ref<any[]>([]);
const result = ref<any[]>([]);
const search = ref<string>("");
const filter = ref<boolean>(false);
const loading = ref<boolean>(false);

const refetch = async () => {
  try {
    loading.value = true;

    const { result: _result } = await props.api.fetchAll({
      filter: `eq(unitId,"${props.unitId}")`,
      pagination: {
        order: {
          criteria: "id",
          direction: false,
        },
      },
    });

    result.value = _result;

    selected.value = props.preSelected.map((s: any) =>
      _result.find((k: any) => k.id === s.id)
    );
  } finally {
    loading.value = false;
  }
};

refetch();

const data = computed(() =>
  filter.value ? [...selected.value] : [...(result.value || [])]
);

const createItem = () => {
  Dialog.create({
    title: i18n.global.t(`${props.model.ITEM_KEY}.erstellen`),
    prompt: {
      model: "",
      label: i18n.global.t(`${props.model.ITEM_KEY}.bezeichnung`),
      isValid: (val: string) => !!val,
      outlined: true,
    },
    ok: {
      label: i18n.global.t(`input.erstellen`),
      color: "primary",
      noCaps: true,
    },
    cancel: {
      label: i18n.global.t(`input.abbrechen`),
      flat: true,
      noCaps: true,
    },
  }).onOk(async (name: string) => {
    const { result: item } = await props.api.create({
      object: {
        object: {
          unitId: props.unitId,
          name,
        },
      },
    });

    await refetch();

    if (props.selection === "multiple") {
      selected.value = [...selected.value, item];
    } else {
      selected.value = [item];
    }

    Notify.create({
      type: "ams-success",
      message: i18n.global.t("nachricht.erstellt", [
        i18n.global.t(`${props.model.ITEM_KEY}.name`),
      ]),
    });
  });
};

const editItem = (item: any) => {
  Dialog.create({
    title: i18n.global.t(`${props.model.ITEM_KEY}.bearbeiten`),
    prompt: {
      model: item.name,
      label: i18n.global.t(`${props.model.ITEM_KEY}.bezeichnung`),
      isValid: (val: string) => !!val,
      outlined: true,
    },
    ok: {
      label: i18n.global.t(`input.aktualisieren`),
      color: "primary",
      noCaps: true,
    },
    cancel: {
      label: i18n.global.t(`input.abbrechen`),
      flat: true,
      noCaps: true,
    },
  }).onOk(async (name: string) => {
    await props.api.update({
      id: item.id,
      object: {
        object: {
          name,
        },
      },
    });

    Notify.create({
      type: "ams-success",
      message: i18n.global.t("nachricht.aktualisiert", [
        i18n.global.t(`${props.model.ITEM_KEY}.name`),
      ]),
    });
  });
};

const deleteItem = (id: number) => {
  confirmDelete(
    i18n.global.t(`${props.model.ITEM_KEY}.loeschen`),
    i18n.global.t(`detailsHeader.loeschen.text`, [
      i18n.global.t(`${props.model.ITEM_KEY}.name`),
    ]),
    async () => {
      await props.api.delete({ id });

      await refetch();

      Notify.create({
        type: "ams-success",
        message: i18n.global.t("nachricht.geloescht", [
          i18n.global.t(`${props.model.ITEM_KEY}.name`),
        ]),
      });
    }
  );
};

const selectItemsAndSave = () => {
  if (props.hasWritePermission) {
    emit("ok", selected.value);
    hide();
  } else {
    missingWritePermission();
  }
};
</script>
