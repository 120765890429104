<template>
  <q-td :props="props" :key="colKey">
    <q-btn
      round
      outline
      :color="currentZusammenlagerung.color"
      dense
      size="12px"
      @click.stop
    >
      <q-icon :name="currentZusammenlagerung.icon" size="18px" />
      <q-tooltip
        v-if="!zusammenlagerungMenu"
        anchor="center right"
        self="center left"
        :offset="[10, 10]"
        >{{ $t(`status.${currentZusammenlagerung.val}`) }}</q-tooltip
      >
      <q-menu
        v-if="
          currentZusammenlagerung.label !== 'Raum oder Lagerklasse unbekannt'
        "
      >
        <div class="q-pa-md">
          <div class="row items-center no-wrap">
            <div class="text-subtitle1 text-bold">
              {{ separatlagerungPhrase }}
            </div>
            <q-space />
            <q-btn
              class="q-ml-lg"
              round
              flat
              dense
              size="12px"
              @click.stop="
                openURL(
                  'https://ams-public-assets.s3.eu-central-1.amazonaws.com/TRGS510.png'
                )
              "
            >
              <q-icon name="mdi-information-outline" size="24px" />
              <q-tooltip>
                {{ $t("gefahrstoff.zusammenlagerungTabelle") }}
              </q-tooltip>
            </q-btn>
          </div>
          <q-separator class="q-my-sm" />
          <q-list separator dense style="min-width: 400px">
            <q-item v-for="item in separatlagerungen" :key="item.id" dense>
              <q-item-section avatar>
                <q-icon name="mdi-flask" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ item.name }}</q-item-label>
                <q-item-label caption
                  >{{ $t("gefahrstoff.lagerklasse") }}:
                  {{ item.lagerklasse }}</q-item-label
                >
              </q-item-section>
              <q-item-section side>
                <q-btn flat round size="12px" @click="openNewTab(item.id)">
                  <q-icon name="mdi-open-in-new" size="24px" />
                  <q-tooltip>{{
                    $t(`input.imTabOeffnen`, [$t(`gefahrstoff.name`)])
                  }}</q-tooltip>
                </q-btn>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </q-menu>
    </q-btn>
  </q-td>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import {
  gefahrstoffeModel as model,
  gefahrstoffeZusammenlagerung,
  GefahrstoffZusammenlagerung,
} from "../../../data";
import { openURL } from "quasar";
import { hashids } from "../../../services/hashids.service";
import i18n from "src/i18n";

export default defineComponent({
  props: {
    props: {
      type: Object,
      required: true,
    },
    colKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const zusammenlagerungMenu = ref<boolean>(false);

    const currentZusammenlagerung = computed(
      () =>
        gefahrstoffeZusammenlagerung[
          props.props.row.zusammenlagerung as GefahrstoffZusammenlagerung
        ]
    );

    const separatlagerungen = computed(() => {
      if (props.props.row.separatlagerungErforderlich.length) {
        return props.props.row.separatlagerungErforderlich;
      } else if (props.props.row.zusammenlagerungEingeschraenkt.length) {
        return props.props.row.zusammenlagerungEingeschraenkt;
      } else {
        return [];
      }
    });

    const separatlagerungPhrase = computed(() => {
      if (props.props.row.separatlagerungErforderlich?.length) {
        return i18n.global.t(`gefahrstoff.separatlagerungErforderlich`, [
          props.props.row.raum?.name,
        ]);
      } else if (props.props.row.zusammenlagerungEingeschraenkt?.length) {
        return i18n.global.t(`gefahrstoff.zusammenlagerungEingeschraenkt`, [
          props.props.row.raum?.name,
        ]);
      } else {
        return i18n.global.t(`gefahrstoff.zusammenlagerungErlaubt`, [
          props.props.row.raum?.name,
        ]);
      }
    });

    const openNewTab = (id: string) => {
      window.open(
        `${window.location.protocol}//${window.location.host}${
          model.ROUTE
        }/${hashids.encode(id)}`,
        "_blank"
      );
    };

    return {
      zusammenlagerungMenu,
      currentZusammenlagerung,
      separatlagerungen,
      separatlagerungPhrase,
      openURL,
      openNewTab,
    };
  },
});
</script>
