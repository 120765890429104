<template>
  <q-footer class="bg-white text-black shadow-8">
    <div class="row items-center" style="height: 36px">
      <span class="q-ml-md">&copy; {{ new Date().getFullYear() }}</span>
      <q-icon class="q-mx-sm" size="20px">
        <img :src="branding.logo" alt="logo">
        <svg
          width="421"
          height="364"
          viewBox="0 0 421 364"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M210 104L269.999 208.036H150L210 104Z" fill="#1EA9ED" />
          <path d="M210 312.036L150 208H269.999L210 312.036Z" fill="#3ABCF3" />
          <path
            d="M270 208.036L210 104L329.999 104L270 208.036Z"
            fill="#2DACEC"
          />
          <path
            d="M150 208.036L90 104L209.999 104L150 208.036Z"
            fill="#3FB5ED"
          />
          <path d="M150 0L90 104H330L270 0H150Z" fill="#63BBE8" />
          <path
            d="M90 311.885L210.067 311.846L90.0666 104L30 207.962L90 311.885Z"
            fill="#8BD8F1"
          />
          <path
            d="M390.067 207.962L330 104L210 311.846L330.067 311.885L390.067 207.962Z"
            fill="#5EC0EE"
          />
        </svg>
      </q-icon>
      <span>{{ branding.company }}</span>
      <q-space />
    </div>
  </q-footer>
</template>

<script lang="ts" setup>
import { useBrandingStore } from '../../store/branding'
const branding = useBrandingStore()
</script>
