import { encodeFilter } from "../utils/methods.util";
import { ref, computed, watch } from "vue";
import { Pagination } from "src/types/graphql.types";

export const useVirtualScroll = (
  api: any,
  pageSize: number,
  unitId?: string
) => {
  const refetching = ref<boolean>(false);

  const queryPagination = ref<Pagination>({
    amount: pageSize,
    order: {
      direction: false,
      criteria: "id",
    },
  });

  const filter = ref<any>({
    val: {},
    meta: {},
  });

  if (unitId) {
    filter.value = {
      ...filter.value,
      val: {
        unitId: {
          eq: {
            unitId,
          },
        },
      },
    };
  }

  const data = ref<any[]>([]);
  const rowsNumber = ref<number>(0);
  const loading = ref<boolean>(false);

  const refetch = async (filterVariables?: any) => {
    try {
      loading.value = true;
      const { result, count } = await api.fetchAll(
        filterVariables || {
          pagination: {
            ...queryPagination.value,
          },
          filter: encodeFilter(filter.value.val) || null,
        }
      );

      rowsNumber.value = count;
      return [...result];
    } catch {
      data.value = [];
    } finally {
      loading.value = false;
    }

    return [];
  };

  const getInitial = async () => {
    data.value = await refetch();
  };

  getInitial();

  const currentPage = ref<number>(0);
  const lastPage = computed(() => Math.floor(rowsNumber.value / pageSize));

  const pagination = ref<any>({
    rowsNumber: rowsNumber.value,
    rowsPerPage: 0,
    sortBy: "id",
    descending: false,
  });

  watch(
    () => filter.value,
    async () => {
      refetching.value = true;
      currentPage.value = 0;

      let filterVariables = {
        pagination: {
          ...queryPagination.value,
          amount: pageSize,
          offset: currentPage.value * pageSize,
        } as Pagination,
        filter: encodeFilter(filter.value.val) || null,
        archiviert: undefined,
      };

      if (filter.value.val.archiviert) {
        filterVariables.archiviert = filter.value.val.archiviert.eq.archived;
      }
      data.value = await refetch(filterVariables);

      refetching.value = false;
    },
    {
      deep: true,
    }
  );

  return {
    data,
    loading,
    rowsNumber,
    pagination,
    filter,
    fetchMore: async (details: any) => {
      const lastIndex = data.value.length - 1;

      if (
        !refetching.value &&
        currentPage.value < lastPage.value &&
        details.to === lastIndex
      ) {
        refetching.value = true;
        currentPage.value++;

        let filterVariables = {
          pagination: {
            ...queryPagination.value,
            amount: pageSize,
            offset: currentPage.value * pageSize,
          } as Pagination,
          filter: encodeFilter(filter.value.val) || null,
          archiviert: undefined,
        };

        if (filter.value.val.archiviert) {
          filterVariables.archiviert = filter.value.val.archiviert.eq.archived;
        }

        const items = await refetch(filterVariables);
        data.value = [...data.value, ...items];

        refetching.value = false;
      }
    },
    updateData: (item: any) => {
      data.value = data.value.map((d: any) => (item.id === d.id ? item : d));
    },
    refetchData: async () => {
      refetching.value = true;

      const items = await refetch();
      data.value = [...items];

      refetching.value = false;
    },
    sort: async (p: any) => {
      const descending = pagination.value.descending;

      pagination.value = {
        ...pagination.value,
        sortBy: p.sortBy,
        descending: !descending,
      };

      refetching.value = true;

      const items = await refetch({
        pagination: {
          ...queryPagination.value,
          order: {
            criteria: pagination.value.sortBy || "id",
            direction: pagination.value.descending,
          },
        } as Pagination,
      });
      data.value = [...items];

      refetching.value = false;
    },
  };
};
