import {
  CountGefaehrdungsbeurteilungenQueryVariables,
  GefaehrdungsbeurteilungenOverviewQueryVariables,
  CreateGefaehrdungsbeurteilungMutationVariables,
  DeleteGefaehrdungsbeurteilungMutationVariables,
  UpdateGefaehrdungsbeurteilungMutationVariables,
  ExportGefaehrdungsbeurteilungenMutationVariables,
} from "../types/graphql.types";
import client from "./index";
import { hashids } from "./hashids.service";

export const useGefaehrdungsbeurteilung = () => {
  return {
    fetchAll: async (
      variables: GefaehrdungsbeurteilungenOverviewQueryVariables
    ) => {
      const { findGefaehrdungsbeurteilungen, countGefaehrdungsbeurteilungen } =
        await client.GefaehrdungsbeurteilungenOverview(variables);

      return {
        result: findGefaehrdungsbeurteilungen,
        count: countGefaehrdungsbeurteilungen,
      };
    },
    count: async (variables: CountGefaehrdungsbeurteilungenQueryVariables) => {
      const { countGefaehrdungsbeurteilungen } =
        await client.CountGefaehrdungsbeurteilungen(variables);

      return {
        result: countGefaehrdungsbeurteilungen,
      };
    },
    fetchById: async (id: string | number) => {
      if (typeof id === "string") {
        const [parsed] = hashids.decode(id) as number[];
        id = parsed;
      }

      const { findGefaehrdungsbeurteilungById } =
        await client.GefaehrdungsbeurteilungById({
          id,
        });

      return {
        result: findGefaehrdungsbeurteilungById,
      };
    },
    create: async (
      variables: CreateGefaehrdungsbeurteilungMutationVariables
    ) => {
      const { createGefaehrdungsbeurteilung } =
        await client.CreateGefaehrdungsbeurteilung(variables);

      return { result: createGefaehrdungsbeurteilung };
    },
    update: async (
      variables: UpdateGefaehrdungsbeurteilungMutationVariables
    ) => {
      const { updateGefaehrdungsbeurteilung } =
        await client.UpdateGefaehrdungsbeurteilung(variables);

      return { result: updateGefaehrdungsbeurteilung };
    },
    delete: async (
      variables: DeleteGefaehrdungsbeurteilungMutationVariables
    ) => {
      const { deleteGefaehrdungsbeurteilung } =
        await client.DeleteGefaehrdungsbeurteilung(variables);

      return { result: deleteGefaehrdungsbeurteilung };
    },
    export: async (variables: ExportGefaehrdungsbeurteilungenMutationVariables) => {
      const { exportGefaehrdungsbeurteilungen } = await client.ExportGefaehrdungsbeurteilungen(variables)
      return { result: exportGefaehrdungsbeurteilungen }
    }
  };
};
