<template>
  <div>
    <q-stepper
      v-model="step"
      flat
      ref="stepper"
      color="primary"
      animated
      keep-alive
    >
      <q-step
        :name="1"
        bordered
        :title="$t('verwaltung.standorterstellen.selectPaymentAccount')"
        icon="mdi-pencil"
        :done="step > 1"
        done-color="green"
      >
        <q-table
          flat
          row-key="id"
          selection="single"
          :columns="columns"
          v-model:selected="selected"
          :rows="accounts"
          :loading="loading"
          :no-data-label="$t('verwaltung.standorterstellen.noPaymentAccount')"
        >
          <template #body="props">
            <q-tr :props="props">
              <q-td key="selected">
                <q-checkbox
                  class="q-ml-sm"
                  color="blue"
                  v-model="props.selected"
                  dense
                />
              </q-td>
              <q-td :props="props" key="name">
                <q-item-label>{{ props.row.name }}</q-item-label>
                <q-item-label caption>
                  {{ props.row.invoiceEmail }}
                </q-item-label>
              </q-td>
              <q-td :props="props" key="quantity">
                {{ props.row.quantity }}
              </q-td>
              <q-td
                :props="props"
                key="free"
                :class="`text-bold ${
                  props.row.quantity - props.row.units.length > 0
                    ? 'text-green'
                    : 'text-red'
                }`"
                >{{ props.row.quantity - props.row.units.length }}</q-td
              >
            </q-tr>
          </template>
        </q-table>
        <div class="row items-center justify-center">
          <q-btn
            :disable="!selected.length"
            color="primary"
            no-caps
            @click="chooseAccount"
          >
            {{ $t("verwaltung.standorterstellen.chooseAccount") }}
          </q-btn>
        </div>
      </q-step>
      <q-step
        :name="2"
        bordered
        :title="$t('standort.appswitcher.createUnit')"
        icon="mdi-credit-card-multiple"
        :done="step > 2"
        done-color="green"
      >
        <div>
          <div class="text-grey-9 text-subtitle1 text-bold">
            {{ $t("standort.appswitcher.createUnit") }}
          </div>
          <div>
            <div class="row q-gutter-x-md q-mt-md">
              <ams-input
                class="col"
                :label="$t('unit.bezeichnung')"
                v-model="unit.name"
                icon="mdi-factory"
              />
              <ams-input
                class="col"
                :label="$t('unit.berufsgenossenschaft')"
                v-model="unit.berufsgenossenschaft"
                icon="mdi-account-group"
              />
            </div>
            <div class="row q-gutter-x-md q-mt-lg">
              <ams-input
                class="col"
                :label="$t('unit.ort')"
                v-model="unit.ort"
                icon="mdi-map-marker"
              />
              <ams-input
                class="col"
                :label="$t('unit.plz')"
                v-model="unit.plz"
                icon="mdi-mailbox-open-outline"
              />
            </div>
            <div class="row q-gutter-x-md q-mt-md">
              <ams-input
                class="col"
                :label="$t('unit.strasse')"
                v-model="unit.strasse"
                icon="mdi-truck-outline"
              />
              <ams-input
                class="col"
                :label="$t('unit.hausnummer')"
                v-model="unit.hausnummer"
                icon="mdi-counter"
              />
            </div>
            <div class="q-mt-lg">
              <ams-input
                :label="$t('unit.anmerkungen')"
                v-model="unit.anmerkungen"
                icon="mdi-square-edit-outline"
                type="textarea"
              />
            </div>
          </div>
          <div class="row items-center justify-center q-gutter-x-sm q-mt-xl">
            <q-btn
              color="grey-7"
              outline
              no-caps
              @click="stepper!.previous()"
              >{{ $t("input.zurueck") }}</q-btn
            >
            <q-btn
              color="primary"
              no-caps
              :loading="creating"
              @click="createUnit"
            >
              {{ $t("standort.appswitcher.createUnit") }}
            </q-btn>
          </div>
        </div>
      </q-step>
    </q-stepper>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { QStepper, Notify, QTableProps } from "quasar";
import AmsInput from "../../components/forms/AmsInput.vue";
import client from "../../services";
import { GetPaymentAccountsQuery, Unit } from "src/types/graphql.types";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const columns: QTableProps["columns"] = [
  {
    name: "name",
    label: t("unit.bezeichnung"),
    field: "name",
    align: "left",
    sortable: true,
  },
  {
    name: "quantity",
    label: t("unit.numberOfUnits"),
    field: "quantity",
    align: "left",
    sortable: true,
  },
  {
    name: "free",
    label: t("unit.unitsRemaining"),
    field: "quantity",
    align: "left",
    sortable: true,
  },
];

const stepper = ref<QStepper | null>(null);
const step = ref(1);
const accounts = ref([] as GetPaymentAccountsQuery["paymentAccounts"]);
const selected = ref([] as GetPaymentAccountsQuery["paymentAccounts"]);
const unit = ref({} as Unit);
const loading = ref(false);
const creating = ref(false);

const getAccounts = async () => {
  try {
    loading.value = true;

    const { paymentAccounts } = await client.GetPaymentAccounts();
    accounts.value = paymentAccounts;
  } finally {
    loading.value = false;
  }
};

getAccounts();

const chooseAccount = () => {
  const account = selected.value[0];

  if (!account.activated) {
    Notify.create({
      type: "ams-warning",
      message: "Rechnungsaccount nicht aktiv!",
      timeout: 6000,
    });
    return;
  }

  if (account.quantity - account.units.length <= 0) {
    Notify.create({
      type: "ams-warning",
      message: "Kontingent ist aufgebraucht!",
      caption:
        "Erhöhen Sie das Kontingent um weitere Standorte mit dieser Rechnungsadresse zu erstellen",
      timeout: 6000,
    });
    return;
  }

  stepper.value!.next();
};

const createUnit = async () => {
  if (!unit.value.name) {
    Notify.create({
      type: "ams-warning",
      message: "Standortname wirh benötigt!",
    });
    return;
  }

  try {
    creating.value = true;

    await client.CreateUnit({
      createUnit: {
        ...unit.value,
        paymentAccountId: selected.value[0].id,
      },
    });

    selected.value = [];
    stepper.value!.previous();

    Notify.create({
      type: "ams-success",
      message: "Standort erstellt!",
      caption: 'Wählen Sie den Standort in "Standorte wechseln" aus',
    });
  } catch {
    Notify.create({
      type: "ams-error",
      message: "Standort konnte nicht erstellt werden!",
    });
  } finally {
    creating.value = false;
  }
};
</script>
